import { Text, Box, Heading } from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { StandardVersions } from './standard/StandardVersions';
import { MatrixVersions } from './matrix/MatrixVersions';
import { useTranslation } from 'react-i18next';

interface Props {
  variantId: number;
  articleNodeGroup: ArticleNodeGroup;
}

export function Versions({ variantId, articleNodeGroup }: Props) {
  const { t } = useTranslation();

  return (
    <BodyOverride>
      <Heading>{t('general.versions')}</Heading>
      <Text>{t('compositionGroup.version.desc')}</Text>
      <Box pb={32} pt={8}>
        {!nodeGroupIsPopulated(articleNodeGroup) ? (
          <StandardVersions variantId={variantId} />
        ) : (
          <MatrixVersions
            variantId={variantId}
            articleNodeGroup={articleNodeGroup}
          />
        )}
      </Box>
    </BodyOverride>
  );
}
