import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  compositionGroupMatrixApi,
  CompositionOptionsCell,
} from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { WarningComponent } from '@texas/components/shared/WarningComponent';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compositionVersionEvents } from '../events';
import { CompositionGroupView } from '../../compositions/shared/group/CompositionGroupView';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { CompositionGroupTitle } from '@texas/components/shared/compositionGroup/CompositionGroupTitle';
import { SpinnerWithLabel } from '@texas/components/shared/SpinnerWithLabel';

export function ControlledOptions({
  cells,
  supplierId,
  totalCells,
  branchId,
  variantId,
}: {
  cells?: CompositionOptionsCell[];
  supplierId?: number;
  totalCells: number;
  branchId: number;
  variantId: number;
}) {
  const { t } = useTranslation();
  const { value, isOpen, onClose, onOpen } = useValueDisclosure<number>();
  const { data, refetch, loading } = useApiResource(
    compositionGroupMatrixApi.getOptions,
  );

  const { request: patchVersionsRequest, loading: isPatching } = useApiRequest(
    compositionGroupMatrixApi.patchVersions,
  );

  useEffect(() => {
    refetch(variantId);
  }, [refetch, variantId]);

  const create = async (controllerId: number) => {
    if (!supplierId) return;
    await request(
      patchVersionsRequest,
      [controllerId, branchId, supplierId],
      () => {
        compositionVersionEvents.onVersionsPatched.dispatch();
      },
    );
  };

  return (
    <Box>
      <Text variant="sub" pb={2}>
        {t('general.options')}
      </Text>
      {loading && (
        <SpinnerWithLabel label={t('compositionGroup.option.loading')} />
      )}
      <Flex gap={3}>
        {data?.map((x) => {
          const usedCells = getUsedOptionsCells(x.id, cells);
          return (
            <WarningComponent
              warning={
                x.isReady && supplierId
                  ? usedCells < totalCells
                    ? t('compositionGroup.version.unusedCells')
                    : undefined
                  : undefined
              }
              key={x.id}
            >
              <Menu>
                <MenuButton
                  as={Button}
                  isLoading={isPatching}
                  rightIcon={<Icons.ChevronDown />}
                  h="auto"
                  borderRadius="md"
                  textAlign="start"
                  isDisabled={!x.isReady}
                >
                  <Flex p={2} flexDir="column" gap={1}>
                    <CompositionGroupTitle
                      version={x.version}
                      note={x.note}
                      type="option"
                      fontSize="md"
                    />

                    {!x.isReady ? (
                      <Text
                        fontSize="sm"
                        fontWeight="normal"
                        color="yellow.400"
                        _light={{ color: 'yellow.500' }}
                      >
                        {t('compositionGroup.option.notYetReady')}
                      </Text>
                    ) : (
                      <>
                        <Text fontWeight="normal" fontSize="sm">
                          {t('compositionGroup.version.usedInCells', {
                            count: usedCells,
                            max: totalCells,
                          })}
                        </Text>
                      </>
                    )}
                  </Flex>
                </MenuButton>
                <MenuList zIndex={3}>
                  <MenuItem
                    icon={<Icons.OpenInNew boxSize={defaultIconSize} />}
                    onClick={() => onOpen(x.id)}
                  >
                    {t('compositionGroup.option.preview')}
                  </MenuItem>
                  {usedCells - totalCells !== 0 && supplierId && (
                    <MenuItem
                      icon={<Icons.Plus boxSize={defaultIconSize} />}
                      onClick={async () => await create(x.id)}
                    >
                      {t('compositionGroup.version.addCells', {
                        count: Math.abs(usedCells - totalCells),
                      })}
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </WarningComponent>
          );
        })}
      </Flex>

      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="auto" maxW="fit-content">
          <ModalBody>{value && <InnerModal id={value} />}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

function InnerModal({ id }: { id: number }) {
  return (
    <>
      <CompositionGroupView
        variantId={0}
        compositionGroupId={id}
        componentSettings={{
          expandedDefault: true,
          addMaterials: 'hidden',
          materialsMode: 'read-only',
          removeMaterials: 'hidden',
          editImage: 'hidden',
          characterEdit: 'hidden',
          dimensionMode: 'read-only',
          compositionArchiveRestore: 'hidden',
          compositionGroupDots: 'hidden',
          colors: 'hidden',
        }}
      />
    </>
  );
}

function getUsedOptionsCells(
  controllerId: number,
  cells?: CompositionOptionsCell[],
) {
  if (!cells) return 0;

  const addedCells: Record<string, boolean> = {};

  cells.forEach((c) => {
    if (
      c.versions.filter((v) => v.basedOnControllerId === controllerId).length >
      0
    ) {
      const format = `${c.x}-${c.y}`;
      if (addedCells[format]) return;
      addedCells[format] = true;
    }
  });

  return Object.keys(addedCells).length;
}
