import {
  Th,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Heading,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';
import { CompositionGroupView } from '../../compositions/shared/group/CompositionGroupView';
import { ConfirmCopy } from '../../compositions/shared/group/ConfirmCopy';
import { CompositionName } from '../../options/shared/CompositionName';
import { CopyProps, GroupedVersions } from '../shared/GroupedVersions';
import { useVersionOverview } from '../shared/useVersionOverview';
import { OptionsOverview } from './Options';
import { VariantBranchSupplier } from '@texas/api/endpoints/variantsApi';
import { SupplierOverview } from './SupplierOverview';

interface Props {
  variantId: number;
  branchId: number;
  suppliers: VariantBranchSupplier[];
}

export function VersionsListViewStandard({
  variantId,
  branchId,
  suppliers,
}: Props) {
  const { t } = useTranslation();

  const { versions, copyGroup } = useVersionOverview({
    variantId,
    branchId,
    isMatrix: false,
  });

  const {
    value: copyValue,
    isOpen: isCopyOpen,
    onClose: onCopyClose,
    onOpen: onCopyOpen,
  } = useValueDisclosure<CopyProps>();
  const { value, isOpen, onClose, onOpen } = useValueDisclosure<number>();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen,
  } = useValueDisclosure<number>();
  const {
    value: restoreValue,
    isOpen: isRestoreOpen,
    onClose: onRestoreClose,
    onOpen: onRestoreOpen,
  } = useValueDisclosure<number>();

  const compositionMaxMaterialsCount: {
    count: number;
    name: string | null;
  }[] = [];
  versions?.forEach((v) => {
    v.compositions.forEach((c, i) => {
      if (c.materials.length === 0) return;

      if (compositionMaxMaterialsCount.at(i) === undefined) {
        compositionMaxMaterialsCount.push({
          count: 1,
          name: c.note,
        });
      }
      compositionMaxMaterialsCount[i].count = Math.max(
        compositionMaxMaterialsCount[i].count,
        c.materials.length,
      );
    });
  });

  const materialHeaders: React.ReactNode[] = [];
  compositionMaxMaterialsCount.forEach((c, cIndex) => {
    for (let i = 0; i < c.count; i++) {
      materialHeaders.push(
        <React.Fragment key={`${cIndex}-${i}`}>
          <Th bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
            <CompositionName note={c.name} index={cIndex + 1} />
            {t('composition.materialNr', { nr: i + 1 })}
          </Th>
          <Th bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
            {t('configuration.quality')}
          </Th>
          <Th bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
            {t('configuration.qualityValue')}
          </Th>
          <Th bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
            {t('configuration.treatments')}
          </Th>
          <Th
            bg="texas.bg.800"
            borderRight="4px solid !important"
            borderRightColor="texas.bg.dark !important"
            _light={{
              borderRightColor: 'texas.bg.light !important',
              bg: 'gray.50',
            }}
          >
            {t('configuration.techniques')}
          </Th>
        </React.Fragment>,
      );
    }
  });

  return (
    <Box>
      <Flex gap={2} py={2}>
        {suppliers.map((s) => {
          return (
            <Flex
              key={s.supplierId}
              flexDir="column"
              gap={2}
              p={2}
              bg="texas.bg.900"
              borderRadius="md"
              _light={{ bg: 'gray.50' }}
            >
              <Heading fontSize="md">{s.supplierName}</Heading>
              <SupplierOverview
                versions={
                  versions?.filter((v) => v.supplierId === s.supplierId) ?? []
                }
              />
            </Flex>
          );
        })}
      </Flex>

      <OptionsOverview suppliers={suppliers} variantId={variantId} />
      <Divider py={2} />
      <GroupedVersions
        materialHeaders={materialHeaders}
        header={<Heading fontSize="md">{t('general.versions')}</Heading>}
        versions={versions ?? []}
        onRestoreOpen={(id) => onRestoreOpen(id)}
        onArchiveOpen={(id) => onArchiveOpen(id)}
        onOpen={(id) => onOpen(id)}
        onCopyOpen={(data) => onCopyOpen(data)}
      />

      {archiveValue && (
        <VerifyDialogWithRequest
          headerTitle={t('general.archive')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={compositionGroupApi.archive}
          onPerformed={() => compositionGroupEvents.onArchived.dispatch()}
          args={[archiveValue]}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onSuccessTitle={t('general.successfullyArchived')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
      )}
      {restoreValue && (
        <VerifyDialogWithRequest
          headerTitle={t('general.restore')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={compositionGroupApi.restore}
          onPerformed={() => compositionGroupEvents.onRestored.dispatch()}
          args={[restoreValue]}
          isOpen={isRestoreOpen}
          onClose={onRestoreClose}
          onSuccessTitle={t('general.restored')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
      )}
      {copyValue && (
        <ConfirmCopy
          title={
            copyValue.replaceId
              ? t('compositionGroup.version.resetToOption')
              : t('compositionGroup.version.copyIntoNew')
          }
          buttonLabel={
            copyValue.replaceId
              ? t('compositionGroup.version.resetToOption')
              : t('compositionGroup.version.copyIntoNew')
          }
          isOpen={isCopyOpen}
          onClose={onCopyClose}
          compositionGroupId={copyValue.groupId}
          onConfirm={async (id) => {
            onCopyClose();
            await copyGroup({
              fromId: id,
              basedOnId: copyValue.basedOnId ?? undefined,
              location: {
                variantId: variantId,
                supplierId: copyValue.supplierId,
                branchId: copyValue.branchId,
                cellX: copyValue.cellX ?? undefined,
                cellY: copyValue.cellY ?? undefined,
              },
              replaceId: copyValue.replaceId ?? undefined,
            });
          }}
        />
      )}
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="auto" maxW="fit-content">
          <ModalBody>{value && <InnerModal id={value} />}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

function InnerModal({ id }: { id: number }) {
  return (
    <>
      <CompositionGroupView
        variantId={0}
        compositionGroupId={id}
        componentSettings={{
          expandedDefault: true,
          compositionArchiveRestore: 'hidden',
          compositionGroupDots: 'hidden',
          addMaterials: 'hidden',
          materialsMode: 'only-edit',
          removeMaterials: 'hidden',
          characterEdit: 'hidden',
          dimensionMode: 'only-values',
        }}
      />
    </>
  );
}
