import {
  QualityListItem,
  qualitiesApi,
} from '@texas/api/endpoints/metadata/qualitiesApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface ArchiveQualityProps extends SharedDisclosureProps {
  onArchive: () => void;
  quality: QualityListItem;
}

export function ArchiveQuality({
  isOpen,
  quality,
  onArchive,
  onClose,
}: ArchiveQualityProps) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('configuration.archiveQuality', {
        name: quality.name,
      })}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={qualitiesApi.archive}
      args={[quality.id]}
      isOpen={isOpen}
      onClose={onClose}
      onPerformed={onArchive}
      onSuccessTitle={t('general.archived')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
