import { FilterOptionType } from '../filter/types/types';
import { hasRole } from '@texas/utils/helpers/roleHelpers';
import { CurrentUser } from '@texas/api/endpoints/sessionApi';
import { Role } from '@texas/api/endpoints/userApi';
import {
  defaultEmptyFilters,
  VariantsSearchQuery,
} from '@texas/api/endpoints/search/searchVariantsTypes';

export const pageSize = 12;

const defaultSearchParams: VariantsSearchQuery = {
  searchTerm: '',
  sortBy: '',
  sortDesc: false,
  page: 1,
  ...defaultEmptyFilters,
  showAllVariants: undefined,
  includeArchived: undefined,
  showMyBrands: true,
};
export const defaultArticlesWidgetOptions = (user: CurrentUser) => {
  return {
    defaultSearchPage: {
      filters: [FilterOptionType.ShowMyBrands],
      searchParams: defaultSearchParams,
    },
    filterOptions: {
      [FilterOptionType.ShowMyBrands]: {
        static: true,
        disabled: !hasRole(user.role, [
          Role.InternalAdministrator,
          Role.SystemAdministrator,
        ]),
      },
    },
  };
};
