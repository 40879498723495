import {
  ProductGroupListItem,
  productGroupsApi,
} from '@texas/api/endpoints/metadata/productGroupsApi';

import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface ArchiveQualityProps extends SharedDisclosureProps {
  onArchive: () => void;
  productGroup: ProductGroupListItem;
}

export function ArchiveProductGroup({
  isOpen,
  productGroup,
  onArchive,
  onClose,
}: ArchiveQualityProps) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('configuration.archive', {
        name: productGroup.value,
      })}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={productGroupsApi.archive}
      args={[productGroup.id]}
      isOpen={isOpen}
      onClose={onClose}
      onPerformed={onArchive}
      onSuccessTitle={t('general.archived')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
