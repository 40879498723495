import { useTranslation } from 'react-i18next';
import { ArticlesDataTableContainerFooter } from '../shared/dataTable/ArticlesDataTableContainerFooter';
import { DataTableContainer } from '../shared/dataTable/DataTableContainer';
import { Dispatch, MutableRefObject, SetStateAction, useMemo } from 'react';
import { TableColumns } from './TableColumns';
import { ServerError } from '@texas/types';
import { RowData, RowSelectionState } from '@tanstack/react-table';
import {
  defaultPage,
  defaultPageSize,
} from '../shared/dataTable/defaultTableOptions';
import { ArticleSearchProps } from './types';
import {
  SearchVariantsResponse,
  VariantsSearchQuery,
} from '@texas/api/endpoints/search/searchVariantsTypes';

export interface SearchArticlesTableProps {
  articles: SearchVariantsResponse | null;
  searchPage: ArticleSearchProps;
  searchParamsRef: MutableRefObject<VariantsSearchQuery>;
  setSearchParamsWithRef: (params: VariantsSearchQuery) => void;
  selectedRows: RowSelectionState;
  setSelectedRows: Dispatch<SetStateAction<RowSelectionState>>;
  error: ServerError | null;
  loading: boolean;
}

export function TableView({
  articles,
  searchPage,
  searchParamsRef,
  setSearchParamsWithRef,
  selectedRows,
  setSelectedRows,
  error,
  loading,
}: SearchArticlesTableProps) {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return TableColumns(t);
  }, [t]);

  return (
    <DataTableContainer
      p={4}
      css={{ ' th, td': { whiteSpace: 'normal' } }}
      error={error}
      datatable={{
        selectionProps: {
          setSelectedRows: setSelectedRows,
          selectedRows: selectedRows,
        },
        rowProps: {
          getRowId: (originalRow, _) => {
            const rowData: RowData = {
              articleId: originalRow.articleId,
              variantId: originalRow.variantId,
              branchId: originalRow.branchId,
            };
            return JSON.stringify(rowData);
          },
        },
        data: articles?.pagedItems.items ?? [],
        columns: columns,
        isLoading: loading,
        sorted: {
          onSortedChange: ({ key, desc }) => {
            setSearchParamsWithRef({
              ...searchParamsRef.current,
              sortBy: key,
              sortDesc: desc,
              page: defaultPage,
            });
          },
          key: searchPage.searchParams['sortBy'],
          desc: searchPage.searchParams['sortDesc'],
        },
      }}
      pagination={{
        totalItems: articles?.pagedItems.totalItems ?? 0,
        pageSize: defaultPageSize,
        currentPage: searchPage.searchParams.page,
        onPageChange: (page) =>
          setSearchParamsWithRef({
            ...searchParamsRef.current,
            page,
          }),
      }}
      footer={
        <ArticlesDataTableContainerFooter
          selectedRows={Object.keys(selectedRows).length}
          onClear={() => setSelectedRows({})}
        />
      }
    />
  );
}
