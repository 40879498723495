import {
  VariantBranchSupplier,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface DisableSupplierDialogProps extends SharedDisclosureProps {
  supplier: VariantBranchSupplier;
  onDisable: () => void;
}

export function DisableSupplierDialog(props: DisableSupplierDialogProps) {
  const { t } = useTranslation();

  return (
    <VerifyDialogWithRequest
      headerTitle={t('variant.supplier.disable')}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={variantsApi.disableSupplierBranch}
      args={[
        props.supplier.variantId,
        props.supplier.branchId,
        props.supplier.supplierId,
      ]}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPerformed={props.onDisable}
      onSuccessTitle={t('general.successfullyDisabled')}
      key={props.supplier.supplierId}
    >
      {t('variant.supplier.disableInfo')}
    </VerifyDialogWithRequest>
  );
}
