import {
  ContactAddToType,
  contactsApi,
} from '@texas/api/endpoints/contactsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ContactsTable } from '@texas/components/contacts/ContactsTable';

export function CustomerContacts({ customerId }: { customerId: number }) {
  const { request: disconnectRequest } = useApiRequest(
    contactsApi.disconnectCustomer,
  );
  const { request: connectRequest } = useApiRequest(
    contactsApi.connectCustomer,
  );

  return (
    <ContactsTable
      addToType={ContactAddToType.Customer}
      referenceId={customerId}
      disconnectRequest={disconnectRequest}
      connectRequest={connectRequest}
    />
  );
}
