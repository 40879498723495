import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { defaultIconSize, Icons } from './Icons';
import { fadeInAnimation } from '@texas/resources/animations/animations';

interface Props extends FlexProps {
  children: React.ReactNode;
}

export function SimpleNote({ children, ...flexProps }: Props) {
  return (
    <Flex
      animation={fadeInAnimation()}
      gap={2}
      alignItems="center"
      {...flexProps}
    >
      <Icons.AlertCircle boxSize={defaultIconSize} />
      <Text fontSize="xs">{children}</Text>
    </Flex>
  );
}
