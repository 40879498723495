import { Role, UserType } from '@texas/api/endpoints/userApi';
import { convertToEnum } from './enumHelpers';
import { TFunction } from 'i18next';

export function hasRole(role: Role | null, requiredRoles: Role[]) {
  if (role === null) return false;
  return requiredRoles.includes(convertToEnum(Role, role));
}

export function roleFriendlyName(role: Role, t: TFunction) {
  switch (role) {
    case Role.SystemAdministrator:
      return t('auth.roles.systemAdmin.title');
    case Role.InternalAdministrator:
      return t('auth.roles.admin.title');
    case Role.InternalAgent:
      return t('auth.roles.agent.title');
    case Role.Brand:
      return t('auth.roles.brand.title');
    case Role.Supplier:
      return t('auth.roles.supplier.title');
  }
}

export function userTypeFriendlyName(value: UserType, t: TFunction) {
  switch (value) {
    case UserType.BrandCoordinator:
      return t('user.brandCoordinator');
    case UserType.ProductDeveloper:
      return t('user.productDeveloper');
    case UserType.KeyAccountManager:
      return t('user.keyAccountManager');
    case UserType.CustomerService:
      return t('user.customerService');
  }
}

export function roleDescription(role: Role, t: TFunction) {
  switch (role) {
    case Role.SystemAdministrator:
      return t('auth.roles.systemAdmin.desc');
    case Role.InternalAdministrator:
      return t('auth.roles.admin.desc');
    case Role.InternalAgent:
      return t('auth.roles.agent.desc');
    case Role.Brand:
      return t('auth.roles.brand.desc');
    case Role.Supplier:
      return t('auth.roles.supplier.desc');
  }
}

export function internalRole(role: Role) {
  return (
    role === Role.InternalAdministrator ||
    role === Role.InternalAgent ||
    role === Role.SystemAdministrator
  );
}
