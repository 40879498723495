import { reactEvents } from '@bridge/reactEvents';
import { Button, Flex, Text } from '@chakra-ui/react';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { blinkAnimation } from '@texas/resources/animations/animations';
import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function MarkReady({
  onClick,
  isReady,
  hasAnyBasedOn,
  containerWarningRef,
}: {
  onClick: () => void;
  isReady: boolean;
  hasAnyBasedOn: boolean;
  containerWarningRef: RefObject<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  const [warning, setWarning] = useState(true);

  useEffect(() => {
    return reactEvents.warningsChanged.sub(() => {
      if (!containerWarningRef.current) {
        setWarning(false);
        return;
      }
      setWarning(
        containerWarningRef.current.querySelectorAll(`[data-warning="1"]`)
          .length > 0,
      );
    });
  }, [containerWarningRef]);

  if (isReady) {
    return (
      <Flex
        gap={2}
        minW="fit-content"
        h="fit-content"
        color="texas.sand.50"
        align="center"
        padding={2}
        borderRadius="md"
        bg="whiteAlpha.100"
        _light={{
          color: 'texas.burntSienna.100',
          bg: 'blackAlpha.100',
        }}
      >
        <Icons.CheckCircle boxSize={defaultIconSize} />
        <Flex flexDir="column" gap={1}>
          <Text>{t('compositionGroup.option.readyForPd')}</Text>
          {hasAnyBasedOn && (
            <Text fontSize="sm" fontWeight="bold">
              {t('compositionGroup.option.isUsed')}
            </Text>
          )}
        </Flex>
      </Flex>
    );
  }

  if (warning) {
    return (
      <Button
        onClick={onClick}
        h="auto"
        minW="fit-content"
        borderRadius="md"
        bg="transparent"
        py={2}
        border="2px dashed"
        borderColor="gray.400"
        color="gray.400"
      >
        <Flex align="center" gap={2}>
          <Icons.AlertCircle boxSize={defaultIconSize} />
          <Flex flexDir="column" align="start" gap={1}>
            <Text>{t('compositionGroup.option.markReady')}</Text>
            <Text fontWeight="normal" fontSize="sm">
              {t('compositionGroup.option.resolveWarnings')}
            </Text>
          </Flex>
        </Flex>
      </Button>
    );
  }

  return (
    <Button
      onClick={onClick}
      minW="fit-content"
      h="auto"
      borderRadius="md"
      bg="transparent"
      py={2}
      border="2px solid"
      borderColor="texas.sand.50"
      color="texas.sand.50"
      animation={blinkAnimation()}
      _light={{
        color: 'texas.burntSienna.100',
        borderColor: 'texas.burntSienna.100',
      }}
    >
      <Flex align="center" gap={2}>
        <Icons.CheckCircle boxSize={defaultIconSize} />
        <Flex flexDir="column" align="start" gap={1}>
          <Text>{t('compositionGroup.option.markReady')}</Text>
          <Text fontWeight="normal" fontSize="sm">
            {t('compositionGroup.option.sendForPd')}
          </Text>
        </Flex>
      </Flex>
    </Button>
  );
}
