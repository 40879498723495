// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.dimensionGroups', {
    url: '/dimensiongroups',
    controller: 'OrganizationConfigurationController as vm',
    templateUrl: 'main/configuration/dimensionGroups/dimensionGroups.tpl.html',
    data: { pageTitle: 'Dimension groups' },
  });
};

class DimensionGroupsController {
  constructor(currentOrganization) {
    'ngInject';
    this.organization = currentOrganization;
  }
}
angular
  .module('configuration.dimensionGroups', [])
  .config(config)
  .controller('DimensionGroupsController', DimensionGroupsController);
