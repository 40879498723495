import { MatrixFoundation } from './matrix/MatrixFoundation';
import { MatrixAxisGuard } from '../matrix/MatrixAxisGuard';

export function MatrixArticleOptions({ variantId }: { variantId: number }) {
  return (
    <MatrixAxisGuard variantId={variantId}>
      <MatrixFoundation variantId={variantId} />
    </MatrixAxisGuard>
  );
}
