import { Flex, Button, Box } from '@chakra-ui/react';
import {
  contactsApi,
  SearchQuery,
  defaultSearchQuery,
  ContactListItem,
  ContactAddToType,
} from '@texas/api/endpoints/contactsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { defaultPageSize } from '@texas/components/shared/dataTable/defaultTableOptions';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { modalContactsTableColumns } from './ModalColumns';
import { Mode } from './ContactsModal';
import { contactEvents } from './events';
import { useSignalSubscriptionEvents } from '../shared/hooks/useSignalSubscriptionEvents';

type State = 'hidden';

interface Props {
  referenceId: number;
  mode: Mode;
  filterOn: ContactAddToType | null;
  selectedIds?: number[];
  onCreateContactClick: (s: string) => void;
  onSelect: (contact: ContactListItem) => void;
  settings?: {
    searchBar?: State;
    alwaysShowCreateButton?: boolean;
  };
}

export function SelectContact({
  settings,
  selectedIds,
  referenceId,
  mode,
  filterOn,
  onCreateContactClick,
  onSelect,
}: Props) {
  const { t } = useTranslation();

  const {
    data: contacts,
    refetch,
    error,
    loading,
  } = useApiResource(contactsApi.getAll);

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    ...defaultSearchQuery,
    ...getFilterOn(filterOn, referenceId),
  });

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  useSignalSubscriptionEvents({
    triggerOnEvent: () => {
      refetch(searchQuery);
    },
    signalEvents: [contactEvents.newContactCreated],
  });

  const columns = useMemo(() => {
    return modalContactsTableColumns(
      t,
      referenceId,
      mode,
      onSelect,
      selectedIds,
    );
  }, [mode, onSelect, referenceId, selectedIds, t]);

  return (
    <Flex direction="column" gap={4}>
      {settings?.searchBar !== 'hidden' && (
        <Box height="fit-content" mt="auto">
          <SearchFilterInput
            value={searchQuery.searchTerm}
            placeholder={t('contacts.searcNameOrEmail')}
            onChange={(s) => setSearchQuery({ ...searchQuery, searchTerm: s })}
          />
        </Box>
      )}

      {(contacts?.totalItems === 0 ||
        settings?.alwaysShowCreateButton === true) && (
        <Button
          borderRadius="md"
          onClick={() => onCreateContactClick(searchQuery.searchTerm)}
          mt={2}
        >
          {t('contacts.createQuestion')}
        </Button>
      )}
      <DataTableContainer
        error={error}
        datatable={{
          data: contacts?.items ?? [],
          sorted: {
            onSortedChange: ({ key, desc }) => {
              setSearchQuery({ ...searchQuery, sortBy: key, sortDesc: desc });
            },
            key: searchQuery.sortBy,
            desc: searchQuery.sortDesc,
          },
          isLoading: loading,
          columns: columns,
          variant: 'none',
        }}
        pagination={{
          totalItems: contacts?.totalItems ?? 0,
          pageSize: defaultPageSize,
          currentPage: searchQuery.page,
          onPageChange: (page) =>
            setSearchQuery({
              ...searchQuery,
              page,
            }),
        }}
      />
    </Flex>
  );
}

function getFilterOn(filterOn: ContactAddToType | null, referenceId: number) {
  switch (filterOn) {
    case ContactAddToType.Customer:
      return { customerId: referenceId };
    case ContactAddToType.Supplier:
      return { supplierId: referenceId };
    case null:
      return {};
  }
}
