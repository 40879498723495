import {
  Flex,
  Box,
  Button,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Popover,
  PopoverTrigger,
  PopoverBody,
  IconButton,
  PopoverContent,
  PopoverArrow,
  Heading,
} from '@chakra-ui/react';
import {
  ProductApproval,
  productApprovalsApi,
  ProductApprovalState,
} from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '@texas/components/shared/Icons';
import { fadeInAnimation } from '@texas/resources/animations/animations';
import {
  MetadataList,
  MetadataListRow,
} from '@texas/components/shared/MetadataList';
import placeholderImg from '@assets/placeholder-250.png';
import { SelectState } from './SelectState';
import { CompositionGroupView } from '../variant/compositions/shared/group/CompositionGroupView';
import { clientEndpoints } from '@texas/clientEndpoints';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { ProductApprovalHistoryModal } from './ProductApprovalHistoryModal';
import { RejectReasons } from './shared/RejectReasons';
import { productApprovalEvents } from './events';

export function ProductApprovalDetails({
  id,
  refetchAll,
}: {
  id: number;
  refetchAll: () => void;
}) {
  const {
    data: productApproval,
    refetch,
    error,
    loading,
  } = useApiResource(productApprovalsApi.get);
  const { t } = useTranslation();

  const triggerRefetch = useCallback(() => {
    refetch(id);
  }, [refetch, id]);

  useEffect(() => {
    refetch(id);
  }, [id, refetch]);

  useMemo(() => {
    return productApprovalEvents.productApprovalChanged.subscribe((event) => {
      if (event.id === id) {
        triggerRefetch();
      }
    });
  }, [triggerRefetch, id]);

  return (
    <>
      {loading && (
        <Flex w="450px" justify="center" align="center">
          <Spinner thickness="3px" color="primary.200" size="lg" />
        </Flex>
      )}

      {!loading && (
        <Box
          animation={fadeInAnimation(400)}
          borderRadius={8}
          backgroundColor="texas.bg.900"
          minW="400px"
          _light={{ backgroundColor: 'white' }}
        >
          <ErrorLabel text={error?.message} />
          {productApproval && (
            <Tabs isLazy={true}>
              <TabList>
                <Tab>{t('productApproval.details')}</Tab>
                <Tab>{t('composition.composition')}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <ProductApprovalInfo
                    refetchAll={() => {
                      refetchAll();
                      triggerRefetch();
                    }}
                    productApproval={productApproval}
                  />
                </TabPanel>
                <TabPanel as={Flex} justifyContent="center">
                  <CompositionGroupView
                    componentSettings={{
                      compositionArchiveRestore: 'hidden',
                      addMaterials: 'hidden',
                      materialsMode: 'read-only',
                      removeMaterials: 'hidden',
                      editImage: 'hidden',
                      characterEdit: 'hidden',
                      dimensionMode: 'read-only',
                      expander: 'hidden',
                      compositionGroupDots: 'hidden',
                      paStates: 'hidden',
                    }}
                    variantId={0}
                    compositionGroupId={productApproval.compositionGroupId}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Box>
      )}
    </>
  );
}

export function ProductApprovalInfo({
  productApproval,
  refetchAll,
}: {
  productApproval: ProductApproval;
  refetchAll: () => void;
}) {
  const { t } = useTranslation();
  const paState = convertToEnum(ProductApprovalState, productApproval.state);

  return (
    <Flex direction="column">
      <Image
        maxW="256px"
        fallbackSrc={placeholderImg}
        objectFit="contain"
        src={
          productApproval.imageIdentifier
            ? clientEndpoints.previewImage(productApproval.imageIdentifier, 256)
            : undefined
        }
        borderRadius={8}
      />
      <MetadataList>
        <MetadataListRow label={t('productApproval.number')}>
          {productApproval.formatNumber}
        </MetadataListRow>
        <MetadataListRow label={t('suppliers.supplier')}>
          {productApproval.supplierName}
        </MetadataListRow>
        <MetadataListRow label={t('general.branch')}>
          {productApproval.branchName}
        </MetadataListRow>
        <MetadataListRow label={t('variant.variant')}>
          {productApproval.variantName}
        </MetadataListRow>
        <MetadataListRow label={t('compositionGroup.version.supplierVersion')}>
          {t('compositionGroup.version.versionNr', {
            nr: productApproval.compositionGroupVersion,
          })}
        </MetadataListRow>
        {productApproval.matrixNodeXId && (
          <MetadataListRow label={productApproval.matrixNodeXName}>
            {productApproval.matrixNodeXValue}
          </MetadataListRow>
        )}
        {productApproval.matrixNodeYId && (
          <MetadataListRow label={productApproval.matrixNodeYName}>
            {productApproval.matrixNodeYValue}
          </MetadataListRow>
        )}
        <MetadataListRow label={t('article.created')}>
          {formatDate(Locale.En, productApproval.created)}
        </MetadataListRow>
        <MetadataListRow label={t('general.state')}>
          <Flex gap={2} alignItems="center">
            <Flex direction="column">
              <Text>{productApproval.state}</Text>
              {productApproval.handledDate && (
                <Text fontSize="sm" fontWeight="bold">
                  {formatDate(Locale.En, productApproval.handledDate)}
                </Text>
              )}
            </Flex>
            <StatePopover
              productApproval={productApproval}
              state={paState}
              onChanged={() => refetchAll()}
            />
          </Flex>
        </MetadataListRow>
        {paState === ProductApprovalState.Accepted && (
          <MetadataListRow label={t('productApproval.acceptedBy')}>
            {productApproval.handledBy}
          </MetadataListRow>
        )}
        {paState === ProductApprovalState.Rejected && (
          <MetadataListRow label={t('productApproval.rejectedBy')}>
            {productApproval.handledBy}
          </MetadataListRow>
        )}
        {paState === ProductApprovalState.Rejected && (
          <MetadataListRow label={t('productApproval.reason')}>
            <RejectReasons reasons={productApproval.handleReason} />
          </MetadataListRow>
        )}
        {paState === ProductApprovalState.Pending &&
          productApproval.handledBy && (
            <MetadataListRow label={t('productApproval.revertedBy')}>
              {productApproval.handledBy}
            </MetadataListRow>
          )}
        {productApproval.handledNote && (
          <MetadataListRow label={t('general.note')}>
            {productApproval.handledNote}
          </MetadataListRow>
        )}
        <MetadataListRow label={t('suppliers.supplierContact')}>
          <Flex flexDir="column" align="flex-end">
            <Heading fontSize="md">
              {productApproval.supplierContactName}
            </Heading>
            {productApproval.supplierContactEmail && (
              <Text fontSize="sm" variant="sub">
                {productApproval.supplierContactEmail}
              </Text>
            )}
          </Flex>
        </MetadataListRow>
        <MetadataListRow label={t('customer.customerContact')}>
          <Flex flexDir="column" align="flex-end">
            <Heading fontSize="md">
              {productApproval.customerContactName}
            </Heading>
            {productApproval.customerContactEmail && (
              <Text fontSize="sm" variant="sub">
                {productApproval.customerContactEmail}
              </Text>
            )}
          </Flex>
        </MetadataListRow>
        <MetadataListRow label={t('productApproval.issuedBy')}>
          {productApproval.issuedBy}
        </MetadataListRow>
      </MetadataList>
      <DownloadButtons productApprovalId={productApproval.id} />
    </Flex>
  );
}

function StatePopover({
  productApproval,
  state,
  onChanged,
}: {
  productApproval: ProductApproval;
  state: ProductApprovalState;
  onChanged: (id?: number) => void;
}) {
  const { t } = useTranslation();

  return (
    <Popover placement="left">
      <PopoverTrigger>
        <IconButton
          variant="texas-outline-light"
          size="sm"
          icon={<Icons.DotsHorizontal />}
          aria-label={t('productApproval.history')}
        />
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverArrow />
        <PopoverBody>
          <Flex gap={3}>
            <SelectState
              onChanged={onChanged}
              id={productApproval.id}
              state={state}
              branchId={productApproval.branchId}
              variantId={productApproval.variantId}
            />
            <ProductApprovalHistoryModal
              productApprovalId={productApproval.id}
              number={productApproval.formatNumber}
            />
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function DownloadButtons({ productApprovalId }: { productApprovalId: number }) {
  const { t } = useTranslation();

  return (
    <Flex pt={4} pb={2} gap={4}>
      <Button
        variant="texas-solid"
        rightIcon={<Icons.Download />}
        as="a"
        href={productApprovalsApi.downloadSupplierProductApproval(
          productApprovalId,
        )}
        target="_blank"
        download={true}
      >
        {t('productApproval.downloadSpa')}
      </Button>
      <Menu>
        <MenuButton
          as={Button}
          variant="texas-solid"
          rightIcon={<Icons.Download />}
        >
          {t('productApproval.downloadPa')}
        </MenuButton>
        <MenuList>
          <MenuItem
            as="a"
            target="_blank"
            download={true}
            href={productApprovalsApi.downloadCustomerProductApproval(
              productApprovalId,
              true,
            )}
          >
            {t('productApproval.excludeMaterialSpecifications')}
          </MenuItem>
          <MenuItem
            as="a"
            target="_blank"
            download={true}
            href={productApprovalsApi.downloadCustomerProductApproval(
              productApprovalId,
              false,
            )}
          >
            {t('productApproval.includeMaterialSpecifications')}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
