import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Badge,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { ArchivedTooltip } from '@texas/components/shared/tooltip/ArchivedTooltip';
import { ProductGroupListItem } from '@texas/api/endpoints/metadata/productGroupsApi';

export const productGroupTableColumns = (
  t: TFunction,
  onArchiveOpen: (productGroup: ProductGroupListItem) => void,
  onRestore: (id: number) => Promise<void>,
  onEditOpen: (id: number) => void,
) => {
  const columnHelper = createColumnHelper<ProductGroupListItem>();
  const columns = [
    columnHelper.accessor('code', {
      cell: (info) => info.getValue(),
      header: t('general.code'),
      maxSize: 100,
    }),
    columnHelper.accessor('value', {
      cell: (info) => info.getValue(),
      header: t('general.value'),
      maxSize: 200,
    }),
    columnHelper.accessor('dimensions', {
      cell: (info) => (
        <Flex gap={2} flexWrap="wrap">
          {info.getValue().map((d) => (
            <Badge
              key={d.name}
              color="texas.sand.50"
              _light={{
                backgroundColor: 'blackAlpha.100',
                color: 'texas.burntSienna.500',
              }}
            >
              {d.name} - {d.defaultMetric}
            </Badge>
          ))}
        </Flex>
      ),
      header: t('configuration.dimensionGroups'),
      enableSorting: false,
    }),
    columnHelper.accessor('displayOrder', {
      cell: (info) => info.getValue(),
      header: t('branch.displayOrder'),
      maxSize: 100,
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {info.row.original.archived && (
              <ArchivedTooltip archived={info.row.original.archived} />
            )}
            <Menu placement="left">
              <MenuButton float="right" aria-label={t('general.more')}>
                <Icons.DotsHorizontal boxSize={6} />
              </MenuButton>
              <Portal>
                <MenuList color="white" zIndex={99}>
                  <MenuItem
                    icon={<Icons.Pencil boxSize={defaultIconSize} />}
                    onClick={() => onEditOpen(info.row.original.id)}
                  >
                    {t('general.edit')}
                  </MenuItem>
                  {info.row.original.archived ? (
                    <MenuItem
                      color="texas.sand.100"
                      icon={<Icons.Undo boxSize={defaultIconSize} />}
                      onClick={() => onRestore(info.row.original.id)}
                    >
                      {t('general.restore')}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      color="texas.sand.50"
                      icon={<Icons.Archive boxSize={defaultIconSize} />}
                      onClick={() => onArchiveOpen(info.row.original)}
                    >
                      {t('general.archive')}
                    </MenuItem>
                  )}
                </MenuList>
              </Portal>
            </Menu>
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
