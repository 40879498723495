import { useTranslation } from 'react-i18next';
import { BaseWidgetProps } from '../widgets';
import {
  defaultEmptyFilters,
  useSearchProductApprovals,
} from '@texas/components/shared/search/useSearchProductApprovals';
import { PaCountOptions, WidgetType } from '../shared/types';
import { searchApi } from '@texas/api/endpoints/search/searchApi';
import {
  Box,
  Flex,
  IconButton,
  MenuItem,
  Tooltip,
  useDisclosure,
  VStack,
  Text,
} from '@chakra-ui/react';
import { SearchProductApprovalsCountContext } from './context';
import { WidgetWrapper } from '../shared/components/WidgetWrapper';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import {
  blinkAnimation,
  fadeInAnimation,
} from '@texas/resources/animations/animations';
import { PaPrinterFilter } from '../shared/components/productApprovals/PaPrinterFilter';
import { PaFilterModal } from '../shared/components/productApprovals/PaFilterModal';
import { PaOverviewModal } from '../shared/components/productApprovals/PaOverviewModal';
import { useEffect } from 'react';
import { productApprovalEvents } from '@texas/components/article/productApproval/events';

interface Props extends BaseWidgetProps {
  options: PaCountOptions;
}

export function ProductApprovalsCountWidget({
  options,
  customTitle,
  widgetId,
  color,
}: Props) {
  const { t } = useTranslation();

  const search = useSearchProductApprovals({
    defaultFilters: defaultEmptyFilters,
    defaultSearchProps: options.defaultSearchPage,
    defaultPage: options.defaultSearchPage.searchParams.page,
    localStorageKey: options.localStorageKey
      ? `${options.localStorageKey}_${widgetId}`
      : undefined,
    filterOptions: options.filterOptions,
    limit: 0,
    request: searchApi.searchProductAppovalsCount,
    defaultData: { totalCount: 0 },
  });

  const { loading, refetchDebounce } = search;

  useEffect(() => {
    return productApprovalEvents.productApprovalChanged.sub(() => {
      refetchDebounce();
    });
  }, [refetchDebounce]);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isFiltersOpen,
    onClose: onFiltersClosed,
    onOpen: onFiltersOpen,
  } = useDisclosure();

  return (
    <>
      <SearchProductApprovalsCountContext.Provider value={search}>
        <WidgetWrapper
          widgetId={widgetId}
          loading={loading}
          settings={options.componentSettings}
          customColor={color}
          type={WidgetType.ProductApprovalsCount}
          style="default"
          header={customTitle ?? t('widgets.productApprovalCount.title')}
          onClick={onOpen}
          toolbox={
            <WidgetToolbox options={options} onFiltersOpen={onFiltersOpen} />
          }
          extendedMenu={
            <>
              <MenuItem
                icon={<Icons.OpenInNew boxSize={defaultIconSize} />}
                onClick={onOpen}
              >
                {t('widgets.showResult')}
              </MenuItem>
            </>
          }
        >
          <VStack h="100%" w="100%" align="start">
            <InnerWidget />
          </VStack>
        </WidgetWrapper>
        <PaFilterModal
          search={search}
          widgetRenderComponent={<InnerWidget />}
          isOpen={isFiltersOpen}
          onClose={onFiltersClosed}
        />
        <PaOverviewModal
          isOpen={isOpen}
          onClose={onClose}
          filters={search.searchParamsRef.current}
          searchProps={search.searchPage}
          columns={options.columns}
        />
      </SearchProductApprovalsCountContext.Provider>
    </>
  );
}

function InnerWidget() {
  const search = useActiveContext(SearchProductApprovalsCountContext);
  const { data, loading } = search;
  const { t } = useTranslation();
  return (
    <Box>
      <Flex alignItems="end" gap={2}>
        <Text
          animation={loading ? blinkAnimation() : fadeInAnimation()}
          fontSize="5xl"
        >
          {data?.totalCount ?? '...'}
        </Text>
        <Text color="gray.300" pb={3}>
          {t('widgets.productApprovalCount.productApprovals', {
            count: data?.totalCount ?? 0,
          })}
        </Text>
      </Flex>
      <PaPrinterFilter search={search} />
    </Box>
  );
}

function WidgetToolbox({
  options,
  onFiltersOpen,
}: {
  options: PaCountOptions;
  onFiltersOpen: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex gap={2}>
      {options.componentSettings?.editFilters !== 'hidden' && (
        <>
          <Tooltip label={t('widgets.changeFilters')}>
            <IconButton
              size="sm"
              variant="texas-light"
              icon={<Icons.FilterVariant />}
              aria-label={t('widgets.changeFilters')}
              onClick={() => onFiltersOpen()}
            />
          </Tooltip>
        </>
      )}
    </Flex>
  );
}
