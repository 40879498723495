// This is is somehow causing prettier to cast a format error here (SyntaxError: Expression expected)
// inside the function call. But the function does not accept any arguments...
// prettier-ignore does not work, so we have to ignore the whole file.

import { SearchVariantsResponse } from "@texas/api/endpoints/search/searchVariantsTypes";
import { useSearchArticles } from "@texas/components/shared/search/useSearchArticles";
import { createContext } from "react";

export type SearchVariants = ReturnType<typeof useSearchArticles<SearchVariantsResponse>>;
export const SearchArticleContext = createContext<SearchVariants | null>(null);
