import {
  Spinner,
  Flex,
  Tooltip,
  Text,
  Box,
  useColorMode,
} from '@chakra-ui/react';
import { Composition } from '@texas/api/endpoints/compositionApi';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { navLinkOffsetStyle } from '@texas/resources/styles';
import { useTranslation } from 'react-i18next';
import options from '@assets/article/options.webp';
import { TopRightContainerComponent } from '@texas/components/shared/TopRightContainerComponent';
import { WarningComponent } from '@texas/components/shared/WarningComponent';

export function OptionsList({
  groups,
  groupsLoading,
  isCreating,
  onCreateClick,
  selectedOption,
  onClick,
}: {
  groups:
    | {
        id: number;
        fileId: number | null;
        version: number;
        note: string | null;
        compositions: Composition[];
        isReady: boolean;
      }[]
    | undefined;
  groupsLoading: boolean;
  isCreating: boolean;
  onCreateClick: () => void;
  selectedOption: number | null;
  onClick: (id: number) => void;
}) {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();

  return (
    <>
      {groupsLoading ? (
        <Spinner />
      ) : (
        <>
          {groups?.length === 0 && !isCreating && (
            <NewOptionCard onClick={onCreateClick} />
          )}
          {groups?.map((x) => {
            return (
              <Box
                animation={fadeInScaleAnimation()}
                key={x.id}
                cursor="pointer"
                borderRadius="md"
                _hover={{
                  transform: 'scale(1.02)',
                }}
                {...navLinkOffsetStyle(
                  selectedOption === x.id,
                  1.02,
                  colorMode,
                )}
              >
                <TopRightContainerComponent
                  bar={
                    <>
                      {x.isReady ? (
                        <Tooltip label={t('compositionGroup.option.ready')}>
                          <Icons.CheckCircle
                            bg="texas.bg.800"
                            borderRadius="full"
                            color="texas.sand.50"
                            _light={{
                              color: 'texas.burntSienna.100',
                            }}
                            boxSize={defaultIconSize}
                          />
                        </Tooltip>
                      ) : null}
                    </>
                  }
                >
                  <Flex
                    background={`linear-gradient(to top, #00000080 20%, transparent), url(${options})`}
                    _light={{
                      background: `linear-gradient(to top, #FFFFFF80 20%, transparent), url(${options})`,
                      ...(x.isReady
                        ? {
                            borderColor: 'texas.burntSienna.100',
                          }
                        : null),
                    }}
                    w="140px"
                    h="200px"
                    borderRadius="md"
                    padding={2}
                    justifyContent="end"
                    flexDir="column"
                    onClick={() => onClick(x.id)}
                    {...(x.isReady
                      ? {
                          border: '2px solid',
                          borderColor: 'texas.sand.50',
                        }
                      : null)}
                  >
                    <Flex align="center">
                      {x.fileId === null ? (
                        <WarningComponent
                          warning={t('compositionGroup.missingLayout')}
                        >
                          <Icons.PencilRuler boxSize={defaultIconSize} />
                        </WarningComponent>
                      ) : (
                        <Icons.PencilRuler boxSize={defaultIconSize} />
                      )}
                      {x.compositions.some((c) => c.fileId) && (
                        <Tooltip
                          label={t('fileBrowser.images', {
                            count: x.compositions.filter((c) => c.fileId)
                              .length,
                          })}
                        >
                          <Icons.ImageOutline
                            boxSize={defaultIconSize}
                            ml={2}
                          />
                        </Tooltip>
                      )}
                    </Flex>
                    <Text fontWeight="bold">
                      {x.note
                        ? x.note
                        : t('compositionGroup.option.optionNr', {
                            nr: x.version,
                          })}
                    </Text>
                  </Flex>
                </TopRightContainerComponent>
              </Box>
            );
          })}
        </>
      )}
    </>
  );
}

function NewOptionCard({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();
  return (
    <Flex
      w="140px"
      h="200px"
      align="center"
      justify="center"
      border="2px dashed"
      borderColor="texas.bg.700"
      textAlign="center"
      borderRadius="md"
      cursor="pointer"
      transition="transform 200ms ease"
      _hover={{
        transform: 'scale(1.02)',
        borderColor: 'white',
        _light: { borderColor: 'black' },
      }}
      _light={{
        borderColor: 'gray.200',
      }}
      onClick={onClick}
    >
      <Text>{t('compositionGroup.option.createFirst')}</Text>
    </Flex>
  );
}
