import { StylesConfig, GroupBase, Theme } from 'react-select';
import { ReactSelectType } from '../types';
import { ColorMode } from '@chakra-ui/react';

export type SelectBorderStyle = 'solid' | 'dotted' | 'dashed' | 'none';

export interface SelectStyle {
  borderStyle?: SelectBorderStyle;
  isAngularTheme?: boolean;
  mode: ColorMode;
}

const gray50 = '#ededed';
const gray100 = '#d9d9d9';
const gray200 = '#bdbdbd';
const gray300 = '#999999';
const gray400 = '#787878';
const gray500 = '#5c5c5c';
const gray600 = '#474747';

const blackAlpha = {
  200: 'RGBA(0, 0, 0, 0.08)',
  300: 'RGBA(0, 0, 0, 0.16)',
  400: 'RGBA(0, 0, 0, 0.24)',
  500: 'RGBA(0, 0, 0, 0.36)',
};

const whiteAlpha = {
  300: 'RGBA(255, 255, 255, 0.16)',
};

const sandColors = {
  10: '#F8E2C8',
  20: '#F3DABE',
  30: '#E8CBAB',
  50: '#DEBE9A',
  100: '#C6A37C',
  40085: '#876d50D9',
};

export function reactSelectStyle<IsMulti extends boolean, T = ReactSelectType>(
  selectStyle: SelectStyle = {
    borderStyle: 'solid',
    isAngularTheme: false,
    mode: 'dark',
  },
): StylesConfig<T, IsMulti, GroupBase<T>> | undefined {
  const isDark = selectStyle.mode === 'dark';
  return {
    indicatorSeparator: (base, _) => ({
      ...base,
      backgroundColor: selectStyle.isAngularTheme
        ? 'gray'
        : isDark
        ? gray500
        : gray200,
    }),
    dropdownIndicator: (base, _) => ({
      ...base,
      color: selectStyle.isAngularTheme ? 'gray' : isDark ? gray500 : gray200,
    }),
    option: (base, _) => ({
      ...base,
      color: 'black',
      fontSize: '14px',
    }),
    control: (base, _) => ({
      ...base,
      backgroundColor: 'transparent',
      borderColor: selectStyle.isAngularTheme
        ? 'gray'
        : isDark
        ? gray500
        : gray200,
      ':hover': {
        ...base[':hover'],
        borderColor: selectStyle.isAngularTheme
          ? gray200
          : isDark
          ? gray400
          : gray300,
        borderStyle: selectStyle.borderStyle,
      },
      ':focus-within': {
        ...base[':focus-within'],
        borderColor: selectStyle.isAngularTheme
          ? gray200
          : isDark
          ? gray400
          : gray300,
        borderStyle: selectStyle.borderStyle,
      },
      boxShadow: 'none',
      borderStyle: selectStyle.borderStyle,
      minHeight: '46px',
    }),
    input: (base, _) => ({
      ...base,
      color: selectStyle.isAngularTheme ? gray600 : isDark ? 'white' : 'black',
      visibility: 'inherit', // Fix to prevent input bleed out when popover is hidden
    }),
    singleValue: (base, _) => ({
      ...base,
      color: selectStyle.isAngularTheme ? gray600 : isDark ? 'white' : 'black',
    }),
    container: (base, _) => ({
      ...base,
      width: '100%',
    }),
    multiValue: (base, _) => ({
      ...base,
      backgroundColor: isDark ? whiteAlpha[300] : blackAlpha[300],
    }),
    multiValueLabel: (base, _) => ({
      ...base,
      color: isDark ? 'white' : 'black',
    }),
  };
}

export const reactSelectTheme = (
  theme: Theme,
  colorMode: ColorMode = 'dark',
) => {
  const isDark = colorMode === 'dark';
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: isDark ? sandColors[50] : gray100,
      primary25: isDark ? sandColors[10] : gray50,
      primary50: isDark ? sandColors[30] : gray50,
    },
  };
};

export function navLinkOffsetStyle(
  isActive: boolean,
  scale: number,
  colorMode: ColorMode = 'dark',
) {
  const isDark = colorMode === 'dark';
  return {
    transition:
      'outline-offset 200ms ease, outline-color 200ms ease, transform 300ms ease',
    outline: '2px solid',
    outlineOffset: 12,
    outlineColor: 'transparent',
    ...(isActive
      ? {
          outlineOffset: 4,
          outlineColor: isDark ? 'white' : 'black',
          transform: `scale(${scale})`,
        }
      : null),
  };
}
