import buttonTheme from './components/button';
import inputTheme from './components/input';
import tabsTheme from './components/tabs';
import cardsTheme from './components/card';
import modalTheme from './components/modal';
import linkTheme from './components/link';
import menuTheme from './components/menu';
import popoverTheme from './components/popover';
import tableTheme from './components/table';
import checkboxTheme from './components/checkbox';
import switchTheme from './components/switch';
import textTheme from './components/text';
import tagTheme from './components/tag';

const customTheme = {
  styles: {
    // Don't replace global styles set by the angular app
    global: () => ({
      ':root': {
        scrollbarColor: 'gray black',
        scrollbarWidth: 'thin !important',
        _light: { scrollbarColor: 'gray white' },
      },
      '::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderRadius: '12px',
        transition: 'background-color 200ms ease',
      },
      '::-webkit-scrollbar-track:hover': {
        backgroundColor: 'rgba(0,0,0,0.3)',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '12px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
      body: {
        bg: null,
        color: null,
        fontFamily: 'Instrument Sans',
      },
      '*::placeholder': {
        color: null,
      },
      '.composition-carousel-list': {
        flexDirection: 'column',
        alignItems: 'baseline',
      },
    }),
  },
  colors: {
    gray: {
      10: '#f7f7f7',
      50: '#ededed',
      80: '#e0e0e0',
      100: '#d9d9d9',
      200: '#bdbdbd',
      300: '#999999',
      400: '#787878',
      500: '#5c5c5c',
      600: '#474747',
      700: '#363636',
      800: '#2e2e2e',
      900: '#262626',
    },
    orangeAlpha: { 30010: '#f6ad551a', 50010: '#DD6B201a' },
    redAlpha: { 60010: '#C530301a' },
    texas: {
      secondary: {
        700: '#403e3a',
      },
      normal: {
        100: '#FFFFFF',
      },
      sand: {
        10: '#F8E2C8',
        20: '#F3DABE',
        30: '#E8CBAB',
        50: '#DEBE9A',
        75: '#D0AE89',
        100: '#C6A37C',
        40085: '#876d50D9',
      },
      burntSienna: { 100: '#D95B35', 200: '#C6502C', 500: '#C6502C' },
      green: {
        100: '#9ade9d',
      },
      danger: {
        50: '#FEF5F2',
        100: '#f27272',
        200: '#B43D18',
      },
      dangerAlpha: { 10010: '#f272721a' },
      warning: {
        100: '#EDD657',
      },
      notification: {
        info: '#26A0F8',
      },
      bg: {
        600: '#474747',
        700: '#3E3E3E',
        800: '#333333',
        900: '#242424',
        90085: '#242424D9',
        950: '#1F1F1F',
        980: '#101010',
        blueTint: {
          50: '#242730',
          100: '#1B1D24',
          200: '#1c1b20',
        },
        dark: '#121212',
        light: '#f2f2f2',
      },
    },
  },
  components: {
    Text: textTheme,
    Heading: {
      baseStyle: {
        textTransform: 'uppercase',
        fontFamily: 'Barlow',
      },
      variants: {
        drawer: {
          color: 'gray.50',
          fontSize: '1.25rem',
          _light: { color: 'gray.800' },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '0.9em',
        fontWeight: 'normal',
        color: 'gray.200',
        _light: { color: 'texas.bg.700' },
      },
      variants: {
        navbar: {
          _light: {
            color: 'gray.200',
          },
        },
      },
    },
    Input: inputTheme,
    Button: buttonTheme,
    Tabs: tabsTheme,
    Card: cardsTheme,
    Link: linkTheme,
    Menu: menuTheme,
    Popover: popoverTheme,
    Checkbox: checkboxTheme,
    Switch: switchTheme,
    Tag: tagTheme,
    TableContainer: {
      background: 'texas.bg.900',
      _light: { background: 'white' },
    },
    Table: tableTheme,
    Modal: modalTheme,
  },
  fonts: {
    barlow: 'Barlow',
    instrumentSans: 'Instrument Sans',
  },
};

export { customTheme };
