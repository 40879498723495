import { Grid, GridItem } from '@chakra-ui/react';
import { blinkAnimation } from '@texas/resources/animations/animations';

type GridLocation = 'rows' | 'columns' | 'cells';

export function MatrixHighlightHelper({ zone }: { zone: GridLocation }) {
  return (
    <Grid
      gridTemplateColumns="12px 64px"
      gridTemplateRows="12px 64px"
      gridGap={1}
    >
      <GridItem />
      <GridItem
        bg="texas.bg.700"
        _light={{ bg: 'gray.100' }}
        {...(zone === 'columns'
          ? {
              animation: blinkAnimation(),
              bg: 'texas.sand.100',
              _light: { bg: 'texas.burntSienna.100' },
            }
          : null)}
      />
      <GridItem
        bg="texas.bg.700"
        _light={{ bg: 'gray.100' }}
        {...(zone === 'rows'
          ? {
              animation: blinkAnimation(),
              bg: 'texas.sand.100',
              _light: { bg: 'texas.burntSienna.100' },
            }
          : null)}
      />
      <GridItem
        bg="texas.bg.700"
        _light={{ bg: 'gray.100' }}
        {...(zone === 'cells'
          ? {
              animation: blinkAnimation(),
              bg: 'texas.sand.100',
              _light: { bg: 'texas.burntSienna.100' },
            }
          : null)}
      />
    </Grid>
  );
}
