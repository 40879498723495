import {
  useDisclosure,
  Flex,
  Heading,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Img,
  useColorMode,
} from '@chakra-ui/react';
import { EmptyDataButton } from '../../button/EmptyDataButton';
import { useState } from 'react';
import { navLinkOffsetStyle } from '@texas/resources/styles';
import { fadeInRightAnimation } from '@texas/resources/animations/animations';
import { Icons, defaultIconSize } from '../../Icons';
import { enumKey } from '@texas/utils/helpers/enumHelpers';
import * as characterImages from '@assets/characters/characterImages';
import {
  Character,
  characterDescription,
  characters,
  characterTitle,
} from './character';
import { useTranslation } from 'react-i18next';
import { compositionApi } from '@texas/api/endpoints/compositionApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ErrorDetails } from '../../alert/ErrorDetails';
import { TopRightContainerComponent } from '../../TopRightContainerComponent';

type Mode = 'read-only';

export function CharacterContainer({
  compositionId,
  character,
  onChange,
  mode,
}: {
  compositionId: number;
  character?: Character;
  onChange: (character: Character) => void;
  mode?: Mode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  if (mode === 'read-only') {
    return (
      <TopRightContainerComponent bar={<Icons.Lock />}>
        <Flex
          flexDir="column"
          sx={{
            ':hover *[data-hover-id="text"]': {
              height: '12px',
              opacity: 1,
            },
          }}
          bg="texas.bg.700"
          _light={{ bg: 'gray.50' }}
          borderRadius="md"
          padding={2}
          cursor="pointer"
        >
          <Flex gap={2} h="64px">
            <Box>
              <Img
                h="full"
                src={
                  characterImages[
                    enumKey(
                      Character,
                      character,
                    ) as keyof typeof characterImages
                  ]
                }
                borderRadius={2}
                aria-label="Character image"
              />
            </Box>
            <Flex flexDir="column" justify="center">
              <Heading fontSize="md">
                {characterTitle(enumKey(Character, character), t)}
              </Heading>
              <Text
                height="0"
                opacity={0}
                transition="height 200ms ease, opacity 200ms ease"
                fontSize="sm"
                data-hover-id="text"
              >
                {t('composition.character.noEdit')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </TopRightContainerComponent>
    );
  }

  return (
    <>
      {character ? (
        <Flex
          onClick={onOpen}
          flexDir="column"
          bg="texas.bg.700"
          _light={{ bg: 'gray.50' }}
          borderRadius="md"
          padding={2}
          cursor="pointer"
          sx={{
            ':hover *[data-hover-id="text"]': {
              height: '12px',
              opacity: 1,
            },
          }}
        >
          <Flex gap={2} h="64px">
            <Box>
              <Img
                h="full"
                src={
                  characterImages[
                    enumKey(
                      Character,
                      character,
                    ) as keyof typeof characterImages
                  ]
                }
                borderRadius={2}
                aria-label="Character image"
              />
            </Box>
            <Flex flexDir="column" justify="center">
              <Heading fontSize="md">
                {characterTitle(enumKey(Character, character), t)}
              </Heading>
              <Text
                data-hover-id="text"
                height="0"
                opacity={0}
                transition="height 200ms ease, opacity 200ms ease"
                fontSize="sm"
              >
                {t('composition.character.change')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <EmptyDataButton
          onClick={onOpen}
          label={t('composition.character.noSelected')}
          description={t('composition.character.viewAvailable')}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('composition.character.select')}</ModalHeader>

          <ModalCloseButton />
          <InnerModal
            compositionId={compositionId}
            onChange={(c) => {
              onChange(c);
              onClose();
            }}
            character={character}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

function InnerModal({
  onChange,
  compositionId,
  character,
}: {
  onChange: (character: Character) => void;
  compositionId: number;
  character?: Character;
}) {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const [selectedCharacter, setSelectedCharacter] = useState<
    Character | undefined
  >(character);
  const {
    request: setRequest,
    loading,
    error,
  } = useApiRequest(compositionApi.setCharacter);

  const performRequest = (character: Character) =>
    request(setRequest, [compositionId, { character }], () => {
      onChange(character);
    });

  return (
    <>
      <ErrorDetails error={error} />
      <ModalBody>
        <Flex gap={6}>
          <Flex flexDir="column" gap={2} minW="300px" maxW="300px">
            {characters.map((x) => {
              return (
                <Button
                  {...navLinkOffsetStyle(
                    x.value === selectedCharacter,
                    1.02,
                    colorMode,
                  )}
                  h="auto"
                  py={2}
                  borderRadius="md"
                  justifyContent="start"
                  key={x.value}
                  textAlign="start"
                  _light={{
                    bg: 'gray.50',
                  }}
                  onClick={() => {
                    setSelectedCharacter(x.value);
                  }}
                >
                  <Heading fontSize="md">
                    {characterTitle(enumKey(Character, x.value), t)}
                  </Heading>
                </Button>
              );
            })}
          </Flex>

          <Box>
            {selectedCharacter !== undefined && (
              <Flex animation={fadeInRightAnimation()} flexDir="column" gap={4}>
                <Flex align="center" gap={2}>
                  <Icons.InformationOutline boxSize={defaultIconSize} />
                  <Heading fontSize="md">{t('general.description')}</Heading>
                </Flex>
                <Text>
                  {characterDescription(
                    enumKey(Character, selectedCharacter),
                    t,
                  )}
                </Text>

                {selectedCharacter !== Character.None && (
                  <>
                    <Heading fontSize="md">{t('general.preview')}</Heading>
                    <Img
                      src={
                        characterImages[
                          enumKey(
                            Character,
                            selectedCharacter,
                          ) as keyof typeof characterImages
                        ]
                      }
                      borderRadius={2}
                      aria-label="Character image"
                    />
                  </>
                )}
              </Flex>
            )}
          </Box>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={loading}
          isDisabled={loading}
          variant="texas-solid"
          onClick={() => {
            if (selectedCharacter === undefined) return;
            performRequest(selectedCharacter);
          }}
        >
          {t('composition.character.choose')}
        </Button>
      </ModalFooter>
    </>
  );
}
