import {
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Flex,
  Text,
  IconButton,
  useDisclosure,
  Button,
  Box,
  Textarea,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import {
  ColorData,
  CompositionMaterial,
  CreateCompositionMaterialRequest,
} from '@texas/api/endpoints/compositionApi';
import { materialsApi } from '@texas/api/endpoints/metadata/materialsApi';
import { qualitiesApi } from '@texas/api/endpoints/metadata/qualitiesApi';
import { techniquesApi } from '@texas/api/endpoints/metadata/techniquesApi';
import { treatmentsApi } from '@texas/api/endpoints/metadata/treatmentsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { Icons } from '@texas/components/shared/Icons';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ReactSelectOption, ReactSelectOptionWithDesc } from '@texas/types';
import { useRef, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ColorPicker, GetColorWithType } from '../../colorPicker/ColorPicker';
import { setValueAsDecimalExtender } from '../../../article/variant/sales/shared';
import { AutoGrowTextareaContainer } from '@texas/components/shared/form/AutoGrowTextareaContainer';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { ColorBox } from '../../colorPicker/ColorBox';
import React from 'react';
import { PopoverOptionComponent } from '../../../article/variant/compositions/shared/composition/PopoverOptionComponent';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';

export type MaterialFormType = 'controller' | 'color-controller';

interface Props extends SharedDisclosureProps {
  loading: boolean;
  compositionMaterial?: CompositionMaterial | null;
  subTitle?: string;
  productGroupId: number | null;
  onFormSubmit: (data: CreateCompositionMaterialRequest) => void;
  formType?: MaterialFormType;
}

export function CompositionMaterialForm({
  loading,
  compositionMaterial,
  productGroupId,
  isOpen,
  onClose,
  onFormSubmit,
  subTitle,
  formType,
}: Props) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateCompositionMaterialRequest>({
    defaultValues: {
      materialId: null,
      note: '',
      techniqueIds: [],
      treatmentIds: [],
      weight: 0,
      colors: [],
      qualities: [],
    },
  });

  const { t } = useTranslation();

  const {
    onOpen: onSelectColorOpen,
    onClose: onSelectColorClose,
    isOpen: isSelectColorOpen,
  } = useDisclosure();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'qualities',
  });

  const [qualityIds, setQualityIds] = useState<number[]>([]);
  const ref = useRef(null);

  useEffect(() => {
    if (!compositionMaterial) return;
    setQualityIds(compositionMaterial.qualities.map((x) => x.id));
    const colors = compositionMaterial.colors.map<ColorData>((x) => ({
      ...(x.colorType == null
        ? GetColorWithType(x)
        : { hex: x.hex, code: x.code, colorType: x.colorType, name: x.name }),
      description: x.description,
    }));

    reset({
      materialId: compositionMaterial.material.id,
      note: compositionMaterial.note,
      techniqueIds: compositionMaterial.techniques.map((x) => x.id),
      treatmentIds: compositionMaterial.treatments.map((x) => x.id),
      weight: compositionMaterial.weight,
      colors: colors,
      qualities: compositionMaterial.qualities.map((x) => ({
        id: x.id,
        value: x.value,
      })),
    });
  }, [compositionMaterial, reset]);

  const onSubmit = (data: CreateCompositionMaterialRequest) => {
    onFormSubmit(data);
    reset();
    setQualityIds([]);
    onClose();
  };

  const {
    qualities,
    treatments,
    techniques,
    materials,
    materialsLoading,
    techniquesLoading,
    treatmentsLoading,
    qualitiesLoading,
  } = useCompositionData(productGroupId);

  return (
    <>
      <ColorPicker
        onSelect={(e) => {
          setValue('colors', e);
          onSelectColorClose();
        }}
        onClose={onSelectColorClose}
        isOpen={isSelectColorOpen}
        addedColors={watch('colors')}
      />
      <Modal size="xl" isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent ref={ref}>
          <ModalHeader>
            <Flex flexDir="column">
              {t('materials.compositionMaterial')}
              {subTitle && <Text fontSize="md">{subTitle}</Text>}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="90dvh" overflow="auto">
            <LoadingOverlayV2
              loaded={!loading}
              label={t('materials.loadingMaterial')}
            />
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Flex flexDir="column" gap={2}>
                {formType !== 'color-controller' && (
                  <>
                    <FormControl isInvalid={!!errors.materialId}>
                      <TexasFormLabel>{t('general.materials')}</TexasFormLabel>
                      <Controller
                        name="materialId"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: t('materials.materialRequired'),
                          },
                        }}
                        render={({ field }) => (
                          <TexasSelect
                            isLoading={materialsLoading}
                            value={{
                              label:
                                materials?.find((p) => p.id === field.value)
                                  ?.name ?? '',
                              value: field.value,
                            }}
                            onChange={(e) => field.onChange(e?.value)}
                            options={
                              materials?.map<ReactSelectOption>((x) => ({
                                value: x.id,
                                label: x.name,
                              })) ?? []
                            }
                            closeMenuOnSelect={true}
                          />
                        )}
                      />
                      <ErrorLabel text={errors.materialId?.message} />
                    </FormControl>
                    <FormControl>
                      <TexasFormLabel>
                        {t('configuration.techniques')}
                      </TexasFormLabel>
                      <Controller
                        name="techniqueIds"
                        control={control}
                        render={({ field }) => (
                          <TexasSelect
                            {...field}
                            isLoading={techniquesLoading}
                            isClearable={true}
                            value={field.value?.map<ReactSelectOptionWithDesc>(
                              (x) => ({
                                label:
                                  techniques?.find((p) => p.id === x)?.value ??
                                  '',
                                value: x,
                                description: techniques?.find((p) => p.id === x)
                                  ?.description,
                              }),
                            )}
                            isMulti={true}
                            onChange={(e) =>
                              field.onChange(e.map((x) => x.value))
                            }
                            options={
                              techniques?.map<ReactSelectOptionWithDesc>(
                                (x) => ({
                                  value: x.id,
                                  label: x.value,
                                  description: x.description,
                                  ref: ref,
                                }),
                              ) ?? []
                            }
                            closeMenuOnSelect={false}
                            components={{ Option: PopoverOptionComponent }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl>
                      <TexasFormLabel>
                        {t('configuration.treatments')}
                      </TexasFormLabel>
                      <Controller
                        name="treatmentIds"
                        control={control}
                        render={({ field }) => (
                          <TexasSelect
                            {...field}
                            isLoading={treatmentsLoading}
                            isClearable={true}
                            value={field.value?.map<ReactSelectOptionWithDesc>(
                              (x) => ({
                                label:
                                  treatments?.find((p) => p.id === x)?.name ??
                                  '',
                                value: x,
                                description: treatments?.find((p) => p.id === x)
                                  ?.description,
                              }),
                            )}
                            isMulti={true}
                            onChange={(e) =>
                              field.onChange(e.map((x) => x.value))
                            }
                            options={
                              treatments?.map<ReactSelectOptionWithDesc>(
                                (x) => ({
                                  value: x.id,
                                  label: x.name,
                                  description: x.description,
                                  ref: ref,
                                }),
                              ) ?? []
                            }
                            closeMenuOnSelect={false}
                            components={{ Option: PopoverOptionComponent }}
                          />
                        )}
                      />
                    </FormControl>
                    <Flex flexDir="column">
                      <TexasFormLabel>
                        {t('configuration.qualities')}
                      </TexasFormLabel>
                      <FormControl
                        bg="texas.bg.800"
                        _light={{ bg: 'gray.10' }}
                        p={2}
                        borderRadius={6}
                      >
                        <TexasSelect
                          isLoading={qualitiesLoading}
                          isClearable={false}
                          isMulti={true}
                          value={qualityIds.map<ReactSelectOption>((x) => ({
                            label:
                              qualities?.find((p) => p.id === x)?.name ?? '',
                            value: x,
                          }))}
                          onChange={(_, meta) => {
                            if (meta.action === 'select-option') {
                              setQualityIds((s) => [...s, meta.option?.value]);
                              append({ id: meta.option?.value, value: '' });
                              return;
                            }

                            setQualityIds((s) =>
                              s.filter((x) => x !== meta.removedValue?.value),
                            );
                            remove(
                              fields.findIndex(
                                (x) => x.id === meta.removedValue?.value,
                              ),
                            );
                          }}
                          options={
                            qualities?.map<ReactSelectOption>((x) => ({
                              value: x.id,
                              label: x.name,
                            })) ?? []
                          }
                          closeMenuOnSelect={false}
                        />
                        <Box pt={2}>
                          {fields.map((x, i) => {
                            const quality = qualities?.find(
                              (q) => q.id === qualityIds[i],
                            );
                            return (
                              <React.Fragment key={x.id}>
                                <Flex alignItems="center" gap={2} pt={2}>
                                  <Text w="xl">{quality?.name}</Text>
                                  {quality?.inputField && (
                                    <FormControl
                                      isInvalid={!!errors.qualities?.[i]?.value}
                                    >
                                      <Input
                                        {...register(
                                          `qualities.${i}.value` as const,
                                          {
                                            maxLength: 50,
                                          },
                                        )}
                                        variant="outline"
                                      />
                                    </FormControl>
                                  )}
                                  <IconButton
                                    ml="auto"
                                    size="sm"
                                    variant="ghost"
                                    icon={<Icons.Close />}
                                    onClick={() => {
                                      setQualityIds((x) =>
                                        x.filter((q) => q !== quality?.id),
                                      );
                                      remove(i);
                                    }}
                                    aria-label={t('general.remove')}
                                  />
                                </Flex>
                                <ErrorLabel
                                  text={errors.qualities?.[i]?.value?.message}
                                />
                              </React.Fragment>
                            );
                          })}
                        </Box>
                      </FormControl>
                    </Flex>
                  </>
                )}

                {formType !== 'controller' && (
                  <Flex direction="column">
                    <TexasFormLabel>{t('general.colors')}</TexasFormLabel>
                    <FormControl
                      bg="texas.bg.800"
                      _light={{ bg: 'gray.10' }}
                      p={2}
                      borderRadius={6}
                    >
                      <Flex flexDir="column" gap={2}>
                        <Button
                          variant="texas-light"
                          onClick={() => onSelectColorOpen()}
                          w="full"
                        >
                          {t('colors.selectColors')}
                        </Button>
                        {watch('colors').map((x) => (
                          <ColorBox
                            color={x}
                            key={`${x.code}-${x.hex}`}
                            colorDescription={x.description}
                          />
                        ))}
                      </Flex>
                    </FormControl>
                  </Flex>
                )}

                {formType !== 'color-controller' && (
                  <>
                    <FormControl isInvalid={!!errors.weight}>
                      <TexasFormLabel>{t('composition.weight')}</TexasFormLabel>
                      <InputGroup>
                        <Input
                          variant="texas"
                          {...register('weight', {
                            setValueAs: (value) =>
                              setValueAsDecimalExtender(value, false),
                            maxLength: {
                              value: 21,
                              message: t('errors.maxLength', { count: 21 }),
                            },
                            min: {
                              value: 0,
                              message: t('composition.canNotBeNegative'),
                            },
                          })}
                          placeholder="0"
                        />
                        <InputRightAddon h="auto" border="none">
                          {t('composition.kg')}
                        </InputRightAddon>
                      </InputGroup>

                      <ErrorLabel text={errors.weight?.message} />
                    </FormControl>
                    <FormControl isInvalid={!!errors.note}>
                      <TexasFormLabel>{t('general.note')}</TexasFormLabel>
                      <AutoGrowTextareaContainer value={watch('note')}>
                        <Textarea
                          {...register('note')}
                          minHeight={10}
                          height="auto"
                        />
                      </AutoGrowTextareaContainer>
                      <ErrorLabel text={errors.note?.message} />
                    </FormControl>
                  </>
                )}

                <SubmitButton loading={isSubmitting}>
                  {t('general.save')}
                </SubmitButton>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function useCompositionData(productGroupId: number | null) {
  const {
    data: materials,
    refetch: refetchMaterials,
    loading: materialsLoading,
  } = useApiResource(materialsApi.getAll);

  const {
    data: qualities,
    refetch: refetchQualities,
    loading: qualitiesLoading,
  } = useApiResource(qualitiesApi.getAll);

  const {
    data: treatments,
    refetch: refetchTreatments,
    loading: treatmentsLoading,
  } = useApiResource(treatmentsApi.getAll);

  const {
    data: techniques,
    refetch: refetchTechniques,
    loading: techniquesLoading,
  } = useApiResource(techniquesApi.getAll);

  useEffect(() => {
    refetchQualities({
      sortBy: '',
      sortDesc: false,
      searchTerm: '',
      includeArchived: false,
      preciseIdFiltering: false,
      productGroupId: productGroupId,
    });
    refetchTechniques();
    refetchMaterials();
    refetchTreatments({
      sortBy: '',
      sortDesc: false,
      searchTerm: '',
      includeArchived: false,
      preciseIdFiltering: false,
      productGroupId: productGroupId,
    });
  }, [
    productGroupId,
    refetchMaterials,
    refetchQualities,
    refetchTechniques,
    refetchTreatments,
  ]);

  return {
    qualities,
    treatments,
    techniques,
    materials,
    materialsLoading,
    techniquesLoading,
    treatmentsLoading,
    qualitiesLoading,
  };
}
