import { Box, Flex, Text } from '@chakra-ui/react';
import { AngularNavLink } from '@texas/components/shared/AngularNavLink';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';

export function MissingSuppliers() {
  const { t } = useTranslation();
  return (
    <Box animation={fadeInLeftAnimation()}>
      <Flex gap={2}>
        <Icons.AlertCircle boxSize={defaultIconSize} pt={1} />
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            {t('variant.supplier.noneConfigured')}
          </Text>
          <AngularNavLink to="variant.suppliers">
            <Flex
              gap={1}
              align="center"
              color="texas.sand.100"
              _light={{ color: 'texas.burntSienna.100' }}
            >
              <Text>{t('variant.supplier.goConfigure')}</Text>
              <Icons.ArrowRight />
            </Flex>
          </AngularNavLink>
        </Box>
      </Flex>
    </Box>
  );
}
