import { Flex, Spinner, Text } from '@chakra-ui/react';

type Variant = 'default' | 'large-center';

interface Props {
  icon?: React.ReactNode;
  variant?: Variant;
  isLoading?: boolean;
  label: string;
  description: string;
  onClick: () => void;
}

export function EmptyDataButton({
  label,
  isLoading,
  description,
  onClick,
  icon,
  variant = 'default',
}: Props) {
  return (
    <Flex
      height="full"
      border="2px dashed"
      borderColor="gray.600"
      _light={{
        borderColor: 'gray.200',
      }}
      py={2}
      px={4}
      borderRadius="md"
      cursor="pointer"
      align="center"
      onClick={onClick}
      sx={{
        ':hover *[data-hover-id="text"]': {
          height: '12px',
          opacity: 1,
        },
      }}
    >
      {variant === 'default' && (
        <Flex flexDir="column" justify="center" h="46px">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Flex gap={1} align="center">
                {icon}
                <Text
                  fontWeight="bold"
                  color="gray.500"
                  _light={{ color: 'gray.300' }}
                >
                  {label}
                </Text>
              </Flex>

              <Text
                data-hover-id="text"
                height="0"
                opacity={0}
                transition="height 200ms ease, opacity 200ms ease"
                fontSize="sm"
              >
                {description}
              </Text>
            </>
          )}
        </Flex>
      )}
      {variant === 'large-center' && (
        <Flex
          flexDir="column"
          justify="center"
          align="center"
          h="46px"
          w="240px"
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {icon}
              <Text
                fontWeight="bold"
                color="gray.500"
                _light={{ color: 'gray.300' }}
              >
                {label}
              </Text>
              <Text
                data-hover-id="text"
                height="0"
                opacity={0}
                transition="height 200ms ease, opacity 200ms ease"
                fontSize="sm"
              >
                {description}
              </Text>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
}
