import { TierPrice, tierPriceApi } from '@texas/api/endpoints/tierPriceApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface DeleteTierPriceDialogProps extends SharedDisclosureProps {
  onDelete: (id: number) => void;
  tierPrice: TierPrice;
}

export function DeleteTierPriceDialog(props: DeleteTierPriceDialogProps) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('tierPrice.deleteTierPrice', {
        quantity: props.tierPrice.quantity,
      })}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={tierPriceApi.deleteTier}
      args={[props.tierPrice.id]}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPerformed={props.onDelete}
      onSuccessTitle={t('tierPrice.tierDeleted')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
