import { Flex, Box, Heading, Text } from '@chakra-ui/react';
import { MatrixHighlightHelper } from '@texas/components/shared/matrix/MatrixHighlightHelper';
import { CompositionMaterialGrid } from './CompositionMaterialGrid';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import {
  AxisType,
  compositionGroupMatrixApi,
  MatrixAxis,
} from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { useEffect } from 'react';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { useTranslation } from 'react-i18next';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { SpinnerWithLabel } from '@texas/components/shared/SpinnerWithLabel';

export function MatrixColors({
  compositionGroupId,
  onLoad,
  colorAxis,
}: {
  compositionGroupId: number;
  onLoad?: (groups: CompositionGroup[]) => void;
  colorAxis: MatrixAxis;
}) {
  const { t } = useTranslation();
  const { data, refetch, loading } = useApiResource(
    compositionGroupMatrixApi.getAxisCells,
  );

  useSignalSubscriptionEvents({
    triggerOnEvent: () => {
      refetch(compositionGroupId, AxisType.Colors);
    },
    signalEvents: [
      compositionEvents.onMaterialAdded,
      compositionEvents.onMaterialChanged,
      compositionEvents.onMaterialRemoved,
      compositionEvents.onAdd,
      compositionEvents.onRestored,
      compositionEvents.onArchived,
      compositionEvents.onRename,
    ],
  });

  useEffect(() => {
    refetch(compositionGroupId, AxisType.Colors);
  }, [compositionGroupId, refetch]);

  useEffect(() => {
    const onMaterialAdded = compositionEvents.onMaterialAdded.sub(() => {
      refetch(compositionGroupId, AxisType.Colors);
    });
    const onMaterialChanged = compositionEvents.onMaterialChanged.sub(() => {
      refetch(compositionGroupId, AxisType.Colors);
    });
    const onCompositionRename = compositionEvents.onRename.sub(() => {
      refetch(compositionGroupId, AxisType.Colors);
    });
    return () => {
      onMaterialAdded();
      onMaterialChanged();
      onCompositionRename();
    };
  }, [compositionGroupId, refetch]);

  useEffect(() => {
    if (!data) return;
    onLoad?.(data);
  }, [data, onLoad]);

  return (
    <Flex flexDir="column" gap={4}>
      <Flex gap={4} align="center">
        {ensureEnumNumber(MatrixAxis, colorAxis) === MatrixAxis.Rows ? (
          <>
            <MatrixHighlightHelper zone="rows" />
            <Box>
              <Heading fontSize="lg">{t('matrix.rows')}</Heading>
              <Text>{t('matrix.colorDesc')}</Text>
            </Box>
          </>
        ) : (
          <>
            <MatrixHighlightHelper zone="columns" />
            <Box>
              <Heading fontSize="lg">{t('matrix.columns')}</Heading>
              <Text>{t('matrix.colorDesc')}</Text>
            </Box>
          </>
        )}
      </Flex>
      {loading && <SpinnerWithLabel label={t('colors.loading')} />}
      {data?.map((y) => {
        return (
          <CompositionMaterialGrid
            key={y.id}
            group={y}
            refetch={() => refetch(compositionGroupId, AxisType.Colors)}
          />
        );
      })}
    </Flex>
  );
}
