import { Flex } from '@chakra-ui/react';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { CountIcon } from '@texas/components/shared/CountIcon';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { useTranslation } from 'react-i18next';

export function SupplierOverview({
  versions,
}: {
  versions: CompositionGroup[];
}) {
  const { t } = useTranslation();
  const activeVersions = versions.filter((v) => !v.archived).length;
  const activePas = getTotalActivePas(versions);
  const acceptedPas = getTotalAcceptedPas(versions);

  return (
    <Flex gap={3}>
      <CountIcon
        icon={<Icons.Layers boxSize={defaultIconSize} />}
        count={activeVersions}
        tooltip={t('compositionGroup.overview.activeVersions')}
      />
      <CountIcon
        icon={<Icons.Eye boxSize={defaultIconSize} />}
        count={activePas}
        tooltip={t('compositionGroup.overview.activePa')}
      />
      <CountIcon
        icon={<Icons.EyeCheck boxSize={defaultIconSize} />}
        count={acceptedPas}
        tooltip={t('compositionGroup.overview.acceptedPa')}
      />
    </Flex>
  );
}

function getTotalActivePas(versions: CompositionGroup[]) {
  let totalPas = 0;

  versions.forEach((v) => {
    const pending =
      v.productApprovals?.filter(
        (p) =>
          ensureEnumNumber(ProductApprovalState, p.state) ===
          ProductApprovalState.Pending,
      ).length ?? 0;
    const accepted =
      v.productApprovals?.filter(
        (p) =>
          ensureEnumNumber(ProductApprovalState, p.state) ===
          ProductApprovalState.Accepted,
      ).length ?? 0;

    totalPas += accepted + pending;
  });

  return totalPas;
}

function getTotalAcceptedPas(versions: CompositionGroup[]) {
  let totalPas = 0;

  versions.forEach((v) => {
    const accepted =
      v.productApprovals?.filter(
        (p) =>
          ensureEnumNumber(ProductApprovalState, p.state) ===
          ProductApprovalState.Accepted,
      ).length ?? 0;

    totalPas += accepted;
  });

  return totalPas;
}
