import { Box, Tooltip } from '@chakra-ui/react';
import checker from '@assets/checker.svg';

export function ColorDot({
  hex,
  name,
  code,
}: {
  hex: string | null;
  name: string;
  code: string;
}) {
  const label = name + ', ' + code;
  return (
    <Tooltip label={label}>
      <Box
        boxSize="4"
        borderRadius="full"
        border="1px solid gray"
        {...((hex?.length ?? 0) > 0
          ? { bgColor: `#${hex}` }
          : {
              bg: `url(${checker})`,
              backgroundSize: 'contain',
              opacity: 0.2,
              backgroundColor: '#b6bde5',
            })}
      />
    </Tooltip>
  );
}
