import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react';
import { Contact, ContactAddToType } from '@texas/api/endpoints/contactsApi';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { CreateContact } from '@texas/components/contacts/CreateContact';
import { SelectContact } from '@texas/components/contacts/SelectContact';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends SharedDisclosureProps {
  supplierId: number;
  variantId: number;
  onContactsSelect: (ids: number[]) => void;
  acceptLabel: string;
}

export function ChooseContactsModal({
  variantId,
  supplierId,
  isOpen,
  onClose,
  onContactsSelect,
  acceptLabel,
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('variant.supplier.selectContacts')}</ModalHeader>
        <ModalCloseButton />
        <Inner
          acceptLabel={acceptLabel}
          variantId={variantId}
          onClose={onClose}
          onContactsSelect={onContactsSelect}
          supplierId={supplierId}
        />
      </ModalContent>
    </Modal>
  );
}

function Inner({
  variantId,
  supplierId,
  onContactsSelect,
  onClose,
  acceptLabel,
}: {
  variantId: number;
  supplierId: number;
  onContactsSelect: (ids: number[]) => void;
  onClose: () => void;
  acceptLabel: string;
}) {
  const { t } = useTranslation();
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const {
    isOpen: isNewContactOpen,
    onClose: onNewContactClose,
    onOpen: onNewContactOpen,
  } = useDisclosure();

  const { data, refetch, loading } = useApiResource(
    variantsApi.getSupplierContacts,
  );

  useEffect(() => {
    refetch(variantId, supplierId);
  }, [refetch, supplierId, variantId]);

  useEffect(() => {
    setSelectedContacts(data?.map((x) => x.id) ?? []);
  }, [data]);

  return (
    <>
      <ModalBody>
        {loading && <Spinner />}
        <Text>{t('variant.supplier.selectContactDesc')}</Text>
        <SelectContact
          settings={{ searchBar: 'hidden', alwaysShowCreateButton: true }}
          selectedIds={selectedContacts}
          mode="select"
          filterOn={ContactAddToType.Supplier}
          onSelect={(c) => {
            if (selectedContacts.some((s) => s === c.id)) {
              setSelectedContacts((s) => s.filter((s2) => s2 !== c.id));
              return;
            }

            setSelectedContacts((s) => [...s, c.id]);
          }}
          referenceId={supplierId}
          onCreateContactClick={onNewContactOpen}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" mr={3} onClick={onClose}>
          {t('general.close')}
        </Button>
        <Button onClick={() => onContactsSelect(selectedContacts)}>
          {acceptLabel}
        </Button>
      </ModalFooter>
      <CreateContactModal
        isOpen={isNewContactOpen}
        onClose={onNewContactClose}
        onCreated={(c) => setSelectedContacts((s) => [...s, c.id])}
        supplierId={supplierId}
      />
    </>
  );
}

function CreateContactModal({
  isOpen,
  onClose,
  onCreated,
  supplierId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCreated: (c: Contact) => void;
  supplierId: number;
}) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('contacts.create')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateContact
            addTo={ContactAddToType.Supplier}
            onCreated={(c) => {
              onCreated(c);
              onClose();
            }}
            referenceId={supplierId}
            input=""
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
