import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import {
  CreateCustomerRequest,
  customersApi,
  CustomerType,
} from '@texas/api/endpoints/customersApi';
import { ReactSelectOption } from '@texas/types';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { Role, User, userApi } from '@texas/api/endpoints/userApi';
import { useActiveSession } from '@texas/hooks/useSession';
import { hasRole } from '@texas/utils/helpers/roleHelpers';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';

const customerTypes = [
  { label: 'Brand', value: CustomerType.Brand },
  { label: 'Vendor', value: CustomerType.Vendor },
];

export function NewCustomer() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();
  const toast = useToast();
  const { error, loading, request } = useApiRequest(
    customersApi.createCustomer,
  );

  const {
    data: userList,
    refetch: refetchUserList,
    loading: userListLoading,
  } = useApiResource(userApi.getUsers);

  useEffect(() => {
    refetchUserList();
  }, [refetchUserList]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<CreateCustomerRequest>({
    defaultValues: { customerType: CustomerType.Brand },
  });

  const customerTypeWatcher = useWatch({ control, name: 'customerType' });

  const onSubmit = async (data: CreateCustomerRequest) => {
    const response = await request(data);
    if (response.hasError) {
      toast({
        title: t('general.createFailed'),
        description: error?.message,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    toast({
      title: t('general.created'),
      status: 'success',
      isClosable: true,
    });
    reset();
    onClose();
  };

  const session = useActiveSession();
  if (
    !hasRole(session.currentUser.role, [
      Role.InternalAdministrator,
      Role.SystemAdministrator,
    ])
  ) {
    return null;
  }

  return (
    <>
      <Button
        className="md-raised"
        height="36px"
        fontSize={14}
        fontFamily="inherit"
        padding={1.5}
        borderRadius={3}
        onClick={onOpen}
        textTransform="uppercase"
        variant="texas-solid"
      >
        {t('general.newBrand')}
      </Button>

      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader fontWeight="bold">
            {customerTypeWatcher == CustomerType.Brand
              ? t('customer.createNewBrand')
              : t('customer.createNewVendor')}
          </ModalHeader>
          <ModalBody>
            {error && <ErrorDetails error={error} />}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" gap={4} pb={2}>
                <FormControl isRequired={true} isInvalid={!!errors.name}>
                  <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('name', {
                      required: true,
                      maxLength: {
                        value: 100,
                        message: t('errors.maxLength', { count: 100 }),
                      },
                    })}
                    placeholder={t('general.name')}
                  />
                  <ErrorLabel text={errors.name?.message} />
                </FormControl>
                <FormControl>
                  <TexasFormLabel>{t('general.type')}</TexasFormLabel>
                  <Controller
                    name="customerType"
                    control={control}
                    render={({ field }) => (
                      <TexasSelect
                        {...field}
                        required={true}
                        onChange={(e) => {
                          field.onChange(e?.value ?? null);
                        }}
                        value={customerTypes.find(
                          (x) => x.value === field.value,
                        )}
                        options={customerTypes}
                      />
                    )}
                  />
                  <ErrorLabel text={errors.customerType?.message} />
                </FormControl>
                <FormControl isRequired={true}>
                  <TexasFormLabel>
                    {t('general.keyAccountManager')}
                  </TexasFormLabel>
                  <Controller
                    name="brandOwnerId"
                    control={control}
                    render={({ field }) => (
                      <TexasSelect
                        {...field}
                        isLoading={userListLoading}
                        value={userList
                          ?.map((k) => ({
                            label: formatUserLabel(k),
                            value: k.id,
                          }))
                          .find((k) => k.value === field.value)}
                        onChange={(value) =>
                          field.onChange(value?.value ?? null)
                        }
                        required={true}
                        isClearable={false}
                        options={userList?.map<ReactSelectOption>((k) => ({
                          label: formatUserLabel(k),
                          value: k.id,
                        }))}
                      />
                    )}
                  />
                  <ErrorLabel text={errors.brandOwnerId?.message} />
                </FormControl>
                <FormControl>
                  <Controller
                    name="bcSampleApprovalRequired"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Flex gap={2}>
                        <Checkbox onChange={onChange} isChecked={value} />
                        <TexasFormLabel>
                          {t('customer.bcSampleApprovalRequired')}
                        </TexasFormLabel>
                      </Flex>
                    )}
                  />
                </FormControl>
                <SubmitButton loading={loading}>
                  {t('general.save')}
                </SubmitButton>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
function formatUserLabel(user: User) {
  return `${user.name} (${user.email})`;
}
