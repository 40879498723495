import { useToast, Box, Text } from '@chakra-ui/react';
import { suppliersApi } from '@texas/api/endpoints/suppliersApi';
import {
  VariantBranchSupplier,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { TexasDrawerBody } from '@texas/components/shared/drawer/TexasDrawerBody';
import { useRegexSearch } from '@texas/components/shared/hooks/useRegexSearch';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SuppliersList } from './SuppliersList';
import { LabelValue } from './LabelValue';

export function RecommendedSuppliers({
  variantId,
  branchId,
  onAdd,
}: {
  variantId: number;
  branchId: number;
  onAdd?: (data: VariantBranchSupplier) => void;
}) {
  const {
    data: recommendedSuppliers,
    refetch,
    loading,
    error,
    set,
  } = useApiResource(suppliersApi.variant.getRecommendedSuppliers);

  const { t } = useTranslation();
  const toast = useToast();

  const { search, setSearch, regexSearch } = useRegexSearch();

  useEffect(() => {
    refetch(variantId, branchId);
  }, [branchId, refetch, variantId]);

  const addSupplierBranch = useApiRequest(variantsApi.addSupplierBranch);

  if (!loading && error) {
    return (
      <TexasDrawerBody>
        <Text variant="main">{error.message}</Text>
      </TexasDrawerBody>
    );
  }

  return (
    <>
      <LoadingOverlayV2 loaded={!loading} label={t('general.loading')}>
        <Box
          pos="sticky"
          top={0}
          zIndex={1}
          bg="texas.bg.900"
          _light={{ bg: 'white' }}
          pt={2}
          gap={2}
        >
          <TexasDrawerBody>
            <Text variant="main">{t('suppliers.recommendationsBasedOn')}</Text>
            <LabelValue
              label={t('general.productGroup')}
              value={recommendedSuppliers?.productGroup}
            />
            <LabelValue
              label={t('general.state')}
              value={recommendedSuppliers?.state}
            />
          </TexasDrawerBody>

          <SearchFilterInput
            value={search}
            onChange={(searchTerm) => setSearch(searchTerm)}
            debounceDelay={0}
            placeholder={t('suppliers.filterSuppliers')}
          />
        </Box>

        <SuppliersList
          variantId={variantId}
          suppliers={
            recommendedSuppliers?.suppliers.filter((c) =>
              c.name.toLowerCase().match(regexSearch),
            ) ?? []
          }
          onClick={async (id, contactIds) => {
            await request(
              addSupplierBranch.request,
              [variantId, { branchId, supplierId: id, contactIds }],
              (data) => {
                if (!recommendedSuppliers) return;

                set((s) => ({
                  ...s!,
                  suppliers: s!.suppliers.map((x) => {
                    if (x.id === data.supplierId) {
                      return { ...x, alreadyAdded: true };
                    }

                    return x;
                  }),
                }));
                if (onAdd) {
                  onAdd(data);
                }
              },
              (error) => {
                toast({
                  title: t('general.createFailed'),
                  description: error.message,
                  status: 'error',
                  isClosable: true,
                });
              },
            );
          }}
        />
      </LoadingOverlayV2>
    </>
  );
}
