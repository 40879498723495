import { Tooltip, Flex, Text } from '@chakra-ui/react';

export function CountIcon({
  icon,
  count,
  tooltip,
}: {
  icon: React.ReactNode;
  count: number;
  tooltip?: string;
}) {
  return (
    <Tooltip label={tooltip}>
      <Flex flexDir="column" align="center" opacity={count === 0 ? 0.6 : 1}>
        {icon}
        <Text fontWeight="bold" fontSize="sm">
          {count}
        </Text>
      </Flex>
    </Tooltip>
  );
}
