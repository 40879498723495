import { ReactSelectOption } from '@texas/types';
import { TFunction } from 'i18next';

export enum Character {
  None = 0,
  CentreFold = 1,
  EndFold = 2,
  LoopFold = 3,
  ManhattanFold = 4,
  MitreFold = 5,
  StraightCut = 6,
  DieCut = 7,
}

export const characters: ReactSelectOption[] = [
  { label: 'None', value: Character.None },
  { label: 'Centre fold', value: Character.CentreFold },
  { label: 'End fold', value: Character.EndFold },
  { label: 'Loop fold', value: Character.LoopFold },
  { label: 'Manhattan fold', value: Character.ManhattanFold },
  { label: 'Mitre fold', value: Character.MitreFold },
  { label: 'Straight cut', value: Character.StraightCut },
  { label: 'Die cut', value: Character.DieCut },
];

export function characterDescription(
  character: keyof typeof Character,
  t: TFunction,
) {
  return t(`composition.character.${character}.desc`);
}

export function characterTitle(
  character: keyof typeof Character,
  t: TFunction,
) {
  return t(`composition.character.${character}.title`);
}
