import { httpClient } from '@texas/api/httpClient/httpClient';
import { Composition, Color } from '../compositionApi';
import { ProductApprovalState } from '../productApprovalsApi';
import { MatrixAxis } from './compositionGroupMatrixApi';

interface CreateCompositionGroupRequest {
  variantId: number;
  basedOnId?: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

interface CopyCompositionGroupRequest extends CreateCompositionGroupRequest {
  replaceCompositionGroupId: number | undefined;
}

export interface UpdateCompositionGroupRequest {
  note: string;
}

export interface SimpleProductApproval {
  productApprovalId: number;
  state: ProductApprovalState;
}

export interface CompositionGroup {
  articleId: number;
  id: number;
  variantId: number;
  cellX: number | null;
  matrixNodeXValue: string | null;
  matrixNodeXName: string | null;
  cellY: number | null;
  matrixNodeYValue: string | null;
  matrixNodeYName: string | null;
  colorAxis: MatrixAxis | null;
  supplierId: number | null;
  branchId: number | null;
  note: string;
  version: number;
  isTemplate: boolean;
  exists: boolean;
  compositions: Composition[];
  productApprovals: SimpleProductApproval[] | null;
  archived: string | null;
  fileId: number | null;
  fileIdentifier: string | null;
  folderId: number;
  hasAnyBasedOn: boolean;
  hasArchivedCompositions: boolean;
  basedOnGroupId: number | null;
  controlledById: number | null;
  isReady: boolean;
  articleProductGroupId: number | null;
  supplierName: string;
  basedOnGroupVersion: number | null;
  basedOnGroupName: string | null;
}

export interface CompositionGroupId {
  id: number;
  variantId: number;
}

interface LocateCompositionGroupParams {
  variantId: number;
  cellX?: number;
  cellY?: number;
  supplierId?: number;
  branchId?: number;
}

interface LocateVersionCompositionGroupParams {
  variantId: number;
  branchId: number;
  cellX?: number;
  cellY?: number;
  supplierId?: number;
}

interface SearchCompositionGroupsParams {
  variantId: number;
  branchIds: number[];
  supplierIds: number[];
}

export interface CompositionCell {
  x: number;
  y: number;
  colors: Color[];
  versionsCount: number;
  optionsCount: number;
}

interface GetCompositionGroupParams {
  includeArchived: boolean;
}

export interface CreateSamplesData {
  groupId: number;
  supplierNote: string;
  externalSupplierItemCode: string;
  supplierContactId: number;
  customerContactId: number;
}

export interface CreateSamplesRequest {
  readyDate: Date | null;
  data: CreateSamplesData[];
}

export const compositionGroupApi = {
  addGroup: (data: CreateCompositionGroupRequest) =>
    httpClient.post<CompositionGroup>(`/compositiongroups`, {
      data,
    }),
  copyGroup: (from: number, data: CopyCompositionGroupRequest) =>
    httpClient.put<CompositionGroup>(`/compositiongroups/${from}/copy`, {
      data,
    }),
  setBasedOn: (id: number, basedOnId: number) =>
    httpClient.put<CompositionGroup>(
      `/compositiongroups/${id}/based-on/${basedOnId}`,
    ),
  updateGroup: (id: number, data: UpdateCompositionGroupRequest) =>
    httpClient.put<CompositionGroup>(`/compositiongroups/${id}`, { data }),
  updateFile: (id: number, fileId: number) =>
    httpClient.put(`/compositiongroups/${id}/file/${fileId}`),
  deleteFile: (id: number) =>
    httpClient.delete(`/compositiongroups/${id}/file`),
  getGroup: (id: number, params?: GetCompositionGroupParams) =>
    httpClient.get<CompositionGroup>(`/compositiongroups/${id}`, { params }),
  getAllPreviousGroupIds: (params: LocateCompositionGroupParams) =>
    httpClient.get<CompositionGroupId[]>(`compositiongroups/previous`, {
      params,
    }),
  searchGroup: (params: SearchCompositionGroupsParams) =>
    httpClient.get<CompositionGroup[]>(`/compositiongroups/search`, {
      params,
    }),
  versionsOverview: (params: LocateVersionCompositionGroupParams) =>
    httpClient.get<CompositionGroup[]>(`/compositiongroups/overview/versions`, {
      params,
    }),
  optionsOverview: (params: { variantId: number }) =>
    httpClient.get<CompositionGroup[]>(`/compositiongroups/overview/options`, {
      params,
    }),
  archive: (id: number) =>
    httpClient.put<CompositionGroup>(`compositiongroups/${id}/archive`),
  restore: (id: number) =>
    httpClient.put<CompositionGroup>(`compositiongroups/${id}/restore`),
  markReady: (id: number) => httpClient.put(`compositiongroups/${id}/ready`),
  markNotReady: (id: number) =>
    httpClient.put(`compositiongroups/${id}/not-ready`),
  createSamples: (data: CreateSamplesRequest) =>
    httpClient.put(`compositiongroups/samples`, { data }),
};
