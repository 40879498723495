import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { CompositionBranch } from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { fadeInScalePopAnimation } from '@texas/resources/animations/animations';
import { clamp } from '@texas/utils/helpers/numberHelpers';
import { useTranslation } from 'react-i18next';

export function BranchVersionsOverview({
  branch,
  totalCellsWithSuppliers,
  totalCells,
}: {
  branch: CompositionBranch | undefined;
  totalCells: number;
  totalCellsWithSuppliers: number;
}) {
  const { t } = useTranslation();
  const activeVersions = getTotalActiveVersions(branch);
  const activePas = getTotalActivePas(branch);
  const acceptedPas = getTotalAcceptedPas(branch);

  return (
    <Box>
      <Heading fontSize="lg" pb={4}>
        {t('compositionGroup.overview.branchProgress')}
      </Heading>
      <Flex gap={6}>
        <ProgressComponent
          icon={<Icons.Layers boxSize={defaultIconSize} />}
          actual={activeVersions}
          max={totalCellsWithSuppliers}
          label={
            <Text color="gray.200" fontSize="sm" _light={{ color: 'gray.500' }}>
              {t('compositionGroup.overview.configuredCells', {
                count: activeVersions,
                max: totalCellsWithSuppliers,
              })}
            </Text>
          }
        />
        <ProgressComponent
          icon={<Icons.Eye boxSize={defaultIconSize} />}
          actual={activePas}
          max={totalCellsWithSuppliers}
          label={
            <Text color="gray.200" fontSize="sm" _light={{ color: 'gray.500' }}>
              {t('compositionGroup.overview.optionsWithPa', {
                count: activePas,
                max: totalCellsWithSuppliers,
              })}
            </Text>
          }
        />
        <ProgressComponent
          icon={<Icons.EyeCheck boxSize={defaultIconSize} />}
          actual={acceptedPas}
          max={totalCells}
          label={
            <Text color="gray.200" fontSize="sm" _light={{ color: 'gray.500' }}>
              {t('compositionGroup.overview.optionsWithAcceptedPa', {
                count: acceptedPas,
                max: totalCells,
              })}
            </Text>
          }
        />
      </Flex>
    </Box>
  );
}

function getTotalActiveVersions(branch?: CompositionBranch) {
  if (!branch) return 0;

  let addedCells = 0;

  branch.suppliers.forEach((s) => {
    s.cells.forEach((c) => {
      if (c.versions.length === 0) return;
      addedCells++;
    });
  });

  return addedCells;
}

function getTotalActivePas(branch?: CompositionBranch) {
  if (!branch) return 0;

  let addedCells = 0;

  branch.suppliers.forEach((s) => {
    s.cells.forEach((c) => {
      c.versions.forEach((v) => {
        addedCells += v.acceptedPas.length + v.pendingPas.length;
      });
    });
  });

  return addedCells;
}

function getTotalAcceptedPas(branch?: CompositionBranch) {
  if (!branch) return 0;

  const addedCells: Record<string, boolean> = {};

  branch.suppliers.forEach((s) => {
    s.cells.forEach((c) => {
      if (c.versions.some((v) => v.acceptedPas.length > 0)) {
        const format = `${c.x}-${c.y}`;
        if (addedCells[format]) return;
        addedCells[format] = true;
      }
    });
  });

  return Object.keys(addedCells).length;
}

function ProgressComponent({
  icon,
  actual,
  max,
  label,
}: {
  icon: React.ReactNode;
  actual: number;
  max: number;
  label: React.ReactNode;
}) {
  const percentage = clamp((actual / max) * 100, 0, 100);
  return (
    <Flex flexDir="column" gap={2} w="fit-content">
      <Flex alignItems="center" gap={2}>
        {percentage === 100 ? (
          <Icons.CheckCircle
            animation={fadeInScalePopAnimation(1000)}
            color="texas.sand.100"
            _light={{ color: 'texas.burntSienna.100' }}
            boxSize={defaultIconSize}
          />
        ) : (
          <>{icon}</>
        )}
        {label}
      </Flex>
      <Box minW="160px" pos="relative" h="4px">
        <Box
          pos="absolute"
          left={0}
          h="full"
          bg="texas.sand.100"
          _light={{ bg: 'texas.burntSienna.100' }}
          w={`${percentage}%`}
          zIndex={1}
        />
        <Box
          pos="absolute"
          inset={0}
          bg="texas.bg.800"
          _light={{ bg: 'gray.100' }}
        />
      </Box>
    </Flex>
  );
}
