export const rudholmMatrixColors = {
  colors: [
    {
      name: '00',
      code: '00',
      hex: 'FFFFFF',
      searchClosest: true,
      description: '',
    },
    {
      name: '01',
      code: '01',
      hex: 'F5F3E0',
      searchClosest: true,
      description: '',
    },
    { name: '02', code: '02', hex: '', searchClosest: true, description: '' },
    { name: '03', code: '03', hex: '', searchClosest: true, description: '' },
    {
      name: '04',
      code: '04',
      hex: 'F0D6A6',
      searchClosest: true,
      description: '',
    },
    { name: '05', code: '05', hex: '', searchClosest: true, description: '' },
    { name: '06', code: '06', hex: '', searchClosest: true, description: '' },
    { name: '07', code: '07', hex: '', searchClosest: true, description: '' },
    { name: '08', code: '08', hex: '', searchClosest: true, description: '' },
    {
      name: '09',
      code: '09',
      hex: 'AD9652',
      searchClosest: true,
      description: '',
    },
    {
      name: '10',
      code: '10',
      hex: 'FEF8C7',
      searchClosest: true,
      description: '',
    },
    { name: '11', code: '11', hex: '', searchClosest: true, description: '' },
    { name: '12', code: '12', hex: '', searchClosest: true, description: '' },
    { name: '13', code: '13', hex: '', searchClosest: true, description: '' },
    {
      name: '14',
      code: '14',
      hex: 'FDEB5B',
      searchClosest: true,
      description: '',
    },
    { name: '15', code: '15', hex: '', searchClosest: true, description: '' },
    { name: '16', code: '16', hex: '', searchClosest: true, description: '' },
    { name: '17', code: '17', hex: '', searchClosest: true, description: '' },
    {
      name: '18',
      code: '18',
      hex: 'FF9A54',
      searchClosest: true,
      description: '',
    },
    {
      name: '19',
      code: '19',
      hex: 'FF6E20',
      searchClosest: true,
      description: '',
    },
    {
      name: '20',
      code: '20',
      hex: 'FBD8E9',
      searchClosest: true,
      description: '',
    },
    { name: '21', code: '21', hex: '', searchClosest: true, description: '' },
    { name: '22', code: '22', hex: '', searchClosest: true, description: '' },
    { name: '23', code: '23', hex: '', searchClosest: true, description: '' },
    {
      name: '24',
      code: '24',
      hex: 'FF4E2C',
      searchClosest: true,
      description: '',
    },
    { name: '25', code: '25', hex: '', searchClosest: true, description: '' },
    { name: '26', code: '26', hex: '', searchClosest: true, description: '' },
    { name: '27', code: '27', hex: '', searchClosest: true, description: '' },
    { name: '28', code: '28', hex: '', searchClosest: true, description: '' },
    {
      name: '29',
      code: '29',
      hex: 'BB2E25',
      searchClosest: true,
      description: '',
    },
    {
      name: '30',
      code: '30',
      hex: 'B8E8F9',
      searchClosest: true,
      description: '',
    },
    { name: '31', code: '31', hex: '', searchClosest: true, description: '' },
    { name: '32', code: '32', hex: '', searchClosest: true, description: '' },
    { name: '33', code: '33', hex: '', searchClosest: true, description: '' },
    {
      name: '34',
      code: '34',
      hex: '037CC6',
      searchClosest: true,
      description: '',
    },
    { name: '35', code: '35', hex: '', searchClosest: true, description: '' },
    { name: '36', code: '36', hex: '', searchClosest: true, description: '' },
    { name: '37', code: '37', hex: '', searchClosest: true, description: '' },
    {
      name: '38',
      code: '38',
      hex: '173C8E',
      searchClosest: true,
      description: '',
    },
    {
      name: '39',
      code: '39',
      hex: '022E5A',
      searchClosest: true,
      description: '',
    },
    {
      name: '40',
      code: '40',
      hex: 'B7E5D1',
      searchClosest: true,
      description: '',
    },
    { name: '41', code: '41', hex: '', searchClosest: true, description: '' },
    { name: '42', code: '42', hex: '', searchClosest: true, description: '' },
    { name: '43', code: '43', hex: '', searchClosest: true, description: '' },
    {
      name: '44',
      code: '44',
      hex: '20B480',
      searchClosest: true,
      description: '',
    },
    { name: '45', code: '45', hex: '', searchClosest: true, description: '' },
    { name: '46', code: '46', hex: '', searchClosest: true, description: '' },
    {
      name: '47',
      code: '47',
      hex: '128845',
      searchClosest: true,
      description: '',
    },
    { name: '48', code: '48', hex: '', searchClosest: true, description: '' },
    {
      name: '49',
      code: '49',
      hex: '175C2E',
      searchClosest: true,
      description: '',
    },
    {
      name: '50',
      code: '50',
      hex: 'F0A858',
      searchClosest: true,
      description: '',
    },
    { name: '51', code: '51', hex: '', searchClosest: true, description: '' },
    { name: '52', code: '52', hex: '', searchClosest: true, description: '' },
    { name: '53', code: '53', hex: '', searchClosest: true, description: '' },
    {
      name: '54',
      code: '54',
      hex: 'BF6F18',
      searchClosest: true,
      description: '',
    },
    { name: '55', code: '55', hex: '', searchClosest: true, description: '' },
    { name: '56', code: '56', hex: '', searchClosest: true, description: '' },
    { name: '57', code: '57', hex: '', searchClosest: true, description: '' },
    { name: '58', code: '58', hex: '', searchClosest: true, description: '' },
    {
      name: '59',
      code: '59',
      hex: '81321D',
      searchClosest: true,
      description: '',
    },
    { name: '60', code: '60', hex: '', searchClosest: false, description: '' },
    { name: '61', code: '61', hex: '', searchClosest: false, description: '' },
    { name: '62', code: '62', hex: '', searchClosest: false, description: '' },
    { name: '63', code: '63', hex: '', searchClosest: false, description: '' },
    { name: '64', code: '64', hex: '', searchClosest: false, description: '' },
    { name: '65', code: '65', hex: '', searchClosest: false, description: '' },
    { name: '66', code: '66', hex: '', searchClosest: false, description: '' },
    { name: '67', code: '67', hex: '', searchClosest: false, description: '' },
    { name: '68', code: '68', hex: '', searchClosest: false, description: '' },
    { name: '69', code: '69', hex: '', searchClosest: false, description: '' },
    { name: '70', code: '70', hex: '', searchClosest: false, description: '' },
    { name: '71', code: '71', hex: '', searchClosest: false, description: '' },
    { name: '72', code: '72', hex: '', searchClosest: false, description: '' },
    { name: '73', code: '73', hex: '', searchClosest: false, description: '' },
    { name: '74', code: '74', hex: '', searchClosest: false, description: '' },
    { name: '75', code: '75', hex: '', searchClosest: false, description: '' },
    { name: '76', code: '76', hex: '', searchClosest: false, description: '' },
    { name: '77', code: '77', hex: '', searchClosest: false, description: '' },
    { name: '78', code: '78', hex: '', searchClosest: false, description: '' },
    { name: '79', code: '79', hex: '', searchClosest: false, description: '' },
    { name: '80', code: '80', hex: '', searchClosest: false, description: '' },
    { name: '81', code: '81', hex: '', searchClosest: false, description: '' },
    { name: '82', code: '82', hex: '', searchClosest: false, description: '' },
    { name: '83', code: '83', hex: '', searchClosest: false, description: '' },
    { name: '84', code: '84', hex: '', searchClosest: false, description: '' },
    { name: '85', code: '85', hex: '', searchClosest: false, description: '' },
    { name: '86', code: '86', hex: '', searchClosest: false, description: '' },
    { name: '87', code: '87', hex: '', searchClosest: false, description: '' },
    { name: '88', code: '88', hex: '', searchClosest: false, description: '' },
    { name: '89', code: '89', hex: '', searchClosest: false, description: '' },
    {
      name: '90',
      code: '90',
      hex: 'F0F4F6',
      searchClosest: true,
      description: '',
    },
    {
      name: '91',
      code: '91',
      hex: 'E1DAD4',
      searchClosest: true,
      description: '',
    },
    { name: '92', code: '92', hex: '', searchClosest: true, description: '' },
    {
      name: '93',
      code: '93',
      hex: 'A4B4BA',
      searchClosest: true,
      description: '',
    },
    { name: '94', code: '94', hex: '', searchClosest: true, description: '' },
    {
      name: '95',
      code: '95',
      hex: '85878B',
      searchClosest: true,
      description: '',
    },
    { name: '96', code: '96', hex: '', searchClosest: true, description: '' },
    { name: '97', code: '97', hex: '', searchClosest: true, description: '' },
    {
      name: '98',
      code: '98',
      hex: '595B61',
      searchClosest: true,
      description: '',
    },
    {
      name: '99',
      code: '99',
      hex: '001023',
      searchClosest: true,
      description: '',
    },
  ],
};
