import { Flex, MenuItem, useDisclosure } from '@chakra-ui/react';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { CompositionLocationData } from '../../compositions/shared/hooks/useCompositionGroup';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { PreviousCompositionGroupsModal } from '../../compositions/shared/group/PreviousCompositionGroupsModal';
import { OptionsList } from '../shared/OptionsList';
import { OptionsListContainer } from '../shared/OptionsListContainer';
import { CompositionGroupContainer } from '../shared/CompositionGroupContainer';
import { SpinnerWithLabel } from '@texas/components/shared/SpinnerWithLabel';
import { useOptionsOverview } from './useOptionsOverview';

export function StandardArticleOptions({ variantId }: { variantId: number }) {
  const { t } = useTranslation();
  const options = useOptionsOverview({
    variantId: variantId,
  });

  const {
    createGroupRequest,
    versionsLoading,
    versions,
    locationData,
    archiveGroupRequest,
    isCreating,
    copyGroup,
  } = options;
  const {
    isOpen: isNewGroupOpen,
    onClose: onNewGroupClose,
    onOpen: onNewGroupOpen,
  } = useDisclosure();
  const {
    value: previousGroupsLocationValue,
    isOpen: isShowPreviousOpen,
    onClose: onShowPreviousClose,
    onOpen: onShowPreviousOpen,
  } = useValueDisclosure<CompositionLocationData>();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const option = versions?.find((x) => x.id === selectedOption);

  return (
    <>
      <Flex pt={4} gap={2}>
        <OptionsListContainer
          menuItems={
            <>
              <MenuItem
                icon={<Icons.Plus boxSize={defaultIconSize} />}
                onClick={() => {
                  if (versions?.length === 0 && !versionsLoading) {
                    createGroupRequest();
                    return;
                  }

                  onNewGroupOpen();
                }}
              >
                {t('compositionGroup.option.addNew')}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onShowPreviousOpen({
                    variantId: locationData.current.variantId,
                  })
                }
                icon={<Icons.Archive boxSize={defaultIconSize} />}
              >
                {t('compositionGroup.option.archivedOptions')}
              </MenuItem>
            </>
          }
        >
          {isCreating && (
            <SpinnerWithLabel label={t('compositionGroup.option.loading')} />
          )}
          <OptionsList
            groupsLoading={versionsLoading}
            isCreating={isCreating}
            onCreateClick={() => {
              if (isCreating) return;
              createGroupRequest();
            }}
            groups={versions?.map((x) => ({
              id: x.id,
              fileId: x.fileId,
              version: x.version,
              compositions: x.compositions,
              note: x.note,
              isReady: x.isReady,
            }))}
            selectedOption={selectedOption}
            onClick={setSelectedOption}
          />
        </OptionsListContainer>

        {option && (
          <CompositionGroupContainer
            key={option.id}
            isMatrix={false}
            option={option}
            onArchive={async () => await archiveGroupRequest(option.id)}
            onCopy={async (fromId) => await copyGroup({ fromId })}
          />
        )}
      </Flex>
      <VerifyDialogWithFunction
        headerTitle={t('compositionGroup.option.createNew')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={() => createGroupRequest()}
        isOpen={isNewGroupOpen}
        onClose={onNewGroupClose}
      >
        {t('composition.newOptionConfirm')}
      </VerifyDialogWithFunction>
      {previousGroupsLocationValue && (
        <PreviousCompositionGroupsModal
          locationData={previousGroupsLocationValue}
          isOpen={isShowPreviousOpen}
          onClose={onShowPreviousClose}
        />
      )}
    </>
  );
}
