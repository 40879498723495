import { useActiveSession } from '@texas/hooks/useSession';
import { BodyOverride } from '../shared/BodyOverride';
import { useWidgetLayout } from '../widgets/useWidgetLayout';
import { TableColumns } from './TableColumns';
import { defaultDashboardWidgetsLayout } from './defaultDashboardWidgetsLayout';
import {
  defaultArticlesWidgetOptions,
  pageSize,
} from './defaultWidgetsOptions';
import { Divider, Flex } from '@chakra-ui/react';
import { AddWidget } from '../widgets/shared/components/AddWidget';
import { WidgetType } from '../widgets/shared/types';
import { PaTableColumns } from './PaTableColumns';

export function Dashboard() {
  const session = useActiveSession();
  const { widgetComponents, addNewWidget, widgets } = useWidgetLayout({
    localStorageKey: `${session.currentUser.id}_dashboard`,
    defaultLayout: defaultDashboardWidgetsLayout,
    widgetOptions: {
      [WidgetType.Articles]: {
        widgetType: WidgetType.Articles,
        options: {
          ...defaultArticlesWidgetOptions(session.currentUser),
          localStorageKey: `${session.currentUser.id}_${WidgetType.Articles}`,
          columns: TableColumns,
          optOutDefaultOrdering: true,
          pageSize: pageSize,
        },
      },
      [WidgetType.ArticlesCount]: {
        widgetType: WidgetType.ArticlesCount,
        options: {
          ...defaultArticlesWidgetOptions(session.currentUser),
          localStorageKey: `${session.currentUser.id}_${WidgetType.Articles}`,
          columns: TableColumns,
          optOutDefaultOrdering: true,
        },
      },
      [WidgetType.ProductApprovalsCount]: {
        widgetType: WidgetType.ProductApprovalsCount,
        options: {
          ...defaultArticlesWidgetOptions(session.currentUser),
          localStorageKey: `${session.currentUser.id}_${WidgetType.ProductApprovalsCount}`,
          columns: PaTableColumns,
          optOutDefaultOrdering: true,
        },
      },
    },
  });

  return (
    <BodyOverride>
      <Divider my={2} />
      <Flex flexDir="column" gap={2}>
        <AddWidget
          currentWidgets={widgets}
          onAdd={(type, name, color) => addNewWidget(type, name, color)}
        />
        {widgetComponents}
      </Flex>
    </BodyOverride>
  );
}
