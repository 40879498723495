import { Flex } from '@chakra-ui/react';
import {
  suppliersApi,
  SupplierSearchQuery,
} from '@texas/api/endpoints/suppliersApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { suppliersColumns } from './Columns';
import { Container } from '../shared/Container';
import { SearchFilterInput } from '../SearchFilterInput';
import { TexasSwitch } from '../shared/form/TexasSwitch';
import { NewSupplier } from './newSupplier/newSupplier';
import { DataTableContainer } from '../shared/dataTable/DataTableContainer';
import { reactEvents } from '@bridge/reactEvents';
import { BodyOverride } from '../shared/BodyOverride';

const defaultSearchQuery: SupplierSearchQuery = {
  sortDesc: false,
  searchTerm: '',
  includeArchived: false,
  page: 1,
  pageSize: 24,
  organizationId: 3,
};

export function Suppliers() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] =
    useState<SupplierSearchQuery>(defaultSearchQuery);

  const {
    data: suppliers,
    refetch,
    loading,
    error,
  } = useApiResource(suppliersApi.searchSuppliers);

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  const triggerRefetchAll = useCallback(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  useEffect(() => {
    return reactEvents.supplierCreated.subscribe(() => {
      triggerRefetchAll();
    });
  }, [triggerRefetchAll]);

  const columns = useMemo(() => {
    return suppliersColumns(t);
  }, [t]);

  return (
    <BodyOverride>
      <Container title={t('general.suppliers')}>
        <Flex direction="column" p={4} gap={2}>
          <Flex justify="space-between">
            <Flex direction="column" gap={4} minWidth={80}>
              <SearchFilterInput
                value={searchQuery.searchTerm}
                onChange={(s) =>
                  setSearchQuery({ ...searchQuery, searchTerm: s, page: 1 })
                }
              />
              <TexasSwitch
                checked={searchQuery.includeArchived}
                label={t('general.includeArchived')}
                onChange={(checked) =>
                  setSearchQuery({
                    ...searchQuery,
                    includeArchived: checked,
                    page: 1,
                  })
                }
              />
            </Flex>
            <NewSupplier />
          </Flex>
          <DataTableContainer
            w="100%"
            datatable={{
              data: suppliers?.items ?? [],
              sorted: {
                onSortedChange: ({ desc }) => {
                  setSearchQuery({ ...searchQuery, sortDesc: desc });
                },
                key: 'name',
                desc: searchQuery.sortDesc,
              },
              isLoading: loading,
              columns: columns,
              variant: 'configuration',
            }}
            error={error}
            pagination={{
              totalItems: suppliers?.totalItems ?? 0,
              pageSize: searchQuery.pageSize,
              currentPage: searchQuery.page,
              onPageChange: (page) =>
                setSearchQuery({
                  ...searchQuery,
                  page,
                }),
            }}
          />
        </Flex>
      </Container>
    </BodyOverride>
  );
}
