import { userApi, UserListItem } from '@texas/api/endpoints/userApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { Trans, useTranslation } from 'react-i18next';

interface Props extends SharedDisclosureProps {
  onArchive: () => void;
  user: UserListItem;
}

export function ArchiveUser({ isOpen, user, onArchive, onClose }: Props) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('user.archiveUser')}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={userApi.archive}
      args={[user.id]}
      isOpen={isOpen}
      onClose={onClose}
      onPerformed={onArchive}
      onSuccessTitle={t('general.archived')}
    >
      <Trans i18nKey="user.archiveEmail" values={{ email: user.email }} />
    </VerifyDialogWithRequest>
  );
}
