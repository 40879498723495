import { ColumnDef } from '@tanstack/react-table';
import { SearchVariantItem } from '@texas/api/endpoints/search/searchVariantsTypes';
import {
  ArticleSearchProps,
  FilterOptions,
} from '@texas/components/searchArticles/types';
import { TFunction } from 'i18next';
import { PaSearchProps } from '../ProductApprovals/types';
import { ProductApprovalItem } from '@texas/api/endpoints/search/searchPaTypes';

export const defaultWidgetColor = 'texas.bg.800';
export const defaultWidgetLightColor = 'gray.100';

type State = 'hidden';

export enum WidgetType {
  Brands = 'brands',
  Articles = 'articles',
  ArticlesCount = 'articlesCount',
  ProductApprovalsCount = 'productApprovalsCount',
}

export interface WidgetComponentSettings {
  settingDots?: State;
  editWidget?: State;
  editFilters?: State;
  notDraggable?: boolean;
}

export interface BaseWidgetOptions {
  customWidgetTitle?: string;
  componentSettings?: WidgetComponentSettings;
}

export interface SearchArticleOptions extends BaseWidgetOptions {
  defaultSearchPage: ArticleSearchProps;
  filterOptions: FilterOptions;
  pageSize: number;
  optOutDefaultOrdering: boolean;
  localStorageKey: string | undefined;
  columns: (t: TFunction) => ColumnDef<SearchVariantItem, any>[];
}

export interface ArticlesCountOptions extends BaseWidgetOptions {
  preConfiguredWidget?: Record<
    string,
    {
      defaultSearchPage: ArticleSearchProps;
      filterOptions: FilterOptions;
      optOutDefaultOrdering: boolean;
      localStorageKey: string | undefined;
      widgetTitle: string;
      componentSettings?: WidgetComponentSettings;
      columns: (t: TFunction) => ColumnDef<SearchVariantItem, any>[];
    }
  >;
  defaultSearchPage: ArticleSearchProps;
  filterOptions: FilterOptions;
  optOutDefaultOrdering: boolean;
  localStorageKey: string | undefined;
  columns: (t: TFunction) => ColumnDef<SearchVariantItem, any>[];
}

export interface PaCountOptions extends BaseWidgetOptions {
  preConfiguredWidget?: Record<
    string,
    {
      defaultSearchPage: PaSearchProps;
      filterOptions: FilterOptions;
      optOutDefaultOrdering: boolean;
      localStorageKey: string | undefined;
      widgetTitle: string;
      componentSettings?: WidgetComponentSettings;
      columns: (t: TFunction) => ColumnDef<ProductApprovalItem, any>[];
    }
  >;
  defaultSearchPage: PaSearchProps;
  filterOptions: FilterOptions;
  optOutDefaultOrdering: boolean;
  localStorageKey: string | undefined;
  columns: (t: TFunction) => ColumnDef<ProductApprovalItem, any>[];
}

export type WidgetOptions =
  | { options: SearchArticleOptions; widgetType: WidgetType.Articles }
  | { options: BaseWidgetOptions; widgetType: WidgetType.Brands }
  | { options: PaCountOptions; widgetType: WidgetType.ProductApprovalsCount }
  | { options: ArticlesCountOptions; widgetType: WidgetType.ArticlesCount };
