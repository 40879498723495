import { Flex, Tag, TagLabel, Text, useToast } from '@chakra-ui/react';
import {
  FileLink,
  filesApi,
  UpdateFileResponse,
} from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';
import { fadeInAnimation } from '@texas/resources/animations/animations';

interface ArchiveFileProps extends SharedDisclosureProps {
  file: FileLink;
  onArchived: (f: UpdateFileResponse) => void;
}

export function ArchiveFile({
  file,
  isOpen,
  onClose,
  onArchived,
}: ArchiveFileProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    data: fileUsage,
    refetch,
    loading: loadingFileUsage,
  } = useApiResource(filesApi.getFileUsage);

  useEffect(() => {
    refetch(file.id);
  }, [file, refetch]);

  const { request: archiveRequest } = useApiRequest(filesApi.archiveFile);

  const onArchive = async () => {
    await request(
      archiveRequest,
      [file.id],
      (response) => {
        toast({
          title: t('general.successfullyArchived'),
          status: 'success',
          isClosable: true,
        });
        onClose();
        onArchived(response);
      },
      (error: ServerError) => {
        toast({
          title: t('general.archiveFailed'),
          description: <ErrorsList errors={error.errors} />,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  return (
    <VerifyDialogWithFunction
      headerTitle={t('fileBrowser.archiveFile')}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      isOpen={isOpen}
      onClose={onClose}
      toPerform={async () => {
        await onArchive();
      }}
    >
      <Flex gap={2} direction="column">
        {loadingFileUsage && (
          <Text variant="sub">{t('fileBrowser.fileUsage.checking')}</Text>
        )}
        {!loadingFileUsage && fileUsage?.artwork && (
          <FileUsageTag label={t('fileBrowser.fileUsage.usedAsArtwork')} />
        )}
        {!loadingFileUsage && fileUsage?.photo && (
          <FileUsageTag label={t('fileBrowser.fileUsage.usedAsPhoto')} />
        )}
        {!loadingFileUsage && fileUsage?.document && (
          <FileUsageTag label={t('fileBrowser.fileUsage.usedAsDocument')} />
        )}
        {!loadingFileUsage && fileUsage?.matrixPhoto && (
          <FileUsageTag label={t('fileBrowser.fileUsage.usedAsCellPhoto')} />
        )}
        {!loadingFileUsage && fileUsage?.layout && (
          <FileUsageTag label={t('fileBrowser.fileUsage.usedAsLayout')} />
        )}
        {!loadingFileUsage && fileUsage?.compositionPhoto && (
          <FileUsageTag
            label={t('fileBrowser.fileUsage.usedAsCompositionPhoto')}
          />
        )}
        <Text>{t('alert.areYouSure')}</Text>
      </Flex>
    </VerifyDialogWithFunction>
  );
}

function FileUsageTag({ label }: { label: string }) {
  return (
    <Tag w="fit-content" colorScheme="orange" animation={fadeInAnimation(400)}>
      <Icons.AlertCircleOutline boxSize={5} mr={2} />
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
}
