import { Button, Flex } from '@chakra-ui/react';
import { FileTagType } from '@texas/api/endpoints/filesApi';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export function FileTagTypeFilterButtons({
  existingTagTypes,
  typeFilter,
  setTypeFilter,
}: {
  existingTagTypes: FileTagType[];
  typeFilter: FileTagType[];
  setTypeFilter: Dispatch<SetStateAction<FileTagType[]>>;
}) {
  function onClickFilter(type: FileTagType) {
    if (typeFilter.includes(type))
      setTypeFilter(typeFilter.filter((t) => t !== type));
    else setTypeFilter((prev) => [...prev, type]);
  }

  return (
    <Flex gap={2}>
      {existingTagTypes.map((type) => (
        <FilterButton
          key={type}
          typeFilter={typeFilter}
          type={convertToEnum(FileTagType, type)}
          onClick={onClickFilter}
        />
      ))}
    </Flex>
  );
}

function FilterButton({
  typeFilter,
  type,
  onClick,
}: {
  typeFilter: FileTagType[];
  type: FileTagType;
  onClick: (type: FileTagType) => void;
}) {
  const { t } = useTranslation();

  return (
    <Button
      variant="filter"
      color={typeFilter.includes(type) ? 'gray.100' : 'gray.400'}
      borderColor={
        typeFilter.includes(type) ? 'whiteAlpha.400' : 'whiteAlpha.200'
      }
      _light={{
        color: typeFilter.includes(type) ? 'gray.700' : 'gray.400',
        borderColor: typeFilter.includes(type)
          ? 'blackAlpha.400'
          : 'blackAlpha.200',
      }}
      size="sm"
      onClick={() => onClick(type)}
    >
      {getLabel(type, t)}
    </Button>
  );
}

function getLabel(type: FileTagType, t: TFunction) {
  switch (type) {
    case FileTagType.VariantArtwork:
      return t('variant.garpArtwork');
    case FileTagType.VariantPhoto:
      return t('variant.garpPhoto');
    case FileTagType.VariantDocument:
      return t('variant.garpDocument');
    case FileTagType.CellPhoto:
      return t('variant.cellPhoto');
    case FileTagType.OptionLayout:
      return t('composition.files.optionLayout');
    case FileTagType.CompositionPhoto:
    default:
      return t('composition.files.compositionPhoto');
  }
}
