import {
  ContactAddToType,
  contactsApi,
} from '@texas/api/endpoints/contactsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ContactsTable } from '@texas/components/contacts/ContactsTable';

export function SupplierContacts({ supplierId }: { supplierId: number }) {
  const { request: disconnectRequest } = useApiRequest(
    contactsApi.disconnectSupplier,
  );
  const { request: connectRequest } = useApiRequest(
    contactsApi.connectSupplier,
  );

  return (
    <ContactsTable
      addToType={ContactAddToType.Supplier}
      referenceId={supplierId}
      disconnectRequest={disconnectRequest}
      connectRequest={connectRequest}
    />
  );
}
