import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { WidgetCategory } from '../widgets';
import { TFunction } from 'i18next';

export function widgetCategoryFriendlyName(
  category: WidgetCategory,
  t: TFunction,
) {
  switch (ensureEnumNumber(WidgetCategory, category)) {
    case WidgetCategory.General:
      return t('general.general');
    case WidgetCategory.Articles:
      return t('general.articles');
    case WidgetCategory.ProductApprovals: {
      return t('productApproval.productApprovals');
    }
  }
}
