import { Flex, Box, Grid, GridItem, useColorMode } from '@chakra-ui/react';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { TexasHeader } from '@texas/components/shared/TexasHeader';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductApprovalDetails } from './ProductApprovalDetails';
import { productApprovalColumns } from './Columns';
import {
  FilterOptionIdsQuery,
  ProductApprovalState,
  ProductApprovalsSearchQuery,
  productApprovalsApi,
} from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { articlesApi } from '@texas/api/endpoints/articlesApi';
import { ReactSelectOption } from '@texas/types';
import { getStateOption } from './shared';
import {
  convertToEnum,
  getEnumNamesAndValues,
} from '@texas/utils/helpers/enumHelpers';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';
import { queryStringToObject } from '@texas/utils/helpers/commonHelpers';
import { productApprovalEvents } from './events';

const defaultPageSize = 50;
const stateOptions: ReactSelectOption[] = getEnumNamesAndValues(
  ProductApprovalState,
).map((s) => {
  return { label: s.name, value: s.value };
});

export function ProductApprovals({ articleId }: { articleId: number }) {
  const { t } = useTranslation();
  const searchQueryParams: FilterOptionIdsQuery | undefined =
    queryStringToObject();

  const [selectedProductApproval, setSelectedProductApproval] =
    useState<number>(0);

  const {
    data: productApprovals,
    refetch,
    error,
    loading,
  } = useApiResource(productApprovalsApi.getAll);

  const {
    data: branches,
    refetch: refetchBranches,
    loading: loadingBranches,
  } = useApiResource(articlesApi.getArticleBranches);

  const {
    data: suppliers,
    refetch: refetchSuppliers,
    loading: loadingSuppliers,
  } = useApiResource(articlesApi.getArticleSuppliers);

  const [searchQuery, setSearchQuery] = useState<ProductApprovalsSearchQuery>({
    articleId: articleId,
    sortDesc: false,
    sortBy: 'formatNumber',
    searchTerm: searchQueryParams?.searchTerm ?? '',
    page: 1,
    pageSize: defaultPageSize,
    branchIds: searchQueryParams?.branchId
      ? [searchQueryParams.branchId]
      : undefined,
    supplierIds: searchQueryParams?.supplierId
      ? [searchQueryParams.supplierId]
      : undefined,
    state: searchQueryParams?.state,
  });

  const triggerRefetchAll = useCallback(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  const onStateChange = useCallback((id: number) => {
    productApprovalEvents.productApprovalChanged.dispatch({ id });
  }, []);

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  useEffect(() => {
    refetchBranches(articleId);
    refetchSuppliers(articleId);
  }, [articleId, refetchBranches, refetchSuppliers]);

  useEffect(() => {
    return productApprovalEvents.productApprovalsCreated.subscribe(() => {
      triggerRefetchAll();
    });
  }, [triggerRefetchAll]);

  const { colorMode } = useColorMode();

  const columns = useMemo(() => {
    if (productApprovals)
      return productApprovalColumns(colorMode === 'dark', t, (id) => {
        triggerRefetchAll();
        if (id) {
          onStateChange(id);
          return;
        }
      });
  }, [productApprovals, colorMode, t, triggerRefetchAll, onStateChange]);

  return (
    <BodyOverride>
      <Flex flexDirection="column" gap={2}>
        <TexasHeader text={t('productApproval.productApprovals')} />
        <Grid
          templateColumns="repeat(7, 1fr)"
          backgroundColor="texas.bg.900"
          _light={{ backgroundColor: 'white' }}
          borderRadius="8"
          py={3}
          px={2}
          gap={4}
          alignItems="start"
        >
          <GridItem colSpan={2}>
            <TexasFormLabel marginBottom={0}>
              {t('general.search')}
            </TexasFormLabel>
            <SearchFilterInput
              value={searchQuery.searchTerm}
              placeholder={t('productApproval.searchNumberOrOption')}
              onChange={(s) =>
                setSearchQuery({ ...searchQuery, searchTerm: s })
              }
            />
          </GridItem>
          <GridItem>
            <TexasFormLabel>{t('general.state')}</TexasFormLabel>
            <TexasSelect
              placeholder={t('filter.allStates')}
              value={
                searchQuery.state
                  ? getStateOption(searchQuery.state)
                  : undefined
              }
              onChange={(e) =>
                setSearchQuery({
                  ...searchQuery,
                  state: convertToEnum(ProductApprovalState, e?.value),
                })
              }
              options={stateOptions}
              isClearable={true}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <TexasFormLabel>{t('general.branches')}</TexasFormLabel>
            <TexasSelect
              isLoading={loadingBranches}
              isClearable={true}
              isMulti={true}
              closeMenuOnSelect={false}
              value={searchQuery.branchIds?.map<ReactSelectOption>((x) => ({
                label: branches?.find((b) => b.id === x)?.name ?? '',
                value: x,
              }))}
              onChange={(e) =>
                setSearchQuery({
                  ...searchQuery,
                  branchIds: e.map((x) => x.value),
                })
              }
              options={branches?.map<ReactSelectOption>((x) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <TexasFormLabel>{t('general.suppliers')}</TexasFormLabel>
            <TexasSelect
              isLoading={loadingSuppliers}
              isClearable={true}
              isMulti={true}
              closeMenuOnSelect={false}
              value={searchQuery.supplierIds?.map<ReactSelectOption>((x) => ({
                label: suppliers?.find((b) => b.id === x)?.name ?? '',
                value: x,
              }))}
              onChange={(e) =>
                setSearchQuery({
                  ...searchQuery,
                  supplierIds: e.map((x) => x.value),
                })
              }
              options={suppliers?.map<ReactSelectOption>((x) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </GridItem>
        </Grid>
        <Flex gap={2}>
          <Flex
            flexGrow={1}
            borderRadius={8}
            backgroundColor="texas.bg.900"
            _light={{ backgroundColor: 'white' }}
          >
            <Box width="100%" minH={0} padding={2} minW={0}>
              <DataTableContainer
                w="100%"
                error={error}
                datatable={{
                  data: productApprovals?.items ?? [],
                  sorted: {
                    onSortedChange: ({ key, desc }) => {
                      setSearchQuery({
                        ...searchQuery,
                        sortBy: key,
                        sortDesc: desc,
                      });
                    },
                    key: searchQuery.sortBy,
                    desc: searchQuery.sortDesc,
                  },
                  isLoading: loading,
                  columns: columns ?? [],

                  rowProps: {
                    onRowClick: (id) => setSelectedProductApproval(id),
                    getRowId: (originalRow, _) => {
                      return originalRow.id.toString();
                    },
                  },
                  variant: 'configuration',
                }}
                pagination={{
                  totalItems: productApprovals?.totalItems ?? 0,
                  pageSize: defaultPageSize,
                  currentPage: searchQuery.page,
                  onPageChange: (page) =>
                    setSearchQuery({
                      ...searchQuery,
                      page,
                    }),
                }}
              />
            </Box>
          </Flex>
          {selectedProductApproval !== 0 && (
            <ProductApprovalDetails
              refetchAll={triggerRefetchAll}
              id={selectedProductApproval}
            />
          )}
        </Flex>
      </Flex>
    </BodyOverride>
  );
}
