import { TFunction } from 'i18next';
import { FilterTypeEnum, FilterOptionType } from './types/types';
import { searchApi } from '@texas/api/endpoints/search/searchApi';
import { PaFilterType } from './types/paTypes';

export const paFilters: PaFilterType[] = [
  {
    type: FilterTypeEnum.Select,
    optionType: FilterOptionType.Branches,
    queryParamsKey: 'branchIds',
    fetchValues: searchApi.getBranches,
    fetchOptions: searchApi.searchBranches,
    minSearchTermLength: 0,
    getName: (t: TFunction): string => {
      return t('general.branches');
    },
  },
  {
    type: FilterTypeEnum.Select,
    optionType: FilterOptionType.Customers,
    queryParamsKey: 'customerIds',
    fetchValues: searchApi.getCustomers,
    fetchOptions: searchApi.searchCustomers,
    minSearchTermLength: 2,
    getName: (t: TFunction): string => {
      return t('general.brand');
    },
  },
  {
    type: FilterTypeEnum.Select,
    optionType: FilterOptionType.Concepts,
    queryParamsKey: 'conceptIds',
    fetchValues: searchApi.getConcepts,
    fetchOptions: searchApi.searchConcepts,
    minSearchTermLength: 2,
    getName: (t: TFunction): string => {
      return t('general.concept');
    },
  },
  {
    type: FilterTypeEnum.Select,
    optionType: FilterOptionType.States,
    queryParamsKey: 'paStates',
    fetchValues: searchApi.getPaStates,
    fetchOptions: searchApi.searchPaStates,
    minSearchTermLength: 0,
    getName: (t: TFunction): string => {
      return t('general.state');
    },
  },
  {
    type: FilterTypeEnum.Select,
    optionType: FilterOptionType.CategoryCodes,
    queryParamsKey: 'categoryCodeIds',
    fetchValues: searchApi.getCategoryCodes,
    fetchOptions: searchApi.searchCategoryCodes,
    minSearchTermLength: 2,
    getName: (t: TFunction): string => {
      return t('searchArticles.categoryCodes');
    },
  },
  {
    type: FilterTypeEnum.Select,
    optionType: FilterOptionType.Materials,
    queryParamsKey: 'supplierIds',
    fetchValues: searchApi.getSuppliers,
    fetchOptions: searchApi.searchSuppliers,
    minSearchTermLength: 2,
    getName: (t: TFunction): string => {
      return t('general.suppliers');
    },
  },
  {
    type: FilterTypeEnum.Select,
    optionType: FilterOptionType.ProductGroups,
    queryParamsKey: 'productGroupIds',
    fetchValues: searchApi.getProductGroups,
    fetchOptions: searchApi.searchProductGroups,
    minSearchTermLength: 2,
    getName: (t: TFunction): string => {
      return t('general.productGroups');
    },
  },
  {
    type: FilterTypeEnum.Checkbox,
    optionType: FilterOptionType.ShowAllOptions,
    queryParamsKey: 'showAllVariants',
    defaultChecked: false,
    getName: (t: TFunction): string => {
      return t('filter.mainOrPerOption');
    },
    getOffLabel: (t: TFunction): string => {
      return t('filter.mainOption');
    },
    getOnLabel: (t: TFunction): string => {
      return t('filter.allOptions');
    },
  },
  {
    type: FilterTypeEnum.Checkbox,
    optionType: FilterOptionType.IncludeArchived,
    queryParamsKey: 'includeArchived',
    defaultChecked: false,
    getName: (t: TFunction): string => {
      return t('filter.includeArchived');
    },
    getOffLabel: (t: TFunction): string => {
      return t('filter.exclude');
    },
    getOnLabel: (t: TFunction): string => {
      return t('filter.include');
    },
  },
  {
    type: FilterTypeEnum.Checkbox,
    optionType: FilterOptionType.ShowMyBrands,
    queryParamsKey: 'showMyBrands',
    defaultChecked: false,
    getName: (t: TFunction): string => {
      return t('filter.myBrandsOrAll');
    },
    getOffLabel: (t: TFunction): string => {
      return t('filter.allBrands');
    },
    getOnLabel: (t: TFunction): string => {
      return t('filter.myBrands');
    },
  },
];
