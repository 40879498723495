import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  ModalFooter,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';
import {
  GroupedOption,
  SelectFilterOption,
  ToggleFilterOption,
  GetFilterGroupedOptions,
} from '../../filterGroupedOptions';
import {
  groupStyles,
  groupBadgeStyles,
  articlesWidgetSelectStyles,
  SearchDropdownIndicator,
} from '../../styles';
import Select from 'react-select';
import { SearchPaGeneric } from '../../returnTypes';
import {
  defaultEmptyFilters,
  SearchBase,
} from '@texas/api/endpoints/search/searchPaTypes';

interface Props<T extends SearchBase> extends SharedDisclosureProps {
  search: SearchPaGeneric<T>;
  widgetRenderComponent?: React.ReactNode;
}

export function PaFilterModal<T extends SearchBase>({
  search,
  isOpen,
  onClose,
  widgetRenderComponent,
}: Props<T>) {
  const { t } = useTranslation();

  const {
    availableFilters,
    setActiveFiltersVariantResults,
    setActiveFilterTypes,
    setSearchPage,
    filterElements,
    searchPage,
    setSearchParamsWithRef,
    searchParamsRef,
  } = search;

  const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('widgets.editWidgetFilters')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column">
            <Box w="fit-content">
              <Select<
                SelectFilterOption | ToggleFilterOption,
                false,
                GroupedOption
              >
                inputId="clickableInput"
                placeholder=""
                styles={articlesWidgetSelectStyles}
                components={{ DropdownIndicator: SearchDropdownIndicator }}
                options={GetFilterGroupedOptions(availableFilters)}
                formatGroupLabel={formatGroupLabel}
                onChange={(value) => {
                  if (!value) return;
                  const type = value.value;
                  setActiveFiltersVariantResults((s) => [
                    ...s,
                    {
                      optionType: type,
                      ...defaultEmptyFilters,
                      isPopulated: false,
                    },
                  ]);
                  setActiveFilterTypes((s) => [...s, [true, type]]);
                  setSearchPage((s) => ({
                    ...s,
                    filters: [...s.filters, type],
                  }));
                }}
                value={null}
              />
            </Box>

            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(6, 1fr)"
              gridGap={1}
              rowGap={1}
            >
              <Box height="fit-content" mt="auto" gridColumn="span 2">
                <SearchFilterInput
                  placeholder={t('productApproval.search')}
                  value={searchPage.searchParams.searchTerm}
                  onChange={(searchTerm) =>
                    setSearchParamsWithRef({
                      ...searchParamsRef.current,
                      searchTerm,
                      page: 1,
                    })
                  }
                />
              </Box>
              {filterElements}
            </Box>
          </Flex>
          <Text pt={4} fontWeight="bold">
            {t('widgets.preview')}
          </Text>
          <Box
            bg="texas.bg.800"
            _light={{ bg: 'gray.50' }}
            padding={4}
            mt={4}
            borderRadius="md"
          >
            {widgetRenderComponent}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>{t('general.close')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
