import { Grid, GridItem, Flex, Text, useToast, Box } from '@chakra-ui/react';
import {
  ColorData,
  CompositionMaterial,
} from '@texas/api/endpoints/compositionApi';
import { CompositionMaterialView } from '@texas/components/shared/composition/material/CompositionMaterialView';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorDot } from '../../../../../shared/colorPicker/ColorDot';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { onlyUnique } from '@texas/utils/helpers/commonHelpers';
import {
  ColorPicker,
  GetColorWithType,
} from '../../../../../shared/colorPicker/ColorPicker';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '@texas/types';
import {
  compositionGroupMatrixApi,
  MatrixAxis,
} from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { WarningComponent } from '@texas/components/shared/WarningComponent';
import { CompositionName } from '../../shared/CompositionName';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { TFunction } from 'i18next';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';

export function CompositionMaterialGrid({
  group,
  refetch,
}: {
  group: CompositionGroup;
  refetch: () => void;
}) {
  const columns = useMemo(() => {
    let nr = 0;

    for (let i = 0; i < group.compositions.length; i++) {
      nr++;
      nr += group.compositions[i].materials.length;
    }

    return nr;
  }, [group]);

  return (
    <>
      <Grid
        role="group"
        gridTemplateColumns={`repeat(${columns}, auto) 1fr`}
        alignItems="center"
        columnGap={1}
      >
        <GridItem
          gridRow={1}
          bg="texas.bg.900"
          _light={{ bg: 'gray.50' }}
          height={12}
          gridColumn="1 / -1"
          mx={4}
        />
        <GridItem gridColumn={2} gridRow={1} width="84px">
          <Text fontWeight="bold" fontSize="sm">
            {ensureEnumNumber(MatrixAxis, group.colorAxis) === MatrixAxis.Rows
              ? group.matrixNodeYValue
              : group.matrixNodeXValue}
          </Text>
        </GridItem>

        <GridComponents group={group} refetch={refetch} />
      </Grid>
    </>
  );
}

function GridComponents({
  group,
  refetch,
}: {
  group: CompositionGroup;
  refetch: () => void;
}) {
  const { t } = useTranslation();
  const {
    isOpen: editIsOpen,
    value,
    onClose: editOnClose,
    onOpen: editOnOpen,
  } = useValueDisclosure<{
    cIndex: number;
    mIndex: number;
    material: CompositionMaterial;
    productGroupId: number | null;
  }>();

  const colors = useMemo(() => {
    return group.compositions
      .flatMap((x) =>
        x.materials
          .flatMap((m) => m.colors)
          .flatMap((c) => ({ hex: c.hex, code: c.code, name: c.name })),
      )
      .filter(onlyUnique);
  }, [group.compositions]);

  const { request: updateMaterialRequest } = useApiRequest(
    compositionGroupMatrixApi.updateColors,
  );
  const toast = useToast();

  if (!group.controlledById && !group.colorAxis) {
    return <>{t('compositionGroup.option.missingConfig')}</>;
  }

  const cellId =
    ensureEnumNumber(MatrixAxis, group.colorAxis) === MatrixAxis.Rows
      ? group.cellY
      : group.cellX;

  const onSubmit = async (data: ColorData[]) => {
    if (!value) return;

    await request(
      updateMaterialRequest,
      [
        group.controlledById!,
        cellId!,
        {
          compositionIndex: value.cIndex,
          materialIndex: value.mIndex,
          colors: data,
        },
      ],
      (_) => {
        refetch();
        compositionGroupEvents.onColorsChanged.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  return (
    <>
      <GridItem gridColumn={1} gridRow={1}>
        <Flex gap={1}>
          <Flex flexDir="column" gap={2} w="22px">
            {colors.map((c, i) => {
              return (
                <ColorDot key={i} hex={c.hex} code={c.code} name={c.name} />
              );
            })}
          </Flex>
        </Flex>
      </GridItem>
      {!group.exists && (
        <GridItem gridRow={1} gridColumn={3}>
          <ErrorDetails
            error={{
              message: t('compositionGroup.notExist'),
              status: 404,
              errors: null,
            }}
          />
        </GridItem>
      )}
      {group.compositions.map((x, i) => {
        return (
          <GridItem
            gridColumn={3 + i}
            gridRow={1}
            key={x.id}
            height="full"
            pl={4}
          >
            <Box pb={2}>
              <CompositionName note={x.note} index={i + 1} />
            </Box>
            <Flex flexDir="column" gap={2}>
              {x.materials.map((m, i2) => {
                return (
                  <WarningComponent key={m.id} warning={colorsWarning(m, t)}>
                    <CompositionMaterialView
                      settings={{
                        metaList: 'hidden',
                        colorComponent: 'detailed',
                      }}
                      material={m}
                      onClick={() =>
                        editOnOpen({
                          cIndex: i,
                          mIndex: i2,
                          material: m,
                          productGroupId: x.articleProductGroupId,
                        })
                      }
                    />
                  </WarningComponent>
                );
              })}
            </Flex>
          </GridItem>
        );
      })}

      {value && (
        <ColorPicker
          matrixNodeValue={group.matrixNodeYValue ?? undefined}
          onSelect={async (colors) => {
            await onSubmit(colors);
            editOnClose();
          }}
          addedColors={value.material.colors.map((c) => {
            const color = c.colorType ? c : GetColorWithType(c);

            return {
              code: color.code,
              name: color.name,
              hex: color.hex,
              description: c.description,
              colorType: color.colorType!,
            };
          })}
          isOpen={editIsOpen}
          onClose={editOnClose}
        />
      )}
    </>
  );
}

function colorsWarning(m: CompositionMaterial, t: TFunction) {
  return m.colors.length === 0 ? t('colors.missing') : undefined;
}
