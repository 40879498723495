import { ArticleProcess } from '@texas/api/endpoints/variantsApi';
import { FilterOptionType } from '../../filter/types/types';
import { ArticleSearchProps, FilterOptions } from '../../searchArticles/types';
import { ArticleState } from '@texas/api/endpoints/articlesApi';
import { emptyVariantsSearchQuery } from '@texas/api/endpoints/search/searchVariantsTypes';

export const defaultUnderSamplingWidgetOptions = (
  customerId: number | undefined,
  conceptId: number | undefined,
) => {
  return widgetOptions(customerId, conceptId);
};

function widgetOptions(
  customerId: number | undefined,
  conceptId: number | undefined,
) {
  if (customerId) {
    return brandsOptions(customerId);
  } else if (conceptId) {
    return conceptOptions(conceptId);
  }

  throw Error('Customer or concept properties not declared');
}

const brandsOptions = (customerId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [
      FilterOptionType.Customers,
      FilterOptionType.Processes,
      FilterOptionType.States,
    ],
    searchParams: {
      ...emptyVariantsSearchQuery,
      customerIds: [customerId],
      processes: [ArticleProcess.InitiateSampling],
      states: [ArticleState.Registered],
    },
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Processes]: {
      disabled: true,
      static: false,
    },
    [FilterOptionType.States]: {
      disabled: true,
      static: false,
    },
  };

  return { defaultSearchPage, filterOptions };
};

const conceptOptions = (conceptId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [
      FilterOptionType.Concepts,
      FilterOptionType.Processes,
      FilterOptionType.States,
    ],
    searchParams: {
      ...emptyVariantsSearchQuery,
      conceptIds: [conceptId],
      processes: [ArticleProcess.InitiateSampling],
      states: [ArticleState.Registered],
    },
    view: 'table',
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Concepts]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Processes]: {
      disabled: true,
      static: false,
    },
    [FilterOptionType.States]: {
      disabled: true,
      static: false,
    },
  };

  return { defaultSearchPage, filterOptions };
};
