import { httpClient } from '@texas/api/httpClient/httpClient';
import { CompositionGroup } from './compositionGroupApi';
import { ColorData, DimensionData } from '../compositionApi';
import {
  AutoUpdateRequestWithData,
  AutoUpdateResponse,
} from '../autoUpdateTypes';

const endpoint = 'compositiongroups/matrix';

export enum MatrixAxis {
  Columns = 0,
  Rows = 1,
}
export enum AxisType {
  Colors = 0,
  Sizes = 1,
}

export interface UpdateMatrixColorsRequest {
  compositionIndex: number;
  materialIndex: number;
  colors: ColorData[];
}

export interface AutoUpdateMatrixSizesAdditionalData {
  compositionIndex: number;
  dimensionId: number;
  matrixNodeId: number;
}
export interface AutoUpdateMatrixSizes {
  value: number;
}

export interface CompositionOption {
  id: number;
  colors: string[];
  version: number;
  note: string;
  isReady: boolean;
}

export interface CompositionVersion {
  basedOnId: number;
  anyPa: boolean;
  pendingPas: number[];
  acceptedPas: number[];
  basedOnControllerId: number;
  id: number;
  versionNr: number;
}

export interface CompositionOptionsCell {
  x: number;
  y: number;
  options: CompositionOption[];
  versions: CompositionVersion[];
}

export interface CompositionSupplier {
  supplierId: number;
  cells: CompositionOptionsCell[];
}

export interface CompositionBranch {
  branchId: number;
  suppliers: CompositionSupplier[];
}

export const compositionGroupMatrixApi = {
  getAllCellGroups: (variantId: number) =>
    httpClient.get<CompositionBranch[]>(`compositiongroups/${variantId}/cells`),
  addNewOption: (variantId: number) =>
    httpClient.post<CompositionGroup>(`${endpoint}/${variantId}`),
  getOptions: (variantId: number) =>
    httpClient.get<CompositionGroup[]>(`${endpoint}/${variantId}`),
  getAxisCells: (controllerId: number, axisType: AxisType) =>
    httpClient.get<CompositionGroup[]>(
      `${endpoint}/${controllerId}/${axisType}`,
    ),
  updateColors: (
    controllerId: number,
    matrixNodeId: number,
    data: UpdateMatrixColorsRequest,
  ) =>
    httpClient.put(`${endpoint}/${controllerId}/${matrixNodeId}/colors`, {
      data,
    }),
  autoUpdateSizes: (
    controllerId: number,
    data: AutoUpdateRequestWithData<
      AutoUpdateMatrixSizes,
      AutoUpdateMatrixSizesAdditionalData
    >,
  ) =>
    httpClient.put<AutoUpdateResponse<DimensionData>>(
      `${endpoint}/${controllerId}/sizes/auto-update`,
      { data },
    ),
  patchVersions: (controllerId: number, branchId: number, supplierId: number) =>
    httpClient.put(
      `${endpoint}/${controllerId}/versions/${supplierId}/${branchId}`,
    ),
};
