import {
  Flex,
  LightMode,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { Role } from '@texas/api/endpoints/userApi';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { clientEndpoints } from '@texas/clientEndpoints';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { fileEvents } from '@texas/components/shared/dropzone/events';
import { FileDropzone } from '@texas/components/shared/dropzone/FileDropzone';
import { ImageDropzone } from '@texas/components/shared/dropzone/ImageDropzone';
import { maxSize } from '@texas/components/shared/dropzone/shared';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { FileBrowserModal } from '@texas/components/shared/files/FileBrowserModal';
import { useActiveSession } from '@texas/hooks/useSession';
import { ServerError } from '@texas/types';
import { hasRole } from '@texas/utils/helpers/roleHelpers';
import {
  acceptedImageFormats,
  imageExtensions,
} from '@texas/utils/helpers/filesHelper';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface MatrixValue {
  id: number;
  matrixNodeValueXId: number;
  matrixNodeValueYId: number;
  photoId?: number;
  photo?: SimpleFileLink;
}

interface SimpleFileLink {
  id: number;
  name: string;
  extension: string;
  identifier: string;
  archived: boolean;
}

export function MatrixCellPhoto({
  matrixValue,
  variantId,
  folderId,
}: {
  matrixValue: MatrixValue;
  variantId: number;
  folderId: number;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const [cellPhoto, setCellPhoto] = useState<SimpleFileLink | null>(
    matrixValue.photo != undefined ? matrixValue.photo : null,
  );

  const session = useActiveSession();
  const allowEdit = hasRole(session.currentUser.role, [
    Role.InternalAdministrator,
    Role.InternalAgent,
    Role.SystemAdministrator,
  ]);

  const { request: updateRequest } = useApiRequest(variantsApi.updatePhoto);

  const {
    isOpen: isRemoveFileOpen,
    onClose: onRemoveFileClose,
    onOpen: onRemoveFileOpen,
  } = useDisclosure();

  const {
    isOpen: isFileBrowserOpen,
    onClose: onFileBrowserClose,
    onOpen: onFileBrowserOpen,
  } = useDisclosure();

  const updateFile = useCallback(
    (file: FileLink) => {
      request(
        updateRequest,
        [
          variantId,
          file.id,
          matrixValue.matrixNodeValueXId,
          matrixValue.matrixNodeValueYId,
        ],
        (_) => {
          toast({
            title: t('fileBrowser.updatedFile'),
            status: 'success',
            isClosable: true,
          });
          setCellPhoto({
            id: file.id,
            identifier: file.identifier,
            name: file.name,
            extension: file.extension,
            archived: false,
          });
          fileEvents.fileChanged.dispatch(file);
        },
        (error: ServerError) => {
          toast({
            title: t('fileBrowser.updateFileFailed'),
            description: <ErrorsList errors={error.errors} />,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [
      updateRequest,
      variantId,
      matrixValue.matrixNodeValueXId,
      matrixValue.matrixNodeValueYId,
      toast,
      t,
    ],
  );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateFile, maxSize);

  return (
    <LightMode>
      <Flex direction="column" gap={2} width="fit-content" color="black">
        <Text variant="header-medium">{t('variant.photo')}</Text>
        {cellPhoto && (
          <ImageDropzone
            imageId={cellPhoto.id}
            imageIdentifier={cellPhoto.identifier}
            imageSrc={clientEndpoints.previewImage(cellPhoto.identifier, 250)}
            imageArchived={cellPhoto.archived}
            allowEdit={allowEdit}
            onUpload={(files) => uploadFiles(files, folderId)}
            isUploading={isUploading}
            uploadFailed={uploadFailed}
            fileUploads={fileUploads}
            abortFileUpload={abortFileUpload}
            onRemovefileOpen={onRemoveFileOpen}
            onFileBrowserOpen={onFileBrowserOpen}
            webcamPhotoPrefix="matrix-cell"
          />
        )}
        {allowEdit && !cellPhoto && (
          <FileDropzone
            accept={acceptedImageFormats}
            isUploading={isUploading}
            uploadFailed={uploadFailed}
            fileUploads={fileUploads}
            abortFileUpload={abortFileUpload}
            onUpload={(files) => uploadFiles(files, folderId)}
            onFileBrowserOpen={onFileBrowserOpen}
            webcamPhotoPrefix="matrix-cell"
          />
        )}
        <VerifyDialogWithRequest
          headerTitle={t('fileBrowser.removeFile')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={variantsApi.deletePhoto}
          args={[
            variantId,
            matrixValue.matrixNodeValueXId,
            matrixValue.matrixNodeValueYId,
          ]}
          isOpen={isRemoveFileOpen}
          onClose={onRemoveFileClose}
          onPerformed={() => {
            setCellPhoto(null);
            fileEvents.fileDeleted.dispatch();
          }}
          onSuccessTitle={t('fileBrowser.fileRemoved')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
        <FileBrowserModal
          rootFolderId={folderId}
          mode="Select"
          isOpen={isFileBrowserOpen}
          onClose={onFileBrowserClose}
          extensionFilter={imageExtensions}
          onSelect={(file: FileLink) => {
            updateFile(file);
            onFileBrowserClose();
          }}
        />
      </Flex>
    </LightMode>
  );
}
