import {
  FilterOptionIds,
  PaSearchQuery,
  PaSearchQueryRequest,
  SearchBase,
} from '@texas/api/endpoints/search/searchPaTypes';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { HttpClientResponse } from '@texas/api/httpClient/types';
import { paFilters } from '@texas/components/filter/paFilters';
import { SelectFilterElement } from '@texas/components/filter/SelectFilter';
import { SwitchFilterElement } from '@texas/components/filter/SwitchFilter';
import {
  PaFilterResult,
  PaFilterType,
} from '@texas/components/filter/types/paTypes';
import {
  FilterOptionType,
  FilterTypeEnum,
} from '@texas/components/filter/types/types';
import { FilterOptions } from '@texas/components/searchArticles/types';
import { PaSearchProps } from '@texas/components/widgets/ProductApprovals/types';
import { useLocalStorage } from '@texas/hooks/useLocalStorage';
import { isArray } from 'angular';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export type ActiveFilterType = [manuallyAdded: boolean, type: FilterOptionType];

export const defaultEmptyFilters: FilterOptionIds = {
  branchIds: [],
  categoryCodeIds: [],
  customerIds: [],
  conceptIds: [],
  supplierIds: [],
  paStates: [],
};

export function useSearchProductApprovals<R extends SearchBase>({
  defaultFilters,
  defaultSearchProps,
  overrideDefaultSearchPage,
  defaultPage,
  limit,
  localStorageKey,
  filterOptions,
  optOutDefaultOrder = false,
  onFiltersChanged = undefined,
  request,
  defaultData,
}: {
  defaultFilters: FilterOptionIds;
  defaultSearchProps: PaSearchProps;
  overrideDefaultSearchPage?: PaSearchProps;
  defaultPage: number;
  limit: number;
  localStorageKey?: string;
  filterOptions?: FilterOptions;
  optOutDefaultOrder?: boolean;
  onFiltersChanged?: () => void;
  request: (query: PaSearchQueryRequest) => Promise<HttpClientResponse<R>>;
  defaultData: Omit<R, keyof SearchBase>;
}) {
  const [searchPage, setSearchPage] = useLocalStorage<PaSearchProps>(
    localStorageKey,
    defaultSearchProps,
    overrideDefaultSearchPage,
  );
  const searchParamsRef = useRef<PaSearchQuery>(searchPage.searchParams);
  const init = useRef(false);
  const defaultDataRef = useRef(defaultData);

  const filteredFilters = searchPage.filters
    .map((type) => paFilters.find((f) => f.optionType === type))
    .filter((f) => f !== undefined) as PaFilterType[];

  const [activeFiltersVariantResults, setActiveFiltersVariantResults] =
    useState<PaFilterResult[]>([
      {
        ...defaultFilters,
        isPopulated: false,
        optionType: undefined,
      },
      ...filteredFilters.map((f) => ({
        optionType: f.optionType,
        ...defaultFilters,
        isPopulated: false,
      })),
    ]);

  const [activeFilterTypes, setActiveFilterTypes] = useState<
    ActiveFilterType[]
  >(filteredFilters.map((f) => [false, f.optionType]));

  const setSearchParamsWithRef = useCallback(
    (params: PaSearchQuery) => {
      if (
        onFiltersChanged &&
        params.page === searchParamsRef.current.page &&
        params.sortBy === searchParamsRef.current.sortBy &&
        params.sortDesc === searchParamsRef.current.sortDesc
      ) {
        onFiltersChanged();
      }
      searchParamsRef.current = { ...params };
      setSearchPage((s) => ({
        ...s,
        searchParams: {
          ...params,
        },
      }));
    },
    [setSearchPage, onFiltersChanged],
  );
  const handleOnFilterRemove = useCallback(
    (filter: PaFilterType) => {
      setActiveFilterTypes((s) =>
        s.filter(([_, type]) => type !== filter.optionType),
      );
      setActiveFiltersVariantResults((s) =>
        s.filter((f) => f.optionType !== filter.optionType),
      );
      setSearchParamsWithRef({
        ...searchParamsRef.current,
        [filter.queryParamsKey]: undefined,
        page: defaultPage,
      });
      setSearchPage((s) => ({
        ...s,
        filters: s.filters.filter((f) => f !== filter.optionType),
      }));
    },
    [defaultPage, setSearchPage, setSearchParamsWithRef],
  );

  const lookUpFilteredOptions = useCallback(
    (
      currentFilter: PaFilterType,
      previousActiveFilterIndex: number,
    ): number[] | undefined => {
      // ActiveFilterVariantResults is always +1 from activeFilters, since we add the search filter input as the first element in the array with optionType undefined
      let previousFilterResult =
        activeFiltersVariantResults[previousActiveFilterIndex];
      for (let i = previousActiveFilterIndex - 1; i >= 0; i--) {
        if (previousFilterResult.isPopulated) break;
        previousFilterResult = activeFiltersVariantResults[i];
      }

      if (
        !previousFilterResult.isPopulated ||
        currentFilter.type === FilterTypeEnum.Checkbox
      ) {
        return undefined;
      }
      return previousFilterResult[currentFilter.queryParamsKey];
    },
    [activeFiltersVariantResults],
  );

  const activeFiltersRef = useRef<PaFilterType[]>([]);
  const activeFilters = useMemo(
    () =>
      activeFilterTypes
        .map(([_, type]) => paFilters.find((f) => f.optionType === type))
        .filter((f) => f !== undefined) as PaFilterType[],
    [activeFilterTypes],
  );
  useEffect(() => {
    activeFiltersRef.current = activeFilters;
  }, [activeFilters]);

  const { data, refetch, set, loading, error } = useApiResource(request);

  const fetchDebounce = useDebouncedCallback(() => {
    refetch({
      ...searchPage.searchParams,
      limit: limit,
      optOutDefaultOrdering: optOutDefaultOrder,
    });
  }, 300);

  useEffect(() => {
    if (!isSearchParamsEmpty(searchPage.searchParams)) {
      fetchDebounce();
    } else {
      set({
        ...(defaultDataRef.current as R),
        exportArticleItems: [],
        filterOptionIds: { ...defaultFilters },
        branchFilters: [],
        customerFilters: [],
        categoryCodeFilters: [],
        productGroupFilters: [],
        materialFilters: [],
        conceptFilters: [],
      });
    }
  }, [refetch, set, searchPage.searchParams, fetchDebounce, defaultFilters]);

  useEffect(() => {
    if (data === null) return;

    setActiveFiltersVariantResults((r) => {
      let unpopulatedFilters = 0;
      for (let i = activeFiltersRef.current.length - 1; i >= 0; i--) {
        const value =
          searchParamsRef.current[activeFiltersRef.current[i].queryParamsKey];
        if (isArray(value) && value.length === 0) {
          unpopulatedFilters++;
          continue;
        }

        break;
      }
      if (!init.current) {
        init.current = true;
        return r.map((s) => {
          return { ...s, ...data.filterOptionIds, isPopulated: true };
        });
      }

      return r.map((s, i) => {
        if (i === r.length - unpopulatedFilters - 1) {
          return { ...s, ...data.filterOptionIds, isPopulated: true };
        }
        return s;
      });
    });
  }, [data]);

  const filterElements = useMemo(() => {
    return activeFilters.map((f, i) => {
      const filterOption = filterOptions
        ? filterOptions[f.optionType]
        : undefined;

      if (filterOption?.disabled) {
        return null;
      }

      if (f.type === FilterTypeEnum.Select) {
        return (
          <SelectFilterElement
            static={filterOption ? filterOption.static : false}
            key={f.optionType}
            customOptionComponentView={f.customOptionComponentView}
            customValueLabelComponentView={f.customValueLabelComponentView}
            filteredOptions={lookUpFilteredOptions(f, i) ?? []}
            valueIds={searchPage.searchParams[f.queryParamsKey] ?? []}
            onChange={(ids) => {
              if (ids.length === 0) {
                setActiveFiltersVariantResults((s) =>
                  s.map((a) => {
                    if (a.optionType === f.optionType) {
                      return {
                        ...a,
                        ...defaultFilters,
                        isPopulated: false,
                      };
                    }
                    return a;
                  }),
                );
              }

              setSearchParamsWithRef({
                ...searchParamsRef.current,
                [f.queryParamsKey]: ids,
                page: defaultPage,
              });
            }}
            onRemove={() => handleOnFilterRemove(f)}
            minSearchTermLength={f.minSearchTermLength}
            fetchOptions={f.fetchOptions}
            fetchValues={f.fetchValues}
            name={f.getName(t)}
            defaultIsOpen={activeFilterTypes.some(
              ([manuallyAdded, type]) => type === f.optionType && manuallyAdded,
            )}
          />
        );
      } else {
        return (
          <SwitchFilterElement
            static={filterOption ? filterOption.static : false}
            key={f.optionType}
            onChange={(value: boolean) => {
              setSearchParamsWithRef({
                ...searchParamsRef.current,
                [f.queryParamsKey]: value,
                page: defaultPage,
              });
            }}
            onLabel={f.getOnLabel(t)}
            offLabel={f.getOffLabel(t)}
            checked={
              searchPage.searchParams[f.queryParamsKey] ?? f.defaultChecked
            }
            onRemove={() => handleOnFilterRemove(f)}
            name={f.getName(t)}
          />
        );
      }
    });
  }, [
    activeFilterTypes,
    activeFilters,
    defaultFilters,
    defaultPage,
    filterOptions,
    handleOnFilterRemove,
    lookUpFilteredOptions,
    searchPage.searchParams,
    setSearchParamsWithRef,
  ]);

  const availableFilters = paFilters.filter((f) => {
    const alreadyAdded = activeFilterTypes.some(
      ([_, type]) => type === f.optionType,
    );
    const filterAvailable = filterOptions
      ? !(filterOptions[f.optionType]?.static ?? false)
      : true;
    return !alreadyAdded && filterAvailable;
  });

  return {
    data,
    filterElements,
    loading,
    error,
    activeFilters,
    searchPage,
    searchParamsRef,
    setSearchPage,
    setSearchParamsWithRef,
    setActiveFiltersVariantResults,
    setActiveFilterTypes,
    refetchDebounce: fetchDebounce,
    availableFilters,
  };
}

function isSearchParamsEmpty(searchParams: PaSearchQuery) {
  return (
    searchParams.searchTerm.length === 0 &&
    (searchParams.branchIds ?? []).length === 0 &&
    (searchParams.categoryCodeIds ?? []).length === 0 &&
    (searchParams.conceptIds ?? []).length === 0 &&
    (searchParams.customerIds ?? []).length === 0 &&
    (searchParams.productGroupIds ?? []).length === 0 &&
    (searchParams.supplierIds ?? []).length === 0 &&
    (searchParams.paStates ?? []).length === 0 &&
    searchParams.showAllVariants === undefined &&
    searchParams.includeArchived === undefined &&
    searchParams.showMyBrands === undefined
  );
}
