import {
  useToast,
  useDisclosure,
  Box,
  Flex,
  Button,
  Divider,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import {
  CompositionGroup,
  compositionGroupApi,
} from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { EmptyDataButton } from '@texas/components/shared/button/EmptyDataButton';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';
import { RenameGroupModal } from '@texas/components/shared/compositionGroup/RenameGroupModal';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { FileBrowserModal } from '@texas/components/shared/files/FileBrowserModal';
import { Icons } from '@texas/components/shared/Icons';
import { WarningComponent } from '@texas/components/shared/WarningComponent';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ServerError } from '@texas/types';
import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CompositionListModal } from '../../compositions/CompositionListModal';
import { DocumentFileModal } from '../../compositions/shared/file/DocumentFileModal';
import { ConfirmCopy } from '../../compositions/shared/group/ConfirmCopy';
import { MatrixColors } from '../matrix/matrixColors/MatrixColors';
import { MatrixSizes } from '../matrix/matrixSizes/MatrixSizes';
import { MarkReady } from './MarkReady';
import { OptionsComponent } from './OptionsComponent';
import { request } from '@texas/utils/helpers/httpHelpers';
import { CompositionGroupTitle } from '@texas/components/shared/compositionGroup/CompositionGroupTitle';
import { TFunction } from 'i18next';
import { imageExtensions } from '@texas/utils/helpers/filesHelper';

export function CompositionGroupContainer({
  option,
  isMatrix,
  onArchive,
  onCopy,
}: {
  option: CompositionGroup;
  isMatrix: boolean;
  onArchive: () => Promise<void>;
  onCopy: (fromId: number) => Promise<void>;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const [sizesWarning, setSizesWarning] = useState<string | undefined>();
  const [colorsWarning, setColorsWarning] = useState<string | undefined>();

  const {
    isOpen: isArchivedCompositionsOpen,
    onClose: onArchivedCompositionsClose,
    onOpen: onArchivedCompositionsOpen,
  } = useDisclosure();

  const {
    isOpen: isFileBrowserOpen,
    onClose: onFileBrowserClose,
    onOpen: onFileBrowserOpen,
  } = useDisclosure();

  const {
    isOpen: isFileModalOpen,
    onClose: onFileModalClose,
    onOpen: onfileModalOpen,
  } = useDisclosure();

  const {
    isOpen: isRemoveFileOpen,
    onClose: onRemoveFileClose,
    onOpen: onRemoveFileOpen,
  } = useDisclosure();
  const { request: updateRequest } = useApiRequest(
    compositionGroupApi.updateFile,
  );

  const updateFile = useCallback(
    (file: FileLink) => {
      request(
        updateRequest,
        [option.id, file.id],
        (_) => {
          toast({
            title: t('fileBrowser.updatedFile'),
            status: 'success',
            isClosable: true,
          });
          compositionGroupEvents.onLayoutChanged.dispatch();
        },
        (error: ServerError) => {
          toast({
            title: t('fileBrowser.updateFileFailed'),
            description: <ErrorsList errors={error.errors} />,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [option.id, t, toast, updateRequest],
  );

  const { request: markNotReadyReqest } = useApiRequest(
    compositionGroupApi.markNotReady,
  );

  const markNotReady = async () =>
    await request(
      markNotReadyReqest,
      [option.id],
      () => {
        toast({
          title: t('compositionGroup.option.markedNotReady'),
          status: 'success',
          isClosable: true,
        });
        compositionGroupEvents.onMarkNotReady.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.actionFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateFile);

  const {
    isOpen: isEditGroupOpen,
    onClose: onEditGroupClose,
    onOpen: onEditGroupOpen,
    value: editGroupValue,
  } = useValueDisclosure<{ id: number; note: string | null }>();
  const {
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen,
  } = useDisclosure();
  const {
    isOpen: isMarkReadyOpen,
    onClose: onMarkReadyClose,
    onOpen: onMarkReadyOpen,
  } = useDisclosure();
  const {
    isOpen: isCopyOpen,
    onClose: onCopyClose,
    onOpen: onCopyOpen,
    value: copyValue,
  } = useValueDisclosure<{
    version: number;
    id: number;
  }>();

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box w="full" overflow="hidden" px={4} pb={4} pl={8} ref={ref}>
      <Flex gap={3} role="group" flexDir="column">
        <CompositionGroupTitle
          version={option.version}
          note={option.note}
          type="option"
        />

        <Flex gap={4}>
          {option.fileId ? (
            <Button
              onClick={onfileModalOpen}
              variant="texas"
              borderRadius="md"
              h="auto"
              minW="fit-content"
              leftIcon={<Icons.PencilRuler />}
            >
              {t('composition.files.viewLayout')}
            </Button>
          ) : (
            <WarningComponent warning={t('compositionGroup.option.noLayout')}>
              <EmptyDataButton
                onClick={onfileModalOpen}
                label={t('composition.files.noLayout')}
                description={t('general.clickToAdd')}
              />
            </WarningComponent>
          )}
          <MarkReady
            isReady={option.isReady}
            hasAnyBasedOn={option.hasAnyBasedOn}
            onClick={onMarkReadyOpen}
            containerWarningRef={ref}
          />
          <Flex
            gap={2}
            align="center"
            transition="opacity 200ms ease, transform 200ms ease"
            flexWrap="wrap"
            opacity={0}
            transform="translateX(0)"
            _groupHover={{ opacity: 1, transform: 'translateX(22px)' }}
            pb={1}
          >
            {option.isReady && (
              <Button
                onClick={markNotReady}
                variant="link"
                leftIcon={<Icons.Undo />}
              >
                {t('compositionGroup.option.markNotReady')}
              </Button>
            )}
            <Button
              onClick={() =>
                onEditGroupOpen({ id: option.id, note: option.note })
              }
              variant="link"
              leftIcon={<Icons.NoteEdit />}
            >
              {t('general.rename')}
            </Button>
            <Button
              variant="link"
              onClick={onArchivedCompositionsOpen}
              leftIcon={<Icons.LayersOutline />}
            >
              {t('composition.archivedCompositions')}
            </Button>
            <Button
              variant="link"
              onClick={() =>
                onCopyOpen({ version: option.version, id: option.id })
              }
              leftIcon={<Icons.ContentCopy />}
            >
              {t('composition.copyIntoNewOption')}
            </Button>
            <Button
              variant="link"
              color="red.200"
              _light={{ color: 'red.400' }}
              onClick={onArchiveOpen}
              leftIcon={<Icons.Archive />}
            >
              {t('compositionGroup.option.archive')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Divider py={2} />
      <Tabs w="full" variant="composition" pt={4}>
        <TabList gap={4}>
          <WarningComponent warning={compositionTabWarning(option, t)}>
            <Tab h="full">
              <Flex gap={1} align="center">
                <Icons.Layers /> <Text>{t('composition.compositions')}</Text>
              </Flex>
            </Tab>
          </WarningComponent>
          {isMatrix && (
            <>
              <WarningComponent warning={colorsWarning}>
                <Tab
                  h="full"
                  flexDir="column"
                  alignItems="start"
                  isDisabled={hasNoMaterials(option)}
                >
                  <Flex gap={1} align="center">
                    <Icons.PaletteSwatch /> <Text>{t('general.colors')}</Text>
                  </Flex>
                  {hasNoMaterials(option) && (
                    <Text
                      color="yellow.300"
                      _light={{ color: 'yellow.400' }}
                      fontSize="sm"
                    >
                      {t('colors.configureMaterialsFirst')}
                    </Text>
                  )}
                </Tab>
              </WarningComponent>

              <WarningComponent warning={sizesWarning}>
                <Tab
                  h="full"
                  flexDir="column"
                  alignItems="start"
                  isDisabled={hasNoDimensionGroups(option)}
                >
                  <Flex gap={1} align="center">
                    <Icons.AxisArrow />
                    <Text>{t('configuration.dimensions')}</Text>
                  </Flex>
                  {hasNoDimensionGroups(option) && (
                    <Text
                      color="yellow.300"
                      _light={{ color: 'yellow.400' }}
                      fontSize="sm"
                    >
                      {t('sizes.configureDimensionsFirst')}
                    </Text>
                  )}
                </Tab>
              </WarningComponent>
            </>
          )}
        </TabList>
        <TabPanels pt="12">
          <TabPanel p={0}>
            <OptionsComponent isMatrix={isMatrix} option={option} />
          </TabPanel>
          <TabPanel>
            {isMatrix && option.colorAxis && (
              <MatrixColors
                colorAxis={option.colorAxis}
                compositionGroupId={option.id}
                onLoad={(groups) => {
                  const warning = hasAnyMaterialNoColors(groups);
                  if (!warning) {
                    setColorsWarning(undefined);
                    return;
                  }
                  setColorsWarning(t('colors.someMissing'));
                }}
              />
            )}
          </TabPanel>
          <TabPanel>
            {isMatrix && option.colorAxis && (
              <MatrixSizes
                colorAxis={option.colorAxis}
                compositionGroupId={option.id}
                onAnyValueChanged={(nrOfZeroDimensions) => {
                  const warning = nrOfZeroDimensions > 0;
                  if (!warning) {
                    setSizesWarning(undefined);
                    return;
                  }
                  setSizesWarning(t('sizes.fillAll'));
                }}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <DocumentFileModal
        fileId={option.fileId}
        onRemoveFileOpen={onRemoveFileOpen}
        onFileBrowserOpen={onFileBrowserOpen}
        onUpload={(files) => uploadFiles(files, option.folderId)}
        isUploading={isUploading}
        uploadFailed={uploadFailed}
        fileUploads={fileUploads}
        abortFileUpload={abortFileUpload}
        isOpen={isFileModalOpen}
        onClose={onFileModalClose}
      />
      <FileBrowserModal
        rootFolderId={option.folderId}
        mode="Select"
        isOpen={isFileBrowserOpen}
        onClose={onFileBrowserClose}
        onSelect={(file: FileLink) => {
          updateFile(file);
          onFileBrowserClose();
        }}
        rejectExtensionFilter={imageExtensions}
      />
      <VerifyDialogWithRequest
        headerTitle={t('compositionGroup.option.markReady')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionGroupApi.markReady}
        args={[option.id]}
        isOpen={isMarkReadyOpen}
        onClose={onMarkReadyClose}
        onPerformed={() => compositionGroupEvents.onMarkReady.dispatch()}
        onSuccessTitle={t('compositionGroup.option.markedReady')}
      >
        {t('compositionGroup.option.markReadyDesc')}
        <br />
        <br />
        {t('compositionGroup.option.markReadyDescNote')}
        <br />
        <br />
        {t('compositionGroup.option.markReadyDescQuestion')}
      </VerifyDialogWithRequest>
      <VerifyDialogWithRequest
        headerTitle={t('fileBrowser.removeLayout')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionGroupApi.deleteFile}
        args={[option.id]}
        isOpen={isRemoveFileOpen}
        onClose={onRemoveFileClose}
        onPerformed={() => compositionGroupEvents.onLayoutChanged.dispatch()}
        onSuccessTitle={t('fileBrowser.fileRemoved')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
      <RenameGroupModal
        onUpdated={() => compositionGroupEvents.onRename.dispatch()}
        group={editGroupValue}
        onClose={onEditGroupClose}
        isOpen={isEditGroupOpen}
      />
      <VerifyDialogWithFunction
        headerTitle={t('compositionGroup.option.archive')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={async () => {
          await onArchive();
          onArchiveClose();
        }}
        isOpen={isArchiveOpen}
        onClose={onArchiveClose}
      >
        {t('compositionGroup.option.archiveDesc')}
      </VerifyDialogWithFunction>
      <CompositionListModal
        isOpen={isArchivedCompositionsOpen}
        onClose={onArchivedCompositionsClose}
        compositionGroupId={option.id}
      />
      {copyValue && (
        <ConfirmCopy
          title="Copy option"
          buttonLabel={t('composition.copyComposition')}
          isOpen={isCopyOpen}
          onClose={onCopyClose}
          compositionGroupId={copyValue.id}
          onConfirm={(id) => {
            onCopyClose();
            onCopy(id);
          }}
        />
      )}
    </Box>
  );
}

function hasNoMaterials(option: CompositionGroup) {
  return option.compositions.every((c) => c.materials.length === 0);
}

function hasNoDimensionGroups(option: CompositionGroup) {
  return option.compositions.every((c) => c.dimension === null);
}

function hasAnyMaterialNoColors(groups: CompositionGroup[]) {
  return groups.some((g) =>
    g.compositions.some((c) => c.materials.some((d) => d.colors.length === 0)),
  );
}

function compositionTabWarning(option: CompositionGroup, t: TFunction) {
  if (option.compositions.length === 0) {
    return t('compositionGroup.option.missingCompositions');
  }

  if (option.compositions.some((c) => c.materials.length === 0)) {
    return t('compositionGroup.option.missingMaterials');
  }

  if (option.compositions.some((c) => c.dimension === null)) {
    return t('compositionGroup.option.missingDimensions');
  }

  return undefined;
}
