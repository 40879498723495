import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  ModalFooter,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useSearchArticles } from '@texas/components/shared/search/useSearchArticles';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GroupedOption,
  SelectFilterOption,
  ToggleFilterOption,
  GetFilterGroupedOptions,
} from '../../filterGroupedOptions';
import {
  groupStyles,
  groupBadgeStyles,
  articlesWidgetSelectStyles,
  SearchDropdownIndicator,
} from '../../styles';
import Select from 'react-select';
import { ArticleSearchProps } from '@texas/components/searchArticles/types';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { TFunction } from 'i18next';
import { emptyPagedItems } from '@texas/utils/helpers/commonHelpers';
import { Icons } from '@texas/components/shared/Icons';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import {
  saveBlobAsFile,
  excelExportFileName,
} from '@texas/utils/helpers/filesHelper';
import { PaFilterOptions } from '@texas/api/endpoints/productApprovalsApi';
import { searchApi } from '@texas/api/endpoints/search/searchApi';
import {
  SearchVariantItem,
  defaultEmptyFilters,
} from '@texas/api/endpoints/search/searchVariantsTypes';

interface ModalProps extends SharedDisclosureProps {
  filters: PaFilterOptions;
  articleSearchProps: ArticleSearchProps;
  columns: (t: TFunction) => ColumnDef<SearchVariantItem, any>[];
}

const pageSize = 12;

export function ArticlesOverviewModal({
  filters,
  articleSearchProps,
  columns,
  isOpen,
  onClose,
}: ModalProps) {
  const { t } = useTranslation();
  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="1400px">
        <ModalHeader>
          {t('widgets.widgetResultOverview')}
          <Text
            fontFamily="Instrument Sans"
            fontWeight="normal"
            fontSize="md"
            color="gray.100"
            _light={{ color: 'texas.bg.800' }}
          >
            {t('widgets.filterDisclaimer')}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InnerComponent
            filters={filters}
            articleSearchProps={articleSearchProps}
            columns={columns}
          />
        </ModalBody>
        <ModalFooter bg="texas.bg.700" _light={{ bg: 'gray.50' }}>
          <Button onClick={onClose}>{t('general.close')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface InnerProps {
  filters: PaFilterOptions;
  articleSearchProps: ArticleSearchProps;
  columns: (t: TFunction) => ColumnDef<SearchVariantItem, any>[];
}

function InnerComponent({
  filters,
  articleSearchProps,
  columns: widgetColumns,
}: InnerProps) {
  const { t } = useTranslation();
  const {
    availableFilters,
    setActiveFiltersVariantResults,
    setActiveFilterTypes,
    setSearchPage,
    filterElements,
    searchPage,
    setSearchParamsWithRef,
    searchParamsRef,
    error,
    articles,
    loading,
    refetchArticles,
  } = useSearchArticles({
    defaultFilters: filters,
    defaultArticleSearchProps: articleSearchProps,
    defaultPage: 1,
    limit: pageSize,
    request: searchApi.searchVariants,
    defaultData: { pagedItems: emptyPagedItems },
  });

  useEffect(() => {
    refetchArticles();
  }, [refetchArticles]);

  const columns = useMemo(() => {
    return widgetColumns(t);
  }, [widgetColumns, t]);

  const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  return (
    <>
      <Flex flexDir="column">
        <Flex alignItems="center" gap={2}>
          <Box flexShrink={0}>
            <Select<
              SelectFilterOption | ToggleFilterOption,
              false,
              GroupedOption
            >
              inputId="clickableInput"
              placeholder=""
              styles={articlesWidgetSelectStyles}
              components={{ DropdownIndicator: SearchDropdownIndicator }}
              options={GetFilterGroupedOptions(availableFilters)}
              formatGroupLabel={formatGroupLabel}
              onChange={(value) => {
                if (!value) return;
                const type = value.value;
                setActiveFiltersVariantResults((s) => [
                  ...s,
                  {
                    optionType: type,
                    ...defaultEmptyFilters,
                    isPopulated: false,
                  },
                ]);
                setActiveFilterTypes((s) => [...s, [true, type]]);
                setSearchPage((s) => ({
                  ...s,
                  filters: [...s.filters, type],
                }));
              }}
              value={null}
            />
          </Box>
          <Button
            variant="texas-light"
            ml="auto"
            leftIcon={<Icons.FileExport />}
            onClick={async () => {
              const response = await variantsApi.downloadExcel(
                articles?.exportArticleItems.flatMap((x) => {
                  return x.branches.flatMap((b) => ({
                    variantId: x.variantId,
                    branchId: b.id,
                  }));
                }) ?? [],
              );

              if (response.hasError) return;

              saveBlobAsFile(response.data, excelExportFileName());
            }}
            isDisabled={loading || articles?.pagedItems.totalItems == 0}
          >
            {t('general.exportExcel')}
          </Button>
        </Flex>

        <Box
          width="100%"
          display="grid"
          gridTemplateColumns="repeat(6, 1fr)"
          gridGap={1}
          rowGap={1}
        >
          <Box height="fit-content" mt="auto" gridColumn="span 2">
            <SearchFilterInput
              placeholder={t('searchArticles.itemcodeOrDescription')}
              value={searchPage.searchParams.searchTerm}
              onChange={(searchTerm) =>
                setSearchParamsWithRef({
                  ...searchParamsRef.current,
                  searchTerm,
                  page: 1,
                })
              }
            />
          </Box>
          {filterElements}
        </Box>
      </Flex>
      <DataTableContainer
        p={4}
        w="100%"
        minH={0}
        display="flex"
        flexDir="column"
        error={error}
        css={{ ' th, td': { whiteSpace: 'normal' } }}
        datatable={{
          data: articles?.pagedItems.items ?? [],
          columns: columns,
          isLoading: loading,
          rowProps: {
            getRowId: (originalRow, _) => {
              return originalRow.articleId.toString();
            },
          },
          sorted: {
            onSortedChange: ({ key, desc }) => {
              setSearchParamsWithRef({
                ...searchParamsRef.current,
                sortBy: key,
                sortDesc: desc,
                page: 1,
              });
            },
            key: searchPage.searchParams['sortBy'],
            desc: searchPage.searchParams['sortDesc'],
          },
        }}
        pagination={{
          totalItems: articles?.pagedItems.totalItems ?? 0,
          pageSize: pageSize,
          currentPage: searchPage.searchParams.page,
          onPageChange: (page) =>
            setSearchParamsWithRef({
              ...searchParamsRef.current,
              page,
            }),
        }}
      />
    </>
  );
}
