import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  useToast,
  ModalBody,
  FormControl,
  Input,
  ModalFooter,
} from '@chakra-ui/react';
import { compositionApi } from '@texas/api/endpoints/compositionApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormCounter } from '../form/FormCounter';

interface Props extends SharedDisclosureProps {
  composition: { id: number; note: string | null } | null;
  onUpdated: (note: string) => void;
}

interface FormData {
  note: string;
}

export function RenameCompositionModal({
  composition,
  isOpen,
  onClose,
  onUpdated,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('general.rename')}</ModalHeader>
        {composition && (
          <Form
            composition={composition}
            onClose={onClose}
            onUpdated={onUpdated}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

function Form({
  composition,
  onClose,
  onUpdated,
}: {
  composition: { id: number; note: string | null };
  onClose: () => void;
  onUpdated: (note: string) => void;
}) {
  const { t } = useTranslation();
  const { request: updateRequest, error } = useApiRequest(
    compositionApi.setNote,
  );
  const toast = useToast();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: { note: composition.note ?? '' },
  });
  const watchNote = useWatch({
    control,
    name: 'note',
    defaultValue: composition.note ?? '',
  });

  const onSubmit = async (data: FormData) =>
    await request(
      updateRequest,
      [composition.id, data],
      (_) => {
        onUpdated(data.note);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormControl isInvalid={!!errors.note}>
            <TexasFormLabel>{t('general.name')}</TexasFormLabel>
            <Input
              variant="texas"
              {...register('note', {
                maxLength: {
                  value: 40,
                  message: t('errors.maxLength', { count: 40 }),
                },
              })}
            />
            <FormCounter length={watchNote.length} maxLength={40} />
            <ErrorLabel text={errors.note?.message} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <SubmitButton loading={isSubmitting}>
            {t('general.update')}
          </SubmitButton>
        </ModalFooter>
      </form>
    </>
  );
}
