import { Box, Text } from '@chakra-ui/react';
import { Color } from './ColorPicker';
import checker from '@assets/checker.svg';

export function ColorComponent({
  color,
  selected,
  onSelect,
}: {
  color: Color;
  selected: boolean;
  onSelect: () => void;
}) {
  return (
    <Box
      boxShadow="base"
      borderRadius="md"
      overflow="hidden"
      cursor="pointer"
      onClick={onSelect}
      borderColor="transparent"
      borderStyle="solid"
      borderWidth="2px"
      transition="transform 200ms ease, border-color 200ms ease"
      _hover={{ transform: 'scale(1.02)' }}
      {...(selected
        ? { borderColor: 'white', _light: { borderColor: 'texas.bg.900' } }
        : null)}
    >
      {(color.hex?.length ?? 0) > 0 ? (
        <Box h="16" bgColor={`#${color.hex}`} />
      ) : (
        <Box
          h={16}
          bg={`url(${checker})`}
          backgroundSize="contain"
          opacity={0.2}
          backgroundColor="#b6bde5"
        />
      )}
      <Box bg="black" color="white" p={2}>
        <Text>{color.name}</Text>
        <Text fontSize="sm">{color.code}</Text>
      </Box>
    </Box>
  );
}
