import { PagedItems } from '@texas/types';
import { ArticleProcess } from '../variantsApi';
import {
  ExportArticleItem,
  SearchFilter,
  SearchArticlesItem,
} from './searchApi';
import { ArticleState } from '../articlesApi';

export const defaultEmptyFilters: FilterOptionIds = {
  branchIds: [],
  categoryCodeIds: [],
  customerIds: [],
  conceptIds: [],
  states: [],
  materialIds: [],
  productGroupIds: [],
  processes: [],
};

export const emptyVariantsSearchQuery: VariantsSearchQuery = {
  searchTerm: '',
  sortBy: 'itemCode',
  sortDesc: false,
  page: 1,
  ...defaultEmptyFilters,
  showAllVariants: undefined,
  includeArchived: undefined,
  showMyBrands: false,
};

export interface SearchVariantsBase {
  exportArticleItems: ExportArticleItem[];
  branchFilters: SearchFilter[];
  customerFilters: SearchFilter[];
  conceptFilters: SearchFilter[];
  materialFilters: SearchFilter[];
  productGroupFilters: SearchFilter[];
  categoryCodeFilters: SearchFilter[];
  filterOptionIds: FilterOptionIds;
}

export interface SearchVariantsCountResponse extends SearchVariantsBase {
  totalCount: number;
}

export interface SearchVariantsResponse extends SearchVariantsBase {
  pagedItems: PagedItems<SearchVariantItem>;
}

export interface FilterOptionIds {
  conceptIds?: number[];
  categoryCodeIds?: number[];
  customerIds?: number[];
  states?: number[];
  branchIds?: number[];
  materialIds?: number[];
  productGroupIds?: number[];
  processes?: number[];
}

export interface SearchVariantItem {
  articleId: number;
  variantId: number;
  itemCode: string;
  name: string;
  variantName: string;
  imageIdentifier: string;
  customerId: number;
  customerIdentifier: string;
  customerName: string;
  articleProcess: ArticleProcess;
  concept: string;
  conceptId: number;
  branch: string;
  branchId: number;
  state: ArticleState;
  productGroup: string;
  color: string;
  size: string;
  material: string;
  created: string;
  sampleReadyDate?: string;
  mainVariant: boolean;
}

export interface VariantsSearchQuery extends FilterOptionIds {
  searchTerm: string;
  sortBy: string;
  sortDesc: boolean;
  page: number;
  showAllVariants?: boolean;
  includeArchived?: boolean;
  showMyBrands?: boolean;
}

export interface VariantsSearchQueryRequest extends VariantsSearchQuery {
  limit: number;
  optOutDefaultOrdering: boolean;
}

export interface SearchArticlesResponse {
  total: number;
  results: SearchArticlesItem[];
}
