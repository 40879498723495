import {
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  freightCostsApi,
  CreateFreightCostRequest,
} from '@texas/api/endpoints/metadata/freightCostsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { reactEvents } from '@bridge/reactEvents';

export function CreateFreightCost() {
  const toast = useToast();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    request: createRequest,
    error,
    loading,
  } = useApiRequest(freightCostsApi.createFreightCost);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateFreightCostRequest>({ defaultValues: { name: '' } });
  const onSubmit = async (data: CreateFreightCostRequest) => {
    await request(
      createRequest,
      [data],
      () => {
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        reset();
        onClose();
        reactEvents.freightCostCreated.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  return (
    <>
      <Button variant="texas-solid" onClick={onOpen}>
        {t('general.addNew')}
      </Button>
      <Modal
        onClose={() => {
          reset();
          onClose();
        }}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader fontWeight="bold">
            {t('configuration.AddNewFreightCost')}
          </ModalHeader>
          <ModalBody>
            {error && <ErrorDetails error={error} />}
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Flex direction="column" gap={4}>
                <FormControl isRequired={true} isInvalid={!!errors.name}>
                  <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('name', {
                      required: true,
                      maxLength: {
                        value: 100,
                        message: t('errors.maxLength', { count: 100 }),
                      },
                    })}
                    placeholder={t('general.name')}
                  />
                  <ErrorLabel text={errors.name?.message} />
                </FormControl>
                <FormControl isRequired={true} isInvalid={!!errors.value}>
                  <TexasFormLabel>{t('general.value')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('value', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    placeholder={t('general.value')}
                  />
                  <ErrorLabel text={errors.value?.message} />
                </FormControl>
                <FormControl
                  isRequired={true}
                  isInvalid={!!errors.displayOrder}
                >
                  <TexasFormLabel>{t('branch.displayOrder')}</TexasFormLabel>
                  <Input
                    type="number"
                    variant="outline"
                    {...register('displayOrder', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    placeholder={t('branch.displayOrder')}
                  />
                  <ErrorLabel text={errors.displayOrder?.message} />
                </FormControl>
                <SubmitButton loading={loading}>
                  {t('general.create')}
                </SubmitButton>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
