import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ConnectContactRequest } from '@texas/api/endpoints/contactsApi';
import { SupplierContact } from '@texas/api/endpoints/suppliersApi';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { ArchivedTooltip } from '@texas/components/shared/tooltip/ArchivedTooltip';
import { TFunction } from 'i18next';

export const contactsColumns = (
  t: TFunction,
  referenceId: number,
  onRemove: (data: ConnectContactRequest) => Promise<void>,
  onEditOpen: (id: number) => void,
) => {
  const columnHelper = createColumnHelper<SupplierContact>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.name'),
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: t('general.email'),
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <Flex justifyContent="end" alignItems="center" gap={2}>
          {info.row.original.archived && (
            <ArchivedTooltip archived={info.row.original.archived} />
          )}
          <Menu placement="left">
            <MenuButton float="right" aria-label={t('general.more')}>
              <Icons.DotsHorizontal boxSize={6} />
            </MenuButton>
            <Portal>
              <MenuList color="white" zIndex={99}>
                <MenuItem
                  icon={<Icons.Pencil boxSize={defaultIconSize} />}
                  onClick={() => onEditOpen(info.row.original.id)}
                >
                  {t('general.edit')}
                </MenuItem>
                <MenuItem
                  icon={<Icons.TrashOutline boxSize={defaultIconSize} />}
                  onClick={() =>
                    onRemove({
                      id: info.row.original.id,
                      referenceId: referenceId,
                    })
                  }
                >
                  {t('general.remove')}
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Flex>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
