import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  useDisclosure,
  ModalFooter,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import {
  DimensionGroup,
  UpdateDimensionGroupRequest,
  dimensionGroupsApi,
} from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { ImageDropzone } from '@texas/components/shared/dropzone/ImageDropzone';
import { FileDropzone } from '@texas/components/shared/dropzone/FileDropzone';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { FileLink, SimpleFileLink } from '@texas/api/endpoints/filesApi';
import { maxSize } from '@texas/components/shared/dropzone/shared';
import { clientEndpoints } from '@texas/clientEndpoints';
import { acceptedImageFormats } from '@texas/utils/helpers/filesHelper';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { ErrorsList } from '@texas/components/shared/ErrorsList';

export interface EditDimensionGroupProps extends SharedDisclosureProps {
  onUpdated: (dimensionGroup: DimensionGroup) => void;
  dimensionGroupId: number | null;
  folderId: number;
}

export function EditDimensionGroup({
  onUpdated,
  dimensionGroupId,
  onClose,
  isOpen,
  folderId,
}: EditDimensionGroupProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('configuration.editDimensionGroup')}</ModalHeader>
        {dimensionGroupId && (
          <Form
            dimensionGroupId={dimensionGroupId}
            onClose={onClose}
            onUpdated={onUpdated}
            folderId={folderId}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

function Form({
  dimensionGroupId,
  onClose,
  onUpdated,
  folderId,
}: {
  dimensionGroupId: number;
  onClose: () => void;
  onUpdated: (dimensionGroup: DimensionGroup) => void;
  folderId: number;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const [file, setFile] = useState<SimpleFileLink | null>(null);

  const {
    isOpen: isRemoveFileOpen,
    onClose: onRemoveFileClose,
    onOpen: onRemoveFileOpen,
  } = useDisclosure();

  const {
    request: updateRequest,
    error,
    loading,
  } = useApiRequest(dimensionGroupsApi.update);
  const {
    data: dimensionGroup,
    refetch: refetchDimensionGroup,
    loading: loadingData,
  } = useApiResource(dimensionGroupsApi.get);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<UpdateDimensionGroupRequest>();

  const { fields, update } = useFieldArray({
    control,
    name: 'dimensions',
    keyName: 'dId',
  });

  useEffect(() => {
    refetchDimensionGroup(dimensionGroupId);
  }, [reset, refetchDimensionGroup, dimensionGroupId]);

  useEffect(() => {
    if (!dimensionGroup) return;

    reset({
      name: dimensionGroup.name,
      description: dimensionGroup.description,
      dimensions: dimensionGroup.dimensions,
    });

    setFile(dimensionGroup.file);
  }, [reset, dimensionGroup]);

  const onSubmit = async (data: UpdateDimensionGroupRequest) =>
    await request(
      updateRequest,
      [dimensionGroup!.id, data],
      (data: DimensionGroup) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        onUpdated(data);
        onClose();
      },
    );

  const { request: updateFileRequest } = useApiRequest(
    dimensionGroupsApi.updateFile,
  );

  const updateFile = useCallback(
    (file: FileLink) => {
      request(
        updateFileRequest,
        [dimensionGroupId, file.id],
        (_) => {
          toast({
            title: t('fileBrowser.updatedFile'),
            status: 'success',
            isClosable: true,
          });
          setFile(file);
        },
        (error: ServerError) => {
          toast({
            title: t('fileBrowser.updateFileFailed'),
            description: <ErrorsList errors={error.errors} />,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [dimensionGroupId, setFile, t, toast, updateFileRequest],
  );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateFile, maxSize);

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Flex flexDir="column" gap={4}>
            {loadingData && (
              <Flex gap={2}>
                <Text>{t('general.loadingData')}</Text>
                <Spinner />
              </Flex>
            )}
            {!loadingData && (
              <>
                <Flex justify="center">
                  {file && (
                    <ImageDropzone
                      imageId={file.id}
                      imageIdentifier={file.identifier}
                      imageSrc={clientEndpoints.previewImage(
                        file.identifier,
                        250,
                      )}
                      imageArchived={false}
                      allowEdit={true}
                      onUpload={(files) => uploadFiles(files, folderId)}
                      isUploading={isUploading}
                      uploadFailed={uploadFailed}
                      fileUploads={fileUploads}
                      abortFileUpload={abortFileUpload}
                      onRemovefileOpen={onRemoveFileOpen}
                      showWebcamBtn={false}
                    />
                  )}
                  {!loading && !file && (
                    <FileDropzone
                      accept={acceptedImageFormats}
                      isUploading={isUploading}
                      uploadFailed={uploadFailed}
                      fileUploads={fileUploads}
                      abortFileUpload={abortFileUpload}
                      onUpload={(files) => uploadFiles(files, folderId)}
                      showWebcamBtn={false}
                    />
                  )}
                </Flex>
                <FormControl isRequired={true}>
                  <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('name', { required: true })}
                    placeholder={t('general.name')}
                  />
                  <ErrorLabel text={errors.name?.message} />
                </FormControl>
                <FormControl>
                  <TexasFormLabel>{t('general.description')}</TexasFormLabel>
                  <Input
                    variant="outline"
                    {...register('description')}
                    placeholder={t('general.description')}
                  />
                  <ErrorLabel text={errors.description?.message} />
                </FormControl>
                <Flex direction="column">
                  <Text variant="main">{t('configuration.dimensions')}</Text>
                  <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                  {fields.map((d, i) => {
                    return (
                      <Flex direction="column" key={d.id} pb={2}>
                        <Flex gap={1}>
                          <Input
                            {...register(`dimensions.${i}.name` as const, {
                              required: {
                                value: true,
                                message: t('errors.nameRequired', {
                                  name: t('configuration.dimensionName'),
                                }),
                              },
                            })}
                            variant="outline"
                            onChange={(x) => {
                              update(i, { ...d, name: x.target.value });
                            }}
                          />
                          <Input
                            {...register(
                              `dimensions.${i}.identifier` as const,
                              {
                                required: false,
                                maxLength: {
                                  value: 10,
                                  message: t('errors.nameMaxLength', {
                                    name: t('configuration.identifier'),
                                    count: 10,
                                  }),
                                },
                              },
                            )}
                            placeholder={t('configuration.identifier')}
                            variant="outline"
                            onChange={(x) => {
                              update(i, { ...d, identifier: x.target.value });
                            }}
                            w={36}
                          />
                        </Flex>
                        <ErrorLabel
                          text={errors.dimensions?.[i]?.name?.message}
                        />
                        <ErrorLabel
                          text={errors.dimensions?.[i]?.identifier?.message}
                        />
                      </Flex>
                    );
                  })}
                </Flex>
                <Text variant="note">
                  {t('configuration.dimensionCanNotBeEdited')}
                </Text>
              </>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <SubmitButton loading={loading} disabled={loading || loadingData}>
            {t('general.update')}
          </SubmitButton>
        </ModalFooter>
      </form>
      {dimensionGroup && (
        <VerifyDialogWithRequest
          headerTitle={t('fileBrowser.removeImage')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={dimensionGroupsApi.deleteFile}
          args={[dimensionGroup.id]}
          isOpen={isRemoveFileOpen}
          onClose={onRemoveFileClose}
          onPerformed={() => {
            setFile(null);
          }}
          onSuccessTitle={t('fileBrowser.fileRemoved')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
      )}
    </>
  );
}
