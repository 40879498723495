// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.options', {
    controller: 'OptionsController as vm',
    parent: 'variant',
    url: '/options',
    templateUrl: 'main/article/variant/options/options.tpl.html',
    data: { pageTitle: 'Options' },
  });
};

class OptionsController {
  constructor(variant) {
    'ngInject';

    this.variant = variant;
    this.article = variant.article;
    this.nodeGroup = {
      nodeXValues: this.article.nodeXValues,
      nodeYValues: this.article.nodeYValues,
    };
  }
}

angular
  .module('main.article.variant.options', [])
  .config(config)
  .controller('OptionsController', OptionsController);
