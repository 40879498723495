import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';
import { CompositionGroupView } from './shared/group/CompositionGroupView';

interface Props extends SharedDisclosureProps {
  compositionGroupId: number;
}

export function CompositionListModal({
  compositionGroupId,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="auto" maxW="fit-content">
        <ModalHeader>{t('composition.archivedCompositions')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CompositionGroupView
            componentSettings={{
              compositionGroupDots: 'hidden',
              addMaterials: 'hidden',
              materialsMode: 'read-only',
              removeMaterials: 'hidden',
              editImage: 'hidden',
              characterEdit: 'hidden',
              dimensionMode: 'read-only',
            }}
            variantId={0}
            compositionGroupId={compositionGroupId}
            onlyArchived={true}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
