import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { useWizardStateStore } from './wizard/hooks/useWizardState';
import { useEffect } from 'react';
import { useCreateProductApprovalsSteps } from './wizard/steps/Steps';
import { WizardSummary } from './wizard/steps/summary/WizardSummary';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import {
  CreateProductApprovalData,
  productApprovalsApi,
} from '@texas/api/endpoints/productApprovalsApi';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useTranslation } from 'react-i18next';
import { ServerError } from '@texas/types';
import {
  ProductApprovalStateData,
  GeneratedProductApprovalState,
} from './types';
import { ProductApprovalWizardStateProvider } from './context';
import { WizardLayout } from './wizard/WizardLayout';
import { productApprovalEvents } from '../events';

export function ProductApprovalModal({
  isOpen,
  onClose,
  variantId,
  customerId,
  articleNodeGroups,
}: {
  isOpen: boolean;
  onClose: () => void;
  variantId: number;
  customerId: number;
  articleNodeGroups: ArticleNodeGroup;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: createRequest } = useApiRequest(productApprovalsApi.create);
  const wizardState = useWizardStateStore<ProductApprovalStateData>({
    stepConfigs: useCreateProductApprovalsSteps(articleNodeGroups),
    submitHandler: async (data) => {
      const validProductApprovals: CreateProductApprovalData[] =
        data.generatedProductApprovalKeys
          .filter(
            (k) =>
              data.generatedProductApprovals[k].state ===
              GeneratedProductApprovalState.Accepted,
          )
          .map((k) => {
            const pa = data.generatedProductApprovals[k];

            return {
              variantId: data.variantId,
              branchId: pa.branch.id,
              supplierContactId: pa.supplierContact!.id,
              matrixNodeXId: pa.cell?.x ?? null,
              matrixNodeYId: pa.cell?.y ?? null,
              compositionGroupId: pa.compositionGroup!.id,
              customerContactId: pa.customerContact!.id,
              supplierId: pa.supplier.id,
              supplierNote: pa.supplierNote,
              externalSupplierItemCode: pa.supplierExternalItemCode,
            };
          });

      await request(
        createRequest,
        [{ data: validProductApprovals }],
        () => {
          onClose();
          productApprovalEvents.productApprovalsCreated.dispatch();
          toast({
            title: t('general.created'),
            status: 'success',
            isClosable: true,
          });
        },
        (error: ServerError) => {
          toast({
            title: t('general.createFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    defaultData: {
      variantId,
      customerId,
      articleNodes: articleNodeGroups,
      selectedBranches: [],
      selectedSuppliers: [],
      selectedCells: {},
      generatedProductApprovals: {},
      generatedProductApprovalKeys: [],
    },
    defaultSummary: <WizardSummary />,
  });

  const { init } = wizardState;

  useEffect(() => init(), [init]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent overflowX="hidden">
        <ModalHeader>{t('productApproval.createNew')}</ModalHeader>
        <ModalCloseButton />
        <ProductApprovalWizardStateProvider value={wizardState}>
          <WizardLayout />
        </ProductApprovalWizardStateProvider>
      </ModalContent>
    </Modal>
  );
}
