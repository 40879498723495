import { SignalDispatcher } from 'ste-signals';

export const compositionGroupEvents = {
  onRename: new SignalDispatcher(),
  onCopy: new SignalDispatcher(),
  onCreate: new SignalDispatcher(),
  onArchived: new SignalDispatcher(),
  onRestored: new SignalDispatcher(),
  onLayoutChanged: new SignalDispatcher(),
  onMarkReady: new SignalDispatcher(),
  onMarkNotReady: new SignalDispatcher(),
  onProductApprovalCreated: new SignalDispatcher(),
  onProductApprovalRejected: new SignalDispatcher(),
  onColorsChanged: new SignalDispatcher(),
};
