// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('suppliers', {
    parent: 'main',
    url: '/suppliers',
    views: {
      'main-content': {
        controller: 'SupplierMenuController as vm',
        templateUrl: 'main/suppliers/suppliers.tpl.html',
      },
    },
    data: { pageTitle: 'Supplier' },
  });
};

class SupplierMenuController {
  constructor() {
    'ngInject';
  }
}

angular
  .module('main.suppliers', [])
  .config(config)
  .controller('SupplierMenuController', SupplierMenuController);
