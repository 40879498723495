import { PagedItems } from '@texas/types';
import { httpClient } from '../../httpClient/httpClient';
import {
  SearchArticlesResponse,
  VariantsSearchQueryRequest,
  SearchVariantsResponse,
  SearchVariantsCountResponse,
} from './searchVariantsTypes';
import {
  PaSearchQueryRequest,
  SearchPaCountResponse,
  SearchPaResponse,
} from './searchPaTypes';

export interface FilterItem {
  value: number;
  label: string;
  tags?: string[];
}

export interface SearchFilter {
  id: number;
  name: string;
}

export interface ExportArticleItem {
  id: number;
  variantId: number;
  customerId: number;
  branches: ExportArticleBranchItem[];
}

export interface ExportArticleBranchItem {
  id: number;
}

export interface SearchFilterQuery {
  searchTerm: string;
  page: number;
  pageSize: number;
  filteredOptions?: number[];
}

export interface SearchArticlesItem {
  id: number;
  name: string;
  itemCode: string;
  imageIdentifier: string;
}

export const searchApi = {
  searchArticles: (searchTerm: string) =>
    httpClient.get<SearchArticlesResponse>('search/articles', {
      params: { searchTerm },
    }),
  searchVariants: (params: VariantsSearchQueryRequest) =>
    httpClient.get<SearchVariantsResponse>(`search/variants`, {
      params,
    }),
  searchVariantsCount: (params: VariantsSearchQueryRequest) =>
    httpClient.get<SearchVariantsCountResponse>(`search/variants/count`, {
      params,
    }),

  searchProductAppovals: (params: PaSearchQueryRequest) =>
    httpClient.get<SearchPaResponse>(`search/product-approvals`, {
      params,
    }),
  searchProductAppovalsCount: (params: PaSearchQueryRequest) =>
    httpClient.get<SearchPaCountResponse>(`search/product-approvals/count`, {
      params,
    }),

  searchBranches: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/branches', { data: query }),
  getBranches: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/branches/ids', { params: { ids } }),

  searchCategoryCodes: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/categorycodes', {
      data: query,
    }),
  getCategoryCodes: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/categorycodes/ids', {
      params: { ids },
    }),

  searchCustomers: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/customers', {
      data: query,
    }),
  getCustomers: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/customers/ids', { params: { ids } }),

  searchConcepts: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/concepts', { data: query }),
  getConcepts: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/concepts/ids', { params: { ids } }),

  searchStates: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/states', { data: query }),
  getStates: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/states/ids', { params: { ids } }),

  searchPaStates: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/product-approval-states', {
      data: query,
    }),
  getPaStates: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/product-approval-states/ids', {
      params: { ids },
    }),

  searchSuppliers: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/suppliers', {
      data: query,
    }),
  getSuppliers: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/suppliers/ids', {
      params: { ids },
    }),

  searchMaterials: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/materials', {
      data: query,
    }),
  getMaterials: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/materials/ids', {
      params: { ids },
    }),

  searchProcesses: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/processes', {
      data: query,
    }),
  getProcesses: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/processes/ids', { params: { ids } }),

  searchProductGroups: (query: SearchFilterQuery) =>
    httpClient.post<PagedItems<FilterItem>>('search/productgroups', {
      data: query,
    }),
  getProductGroups: (ids: number[]) =>
    httpClient.get<FilterItem[]>('search/productgroups/ids', {
      params: { ids },
    }),
};
