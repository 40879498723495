import { httpClient } from '@texas/api/httpClient/httpClient';

export interface FreightCostItem {
  name: string;
  value: number;
  displayOrder: number;
}

export interface CreateFreightCostRequest {
  name: string;
  value: number;
  displayOrder: number;
}

export const freightCostsApi = {
  createFreightCost: (request: CreateFreightCostRequest) =>
    httpClient.post<FreightCostItem>(`organizations/3/freightcosts`, {
      data: request,
    }),
};
