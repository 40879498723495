import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GeneratedProductApproval } from '../../../types';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';

export function CopyPreviousValue({
  previousPa,
  value,
  onCopy,
}: {
  previousPa: GeneratedProductApproval | null | undefined;
  value: string | undefined;
  onCopy: (previousPa: GeneratedProductApproval) => void;
}) {
  const { t } = useTranslation();
  if (!previousPa || !value) return null;

  return (
    <Button
      justifyContent="start"
      onClick={() => onCopy(previousPa)}
      borderRadius="md"
      size="sm"
      leftIcon={<Icons.ArrowUpLeft boxSize={defaultIconSize} />}
    >
      {t('general.copyValue', { value: value })}
    </Button>
  );
}
