import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';
import { MutableRefObject, useMemo } from 'react';
import { ReactSelectOptionWithIcon } from '@texas/types';
import { Flex } from '@chakra-ui/react';
import { defaultPage } from '../../customer/widgetOptions/defaultWidgetsOptions';
import { TexasFormLabel } from '../form/TexasFormLabel';
import { TexasSelect } from '../form/TexasSelect';
import { IconOptionComponent } from './IconOptionComponent';
import { IconSingleValueComponent } from './IconSingleValueComponent';
import { VariantsSearchQuery } from '@texas/api/endpoints/search/searchVariantsTypes';

const boxSize = 4;

export function SelectSortOption({
  searchParamsRef,
  setSearchParamsWithRef,
}: {
  searchParamsRef: MutableRefObject<VariantsSearchQuery>;
  setSearchParamsWithRef: (params: VariantsSearchQuery) => void;
}) {
  const { t } = useTranslation();
  const sortOptions: ReactSelectOptionWithIcon[] = useMemo(
    () => [
      {
        label: t('general.itemCode'),
        value: { key: 'itemCode', desc: false },
        icon: <Icons.SortAscending boxSize={boxSize} />,
      },
      {
        label: t('general.itemCode'),
        value: { key: 'itemCode', desc: true },
        icon: <Icons.SortDescending boxSize={boxSize} />,
      },
      {
        label: t('variant.variant'),
        value: { key: 'variantName', desc: false },
        icon: <Icons.SortAscending boxSize={boxSize} />,
      },
      {
        label: t('variant.variant'),
        value: { key: 'variantName', desc: true },
        icon: <Icons.SortDescending boxSize={boxSize} />,
      },
      {
        label: t('general.productGroup'),
        value: { key: 'productGroup', desc: false },
        icon: <Icons.SortAscending boxSize={boxSize} />,
      },
      {
        label: t('general.productGroup'),
        value: { key: 'productGroup', desc: true },
        icon: <Icons.SortDescending boxSize={boxSize} />,
      },
      {
        label: t('article.created'),
        value: { key: 'created', desc: false },
        icon: <Icons.SortAscending boxSize={boxSize} />,
      },
      {
        label: t('article.created'),
        value: { key: 'created', desc: true },
        icon: <Icons.SortDescending boxSize={boxSize} />,
      },
      {
        label: t('article.sampleReadyDate'),
        value: { key: 'sampleReadyDate', desc: false },
        icon: <Icons.SortAscending boxSize={boxSize} />,
      },
      {
        label: t('article.sampleReadyDate'),
        value: { key: 'sampleReadyDate', desc: true },
        icon: <Icons.SortDescending boxSize={boxSize} />,
      },
    ],
    [t],
  );

  return (
    <Flex direction="column" width={48} mr={4}>
      <TexasFormLabel whiteSpace="nowrap">{t('view.sortBy')}</TexasFormLabel>
      <TexasSelect
        isMulti={false}
        options={sortOptions}
        value={getSelectedSortOption(
          sortOptions,
          searchParamsRef.current.sortBy,
          searchParamsRef.current.sortDesc,
        )}
        onChange={(e) => {
          setSearchParamsWithRef({
            ...searchParamsRef.current,
            sortBy: e?.value.key,
            sortDesc: e?.value.desc,
            page: defaultPage,
          });
        }}
        components={{
          Option: IconOptionComponent,
          SingleValue: IconSingleValueComponent,
        }}
      />
    </Flex>
  );
}

function getSelectedSortOption(
  sortOptions: ReactSelectOptionWithIcon[],
  sortBy: string,
  sortDesc: boolean,
): ReactSelectOptionWithIcon {
  const selected = sortOptions.find(
    (x) => x.value.key === sortBy && x.value.desc === sortDesc,
  );

  if (selected === undefined)
    return {
      label: 'itemCode',
      value: { key: 'itemCode', desc: false },
      icon: <Icons.SortAscending />,
    };
  return selected;
}
