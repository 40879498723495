import { compositionGroupMatrixApi } from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';
import { useEffect } from 'react';
import { compositionVersionEvents } from '../events';
import { productApprovalEvents } from '@texas/components/article/productApproval/events';

export function useMatrixVersions(variantId: number) {
  const { data, refetch, loading } = useApiResource(
    compositionGroupMatrixApi.getAllCellGroups,
  );

  useEffect(() => {
    refetch(variantId);
  }, [refetch, variantId]);

  useSignalSubscriptionEvents({
    triggerOnEvent: () => {
      refetch(variantId);
    },
    signalEvents: [
      compositionVersionEvents.onVersionsPatched,
      productApprovalEvents.productApprovalsCreated,
    ],
  });

  return { data, loading };
}
