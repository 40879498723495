import { Grid, GridItem, VStack, Text } from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { useRef, MutableRefObject, useState, useEffect, Fragment } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import { useResizeObserver } from '../hooks/useResizeObserver';

const cellPadding = '1em';
const maxHeight = '800px';

export function MatrixGrid({
  articleNodeGroup,
  cell,
  row,
  TopLeft,
}: {
  articleNodeGroup: ArticleNodeGroup;
  cell: (
    x: number,
    y: number,
    xIndex: number,
    yIndex: number,
  ) => React.ReactNode;
  row?: (y: number, yIndex: number) => React.ReactNode;
  TopLeft?: JSX.Element;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);
  const [cellRef, setCellRef] = useState<HTMLDivElement | null>(null);
  const [height, setHeight] = useState('auto');

  const [observer, entries] = useResizeObserver();

  useEffect(() => {
    if (entries.length === 0) return;
    const entry = entries[0];
    setHeight(`${entry.borderBoxSize[0].blockSize}px`);
  }, [entries]);

  useEffect(() => {
    if (!cellRef) return;
    observer.observe(cellRef);
  }, [cellRef, observer]);

  return (
    <Grid templateColumns="fit-content(200px) 1fr">
      <GridItem maxHeight={maxHeight} overflow="hidden" ref={rowRef}>
        <Grid templateColumns="repeat(1, 1fr)">
          <GridItem
            position="sticky"
            top={0}
            zIndex={2}
            backgroundColor="transparent"
            h="100px"
            padding={cellPadding}
          >
            {TopLeft}
          </GridItem>
          {articleNodeGroup.nodeYValues.map((y, yIndex) => {
            return (
              <GridItem
                minHeight={height}
                maxHeight={height}
                padding={cellPadding}
                key={yIndex}
                borderTop={yIndex === 0 ? '1px solid' : '0'}
                borderBottom="1px solid"
                borderLeft="1px solid"
                borderColor="gray.700"
                backgroundColor="texas.bg.blueTint.100"
                _light={{
                  backgroundColor: 'gray.50',
                  borderColor: 'gray.100',
                }}
              >
                <VStack alignItems="start">
                  <Text>{`${y.matrixNodeValue.identifier}: ${
                    y.name ?? y.matrixNodeValue.name
                  }`}</Text>
                  {row ? row(y.matrixNodeValue.id, yIndex) : null}
                </VStack>
              </GridItem>
            );
          })}
        </Grid>
      </GridItem>
      <GridItem
        overflow="auto"
        maxHeight={maxHeight}
        ref={ref}
        {...events}
        onScroll={(e) => {
          if (!rowRef.current) return;
          rowRef.current.scrollTop = e.currentTarget.scrollTop;
        }}
      >
        <Grid
          gridAutoColumns="1fr"
          gridAutoFlow="column"
          templateRows={`100px repeat(${articleNodeGroup.nodeYValues.length}, 1fr)`}
        >
          {articleNodeGroup.nodeXValues.map((x, xIndex) => {
            return (
              <Fragment key={xIndex}>
                <GridItem
                  padding={cellPadding}
                  fontWeight="bold"
                  pos="sticky"
                  top="0"
                  zIndex={2}
                  borderTop="1px solid"
                  borderLeft={xIndex === 0 ? '1px solid' : '0'}
                  borderRight="1px solid"
                  borderColor="gray.700"
                  backgroundColor="texas.bg.blueTint.100"
                  _light={{
                    backgroundColor: 'gray.50',
                    borderColor: 'gray.100',
                  }}
                >
                  <VStack>
                    <Text>
                      {`${x.matrixNodeValue.identifier}: ${
                        x.name ?? x.matrixNodeValue.name
                      }`}
                    </Text>
                  </VStack>
                </GridItem>
                {articleNodeGroup.nodeYValues.map((y, yIndex) => {
                  return (
                    <GridItem
                      {...(yIndex === 0 ? { ref: (r) => setCellRef(r) } : null)}
                      padding={cellPadding}
                      key={yIndex}
                      borderTop={yIndex === 0 ? '1px solid' : '0'}
                      borderRight="1px solid"
                      borderLeft={xIndex === 0 ? '1px solid' : '0'}
                      borderBottom="1px solid"
                      borderColor="gray.700"
                      _light={{
                        borderColor: 'gray.100',
                      }}
                      backgroundColor="transparent"
                      role="group"
                    >
                      {cell(
                        x.matrixNodeValue.id,
                        y.matrixNodeValue.id,
                        xIndex,
                        yIndex,
                      )}
                    </GridItem>
                  );
                })}
              </Fragment>
            );
          })}
        </Grid>
      </GridItem>
    </Grid>
  );
}
