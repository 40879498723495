import { Flex, Box, Text } from '@chakra-ui/react';
import { FileLink, FileTagType } from '@texas/api/endpoints/filesApi';
import { useTranslation } from 'react-i18next';
import { FileBrowserMode } from './FileBrowserModal';
import { RefObject } from 'react';
import { FileCard } from './FileCard';

export function FolderFiles({
  files,
  filteredFileIds,
  typeFilter,
  mode,
  onSelect,
  onEditFileOpen,
  onArchiveOpen,
  onRestoreOpen,
  containerRef,
}: {
  files?: FileLink[];
  filteredFileIds?: Record<number, number>;
  typeFilter: FileTagType[];
  mode: FileBrowserMode;
  onSelect?: (image: FileLink) => void;
  onEditFileOpen?: (image: FileLink) => void;
  onArchiveOpen?: (image: FileLink) => void;
  onRestoreOpen?: (image: FileLink) => void;
  containerRef: RefObject<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  return (
    <>
      {files?.length === 0 && (
        <Text variant="sub" p={4}>
          {t('fileBrowser.noFiles')}
        </Text>
      )}
      <Flex
        maxW="100%"
        overflowX="auto"
        overflowY="auto"
        py={5}
        __css={{ container: 'filesContainer / inline-size' }}
      >
        <Box
          gap={6}
          __css={{
            display: 'grid',
            '@container filesContainer (min-width: 0px)': {
              gridTemplateColumns: 'repeat(1, 1fr)',
            },
            '@container filesContainer (min-width: 680px)': {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
            '@container filesContainer (min-width: 980px)': {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
            '@container filesContainer (min-width: 1380px)': {
              gridTemplateColumns: 'repeat(4, 1fr)',
            },
            '@container filesContainer (min-width: 1800px)': {
              gridTemplateColumns: 'repeat(6, minmax(200px, auto))',
            },
          }}
        >
          {files?.map((file) => (
            <FileCard
              key={file.id}
              file={file}
              filteredFileIds={filteredFileIds}
              typeFilter={typeFilter}
              mode={mode}
              onSelect={onSelect}
              onEditFileOpen={onEditFileOpen}
              onArchiveOpen={onArchiveOpen}
              onRestoreOpen={onRestoreOpen}
              containerRef={containerRef}
            />
          ))}
        </Box>
      </Flex>
    </>
  );
}
