import {
  Flex,
  FormControl,
  LightMode,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  articlesApi,
  BranchProductDeveloper,
  ProductDevelopersResponse,
  UpdateProductDevelopersRequest,
} from '@texas/api/endpoints/articlesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import {
  groupBadgeStyles,
  groupStyles,
} from '@texas/components/widgets/shared/styles';
import { ReactSelectOption, ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';
import { GroupBase } from 'react-select';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { articleProductDevelopersEvents } from './events';

export function ArticleProductDevelopers({ articleId }: { articleId: number }) {
  const { t } = useTranslation();
  const toast = useToast();

  const { request: updateRequest, loading: updateLoading } = useApiRequest(
    articlesApi.updateProductDevelopers,
  );

  const {
    data: articleProductDevelopers,
    set,
    refetch,
    error,
    loading,
  } = useApiResource(articlesApi.getArticleProductDevelopers);

  const {
    data: productDevelopers,
    refetch: refetchRecommended,
    loading: loadingRecommended,
  } = useApiResource(articlesApi.getRecommendedProductDevelopers);

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<UpdateProductDevelopersRequest>();

  const { fields, update } = useFieldArray({
    control,
    name: 'productDevelopers',
    keyName: 'id',
  });

  useEffect(() => {
    refetch(articleId);
    refetchRecommended(articleId);
  }, [refetch, refetchRecommended, articleId]);

  useEffect(() => {
    reset({
      productDevelopers: articleProductDevelopers ?? [],
    });
  }, [articleProductDevelopers, reset]);

  const triggerRefetch = useCallback(() => {
    refetch(articleId);
  }, [refetch, articleId]);

  useEffect(() => {
    return articleProductDevelopersEvents.branchesModified.subscribe(() => {
      triggerRefetch();
    });
  }, [triggerRefetch]);

  const onSubmit = async (data: UpdateProductDevelopersRequest) =>
    await request(
      updateRequest,
      [articleId, data],
      (data: BranchProductDeveloper[]) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        set(data);
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  const formatGroupLabel = (data: GroupBase<ReactSelectOption>) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <LightMode>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap={4} mt={4}>
          <Text variant="header">
            {t('article.productDevelopers.productDevelopers')}
          </Text>
          {loading && <Spinner />}
          {error && <ErrorDetails error={error} />}
          <SimpleGrid columns={2} spacing={4}>
            {fields.map((item, index) => (
              <FormControl key={item.id}>
                <TexasFormLabel>{item.branchIdentifier}</TexasFormLabel>
                <Controller
                  name={`productDevelopers.${index}`}
                  control={control}
                  render={({ field }) => (
                    <TexasSelect
                      {...field}
                      isLoading={loadingRecommended}
                      isClearable={true}
                      formatGroupLabel={formatGroupLabel}
                      options={GetGroupedOptions(
                        productDevelopers,
                        item.branchId,
                      )}
                      value={getProductDeveloperOption(
                        productDevelopers,
                        item.userId,
                      )}
                      onChange={(x) => {
                        if (!x) {
                          update(index, {
                            userId: null,
                            branchId: item.branchId,
                            branchIdentifier: item.branchIdentifier,
                          });
                          return;
                        }

                        update(index, {
                          userId: x.value.userId,
                          branchId: item.branchId,
                          branchIdentifier: item.branchIdentifier,
                        });
                      }}
                    />
                  )}
                />
              </FormControl>
            ))}
          </SimpleGrid>
          {isDirty && (
            <Flex w={80}>
              <SubmitButton loading={updateLoading}>
                {t('article.productDevelopers.updateProductDevelopers')}
              </SubmitButton>
            </Flex>
          )}
        </Flex>
      </form>
    </LightMode>
  );
}

function getProductDeveloperOption(
  productDevelopers: ProductDevelopersResponse | null,
  userId: number | null,
): ReactSelectOption | undefined {
  if (productDevelopers === null || userId === null) return undefined;

  const userItem = productDevelopers.all.find((x) => x.id === userId);

  if (userItem === undefined) return undefined;

  return {
    label: `${userItem.name} (${userItem.email})`,
    value: {
      userId: userItem.id,
    },
  };
}

function GetGroupedOptions(
  productDevelopers: ProductDevelopersResponse | null,
  branchId: number,
): GroupBase<ReactSelectOption>[] {
  const { t } = useTranslation();
  const branchProductDevelopers = productDevelopers?.recommended.find(
    (x) => x.branchId === branchId,
  );

  return [
    {
      label: t('article.productDevelopers.recommendedUsers'),
      options:
        branchProductDevelopers?.recommended.map<ReactSelectOption>((u) => {
          return {
            label: `${u.name} (${u.email})`,
            value: {
              userId: u.id,
            },
          };
        }) ?? [],
    },
    {
      label: t('article.productDevelopers.allUsers'),
      options:
        productDevelopers?.all.map<ReactSelectOption>((u) => {
          return {
            label: `${u.name} (${u.email})`,
            value: {
              userId: u.id,
            },
          };
        }) ?? [],
    },
  ];
}
