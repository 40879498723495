import {
  useDisclosure,
  Flex,
  Button,
  Box,
  Text,
  Heading,
  Tooltip,
} from '@chakra-ui/react';
import {
  CompositionMaterial,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import useFormat from '@texas/hooks/useFormat';
import { useTranslation } from 'react-i18next';
import { VerifyDialogWithRequest } from '../../dialog/VerifyDialogWithRequest';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { GetColorWithType } from '../../colorPicker/ColorPicker';
import { RemoveComponent } from '../../RemoveComponent';
import { ColorDot } from '../../colorPicker/ColorDot';
import { ColorBox } from '../../colorPicker/ColorBox';

type State = 'hidden';
type ColorComponentType = 'dots' | 'detailed';

interface ComponentSettings {
  metaList?: State;
  colors?: State;
  colorComponent?: ColorComponentType;
}

export function CompositionMaterialView({
  material,
  onClick,
  onRemoved,
  settings,
}: {
  material: CompositionMaterial;
  onClick: () => void;
  onRemoved?: () => void;
  settings?: ComponentSettings;
}) {
  const { formatWeight } = useFormat();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <RemoveComponent onClick={onRemoved ? onOpen : undefined}>
        <Flex
          animation={fadeInScaleAnimation()}
          flexDirection="column"
          bg="texas.bg.800"
          border="1px solid"
          borderColor="texas.bg.800"
          _light={{ bg: 'gray.10' }}
          p={4}
          gap={2}
          role="group"
          borderRadius="md"
          maxW="500px"
        >
          <Tooltip label="Click to edit">
            <Button
              boxSize="full"
              onClick={() => onClick()}
              variant="unstyled"
              _hover={{ opacity: 0.7, transform: 'scale(1.02)' }}
              textAlign="start"
            >
              <Flex flexDir="column" gap={2}>
                <Box>
                  <Heading fontSize="lg" whiteSpace="normal">
                    {material.material.text}
                  </Heading>
                  <Text
                    overflowWrap="anywhere"
                    noOfLines={3}
                    fontSize="sm"
                    fontWeight="normal"
                    color="gray.300"
                    css={{ textWrap: 'wrap' }}
                  >
                    {material.note}
                  </Text>
                  {settings?.metaList !== 'hidden' && (
                    <Flex
                      gap={2}
                      flexWrap="wrap"
                      fontSize="sm"
                      fontWeight="normal"
                    >
                      <DottedArray
                        values={[
                          {
                            amount: material.qualities.length,
                            label: t('configuration.qualities'),
                          },
                          {
                            amount: material.treatments.length,
                            label: t('configuration.treatments'),
                          },
                          {
                            amount: material.techniques.length,
                            label: t('configuration.techniques'),
                          },
                        ]}
                      />
                      <Text>{formatWeight(material.weight)}</Text>
                    </Flex>
                  )}
                </Box>

                {settings?.colors !== 'hidden' && (
                  <Flex gap={2} flexWrap="wrap">
                    {material.colors.length === 0 && (
                      <Text variant="sub" fontWeight="normal" fontSize="sm">
                        {t('colors.noColors')}
                      </Text>
                    )}
                    {material.colors.map((x) => {
                      const color =
                        x.colorType === null ? GetColorWithType(x) : x;

                      if (settings?.colorComponent === 'detailed') {
                        return <ColorBox key={color.name} color={color} />;
                      }

                      return (
                        <ColorDot
                          key={color.name}
                          hex={color.hex}
                          name={color.name}
                          code={color.code}
                        />
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            </Button>
          </Tooltip>
        </Flex>
      </RemoveComponent>
      {onRemoved && (
        <VerifyDialogWithRequest
          headerTitle={t('materials.remove')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={compositionApi.removeCompositionMaterial}
          args={[material.id]}
          isOpen={isOpen}
          onClose={onClose}
          onPerformed={onRemoved}
          onSuccessTitle={t('general.successfullyRemoved')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
      )}
    </>
  );
}

function DottedArray({
  values,
}: {
  values: { amount: number; label: string }[];
}) {
  return (
    <>
      {values.map((x) => {
        if (x.amount === 0) return null;
        return (
          <Text key={x.label}>
            {x.amount} {x.label}
          </Text>
        );
      })}
    </>
  );
}
