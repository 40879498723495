import { Flex, Text, useDisclosure, useToast } from '@chakra-ui/react';
import {
  VariantFilesInfo,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { clientEndpoints } from '@texas/clientEndpoints';
import {
  acceptedImageFormats,
  imageExtensions,
} from '@texas/utils/helpers/filesHelper';
import { useTranslation } from 'react-i18next';
import { FileDropzone } from '../../../../shared/dropzone/FileDropzone';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { ServerError } from '@texas/types';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { request } from '@texas/utils/helpers/httpHelpers';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { FileBrowserModal } from '../../../../shared/files/FileBrowserModal';
import { ImageDropzone } from '../../../../shared/dropzone/ImageDropzone';
import { maxSize } from '@texas/components/shared/dropzone/shared';

export function Photo({
  variantId,
  variantFilesInfo,
  setVariantFilesInfo,
  allowEdit,
}: {
  variantId: number;
  variantFilesInfo: VariantFilesInfo;
  setVariantFilesInfo: Dispatch<SetStateAction<VariantFilesInfo | null>>;
  allowEdit: boolean;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { request: updateRequest } = useApiRequest(variantsApi.updatePhoto);

  const {
    isOpen: isRemoveFileOpen,
    onClose: onRemoveFileClose,
    onOpen: onRemoveFileOpen,
  } = useDisclosure();

  const {
    isOpen: isFileBrowserOpen,
    onClose: onFileBrowserClose,
    onOpen: onFileBrowserOpen,
  } = useDisclosure();

  const updateFile = useCallback(
    (file: FileLink) => {
      request(
        updateRequest,
        [variantId, file.id],
        (_) => {
          toast({
            title: t('fileBrowser.updatedFile'),
            status: 'success',
            isClosable: true,
          });
          setVariantFilesInfo({
            ...variantFilesInfo,
            photoId: file.id,
            photoIdentifier: file.identifier,
          });
        },
        (error: ServerError) => {
          toast({
            title: t('fileBrowser.updateFileFailed'),
            description: <ErrorsList errors={error.errors} />,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [setVariantFilesInfo, t, toast, updateRequest, variantFilesInfo, variantId],
  );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateFile, maxSize);

  return (
    <Flex direction="column" gap={2}>
      <Text variant="header">{t('variant.photo')}</Text>
      {variantFilesInfo.photoId && variantFilesInfo.photoIdentifier && (
        <ImageDropzone
          imageId={variantFilesInfo.photoId}
          imageIdentifier={variantFilesInfo.photoIdentifier}
          imageSrc={clientEndpoints.previewArticlePhoto(
            variantId,
            variantFilesInfo.photoId,
          )}
          imageArchived={variantFilesInfo.photoArchived}
          allowEdit={allowEdit}
          isUploading={isUploading}
          uploadFailed={uploadFailed}
          fileUploads={fileUploads}
          abortFileUpload={abortFileUpload}
          onUpload={(files) => uploadFiles(files, variantFilesInfo.folderId)}
          onRemovefileOpen={onRemoveFileOpen}
          onFileBrowserOpen={onFileBrowserOpen}
          webcamPhotoPrefix="garp-photo"
        />
      )}
      {allowEdit && !variantFilesInfo.photoId && (
        <FileDropzone
          accept={acceptedImageFormats}
          isUploading={isUploading}
          uploadFailed={uploadFailed}
          fileUploads={fileUploads}
          abortFileUpload={abortFileUpload}
          onUpload={(files) => uploadFiles(files, variantFilesInfo.folderId)}
          onFileBrowserOpen={onFileBrowserOpen}
          webcamPhotoPrefix="garp-photo"
        />
      )}
      <VerifyDialogWithRequest
        headerTitle={t('fileBrowser.removeFile')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={variantsApi.deletePhoto}
        args={[variantId]}
        isOpen={isRemoveFileOpen}
        onClose={onRemoveFileClose}
        onPerformed={() =>
          setVariantFilesInfo({
            ...variantFilesInfo,
            photoId: undefined,
            photoIdentifier: undefined,
          })
        }
        onSuccessTitle={t('fileBrowser.fileRemoved')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
      <FileBrowserModal
        rootFolderId={variantFilesInfo.folderId}
        mode="Select"
        isOpen={isFileBrowserOpen}
        onClose={onFileBrowserClose}
        extensionFilter={imageExtensions}
        onSelect={(file: FileLink) => {
          updateFile(file);
          onFileBrowserClose();
        }}
      />
    </Flex>
  );
}
