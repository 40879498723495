import { createColumnHelper } from '@tanstack/react-table';
import { SupplierListItem } from '@texas/api/endpoints/suppliersApi';
import { TFunction } from 'i18next';
import { AngularNavLink } from '../shared/AngularNavLink';
import { ArchivedTooltip } from '../shared/tooltip/ArchivedTooltip';
import { Flex } from '@chakra-ui/react';

export const suppliersColumns = (t: TFunction) => {
  const columnHelper = createColumnHelper<SupplierListItem>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <AngularNavLink
          to="supplier"
          navobj={{ supplierId: info.row.original.id }}
        >
          {info.getValue()}
        </AngularNavLink>
      ),
      header: t('general.name'),
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <Flex justifyContent="end" alignItems="center">
          {info.row.original.archived && (
            <ArchivedTooltip archived={info.row.original.archived} />
          )}
        </Flex>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
