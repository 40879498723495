import { Box, BoxProps, Tooltip } from '@chakra-ui/react';
import { stretchAnimation } from '@texas/resources/animations/animations';
import { Icons, defaultIconSize } from './Icons';
import { useEffect, useRef } from 'react';
import { reactEvents } from '@bridge/reactEvents';

interface Props extends BoxProps {
  children: React.ReactNode;
  warning?: string;
}

export function WarningComponent({ children, warning, ...props }: Props) {
  const refWarning = useRef(warning !== undefined);

  useEffect(() => {
    const hasWarning = warning === undefined;
    if (refWarning.current !== hasWarning) {
      refWarning.current = hasWarning;
      reactEvents.warningsChanged.dispatch();
    }
  }, [warning]);

  return (
    <Box pos="relative" w="fit-content" {...props}>
      {children}
      {warning && (
        <Box pos="absolute" right={-2} top={-3} data-warning="1">
          <Tooltip label={warning}>
            <Icons.AlertCircle
              animation={stretchAnimation()}
              color="yellow.200"
              bg="#000000a1"
              borderRadius="full"
              boxSize={defaultIconSize}
            />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
