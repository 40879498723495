import { emptyVariantsSearchQuery } from '@texas/api/endpoints/search/searchVariantsTypes';
import { FilterOptionType } from '../../filter/types/types';
import { ArticleSearchProps, FilterOptions } from '../../searchArticles/types';
import { ArticleState } from '@texas/api/endpoints/articlesApi';

export const defaultOnHoldWidgetsOptions = (
  customerId: number | undefined,
  conceptId: number | undefined,
) => {
  return widgetKickOffOptions(customerId, conceptId);
};

function widgetKickOffOptions(
  customerId: number | undefined,
  conceptId: number | undefined,
) {
  if (customerId) {
    return brandsKickOffOptions(customerId);
  } else if (conceptId) {
    return conceptKickOffOptions(conceptId);
  }

  throw Error('Customer or concept properties not declared');
}

const brandsKickOffOptions = (customerId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [FilterOptionType.Customers, FilterOptionType.States],
    searchParams: {
      ...emptyVariantsSearchQuery,
      customerIds: [customerId],
      states: [ArticleState.OnHold],
    },
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.States]: {
      disabled: true,
      static: false,
    },
  };

  return { defaultSearchPage, filterOptions };
};

const conceptKickOffOptions = (conceptId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [FilterOptionType.Concepts, FilterOptionType.States],
    searchParams: {
      ...emptyVariantsSearchQuery,
      conceptIds: [conceptId],
      states: [ArticleState.OnHold],
    },
    view: 'table',
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Concepts]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.States]: {
      disabled: true,
      static: false,
    },
  };

  return { defaultSearchPage, filterOptions };
};
