// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('supplier.contacts', {
    controller: 'SupplierContactsController as vm',
    url: '/contacts',
    templateUrl: 'main/supplier/contacts/contacts.tpl.html',
    data: { pageTitle: 'Contacts' },
  });
};

class SupplierContactsController {
  constructor(supplier) {
    'ngInject';

    this.supplier = supplier;
  }
}

angular
  .module('main.supplier.contacts', [])
  .config(config)
  .controller('SupplierContactsController', SupplierContactsController);
