import { Flex, Box, Heading, MenuItem, useDisclosure } from '@chakra-ui/react';
import { useMatrixOptions } from './useMatrixOptions';
import { OptionsList } from '../shared/OptionsList';
import { createContext, useState } from 'react';
import { OptionsListContainer } from '../shared/OptionsListContainer';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { PreviousCompositionGroupsModal } from '../../compositions/shared/group/PreviousCompositionGroupsModal';
import { useTranslation } from 'react-i18next';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { CompositionLocationData } from '../../compositions/shared/hooks/useCompositionGroup';
import { CompositionGroupContainer } from '../shared/CompositionGroupContainer';
import { SpinnerWithLabel } from '@texas/components/shared/SpinnerWithLabel';

export const MatrixOptionsContext = createContext<ReturnType<
  typeof useMatrixOptions
> | null>(null);

export function MatrixFoundation({ variantId }: { variantId: number }) {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const useOptions = useMatrixOptions({ variantId });
  const { t } = useTranslation();
  const {
    isOpen: isNewGroupOpen,
    onClose: onNewGroupClose,
    onOpen: onNewGroupOpen,
  } = useDisclosure();
  const {
    value: previousGroupsLocationValue,
    isOpen: isShowPreviousOpen,
    onClose: onShowPreviousClose,
    onOpen: onShowPreviousOpen,
  } = useValueDisclosure<CompositionLocationData>();
  const {
    options,
    loadingOptions,
    createControllerRequest,
    archiveController,
    copyGroup,
    createControllerLoading,
  } = useOptions;

  const option = options?.find((o) => o.id === selectedOption);

  return (
    <MatrixOptionsContext.Provider value={useOptions}>
      <Flex flexDir="column" gap={4}>
        <Flex gap={4} align="center">
          <Box>
            <Heading>{t('general.options')}</Heading>
          </Box>
        </Flex>
        {createControllerLoading && (
          <SpinnerWithLabel label={t('general.creating')} />
        )}
        <Flex gap={2} pt={6}>
          <OptionsListContainer
            menuItems={
              <>
                <MenuItem
                  icon={<Icons.Plus boxSize={defaultIconSize} />}
                  onClick={() => {
                    if (options?.length === 0 && !loadingOptions) {
                      createControllerRequest();
                      return;
                    }

                    onNewGroupOpen();
                  }}
                >
                  {t('compositionGroup.option.addNew')}
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    onShowPreviousOpen({
                      variantId: variantId,
                    })
                  }
                  icon={<Icons.Archive boxSize={defaultIconSize} />}
                >
                  {t('compositionGroup.option.archivedOptions')}
                </MenuItem>
              </>
            }
          >
            <OptionsList
              groups={options?.map((x) => ({
                id: x.id,
                fileId: x.fileId,
                version: x.version,
                compositions: x.compositions,
                note: x.note,
                isReady: x.isReady,
              }))}
              isCreating={createControllerLoading}
              groupsLoading={loadingOptions}
              onCreateClick={() => {
                if (createControllerLoading) return;
                createControllerRequest();
              }}
              selectedOption={selectedOption}
              onClick={setSelectedOption}
            />
          </OptionsListContainer>

          {option && (
            <CompositionGroupContainer
              key={option.id}
              option={option}
              onArchive={async () => await archiveController(option.id)}
              isMatrix={true}
              onCopy={async (fromId) => await copyGroup(fromId)}
            />
          )}
        </Flex>

        <VerifyDialogWithFunction
          headerTitle={t('compositionGroup.option.createNew')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          toPerform={() => createControllerRequest()}
          isOpen={isNewGroupOpen}
          onClose={onNewGroupClose}
        >
          {t('composition.newOptionConfirm')}
        </VerifyDialogWithFunction>
        {previousGroupsLocationValue && (
          <PreviousCompositionGroupsModal
            locationData={previousGroupsLocationValue}
            isOpen={isShowPreviousOpen}
            onClose={onShowPreviousClose}
          />
        )}
      </Flex>
    </MatrixOptionsContext.Provider>
  );
}
