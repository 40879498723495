import { httpClient } from '@texas/api/httpClient/httpClient';

export interface HandlingFeeItem {
  name: string;
  value: number;
  displayOrder: number;
}

export interface CreateHandlingFeeRequest {
  name: string;
  value: number;
  displayOrder: number;
}

export const handlingFeesApi = {
  createHandlingFee: (request: CreateHandlingFeeRequest) =>
    httpClient.post<HandlingFeeItem>(`organizations/3/handlingfees`, {
      data: request,
    }),
};
