import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Button,
  Flex,
  ModalBody,
  Img,
  ModalFooter,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '../types';
import Webcam from 'react-webcam';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TexasFormLabel } from '../form/TexasFormLabel';
import { defaultIconSize, Icons } from '../Icons';
import { ErrorLabel } from '../ErrorLabel';
import { useTranslation } from 'react-i18next';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'environment',
};

interface Props extends SharedDisclosureProps {
  onAccept: (blob: string) => void;
}

export function WebcamScreenshot({ onAccept, isOpen, onClose }: Props) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('webcam.capture')}</ModalHeader>
        <ModalCloseButton />
        <Inner onAccept={onAccept} />
      </ModalContent>
    </Modal>
  );
}

function Inner({ onAccept }: { onAccept: (blob: string) => void }) {
  const { t } = useTranslation();
  const [src, setSrc] = useState<string | null>('');
  const webcamRef = useRef<Webcam>(null);
  const capture = useCallback(() => {
    if (!webcamRef.current) return;
    setSrc(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const [deviceId, setDeviceId] = useState<ConstrainDOMString | undefined>(
    undefined,
  );
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [error, setError] = useState<string | DOMException | null>();

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices],
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    const firstDevice = devices.at(0);

    if (!firstDevice) return;
    setDeviceId(firstDevice.deviceId);
  }, [devices]);

  return (
    <>
      <ModalBody>
        <TexasFormLabel>{t('webcam.selectCamera')}</TexasFormLabel>
        <Select
          onChange={(e) => setDeviceId(e.target.value)}
          value={deviceId?.toString()}
        >
          {devices.map((d) => {
            return (
              <option key={d.deviceId} value={d.deviceId}>
                {d.label}
              </option>
            );
          })}
        </Select>
        <Flex flexDir="column" gap={2} pt={2}>
          {error && <ErrorLabel text={error.toString()} />}
          <Webcam
            onUserMediaError={(e) => setError(e)}
            audio={false}
            height={720}
            screenshotFormat="image/jpeg"
            width={1280}
            ref={webcamRef}
            videoConstraints={{ ...videoConstraints, deviceId: deviceId }}
          />

          <Tooltip
            label={deviceId === undefined ? t('webcam.noSelected') : null}
          >
            <Button
              isDisabled={deviceId === undefined}
              onClick={capture}
              leftIcon={<Icons.Camera boxSize={defaultIconSize} />}
            >
              {t('webcam.capture')}
            </Button>
          </Tooltip>
          {src && <Img src={src} />}
        </Flex>
      </ModalBody>
      <ModalFooter bg="texas.bg.800" _light={{ bg: 'gray.100' }}>
        <Button
          variant="texas-solid"
          isDisabled={!src}
          onClick={() => onAccept(src!)}
        >
          {t('webcam.use')}
        </Button>
      </ModalFooter>
    </>
  );
}
