import { Button, Flex, Heading, Text, Tooltip } from '@chakra-ui/react';
import { VariantBranchSupplier } from '@texas/api/endpoints/variantsApi';
import { useTranslation } from 'react-i18next';

export function SupplierCell({
  supplier,
  onDisable,
  onEnable,
  onChooseContacts,
}: {
  supplier: VariantBranchSupplier;
  onDisable: (supplierId: number, branchId: number) => void;
  onEnable: (supplierId: number, branchId: number) => void;
  onChooseContacts: (supplierId: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex direction="column" grow={1} py={2} px={4} align="start">
      <Heading
        fontSize="md"
        variant={supplier.disabled ? 'sub' : undefined}
        textDecoration={supplier.disabled ? 'line-through' : undefined}
      >
        {supplier.supplierName}
      </Heading>
      {supplier.contacts.length > 0 && (
        <Tooltip label={supplier.contacts.join(', ')}>
          <Text fontSize="sm" variant="sub">
            {t('variant.supplier.contactsConfigured', {
              count: supplier.contacts.length,
            })}
          </Text>
        </Tooltip>
      )}
      <Flex gap={2}>
        {supplier.disabled ? (
          <Button
            p={0}
            size="xs"
            variant="texas-link"
            color="whiteAlpha.600"
            _light={{ color: 'blackAlpha.800' }}
            textDecoration="underline"
            visibility="hidden"
            _groupHover={{ visibility: 'visible' }}
            onClick={() => onEnable(supplier.supplierId, supplier.branchId)}
          >
            {t('general.enable')}
          </Button>
        ) : (
          <>
            <Button
              p={0}
              size="xs"
              variant="texas-link-danger"
              visibility="hidden"
              _groupHover={{ visibility: 'visible' }}
              onClick={() => onDisable(supplier.supplierId, supplier.branchId)}
            >
              {t('general.disable')}
            </Button>
            <Button
              p={0}
              size="xs"
              variant="texas-link"
              visibility="hidden"
              _groupHover={{ visibility: 'visible' }}
              onClick={() => onChooseContacts(supplier.supplierId)}
            >
              {t('variant.supplier.changeContacts')}
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
}
