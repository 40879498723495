import { HStack, Switch } from '@chakra-ui/react';
import { SwitchFilterProps } from './types/types';
import { Text } from '@chakra-ui/react';
import { FilterContainerElement } from './FilterContainer';

export const SwitchFilterElement = (props: SwitchFilterProps) => {
  return (
    <FilterContainerElement
      gridColumn="span 1"
      onRemove={props.onRemove}
      static={props.static}
    >
      <HStack p={2} minH="46px">
        <Text opacity={`${!props.checked ? '1' : '0.6'}`}>
          {props.offLabel}
        </Text>
        <Switch
          defaultChecked={props.checked}
          onChange={(e) => props.onChange(e.target.checked)}
          name={props.name}
        />
        <Text opacity={`${props.checked ? '1' : '0.6'}`}>{props.onLabel}</Text>
      </HStack>
    </FilterContainerElement>
  );
};
