import { Tooltip, Flex, Switch } from '@chakra-ui/react';

export function TexasIconSwitch({
  checked,
  tooltip,
  icon,
  onChange,
}: {
  checked: boolean;
  tooltip: string;
  icon: React.ReactNode;
  onChange: (checked: boolean) => void;
}) {
  return (
    <Tooltip label={tooltip}>
      <Flex w="fit-content" gap={1} align="center">
        <Flex opacity={checked ? 1 : 0.7}>{icon}</Flex>
        <Switch onChange={(e) => onChange(e.target.checked)} />
      </Flex>
    </Tooltip>
  );
}
