import {
  FormControl,
  InputGroup,
  Input,
  InputRightAddon,
} from '@chakra-ui/react';
import {
  DimensionValue,
  AutoUpdateDimensionRequest,
  DimensionData,
  AutoUpdateDimensionAdditionalData,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { setValueAsDecimalExtender } from '@texas/components/article/variant/sales/shared';
import {
  useAutoUpdateForm,
  AutoUpdateWrapper,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { metricShortName } from './translations';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { Metric } from '@texas/api/endpoints/metadata/dimensionGroupsApi';

export function DefaultDimensionsForm({
  compositionId,
  dimension,
  onUpdate,
}: {
  compositionId: number;
  dimension: DimensionValue;
  onUpdate?: (dimensionId: number, value: number) => void;
}) {
  const {
    register,
    registerAutoUpdate,
    triggerSubmit,
    form,
    formState: { errors },
  } = useAutoUpdateForm<
    AutoUpdateDimensionRequest,
    DimensionData,
    AutoUpdateDimensionAdditionalData
  >({
    defaultValue: {
      value: dimension.value,
    },
    valueId: compositionId,
    namePrefix: 'dimension',
    additionalData: {
      dimensionId: dimension.id,
    },
    autoUpdateRequest: compositionApi.autoUpdateDimension,
    onResponse(data) {
      if (!onUpdate) return;
      onUpdate(data.unmodifiedServerData.id, data.mappedServerData.value);
    },
  });

  return (
    <form {...form}>
      <FormControl isInvalid={!!errors.value?.message}>
        <TexasFormLabel mb={0}>{dimension.label}</TexasFormLabel>
        <InputGroup>
          <AutoUpdateWrapper autoUpdateProps={registerAutoUpdate} path="value">
            <Input
              {...register('value', {
                onBlur: triggerSubmit,
                setValueAs: (value) => setValueAsDecimalExtender(value, true),
              })}
              variant="texas"
            />
          </AutoUpdateWrapper>
          <InputRightAddon h="auto" border="none">
            {metricShortName(ensureEnumNumber(Metric, dimension.metric))}
          </InputRightAddon>
        </InputGroup>
        <ErrorLabel text={errors.value?.message} />
      </FormControl>
    </form>
  );
}
