import {
  Material,
  materialsApi,
} from '@texas/api/endpoints/metadata/materialsApi';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';

interface Props extends SharedDisclosureProps {
  onArchive: () => void;
  material: Material;
}

export function ArchiveMaterial({
  isOpen,
  material,
  onArchive,
  onClose,
}: Props) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('configuration.archive', {
        name: material.name,
      })}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={materialsApi.archive}
      args={[material.id]}
      isOpen={isOpen}
      onClose={onClose}
      onPerformed={onArchive}
      onSuccessTitle={t('general.archived')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
