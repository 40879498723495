import { Heading, HeadingProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type GroupType = 'version' | 'option';

interface Props extends HeadingProps {
  version: number;
  note: string | null;
  type: GroupType;
}

export function CompositionGroupTitle({
  type,
  version,
  note,
  ...props
}: Props) {
  const { t } = useTranslation();

  if (type === 'option') {
    return (
      <>
        {note ? (
          <Heading {...props}>
            {note} ({t('compositionGroup.option.optionNr', { nr: version })})
          </Heading>
        ) : (
          <Heading {...props}>
            {t('compositionGroup.option.optionNr', { nr: version })}
          </Heading>
        )}
      </>
    );
  }

  return (
    <>
      {note ? (
        <Heading {...props}>
          {note} ({t('compositionGroup.version.versionNr', { nr: version })})
        </Heading>
      ) : (
        <Heading {...props}>
          {t('compositionGroup.version.versionNr', { nr: version })}
        </Heading>
      )}
    </>
  );
}
