import {
  FormControl,
  InputGroup,
  Input,
  InputRightAddon,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DimensionValue,
  DimensionData,
} from '@texas/api/endpoints/compositionApi';
import {
  AutoUpdateMatrixSizes,
  AutoUpdateMatrixSizesAdditionalData,
  compositionGroupMatrixApi,
} from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { Metric } from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { setValueAsDecimalExtender } from '@texas/components/article/variant/sales/shared';
import {
  useAutoUpdateForm,
  AutoUpdateWrapper,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { metricShortName } from '@texas/components/shared/composition/dimension/translations';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { number, object } from 'yup';

const schema = object({
  value: number().moreThan(-1).typeError('Not a valid number'),
}).required();

export function MatrixDimensionsForm({
  matrixNodeId,
  dimension,
  compositionIndex,
  controlledByGroupId,
}: {
  compositionIndex: number;
  matrixNodeId: number;
  dimension: DimensionValue;
  controlledByGroupId: number;
}) {
  const {
    register,
    registerAutoUpdate,
    triggerSubmit,
    form,
    watch,
    formState: { errors },
  } = useAutoUpdateForm<
    AutoUpdateMatrixSizes,
    DimensionData,
    AutoUpdateMatrixSizesAdditionalData
  >({
    defaultValue: {
      value: dimension.value,
    },
    valueId: controlledByGroupId,
    namePrefix: 'dimension',
    additionalData: {
      dimensionId: dimension.id,
      compositionIndex: compositionIndex,
      matrixNodeId,
    },
    onResponse() {
      compositionEvents.onDimensionValueChanged.dispatch({
        groupId: controlledByGroupId,
      });
    },
    resolver: yupResolver(schema),
    autoUpdateRequest: compositionGroupMatrixApi.autoUpdateSizes,
  });
  const watchedValue = watch('value');

  return (
    <form {...form}>
      <FormControl
        isInvalid={!!errors.value?.message}
        data-value={watchedValue}
      >
        <TexasFormLabel mb={0}>{dimension.label}</TexasFormLabel>
        <InputGroup>
          <AutoUpdateWrapper autoUpdateProps={registerAutoUpdate} path="value">
            <Input
              {...register('value', {
                onBlur: triggerSubmit,
                setValueAs: (value) => setValueAsDecimalExtender(value, true),
              })}
              variant="texas"
            />
          </AutoUpdateWrapper>
          <InputRightAddon h="auto" border="none">
            {metricShortName(ensureEnumNumber(Metric, dimension.metric))}
          </InputRightAddon>
        </InputGroup>
        <ErrorLabel text={errors.value?.message} />
      </FormControl>
    </form>
  );
}
