import { Flex, Heading, Text } from '@chakra-ui/react';
import { Role } from '@texas/api/endpoints/userApi';
import { RoleIcon } from '@texas/components/shared/RoleIcon';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import {
  roleFriendlyName,
  roleDescription,
} from '@texas/utils/helpers/roleHelpers';
import { useTranslation } from 'react-i18next';

export function RoleRadioCard({
  role,
  delayMultiplier,
}: {
  role: Role;
  delayMultiplier: number;
}) {
  const { t } = useTranslation();
  return (
    <Flex
      animation={fadeInScaleAnimation(200, 100 * delayMultiplier)}
      pos="relative"
      flexDir="column"
      borderRadius="md"
      border="2px solid transparent"
      gap={2}
      alignItems="center"
      textAlign="center"
      bg="texas.bg.800"
      p={2}
      width="160px"
      _groupChecked={{
        borderColor: 'texas.sand.100',
      }}
      _hover={{ transform: 'scale(1.03)' }}
      transition="transform 200ms ease, border-color 200ms ease"
      opacity={0}
      sx={{ animationFillMode: 'forwards' }}
      onAnimationEnd={(e) => {
        e.currentTarget.style.animationFillMode = 'none';
        e.currentTarget.style.opacity = '1';
      }}
      _light={{
        bg: 'gray.50',
        _groupChecked: {
          borderColor: 'texas.burntSienna.100',
        },
      }}
    >
      <Flex
        _groupChecked={{ transform: 'scale(1.2)' }}
        align="center"
        justify="center"
        borderRadius="full"
        bg="texas.bg.800"
        pos="absolute"
        top={-4}
        transition="transform 200ms ease"
        _light={{ bg: 'gray.50' }}
      >
        <RoleIcon boxSize={6} userRole={role} />
      </Flex>

      <Heading pt={2} fontSize="md">
        {roleFriendlyName(role, t)}
      </Heading>
      <Text fontSize="xs">{roleDescription(role, t)}</Text>
    </Flex>
  );
}
