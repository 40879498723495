import {
  Flex,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function OptionsListContainer({
  menuItems,
  children,
}: {
  menuItems: React.ReactNode;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <Flex
      flexDir="column"
      gap={4}
      bg="linear-gradient(to top, #28282800, #38383863)"
      _light={{
        bg: 'linear-gradient(to top,rgba(230, 230, 230, 0),rgba(176, 176, 176, 0.39))',
      }}
      borderRadius="md"
      padding={4}
    >
      <Flex align="center" justify="space-between" p={2}>
        <Text>{t('variant.overview.options.title')}</Text>
        <Menu>
          <MenuButton
            as={IconButton}
            size="sm"
            variant="ghost"
            icon={<Icons.DotsHorizontal boxSize={defaultIconSize} />}
          />
          <MenuList>{menuItems}</MenuList>
        </Menu>
      </Flex>
      {children}
    </Flex>
  );
}
