import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Text,
  useToast,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '../shared/types';
import { Trans, useTranslation } from 'react-i18next';
import {
  Role,
  UpdateUserRoleRequest,
  userApi,
} from '@texas/api/endpoints/userApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { internalRole } from '@texas/utils/helpers/roleHelpers';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { useForm, Controller } from 'react-hook-form';
import { ErrorDetails } from '../shared/alert/ErrorDetails';
import { ErrorLabel } from '../shared/ErrorLabel';
import { SubmitButton } from '../shared/form/SubmitButton';
import { Icons, defaultIconSize } from '../shared/Icons';
import { userEvents } from './events';
import { RoleSelector } from './role/RoleSelector';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { ServerError } from '@texas/types';

interface Props extends SharedDisclosureProps {
  id: number;
}

export function SelectRole({ id, isOpen, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('user.changeRole')}</ModalHeader>
          <ModalCloseButton />
          <InnerModal id={id} onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
}

function InnerModal({ onClose, id }: { onClose: () => void; id: number }) {
  const toast = useToast();
  const { t } = useTranslation();

  const { request: updateRequest, error } = useApiRequest(
    userApi.updateUserRole,
  );

  const { data, refetch } = useApiResource(userApi.getUser);

  useEffect(() => {
    refetch(id);
  }, [id, refetch]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isValid },
    reset,
    watch,
  } = useForm<UpdateUserRoleRequest>();

  useEffect(() => {
    if (!data) return;
    reset({
      role: data.role,
    });
  }, [data, reset]);

  const role = watch('role');

  const onSubmit = async (data: UpdateUserRoleRequest) =>
    await request(
      updateRequest,
      [id, data],
      (_) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        reset();
        onClose();
        userEvents.userUpdated.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Flex gap={4} flexDir="column" mb={4}>
            <Text>
              <Trans
                i18nKey="user.pleaseSelectRoleFor"
                values={{ email: data?.email }}
              />
            </Text>
            <FormControl pt={4}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="role"
                render={({ field }) => <RoleSelector {...field} />}
              />
              <ErrorLabel text={errors.role?.message} />
            </FormControl>
            {internalRole(convertToEnum(Role, role)) && (
              <Flex gap={2}>
                <Icons.AlertCircle boxSize={defaultIconSize} />
                <Text fontWeight="bold">{t('user.setInternalRoleAlert')}</Text>
              </Flex>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter
          bg="texas.bg.950"
          borderBottomRadius="md"
          as={Flex}
          gap={6}
          alignItems="end"
          _light={{ bg: 'gray.50' }}
        >
          <FormControl
            opacity={internalRole(convertToEnum(Role, role)) ? 1 : 0}
            transition="opacity 100ms linear"
          >
            <FormLabel>
              <Flex gap={2}>
                <Icons.ShieldKey boxSize={defaultIconSize} />
                <Text>{t('user.passwordRequiredForRole')}</Text>
              </Flex>
            </FormLabel>
            <Input
              type="password"
              placeholder="Your password"
              {...register('providedPassword')}
              variant="texas"
            />
          </FormControl>
          <SubmitButton disabled={!isValid} loading={isSubmitting}>
            {t('user.updateUserRole')}
          </SubmitButton>
        </ModalFooter>
      </form>
    </>
  );
}
