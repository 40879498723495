// This is is somehow causing prettier to cast a format error here (SyntaxError: Expression expected)
// inside the function call. But the function does not accept any arguments...
// prettier-ignore does not work, so we have to ignore the whole file.

import { SearchPaCountResponse } from "@texas/api/endpoints/search/searchPaTypes";
import { useSearchProductApprovals } from "@texas/components/shared/search/useSearchProductApprovals";
import { createContext } from "react";



export type SearchProductApprovalsCount = ReturnType<typeof useSearchProductApprovals<SearchPaCountResponse>>;
export const SearchProductApprovalsCountContext = createContext<SearchProductApprovalsCount | null>(null);
