import { RowSelectionState } from '@tanstack/react-table';
import { ExportArticleItem } from '@texas/api/endpoints/search/searchApi';
import { useEffect, useState } from 'react';

export function useExportableArticles(
  selectedRows: RowSelectionState,
  exportArticleItems: ExportArticleItem[],
) {
  const [exportableArticles, setExportableArticles] = useState<
    ExportArticleItem[]
  >([]);

  useEffect(() => {
    const selectedArticleIds = Object.keys(selectedRows).map((x) =>
      parseInt(x),
    );
    const exportableArticleIds = exportableArticles.map((x) => x.id);

    const changedRows = selectedArticleIds
      .filter((x) => !exportableArticleIds.includes(x))
      .concat(
        exportableArticleIds.filter((x) => !selectedArticleIds.includes(x)),
      );

    for (let i = 0; i < changedRows.length; i++) {
      const changedRow = changedRows[i];

      if (selectedRows[changedRow]) {
        const article = exportArticleItems.find((x) => x.id === changedRow);

        if (!article) return;

        setExportableArticles((s) => [...s, article]);
      } else {
        setExportableArticles((s) => s.filter((x) => x.id !== changedRow));
      }
    }
  }, [exportArticleItems, exportableArticles, selectedRows]);

  return exportableArticles;
}
