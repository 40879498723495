import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';
import {
  DimensionGroupListItem,
  dimensionGroupsApi,
} from '../../../api/endpoints/metadata/dimensionGroupsApi';

interface ArchiveDimensionGroupProps extends SharedDisclosureProps {
  onArchive: () => void;
  dimensionGroup: DimensionGroupListItem;
}

export function ArchiveDimensionGroup({
  isOpen,
  dimensionGroup,
  onArchive,
  onClose,
}: ArchiveDimensionGroupProps) {
  const { t } = useTranslation();
  return (
    <VerifyDialogWithRequest
      headerTitle={t('configuration.archive', {
        name: dimensionGroup.name,
      })}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      primaryRequest={dimensionGroupsApi.archive}
      args={[dimensionGroup.id]}
      isOpen={isOpen}
      onClose={onClose}
      onPerformed={onArchive}
      onSuccessTitle={t('general.successfullyArchived')}
    >
      {t('alert.areYouSure')}
    </VerifyDialogWithRequest>
  );
}
