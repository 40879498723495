import { useActiveSession } from '@texas/hooks/useSession';
import { BetaFeature, hasAccessToFeature } from './features';

export function FeatureGuard({
  feature,
  children,
}: {
  feature: BetaFeature | undefined;
  children: React.ReactNode;
}) {
  const session = useActiveSession();

  if (!feature) return <>{children}</>;

  if (!hasAccessToFeature(session.currentUser.id, feature)) {
    return null;
  }

  return <>{children}</>;
}
