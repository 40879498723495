import { Flex, Button, useDisclosure } from '@chakra-ui/react';
import { FileUpload } from '@texas/types';
import { Accept, FileError } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FileUploads } from './FileUploads';
import { DropZone } from './DropZone';
import { Icons } from '../Icons';
import { imageMimeTypes } from '@texas/utils/helpers/filesHelper';
import { WebcamScreenshot } from './WebcamScreenshot';
import { base64toFile } from './shared';
import { currentDateOnlyDateToString } from '@texas/utils/helpers/dateHelper';

export function FileDropzone({
  accept,
  multiple = false,
  onUpload,
  isUploading,
  uploadFailed,
  fileUploads,
  abortFileUpload,
  onFileBrowserOpen,
  validator,
  showWebcamBtn = true,
  webcamPhotoPrefix = '',
}: {
  accept?: Accept;
  multiple?: boolean;
  onUpload: (files: File[]) => void;
  isUploading: boolean;
  uploadFailed: boolean;
  fileUploads: FileUpload[];
  abortFileUpload: (file: FileUpload) => void;
  onFileBrowserOpen?: () => void;
  validator?: <T extends File>(
    file: T,
  ) => FileError | readonly FileError[] | null;
  showWebcamBtn?: boolean;
  webcamPhotoPrefix?: string;
}) {
  const { t } = useTranslation();
  const {
    isOpen: isWebcamOpen,
    onClose: onWebcamClose,
    onOpen: onWebcamOpen,
  } = useDisclosure();

  return (
    <Flex direction="column" gap={2} flexGrow={1} alignItems="center">
      {(isUploading || uploadFailed) && (
        <FileUploads
          fileUploads={fileUploads}
          abortFileUpload={abortFileUpload}
        />
      )}
      <DropZone
        accept={accept}
        multiple={multiple}
        onUpload={onUpload}
        validator={validator}
      />
      <Flex>
        {onFileBrowserOpen && (
          <Button
            leftIcon={<Icons.FolderOutline />}
            variant="texas-link-light"
            textDecoration="underline"
            onClick={onFileBrowserOpen}
            isLoading={isUploading}
          >
            {t('fileBrowser.selectFromLibrary')}
          </Button>
        )}
        {showWebcamBtn && accept?.[imageMimeTypes.jpeg] !== undefined && (
          <Button
            variant="texas-link-light"
            textDecoration="underline"
            onClick={onWebcamOpen}
            isLoading={isUploading}
            leftIcon={<Icons.Camera />}
          >
            {t('webcam.capture')}
          </Button>
        )}
      </Flex>
      {showWebcamBtn && (
        <WebcamScreenshot
          isOpen={isWebcamOpen}
          onClose={onWebcamClose}
          onAccept={async (blob) => {
            const date = currentDateOnlyDateToString();
            const prefix =
              webcamPhotoPrefix.length > 0 ? `${webcamPhotoPrefix}-` : '';
            const file = await base64toFile(
              blob,
              `${prefix}${date}-capture.jpeg`,
            );

            onUpload([file]);
            onWebcamClose();
          }}
        />
      )}
    </Flex>
  );
}
