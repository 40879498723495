import {
  useToast,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';
import { ColorData, ColorType } from '@texas/api/endpoints/compositionApi';
import { SelectColor } from '@texas/components/widgets/shared/components/SelectColor';
import { useEffect } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { ColorBox } from './ColorBox';
import { Color } from './ColorPicker';
import { useTranslation } from 'react-i18next';

export function CustomColorPicker({
  onAdd,
  pickedColors,
  preset,
}: {
  onAdd: (color: ColorData) => void;
  pickedColors: ColorData[];
  preset?: Color;
}) {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, control } = useForm<ColorData>({
    defaultValues: { name: '', code: '', description: '', hex: '' },
  });

  useEffect(() => {
    if (!preset) return;
    reset({ name: preset.name, hex: preset.hex, code: preset.code });
  }, [preset, reset]);

  const formWatch = useWatch({ control });
  const toast = useToast();

  const submit = (data: ColorData) => {
    if (
      pickedColors.some(
        (x) =>
          x.colorType === ColorType.Custom &&
          x.code === data.code &&
          x.name === data.name,
      )
    ) {
      toast({
        title: t('colors.alreadyAdded'),
        description: t('colors.alreadyAddedDesc'),
        status: 'error',
        isClosable: true,
      });
      return;
    }

    onAdd({
      description: data.description,
      name: data.name,
      code: data.code,
      colorType: ColorType.Custom,
      hex: data.hex,
    });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex flexDir="column" gap={2}>
        <Flex
          flexDir="column"
          gap={2}
          padding={4}
          bg="texas.bg.900"
          borderRadius="md"
          _light={{
            bg: 'gray.100',
          }}
        >
          <Text>{t('colors.customColorDesc')}</Text>

          <FormControl>
            <FormLabel>{t('colors.name')}</FormLabel>
            <Input
              {...register('name', { required: true, maxLength: 30 })}
              variant="texas"
              maxLength={30}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t('colors.code')}</FormLabel>
            <Input
              {...register('code', { required: true, maxLength: 20 })}
              variant="texas"
              maxLength={20}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t('colors.description')}</FormLabel>
            <Input
              {...register('description', { maxLength: 30 })}
              variant="texas"
              maxLength={30}
            />
          </FormControl>
          <Controller
            control={control}
            name="hex"
            render={({ field }) => {
              return (
                <FormControl>
                  <FormLabel>{t('colors.representation')}</FormLabel>
                  <SelectColor
                    onChange={(v) => field.onChange(v?.substring(1, v.length))}
                    selectedColor={`#${field.value}`}
                  />
                </FormControl>
              );
            }}
          />
        </Flex>
        {(formWatch.name?.length ?? 0) > 0 && (
          <>
            <Text fontWeight="bold">{t('colors.preview')}</Text>
            <ColorBox
              colorDescription={formWatch.description}
              color={{
                code: formWatch.code ?? '',
                hex: formWatch.hex ?? '',
                name: formWatch.name ?? '',
              }}
            />
            <Button variant="texas-solid" type="submit" w="fit-content">
              {t('colors.addCustom')}
            </Button>
          </>
        )}
      </Flex>
    </form>
  );
}
