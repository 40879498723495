angular.module('app.core', [
  'decorators.texasDialog',
  'resources',
  'security',
  'constant',
  'orderEmpty',
  'fromNowFilter',
  'services.errorLogger',
  'services.dialogs',
  'services.uploader',
  'services.priceMatrix',
  'services.matrixNode',
  'services.toaster',
  'services.helper',
  'services.loadSpinner',
  'services.saving',
  'services.poller',
  'core.init',
  'core.config',
  'directives',
  'tabs',
]);
