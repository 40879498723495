import { httpClient } from '../httpClient/httpClient';
import { UserListItem } from './userApi';
import { MatrixAxis } from './compositionGroup/compositionGroupMatrixApi';

export enum ArticleState {
  Registered = 0,
  Accepted = 1,
  Rejected = 2,
  OnHold = 3,
  Cancelled = 4,
}

export interface MatrixNodeValue {
  id: number;
  matrixNodeId: number;
  identifier: string;
  name: string | null;
  displayOrder: number;
  archived: boolean;
}

export interface ArticleNodeValue {
  id: number;
  name: string | null;
  matrixNodeValue: MatrixNodeValue;
}

export interface ArticleNodeGroup {
  nodeXValues: ArticleNodeValue[];
  nodeYValues: ArticleNodeValue[];
}

export interface MoveArticle {
  articleId: number;
  itemCode: string;
  brand: string;
  project: string;
  concept: string;
}

export interface UpdateArticle {
  variantId: number;
  name: string;
  itemCode: string;
  branchId: number;
  branchIdentifier: string;
  state: ArticleState;
  categoryCode: string;
}

interface MoveRequest {
  articleIds: number[];
  toCustomerId: number;
  toConceptId: number | null;
}

interface UpdateStateRequest extends UpdateVariants {
  state: ArticleState;
}

interface UpdateCategoryCodeRequest extends UpdateVariants {
  categoryCode: string;
}

interface UpdateVariants {
  variants: UpdateVariant[];
}

export interface UpdateVariant {
  variantId: number;
  branchId: number;
}

export interface IdNameDto {
  id: number;
  name: string;
}

export interface ArticleAxisInfo {
  articleId: number;
  variantId: number;
  colorAxis: MatrixAxis | null;
  nodeXValues: ArticleNodeValue[];
  nodeYValues: ArticleNodeValue[];
}

export interface ProductDevelopersResponse {
  all: UserListItem[];
  recommended: RecommendedBranchProductDevelopers[];
}

export interface UpdateProductDevelopersRequest {
  productDevelopers: BranchProductDeveloper[];
}

export interface RecommendedBranchProductDevelopers {
  branchId: number | null;
  branchIdentifier: string | null;
  recommended: UserListItem[];
}

export interface BranchProductDeveloper {
  userId: number | null;
  branchId: number;
  branchIdentifier: string;
}

export const articlesApi = {
  moveArticles: (request: MoveRequest) =>
    httpClient.post<MoveArticle[]>('articles/move', { data: request }),
  updateState: (request: UpdateStateRequest) =>
    httpClient.put<UpdateArticle[]>('articles/state', { data: request }),
  updateCategoryCode: (request: UpdateCategoryCodeRequest) =>
    httpClient.put<UpdateArticle[]>('articles/category-code', {
      data: request,
    }),
  getUpdateVariants: (request: UpdateVariants) =>
    httpClient.post<UpdateArticle[]>('articles/update', {
      data: request,
    }),
  getMoveArticles: (articleIds: number[]) =>
    httpClient.get<MoveArticle[]>(`articles/move`, {
      params: { articleIds: articleIds },
    }),
  getArticleBranches: (articleId: number) =>
    httpClient.get<IdNameDto[]>(`articles/${articleId}/branches`),
  getArticleSuppliers: (articleId: number) =>
    httpClient.get<IdNameDto[]>(`articles/${articleId}/suppliers`),
  getAxisInfo: (id: number) =>
    httpClient.get<ArticleAxisInfo>(`articles/${id}/matrix/axis`),
  setColorAxis: (id: number, axis: MatrixAxis) =>
    httpClient.put<ArticleAxisInfo>(`articles/${id}/matrix/axis/${axis}`),
  getArticleProductDevelopers: (articleId: number) =>
    httpClient.get<BranchProductDeveloper[]>(
      `articles/${articleId}/product-developers`,
    ),
  getRecommendedProductDevelopers: (articleId: number) =>
    httpClient.get<ProductDevelopersResponse>(
      `articles/${articleId}/recommended/product-developers`,
    ),
  updateProductDevelopers: (
    articleId: number,
    request: UpdateProductDevelopersRequest,
  ) =>
    httpClient.put<BranchProductDeveloper[]>(
      `articles/${articleId}/product-developers`,
      {
        data: request,
      },
    ),
};
