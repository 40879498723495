import { Box, VStack, Text, Grid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FilterOptionType, FilterTypeEnum } from '../filter/types/types';
import { TexasDrawerFooter } from '../shared/drawer/TexasDrawerFooter';
import { TexasDrawerSubHeader } from '../shared/drawer/TexasDrawerSubHeader';
import { VariantsFilterType } from '../filter/types/variantTypes';

interface AddFilterProps {
  availableFilters: VariantsFilterType[];
  onAdd: (type: FilterOptionType) => void;
}

export function AddFilter(props: AddFilterProps) {
  const { t } = useTranslation();
  return (
    <Box>
      <TexasDrawerSubHeader
        title={`${t('filter.dropdown').toString()} ${t(
          'general.filters',
        ).toLowerCase()}`}
        subTitle={t('filter.dropdownDescription')}
      />
      <Box padding={4}>
        <FilterGridComponent
          filters={props.availableFilters.filter(
            (f) => f.type === FilterTypeEnum.Select,
          )}
          onAdd={props.onAdd}
        />
      </Box>

      <TexasDrawerSubHeader
        title={`${t('filter.toggle').toString()} ${t(
          'general.filters',
        ).toLowerCase()}`}
        subTitle={t('filter.toggleDescription')}
      />
      <Box padding={4}>
        <FilterGridComponent
          filters={props.availableFilters.filter(
            (f) => f.type === FilterTypeEnum.Checkbox,
          )}
          onAdd={props.onAdd}
        />
      </Box>
      <TexasDrawerFooter>
        <Text fontSize={14} color="gray.400" fontStyle="italic">
          {t('drawer.closeDescription')}
        </Text>
      </TexasDrawerFooter>
    </Box>
  );
}

function FilterGridComponent({
  filters,
  onAdd,
}: {
  filters: VariantsFilterType[];
  onAdd: (type: FilterOptionType) => void;
}) {
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      columnGap={2}
      rowGap={4}
      paddingBottom={2}
    >
      {filters.map((f) => {
        return <FilterComponent key={f.optionType} filter={f} onAdd={onAdd} />;
      })}
    </Grid>
  );
}

function FilterComponent({
  filter,
  onAdd,
}: {
  filter: VariantsFilterType;
  onAdd: (type: FilterOptionType) => void;
}) {
  const { t } = useTranslation();
  return (
    <Box
      border="1px solid"
      outline="2px solid transparent"
      borderRadius="40px"
      cursor="pointer"
      transition="outline 200ms ease, background-color 200ms ease"
      backgroundColor="texas.bg.950"
      borderColor="texas.bg.700"
      _hover={{
        backgroundColor: 'texas.bg.800',
        outlineColor: 'texas.sand.100',
      }}
      _light={{
        color: 'black',
        backgroundColor: 'gray.50',
        borderColor: 'gray.200',
        _hover: {
          backgroundColor: 'gray.100',
          outlineColor: 'texas.burntSienna.500',
          borderColor: 'texas.burntSienna.500',
        },
      }}
      onClick={() => {
        onAdd(filter.optionType);
      }}
    >
      <VStack padding={3}>
        <Text fontWeight="bold">{filter.getName(t)}</Text>
      </VStack>
    </Box>
  );
}
