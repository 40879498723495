import {
  Th,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Divider,
  Box,
  Heading,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ConfirmCopy } from '../compositions/shared/group/ConfirmCopy';
import { useVersionOverview } from './shared/useVersionOverview';
import { CompositionGroupView } from '../compositions/shared/group/CompositionGroupView';
import { CompositionName } from '../options/shared/CompositionName';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';
import { CopyProps, GroupedVersions } from './shared/GroupedVersions';
import { formatNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';

interface Props {
  variantId: number;
  branchId: number;
  articleNodes: ArticleNodeGroup;
  supplierId?: number;
}

export function VersionsListView({
  variantId,
  branchId,
  supplierId,
  articleNodes,
}: Props) {
  const { t } = useTranslation();

  const { versions, copyGroup } = useVersionOverview({
    variantId,
    branchId,
    supplierId,
    isMatrix: true,
  });

  const {
    value: copyValue,
    isOpen: isCopyOpen,
    onClose: onCopyClose,
    onOpen: onCopyOpen,
  } = useValueDisclosure<CopyProps>();
  const { value, isOpen, onClose, onOpen } = useValueDisclosure<number>();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen,
  } = useValueDisclosure<number>();
  const {
    value: restoreValue,
    isOpen: isRestoreOpen,
    onClose: onRestoreClose,
    onOpen: onRestoreOpen,
  } = useValueDisclosure<number>();

  return (
    <Box>
      {articleNodes.nodeXValues.map((x) => {
        return (
          <React.Fragment key={x.id}>
            <Divider py={4} />
            {articleNodes.nodeYValues.map((y) => {
              const cellVersions = versions?.filter(
                (d) => d.cellX === x.id && d.cellY === y.id,
              );
              const compositionMaxMaterialsCount: {
                count: number;
                name: string | null;
              }[] = [];
              cellVersions?.forEach((v) => {
                v.compositions.forEach((c, i) => {
                  if (c.materials.length === 0) return;

                  if (compositionMaxMaterialsCount.at(i) === undefined) {
                    compositionMaxMaterialsCount.push({
                      count: 1,
                      name: c.note,
                    });
                  }
                  compositionMaxMaterialsCount[i].count = Math.max(
                    compositionMaxMaterialsCount[i].count,
                    c.materials.length,
                  );
                });
              });

              const materialHeaders: React.ReactNode[] = [];
              compositionMaxMaterialsCount.forEach((c, cIndex) => {
                for (let i = 0; i < c.count; i++) {
                  materialHeaders.push(
                    <React.Fragment key={`${cIndex}-${i}`}>
                      <Th bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
                        <CompositionName note={c.name} index={cIndex + 1} />
                        {t('composition.materialNr', { nr: i + 1 })}
                      </Th>
                      <Th bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
                        {t('configuration.quality')}
                      </Th>
                      <Th bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
                        {t('configuration.qualityValue')}
                      </Th>
                      <Th bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
                        {t('configuration.treatments')}
                      </Th>
                      <Th
                        bg="texas.bg.800"
                        borderRight="4px solid !important"
                        borderRightColor="texas.bg.dark !important"
                        _light={{
                          borderRightColor: 'texas.bg.light !important',
                          bg: 'gray.50',
                        }}
                      >
                        {t('configuration.techniques')}
                      </Th>
                    </React.Fragment>,
                  );
                }
              });

              return (
                <GroupedVersions
                  key={`${x.id}-${y.id}`}
                  materialHeaders={materialHeaders}
                  header={
                    <Heading fontSize="md">{formatNodeGroup(x, y)}</Heading>
                  }
                  versions={cellVersions ?? []}
                  onRestoreOpen={(id) => onRestoreOpen(id)}
                  onArchiveOpen={(id) => onArchiveOpen(id)}
                  onOpen={(id) => onOpen(id)}
                  onCopyOpen={(data) => onCopyOpen(data)}
                />
              );
            })}
          </React.Fragment>
        );
      })}

      {archiveValue && (
        <VerifyDialogWithRequest
          headerTitle={t('general.archive')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={compositionGroupApi.archive}
          onPerformed={() => compositionGroupEvents.onArchived.dispatch()}
          args={[archiveValue]}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onSuccessTitle={t('general.successfullyArchived')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
      )}
      {restoreValue && (
        <VerifyDialogWithRequest
          headerTitle={t('general.restore')}
          secondaryButtonTitle={t('general.cancel')}
          primaryButtonTitle={t('general.confirm')}
          primaryRequest={compositionGroupApi.restore}
          onPerformed={() => compositionGroupEvents.onRestored.dispatch()}
          args={[restoreValue]}
          isOpen={isRestoreOpen}
          onClose={onRestoreClose}
          onSuccessTitle={t('general.restored')}
        >
          {t('alert.areYouSure')}
        </VerifyDialogWithRequest>
      )}
      {copyValue && (
        <ConfirmCopy
          title={
            copyValue.replaceId
              ? t('compositionGroup.version.resetToOption')
              : t('compositionGroup.version.copyIntoNew')
          }
          buttonLabel={
            copyValue.replaceId
              ? t('compositionGroup.version.resetToOption')
              : t('compositionGroup.version.copyIntoNew')
          }
          isOpen={isCopyOpen}
          onClose={onCopyClose}
          compositionGroupId={copyValue.groupId}
          onConfirm={async (id) => {
            onCopyClose();
            await copyGroup({
              fromId: id,
              basedOnId: copyValue.basedOnId ?? undefined,
              location: {
                variantId: variantId,
                supplierId: copyValue.supplierId,
                branchId: copyValue.branchId,
                cellX: copyValue.cellX ?? undefined,
                cellY: copyValue.cellY ?? undefined,
              },
              replaceId: copyValue.replaceId ?? undefined,
            });
          }}
        />
      )}
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="auto" maxW="fit-content">
          <ModalBody>{value && <InnerModal id={value} />}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

function InnerModal({ id }: { id: number }) {
  return (
    <>
      <CompositionGroupView
        variantId={0}
        compositionGroupId={id}
        componentSettings={{
          expandedDefault: true,
          compositionArchiveRestore: 'hidden',
          compositionGroupDots: 'hidden',
          addMaterials: 'hidden',
          materialsMode: 'only-edit',
          removeMaterials: 'hidden',
          characterEdit: 'hidden',
          dimensionMode: 'only-values',
        }}
      />
    </>
  );
}
