import { Box } from '@chakra-ui/react';

export function TopRightContainerComponent({
  children,
  bar,
}: {
  children: React.ReactNode;
  bar: React.ReactNode;
}) {
  return (
    <Box pos="relative">
      {children}
      {bar && (
        <Box pos="absolute" right={-2} top={-3}>
          {bar}
        </Box>
      )}
    </Box>
  );
}
