import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function CompositionName({
  note,
  index,
}: {
  note: string | null;
  index: number;
}) {
  const { t } = useTranslation();

  return (
    <>
      {note ? (
        <Heading textTransform="none" fontSize="xl">
          {note}
        </Heading>
      ) : (
        <Heading textTransform="none" fontSize="xl">
          {t('composition.compositionNr', { nr: index })}
        </Heading>
      )}
    </>
  );
}
