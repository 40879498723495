import {
  CreateCompositionMaterialRequest,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import {
  CompositionMaterialForm,
  MaterialFormType,
} from './CompositionMaterialForm';
import { useEffect } from 'react';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';

interface UpdateCompositionMaterialProps extends SharedDisclosureProps {
  compositionMaterialId: number;
  productGroupId: number | null;
  onSubmit: (data: CreateCompositionMaterialRequest) => void;
  formType?: MaterialFormType;
}

export function UpdateCompositionMaterial({
  compositionMaterialId,
  productGroupId,
  isOpen,
  onClose,
  onSubmit,
  formType,
}: UpdateCompositionMaterialProps) {
  const {
    data,
    refetch,
    loading,
    error: getError,
  } = useApiResource(compositionApi.getCompositionMaterial);

  useEffect(() => {
    if (!isOpen) return;
    refetch(compositionMaterialId);
  }, [compositionMaterialId, refetch, isOpen]);

  if (getError) {
    return <ErrorDetails error={getError} />;
  }

  return (
    <CompositionMaterialForm
      formType={formType}
      loading={loading}
      compositionMaterial={data}
      onClose={onClose}
      isOpen={isOpen}
      productGroupId={productGroupId}
      onFormSubmit={(data) => onSubmit(data)}
    />
  );
}
