import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';
import { SelectContact } from './SelectContact';
import { CreateContact } from './CreateContact';
import { useState } from 'react';
import { ContactAddToType } from '@texas/api/endpoints/contactsApi';

export interface AddContactProps extends SharedDisclosureProps {
  referenceId: number;
  onSelect: (c: { id: number; name: string; email: string }) => void;
  onCreatedAndConnected: (c: {
    id: number;
    name: string;
    email: string;
  }) => void;
  addTo: ContactAddToType;
  filterOn: ContactAddToType | null;
  mode: Mode;
}

export type Mode = 'link' | 'select';

export function ContactsModal({
  referenceId,
  addTo,
  filterOn,
  onSelect,
  onCreatedAndConnected,
  onClose,
  isOpen,
  mode,
}: AddContactProps) {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState<string>('');
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const onCreateContact = (s: string) => {
    setTabIndex(1);
    setSearchInput(s);
  };

  const resetModal = () => {
    setSearchInput('');
    setTabIndex(0);
    onClose();
  };

  return (
    <Modal onClose={resetModal} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {mode === 'link'
            ? t('contacts.addContact')
            : t('contacts.selectContact')}
        </ModalHeader>
        <ModalBody>
          <Tabs index={tabIndex} onChange={handleTabsChange}>
            <TabList>
              <Tab>
                {mode === 'select'
                  ? t('contacts.selectContact')
                  : t('contacts.addExisting')}
              </Tab>
              <Tab>{t('contacts.create')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <SelectContact
                  mode={mode}
                  filterOn={filterOn}
                  onSelect={(c) =>
                    onSelect({ id: c.id, name: c.name, email: c.email })
                  }
                  referenceId={referenceId}
                  onCreateContactClick={(s) => onCreateContact(s)}
                />
              </TabPanel>
              <TabPanel>
                <CreateContact
                  addTo={addTo}
                  onCreated={(c) => {
                    onCreatedAndConnected({
                      id: c.id,
                      name: c.name,
                      email: c.email,
                    });
                    resetModal();
                  }}
                  referenceId={referenceId}
                  input={searchInput}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
