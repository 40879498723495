import {
  CardBody,
  Flex,
  IconButton,
  Tooltip,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Composition } from '@texas/api/endpoints/compositionApi';
import { useContext, useEffect } from 'react';
import { DetailedCompositon } from '../composition/DetailedCompositon';
import { useTranslation } from 'react-i18next';
import { TemplateCard } from '../composition/TemplateCard';
import { Icons } from '@texas/components/shared/Icons';
import { GroupContext } from './CompositionGroupView';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';

export function CompositionCarouselCard({
  composition,
  onLoaded,
}: {
  composition: Composition;
  onLoaded?: (template: Composition | null) => void;
}) {
  useEffect(() => {
    if (!onLoaded) return;
    onLoaded(composition);
  }, [composition, onLoaded]);

  return (
    <TemplateCard exists={!composition.archived}>
      <CardBody
        p={0}
        as={Flex}
        overflowX="hidden"
        flexDir="column"
        border="1px solid"
        bg="texas.bg.dark"
        borderColor="texas.bg.700"
        _light={{ borderColor: 'gray.200', bg: 'white' }}
      >
        <DetailedCompositon composition={composition} archiveDisabled={true} />
        <Footer composition={composition} />
      </CardBody>
    </TemplateCard>
  );
}

function Footer({ composition }: { composition: Composition }) {
  const { t } = useTranslation();
  const {
    archiveCompositionRequest,
    restoreCompositionRequest,
    componentSettings,
    anyProductApproval,
  } = useContext(GroupContext)!;
  const { isOpen, onClose, onOpen } = useDisclosure();

  if (anyProductApproval) {
    return null;
  }

  return (
    <Flex
      pos="sticky"
      bottom={0}
      mt="auto"
      gap={2}
      alignItems="center"
      p={2}
      borderRadius="md"
      bg="texas.bg.800"
      _light={{ bg: 'gray.50' }}
    >
      {!composition.archived ? (
        <>
          {componentSettings?.compositionArchiveRestore !== 'hidden' && (
            <Tooltip label={t('composition.archiveComposition')}>
              <IconButton
                onClick={onOpen}
                variant="ghost"
                icon={<Icons.Archive />}
                aria-label="archive composition"
              />
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <Text>
            {t('general.archivedDate', {
              date: formatDate(Locale.En, composition.archived),
            })}
          </Text>
          {componentSettings?.compositionArchiveRestore !== 'hidden' && (
            <Tooltip label={t('general.restore')}>
              <IconButton
                onClick={() => restoreCompositionRequest(composition.id)}
                variant="ghost"
                icon={<Icons.Undo />}
                aria-label="restore composition"
              />
            </Tooltip>
          )}
        </>
      )}
      <VerifyDialogWithFunction
        headerTitle={t('composition.archiveComposition')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={() => archiveCompositionRequest(composition.id)}
        isOpen={isOpen}
        onClose={onClose}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithFunction>
    </Flex>
  );
}
