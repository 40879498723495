import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  IconButton,
  Input,
  useToast,
  Text,
  Flex,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import {
  CreateDimensionGroupRequest,
  DimensionGroup,
  dimensionGroupsApi,
} from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { ImageDropzone } from '@texas/components/shared/dropzone/ImageDropzone';
import { FileDropzone } from '@texas/components/shared/dropzone/FileDropzone';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { maxSize } from '@texas/components/shared/dropzone/shared';
import { useCallback, useState } from 'react';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { acceptedImageFormats } from '@texas/utils/helpers/filesHelper';
import { clientEndpoints } from '@texas/clientEndpoints';

export interface CreateDimensionGroupProps extends SharedDisclosureProps {
  onCreated: (dimensionGroup: DimensionGroup) => void;
  folderId: number;
}

export interface CreateDimensionGroupForm {
  name: string;
  description: string | undefined;
  dimensions: { name: string; identifier: string }[];
}

const defaultValues: CreateDimensionGroupForm = {
  name: '',
  description: '',
  dimensions: [{ name: '', identifier: '' }],
};

export function CreateDimensionGroup({
  onCreated,
  isOpen,
  onClose,
  folderId,
}: CreateDimensionGroupProps) {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('configuration.addNewDimensionGroup')}
        </ModalHeader>
        <Form onClose={onClose} onCreated={onCreated} folderId={folderId} />
      </ModalContent>
    </Modal>
  );
}

function Form({
  onCreated,
  onClose,
  folderId,
}: {
  onCreated: (dimensionGroup: DimensionGroup) => void;
  onClose: () => void;
  folderId: number;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const [file, setFile] = useState<FileLink | null>(null);

  const {
    request: createRequest,
    error,
    loading,
  } = useApiRequest(dimensionGroupsApi.create);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CreateDimensionGroupForm>({
    defaultValues: defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dimensions',
    rules: {
      required: { value: true, message: t('configuration.dimensionRequired') },
      minLength: { value: 1, message: t('configuration.dimensionRequired') },
    },
  });

  const onSubmit = async (data: CreateDimensionGroupForm) => {
    const formRequest: CreateDimensionGroupRequest = {
      ...data,
      fileId: file?.id ?? null,
    };
    await request(createRequest, [formRequest], (data: DimensionGroup) => {
      toast({
        title: t('general.created'),
        status: 'success',
        isClosable: true,
      });
      reset(defaultValues);
      onCreated(data);
      onClose();
    });
  };

  const onUpload = useCallback(
    (file: FileLink) => {
      setFile(file);
    },
    [setFile],
  );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(onUpload, maxSize);

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <ModalBody>
          <Flex flexDir="column" gap={4}>
            <Flex justify="center">
              {file && (
                <ImageDropzone
                  imageId={file.id}
                  imageIdentifier={file.identifier}
                  imageSrc={clientEndpoints.previewImage(file.identifier, 250)}
                  imageArchived={false}
                  allowEdit={true}
                  onUpload={(files) => uploadFiles(files, folderId)}
                  isUploading={isUploading}
                  uploadFailed={uploadFailed}
                  fileUploads={fileUploads}
                  abortFileUpload={abortFileUpload}
                  onRemovefileOpen={() => setFile(null)}
                  showWebcamBtn={false}
                />
              )}
              {!loading && !file && (
                <FileDropzone
                  accept={acceptedImageFormats}
                  isUploading={isUploading}
                  uploadFailed={uploadFailed}
                  fileUploads={fileUploads}
                  abortFileUpload={abortFileUpload}
                  onUpload={(files) => uploadFiles(files, folderId)}
                  showWebcamBtn={false}
                />
              )}
            </Flex>
            <FormControl isRequired={true} isInvalid={!!errors.name}>
              <TexasFormLabel>{t('general.name')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('name', {
                  required: true,
                })}
                placeholder={t('general.name')}
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <FormControl isInvalid={!!errors.description}>
              <TexasFormLabel>{t('general.description')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('description')}
                placeholder={t('general.description')}
              />
              <ErrorLabel text={errors.description?.message} />
            </FormControl>
            <FormControl isInvalid={!!errors.dimensions?.root?.message}>
              <Text variant="main">{t('configuration.dimensions')}</Text>

              <TexasFormLabel>{t('general.name')}</TexasFormLabel>

              {fields.map((d, i) => {
                return (
                  <Flex direction="column" key={d.id} pb={2}>
                    <Flex gap={2}>
                      <Input
                        {...register(`dimensions.${i}.name` as const, {
                          required: {
                            value: true,
                            message: t('errors.nameRequired', {
                              name: t('configuration.dimensionName'),
                            }),
                          },
                        })}
                        variant="outline"
                      />
                      <Input
                        {...register(`dimensions.${i}.identifier` as const, {
                          required: false,
                          maxLength: {
                            value: 10,
                            message: t('errors.nameMaxLength', {
                              name: t('configuration.identifier'),
                              count: 10,
                            }),
                          },
                        })}
                        placeholder={t('configuration.identifier')}
                        variant="outline"
                        w={36}
                      />
                      <IconButton
                        variant="texas-light"
                        size="sm"
                        icon={<Icons.Close />}
                        onClick={() => remove(i)}
                        aria-label={t('general.remove')}
                      />
                    </Flex>
                    <ErrorLabel text={errors.dimensions?.[i]?.name?.message} />
                    <ErrorLabel
                      text={errors.dimensions?.[i]?.identifier?.message}
                    />
                  </Flex>
                );
              })}
              <ErrorLabel text={errors.dimensions?.root?.message} />
              <Button
                variant="texas-light"
                my={2}
                size="sm"
                onClick={() => append({ name: '', identifier: '' })}
                aria-label={t('general.add')}
                rightIcon={<Icons.Plus boxSize={defaultIconSize} />}
              >
                {t('general.add')}
              </Button>
              <Text variant="note" mt={2}>
                {t('configuration.dimensionGroupNote')}
              </Text>
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <SubmitButton loading={isSubmitting} disabled={loading}>
            {t('general.create')}
          </SubmitButton>
        </ModalFooter>
      </form>
    </>
  );
}
