import { Flex, Box, Heading, Text, Spinner } from '@chakra-ui/react';
import { MatrixHighlightHelper } from '@texas/components/shared/matrix/MatrixHighlightHelper';
import {
  AxisType,
  compositionGroupMatrixApi,
  MatrixAxis,
} from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { MutableRefObject, useEffect, useRef } from 'react';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { DimensionGroupContainer } from '../../../../../shared/composition/dimension/DimensionGroupContainer';
import { MatrixDimensionsForm } from './MatrixDimensionForm';
import { useDraggable } from 'react-use-draggable-scroll';
import { CompositionName } from '../../shared/CompositionName';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { useTranslation } from 'react-i18next';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';

export function MatrixSizes({
  colorAxis,
  compositionGroupId,
  onAnyValueChanged,
}: {
  colorAxis: MatrixAxis;
  compositionGroupId: number;
  onAnyValueChanged?: (nrOfZeroDimensions: number) => void;
}) {
  const { t } = useTranslation();
  const { data, refetch, loading } = useApiResource(
    compositionGroupMatrixApi.getAxisCells,
  );

  useEffect(() => {
    refetch(compositionGroupId, AxisType.Sizes);
  }, [compositionGroupId, refetch]);

  const ref = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);

  useSignalSubscriptionEvents({
    triggerOnEvent: () => {
      refetch(compositionGroupId, AxisType.Sizes);
    },
    signalEvents: [
      compositionEvents.onDimensionGroupSet,
      compositionEvents.onRename,
      compositionEvents.onAdd,
      compositionEvents.onRestored,
      compositionEvents.onArchived,
    ],
  });

  useEffect(() => {
    if (!data) return;
    const anyValueDataZero =
      ref.current?.querySelectorAll('* [data-value="0"]');

    onAnyValueChanged?.(anyValueDataZero?.length ?? 0);
  }, [data, onAnyValueChanged]);

  useEffect(() => {
    return compositionEvents.onDimensionValueChanged.sub(() => {
      const anyValueDataZero =
        ref.current?.querySelectorAll('* [data-value="0"]');

      onAnyValueChanged?.(anyValueDataZero?.length ?? 0);
    });
  }, [onAnyValueChanged]);

  return (
    <Flex flexDir="column" gap={4}>
      <Flex gap={4} align="center">
        {ensureEnumNumber(MatrixAxis, colorAxis) === MatrixAxis.Columns ? (
          <>
            <MatrixHighlightHelper zone="rows" />
            <Box>
              <Heading fontSize="lg">{t('matrix.rows')}</Heading>
              <Text>{t('matrix.dimensionDesc')}</Text>
            </Box>
          </>
        ) : (
          <>
            <MatrixHighlightHelper zone="columns" />
            <Box>
              <Heading fontSize="lg">{t('matrix.columns')}</Heading>
              <Text>{t('matrix.dimensionDesc')}</Text>
            </Box>
          </>
        )}
      </Flex>
      <Flex gap={2} overflowX="auto" ref={ref} {...events} pb={6}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {data?.map((x) => {
              return (
                <Component
                  key={x.id}
                  group={x}
                  refetch={() => refetch(compositionGroupId, AxisType.Sizes)}
                />
              );
            })}
          </>
        )}
      </Flex>
    </Flex>
  );
}

function Component({
  group,
  refetch,
}: {
  group: CompositionGroup;
  refetch: () => void;
}) {
  const { t } = useTranslation();

  const cellId =
    ensureEnumNumber(MatrixAxis, group.colorAxis) === MatrixAxis.Columns
      ? group.cellY
      : group.cellX;

  return (
    <Flex gap={2} flexDir="column" h="fit-content" minW="200px" px={2}>
      <Heading fontSize="xl">
        {ensureEnumNumber(MatrixAxis, group.colorAxis) === MatrixAxis.Columns
          ? group.matrixNodeYValue
          : group.matrixNodeXValue}
      </Heading>
      {group.compositions.map((x, i) => {
        return (
          <Flex key={x.id} flexDir="column" gap={1}>
            <CompositionName note={x.note} index={i + 1} />
            <Box
              animation={fadeInScaleAnimation()}
              bg="texas.bg.900"
              _light={{ bg: 'gray.50' }}
              padding={2}
              borderRadius="md"
            >
              {!x.dimension && <Text variant="sub">{t('general.noData')}</Text>}
              <DimensionGroupContainer
                mode="only-values"
                composition={x}
                onGroupChange={(_) => {
                  refetch();
                }}
              >
                {x.dimension && (
                  <Flex gap={2} flexDir="column">
                    {x.dimension.dimensions.map((d) => {
                      return (
                        <MatrixDimensionsForm
                          key={d.id}
                          controlledByGroupId={group.controlledById!}
                          compositionIndex={i}
                          matrixNodeId={cellId!}
                          dimension={d}
                        />
                      );
                    })}
                  </Flex>
                )}
              </DimensionGroupContainer>
            </Box>
          </Flex>
        );
      })}
    </Flex>
  );
}
