import {
  Tabs,
  TabList,
  Tab,
  Flex,
  TabPanels,
  TabPanel,
  Text,
  Box,
} from '@chakra-ui/react';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { VersionsListViewStandard } from './VersionListViewStandard';
import { MissingSuppliers } from '../MissingSuppliers';
import { SpinnerWithLabel } from '@texas/components/shared/SpinnerWithLabel';

export function StandardVersions({ variantId }: { variantId: number }) {
  const { t } = useTranslation();
  const {
    data: suppliers,
    refetch: refetchSuppliers,
    loading,
  } = useApiResource(variantsApi.getVariantBranchSuppliers);

  useEffect(() => {
    refetchSuppliers(variantId, false);
  }, [refetchSuppliers, variantId]);

  return (
    <Box>
      {loading && (
        <Box py={2}>
          <SpinnerWithLabel label={t('suppliers.loading')} />
        </Box>
      )}
      <Tabs variant="line" isLazy={true}>
        <TabList>
          {suppliers?.branches.map((x) => {
            return (
              <Tab key={x.id}>
                <Flex flexDir="column" align="start">
                  <Text variant="tab-header">{x.identifier}</Text>
                  <Text variant="sub">{x.name}</Text>
                </Flex>
              </Tab>
            );
          })}
        </TabList>
        <TabPanels>
          {suppliers?.branches.map((x) => {
            const suppliersInBranch = suppliers.suppliers.filter(
              (s) => s.branchId === x.id,
            );

            if (suppliersInBranch.length == 0) {
              return (
                <TabPanel key={x.id}>
                  <MissingSuppliers />
                </TabPanel>
              );
            }
            return (
              <TabPanel key={x.id}>
                <VersionsListViewStandard
                  suppliers={suppliersInBranch}
                  variantId={variantId}
                  branchId={x.id}
                />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
