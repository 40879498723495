import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Spinner,
  useDisclosure,
  Tooltip,
  Collapse,
  useColorMode,
} from '@chakra-ui/react';
import {
  Composition,
  compositionApi,
  Dimension,
  DimensionValue,
} from '@texas/api/endpoints/compositionApi';
import {
  DimensionGroup,
  dimensionGroupsApi,
  Metric,
} from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { EmptyDataButton } from '@texas/components/shared/button/EmptyDataButton';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import {
  fadeInRightAnimation,
  moveDownRotateAnimation,
} from '@texas/resources/animations/animations';
import { navLinkOffsetStyle } from '@texas/resources/styles';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect, useState } from 'react';
import { ErrorDetails } from '../../alert/ErrorDetails';
import { TexasSelect } from '../../form/TexasSelect';
import {
  convertToEnum,
  getEnumNamesAndValues,
} from '@texas/utils/helpers/enumHelpers';
import { TexasFormLabel } from '../../form/TexasFormLabel';
import { onlyUnique } from '@texas/utils/helpers/commonHelpers';
import { metricFriendlyName } from './translations';
import { SimpleNote } from '../../SimpleNote';
import { useTranslation } from 'react-i18next';
import { TopRightContainerComponent } from '../../TopRightContainerComponent';

export type DimensionGroupMode = 'only-group' | 'only-values' | 'read-only';

export function DimensionGroupContainer({
  composition,
  mode,
  onGroupChange,
  children: dimensionForm,
}: {
  composition: Composition;
  mode?: DimensionGroupMode;
  onGroupChange: (dimension: Dimension) => void;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  if (mode == 'read-only') {
    return (
      <TopRightContainerComponent bar={<Icons.Lock />}>
        <Flex
          flexDir="column"
          sx={{
            ':hover *[data-hover-id="text"]': {
              height: '12px',
              opacity: 1,
            },
          }}
          bg="texas.bg.700"
          _light={{ bg: 'gray.50' }}
          borderRadius="md"
          padding={2}
          cursor="pointer"
        >
          <Flex flexDir="column" justify="center" h="46px">
            <Flex gap={2}>
              <Heading fontSize="md">
                {composition.dimension?.name ?? t('composition.noDimensions')}
              </Heading>
              <MetricTags
                dimensions={composition.dimension?.dimensions ?? []}
              />
            </Flex>
            <Text
              data-hover-id="text"
              height="0"
              opacity={0}
              transition="height 200ms ease, opacity 200ms ease"
              fontSize="sm"
            >
              {t('sizes.noEdit')}
            </Text>
          </Flex>
        </Flex>
      </TopRightContainerComponent>
    );
  }

  return (
    <Box>
      {mode !== 'only-values' && (
        <ChangeDimensionGroup
          composition={composition}
          onChange={onGroupChange}
        />
      )}

      {mode !== 'only-group' && <>{dimensionForm}</>}
    </Box>
  );
}

function ChangeDimensionGroup({
  onChange,
  composition,
}: {
  onChange: (dimension: Dimension) => void;
  composition: Composition;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIds, setSelectedIds] = useState<number[] | null>(
    composition.dimension?.dimensions.map((d) => d.id) ?? null,
  );
  const firstDimension = composition.dimension?.dimensions.at(0);
  const [metric, setMetric] = useState<Metric>(
    firstDimension
      ? convertToEnum(Metric, firstDimension.metric)
      : Metric.Millimeters,
  );

  const {
    request: setRequest,
    loading,
    error,
  } = useApiRequest(compositionApi.setDimensionGroup);

  const updateRequest = async () => {
    if (!selectedIds) return;

    await request(
      setRequest,
      [composition.id, { dimensionIds: selectedIds, metric: metric }],
      (data) => {
        onChange(data);
        onClose();
      },
    );
  };

  return (
    <>
      {composition.dimension ? (
        <Flex
          onClick={onOpen}
          flexDir="column"
          sx={{
            ':hover *[data-hover-id="text"]': {
              height: '12px',
              opacity: 1,
            },
          }}
          bg="texas.bg.700"
          _light={{ bg: 'gray.50' }}
          borderRadius="md"
          padding={2}
          cursor="pointer"
        >
          <Flex flexDir="column" justify="center" h="46px">
            <Flex gap={2}>
              <Heading fontSize="md">{composition.dimension.name}</Heading>
              <MetricTags dimensions={composition.dimension.dimensions} />
            </Flex>
            <Text
              height="0"
              opacity={0}
              transition="height 200ms ease, opacity 200ms ease"
              fontSize="sm"
              data-hover-id="text"
            >
              {t('composition.changeDimensions')}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <EmptyDataButton
          icon={<Icons.AxisArrow />}
          onClick={onOpen}
          label={t('sizes.noSelected')}
          description={t('sizes.viewAvailable')}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('sizes.selectDimensionGroup')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ErrorDetails error={error} />
            <InnerModal
              onChange={(ids) => setSelectedIds(ids)}
              onMetricChange={(m) => setMetric(m)}
              selectedMetric={metric}
              composition={composition}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={selectedIds === null || loading}
              variant="texas-solid"
              isLoading={loading}
              onClick={async () => {
                await updateRequest();
              }}
            >
              {t('sizes.selectDimensions')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function MetricTags({ dimensions }: { dimensions: DimensionValue[] }) {
  const metrics = dimensions
    .map((d) => convertToEnum(Metric, d.metric))
    .filter(onlyUnique);

  return (
    <>
      {metrics.map((m) => (
        <Badge h="fit-content" key={m}>
          {metricFriendlyName(m)}
        </Badge>
      ))}
    </>
  );
}

function InnerModal({
  onChange,
  onMetricChange,
  composition,
  selectedMetric,
}: {
  onChange: (ids: number[]) => void;
  onMetricChange: (metric: Metric) => void;
  selectedMetric: Metric;
  composition: Composition;
}) {
  const { t } = useTranslation();
  const {
    data: groups,
    refetch: refetchGroups,
    loading: groupsLoading,
  } = useApiResource(dimensionGroupsApi.getAll);

  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(
    composition.dimension?.id,
  );

  useEffect(() => {
    refetchGroups();
  }, [refetchGroups]);

  const selectedDimensionGroup = groups?.find((x) => x.id === selectedGroupId);
  const firstDimension = composition.dimension?.dimensions.at(0);
  const { isOpen, onToggle } = useDisclosure();

  if (groupsLoading) return <Spinner />;

  const hasRecommendedGroups = groups?.some((g) =>
    g.linkedProductGroupIds.some(
      (l) => composition.articleProductGroupId === l,
    ),
  );

  return (
    <Flex gap={6}>
      <Flex flexDir="column" gap={2} minW="300px" maxW="300px">
        {hasRecommendedGroups ? (
          <>
            <Flex gap={2}>
              <Tooltip label="Recommended for this article">
                <Icons.ThumbUp
                  animation={moveDownRotateAnimation()}
                  boxSize={defaultIconSize}
                  color="texas.sand.50"
                />
              </Tooltip>
              <Heading fontSize="md">{t('sizes.recommended')}</Heading>
            </Flex>

            {groups
              ?.filter((x) =>
                x.linkedProductGroupIds.some(
                  (l) => composition.articleProductGroupId === l,
                ),
              )
              .map((x) => {
                return (
                  <DimensionGroupButton
                    key={x.id}
                    onClick={() => {
                      setSelectedGroupId(x.id);
                      onChange(x.dimensions.map((d) => d.id));
                    }}
                    group={x}
                    selectedGroupId={selectedGroupId}
                  />
                );
              })}
            <Button
              mt={6}
              justifyContent="space-between"
              borderRadius="md"
              onClick={onToggle}
              rightIcon={
                isOpen ? (
                  <Icons.ChevronUp boxSize={defaultIconSize} />
                ) : (
                  <Icons.ChevronDown boxSize={defaultIconSize} />
                )
              }
            >
              {t('sizes.other')}
            </Button>
            <Collapse in={isOpen} animateOpacity={true}>
              <Flex flexDir="column" gap={2} padding={2}>
                {groups
                  ?.filter(
                    (x) =>
                      !x.linkedProductGroupIds.some(
                        (l) => composition.articleProductGroupId === l,
                      ),
                  )
                  .map((x) => {
                    return (
                      <DimensionGroupButton
                        key={x.id}
                        onClick={() => {
                          setSelectedGroupId(x.id);
                          onChange(x.dimensions.map((d) => d.id));
                        }}
                        group={x}
                        selectedGroupId={selectedGroupId}
                      />
                    );
                  })}
              </Flex>
            </Collapse>
          </>
        ) : (
          <>
            {groups
              ?.filter(
                (x) =>
                  !x.linkedProductGroupIds.some(
                    (l) => composition.articleProductGroupId === l,
                  ),
              )
              .map((x) => {
                return (
                  <DimensionGroupButton
                    key={x.id}
                    onClick={() => {
                      setSelectedGroupId(x.id);
                      onChange(x.dimensions.map((d) => d.id));
                    }}
                    group={x}
                    selectedGroupId={selectedGroupId}
                  />
                );
              })}
          </>
        )}
      </Flex>

      <Box>
        {selectedDimensionGroup && (
          <Flex animation={fadeInRightAnimation()} flexDir="column" gap={4}>
            {selectedDimensionGroup.description.length > 0 && (
              <>
                <Flex align="center" gap={2}>
                  <Icons.InformationOutline boxSize={defaultIconSize} />
                  <Heading fontSize="md">{t('general.description')}</Heading>
                </Flex>
                <Text>{selectedDimensionGroup.description}</Text>
              </>
            )}

            <Flex align="center" gap={2}>
              <Icons.AxisArrow boxSize={defaultIconSize} />
              <Heading fontSize="md">{t('sizes.available')}</Heading>
            </Flex>
            <Flex flexWrap="wrap" gap={2}>
              {selectedDimensionGroup.dimensions.map((d) => (
                <Badge key={d.id}>{d.name}</Badge>
              ))}
            </Flex>
            <Box>
              <TexasFormLabel>{t('sizes.selectMetric')}</TexasFormLabel>
              <TexasSelect
                value={{
                  label: metricFriendlyName(selectedMetric),
                  value: selectedMetric,
                }}
                onChange={(e) => {
                  onMetricChange(e?.value as Metric);
                }}
                options={getEnumNamesAndValues(Metric).map((s) => {
                  return {
                    label: metricFriendlyName(s.value as Metric),
                    value: s.value,
                  };
                })}
              />
              {selectedMetric !==
                convertToEnum(Metric, firstDimension?.metric) && (
                <SimpleNote
                  pt={2}
                  color="yellow.300"
                  _light={{ color: 'yellow.500' }}
                >
                  {t('sizes.changeMetricNote')}
                </SimpleNote>
              )}
            </Box>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

function DimensionGroupButton({
  onClick,
  selectedGroupId,
  group,
}: {
  onClick: () => void;
  group: DimensionGroup;
  selectedGroupId?: number;
}) {
  const { colorMode } = useColorMode();

  return (
    <Button
      {...navLinkOffsetStyle(group.id === selectedGroupId, 1.02, colorMode)}
      h="auto"
      py={2}
      borderRadius="md"
      gap={2}
      alignItems="center"
      justifyContent="start"
      textAlign="start"
      _light={{
        bg: 'gray.50',
      }}
      onClick={onClick}
    >
      <Flex flexDir="column" gap={2} whiteSpace="normal">
        <Heading fontSize="md" wordBreak="break-word">
          {group.name}
        </Heading>
        <Flex flexWrap="wrap" gap={2}>
          {group.dimensions.map((d) => (
            <Badge key={d.id}>{d.name}</Badge>
          ))}
        </Flex>
      </Flex>
      {group.description.length > 0 && (
        <Icons.InformationOutline ml="auto" boxSize={defaultIconSize} />
      )}
    </Button>
  );
}
