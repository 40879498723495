import { useToast } from '@chakra-ui/react';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { compositionGroupMatrixApi } from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  variantId: number;
}
export function useMatrixOptions({ variantId }: Props) {
  const toast = useToast();
  const { t } = useTranslation();

  const {
    data: options,
    refetch: refetchOptions,
    loading: loadingOptions,
    set: setOptions,
  } = useApiResource(compositionGroupMatrixApi.getOptions);

  const { request: addRequest, loading: createControllerLoading } =
    useApiRequest(compositionGroupMatrixApi.addNewOption);

  useEffect(() => {
    refetchOptions(variantId);
  }, [refetchOptions, variantId]);

  const refetchAllOptions = useCallback(
    () => refetchOptions(variantId),
    [refetchOptions, variantId],
  );

  const createControllerRequest = async () => {
    await request(
      addRequest,
      [variantId],
      (_) => {
        refetchOptions(variantId);
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  const archiveController = async (id: number) => {
    await request(
      compositionGroupApi.archive,
      [id],
      () => {
        compositionGroupEvents.onArchived.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.archiveFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  const copyGroup = async (fromId: number) => {
    await request(
      compositionGroupApi.copyGroup,
      [
        fromId,
        {
          variantId: variantId,
          replaceCompositionGroupId: undefined,
          basedOnId: undefined,
        },
      ],
      (_) => {
        compositionGroupEvents.onCopy.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.copyFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  useSignalSubscriptionEvents({
    triggerOnEvent: () => {
      refetchAllOptions();
    },
    signalEvents: [
      compositionEvents.onDimensionGroupSet,
      compositionEvents.onRename,
      compositionEvents.onCharacterSet,
      compositionGroupEvents.onRename,
      compositionGroupEvents.onCopy,
      compositionEvents.onMaterialAdded,
      compositionEvents.onMaterialRemoved,
      compositionEvents.onMaterialChanged,
      compositionEvents.onAdd,
      compositionEvents.onArchived,
      compositionEvents.onRestored,
      compositionGroupEvents.onLayoutChanged,
      compositionGroupEvents.onMarkReady,
      compositionGroupEvents.onArchived,
      compositionGroupEvents.onRestored,
      compositionGroupEvents.onColorsChanged,
    ],
  });

  useEffect(() => {
    // We do no refetch here since dimensions are changed using auto update which would result in a lot of calls
    return compositionEvents.onDimensionValueChanged.sub(({ groupId }) => {
      setOptions((x) =>
        x
          ? x.map((o) => {
              if (o.id === groupId) {
                return { ...o, isReady: false };
              }

              return o;
            })
          : [],
      );
    });
  }, [setOptions]);

  return {
    options,
    createControllerRequest,
    loadingOptions,
    createControllerLoading,
    archiveController,
    copyGroup,
  };
}
