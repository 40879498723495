import { Metric } from '@texas/api/endpoints/metadata/dimensionGroupsApi';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';

export function metricFriendlyName(metric: Metric) {
  switch (ensureEnumNumber(Metric, metric)) {
    case Metric.Millimeters: {
      return 'Millimeters';
    }
    case Metric.Centimeters: {
      return 'Centimeters';
    }
    case Metric.Inch: {
      return 'Inch';
    }
    case Metric.Meters: {
      return 'Meters';
    }
    case Metric.Ligne: {
      return 'Ligne';
    }
    case Metric.Micron: {
      return 'Micron';
    }
    case Metric.Mil: {
      return 'Mil';
    }
  }
}

export function metricShortName(metric: Metric) {
  switch (ensureEnumNumber(Metric, metric)) {
    case Metric.Millimeters: {
      return 'MM';
    }
    case Metric.Centimeters: {
      return 'CM';
    }
    case Metric.Inch: {
      return 'IN';
    }
    case Metric.Meters: {
      return 'M';
    }
    case Metric.Ligne: {
      return 'L';
    }
    case Metric.Micron: {
      return 'Micron';
    }
    case Metric.Mil: {
      return 'Mil';
    }
  }
}
