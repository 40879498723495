import { useToast } from '@chakra-ui/react';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ServerError } from '@texas/types';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';
import { compositionEvents } from '@texas/components/shared/composition/events';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';

interface Props {
  variantId: number;
}

interface CompositionLocationData {
  variantId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

export function useOptionsOverview({ variantId }: Props) {
  const {
    refetch,
    data: versions,
    loading: versionsLoading,
    set: setOptions,
  } = useApiResource(compositionGroupApi.optionsOverview);
  const toast = useToast();
  const { t } = useTranslation();
  const locationData = useRef<CompositionLocationData>({
    variantId,
  });

  useEffect(() => {
    refetch({
      variantId,
    });
  }, [refetch, variantId]);

  useEffect(() => {
    locationData.current = {
      variantId,
    };
  }, [variantId]);

  const refetchOptions = useCallback(() => {
    refetch({
      variantId,
    });
  }, [refetch, variantId]);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    // We do no refetch here since dimensions are changed using auto update which would result in a lot of calls
    return compositionEvents.onDimensionValueChanged.sub(({ groupId }) => {
      setOptions((x) =>
        x
          ? x.map((o) => {
              if (o.id === groupId) {
                return { ...o, isReady: false };
              }

              return o;
            })
          : [],
      );
    });
  }, [setOptions]);

  useSignalSubscriptionEvents({
    triggerOnEvent: refetchOptions,
    signalEvents: [
      compositionGroupEvents.onArchived,
      compositionGroupEvents.onRestored,
      compositionGroupEvents.onCreate,
      compositionGroupEvents.onCopy,
      compositionEvents.fileUpdated,
      compositionEvents.onRename,
      compositionEvents.onDimensionGroupSet,
      compositionEvents.onCharacterSet,
      compositionGroupEvents.onRename,
      compositionEvents.onMaterialAdded,
      compositionEvents.onMaterialChanged,
      compositionEvents.onMaterialRemoved,
      compositionEvents.onAdd,
      compositionEvents.onArchived,
      compositionEvents.onRestored,
      compositionGroupEvents.onLayoutChanged,
      compositionGroupEvents.onMarkReady,
      compositionGroupEvents.onMarkNotReady,
    ],
  });

  const createGroupRequest = async () => {
    setIsCreating(true);
    await request(
      compositionGroupApi.addGroup,
      [{ variantId }],
      (_) => {
        compositionGroupEvents.onCreate.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
    setIsCreating(false);
  };
  const copyGroup = async (props: {
    fromId: number;
    location?: CompositionLocationData;
    basedOnId?: number;
  }) => {
    setIsCreating(true);
    await request(
      compositionGroupApi.copyGroup,
      [
        props.fromId,
        {
          ...(props.location ?? locationData.current),
          replaceCompositionGroupId: undefined,
          basedOnId: props.basedOnId,
        },
      ],
      (_) => {
        compositionGroupEvents.onCopy.dispatch();
        toast({
          title: t('general.copied'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        toast({
          title: t('general.copyFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
    setIsCreating(false);
  };
  const archiveGroupRequest = async (id: number) => {
    await request(
      compositionGroupApi.archive,
      [id],
      (_) => {
        compositionGroupEvents.onArchived.dispatch();
      },
      (error: ServerError) => {
        toast({
          title: t('general.archiveFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  const productApprovalsCount = (state: ProductApprovalState, id: number) => {
    return (
      versions
        ?.find((v) => v.id === id)
        ?.productApprovals?.filter(
          (x) => convertToEnum(ProductApprovalState, x.state) == state,
        ).length ?? 0
    );
  };

  const anyProductApproval = (id: number) =>
    (versions?.find((v) => v.id === id)?.productApprovals?.length ?? 0) > 0;

  return {
    versions,
    versionsLoading,
    createGroupRequest,
    copyGroup,
    productApprovalsCount,
    locationData,
    anyProductApproval,
    archiveGroupRequest,
    refetchOptions,
    isCreating,
  };
}
