import { httpClient } from '../httpClient/httpClient';

export interface BrandsDto {
  name: string;
  id: number;
  nrOfArticles: number;
  project: string;
}

interface FilterCustomerRequest {
  assignedTo: number | null;
  customerName: string;
}

interface Concept {
  name: string;
  id: number;
}

export enum CustomerType {
  Brand = 0,
  Vendor = 1,
}

interface UserEntity {
  id: number;
}

interface CustomerBranchData {
  customerId: number;
  branchId: number;
  deliveryTermId: number;
  paymentTermId: number;
  useDefaultCurrency: boolean;
  defaultCurrencyIsMain: boolean;
  currencies: CustomerBranchCurrency;
}

interface CustomerBranchCurrency {
  currencyId: number;
  currencyCode: string;
  isMain: boolean;
  useCustomSellingRate: boolean;
  sellingRate: number;
  garpSellingRate: number;
}

export interface CreateCustomerRequest {
  name: string;
  customerType: number;
  brandOwnerId: number;
  bcSampleApprovalRequired: boolean;
}

interface Customer {
  id: number;
  name: string;
  identifier: string;
  officeAddress: string;
  website: string;
  email: string;
  phone: string;
  notes: string;
  users: UserEntity[];
  brandOwner: number | null;
  organizationId: number;
  folderId: number;
  archived: boolean;
  customerType: CustomerType;
  bcSampleApprovalRequired: boolean;
  branchData: CustomerBranchData;
}

export interface CustomerListItem {
  id: number;
  name: string;
}

export const customersApi = {
  filterCustomers: (request: FilterCustomerRequest) =>
    httpClient.post<BrandsDto[]>('customers/filter', { data: request }),
  getConcepts: (customerId: number) =>
    httpClient.get<Concept[]>(`customers/${customerId}/concepts`, {
      params: { includeArticles: false },
    }),
  createCustomer: (request: CreateCustomerRequest) =>
    httpClient.post<Customer>(`organizations/3/customers`, {
      data: request,
    }),
  getAll: () => httpClient.get<CustomerListItem[]>(`organizations/3/customers`),
};
