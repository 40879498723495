import { useEffect } from 'react';
import { SignalDispatcher } from 'ste-signals';

interface Props {
  triggerOnEvent: () => void;
  signalEvents: SignalDispatcher[];
}

export function useSignalSubscriptionEvents({
  triggerOnEvent,
  signalEvents,
}: Props) {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const subEvents: Function[] = [];

    signalEvents.forEach((e) => {
      subEvents.push(e.sub(triggerOnEvent));
    });

    return () => {
      for (let i = 0; i < subEvents.length; i++) {
        subEvents[i]();
      }
    };
  }, [signalEvents, triggerOnEvent]);
}
