import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Box,
  Text,
  FormControl,
  Flex,
  ModalOverlay,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Tabs,
  Heading,
  Collapse,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { cottonTcx } from '@texas/resources/colors/tcx';
import { paperTpg } from '@texas/resources/colors/tpg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorBox } from './ColorBox';
import { useRegexSearch } from '@texas/components/shared/hooks/useRegexSearch';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { ColorData, ColorType } from '@texas/api/endpoints/compositionApi';
import { rudholmMatrixColors } from '@texas/resources/colors/rudholmColors';
import { CustomColorPicker } from './CustomColorPicker';
import { RecommendedColors } from './RecommendedColors';
import { ensureEnumNumber } from '@texas/utils/helpers/enumHelpers';
import { ColorGrid } from './ColorGrid';

export interface Color {
  code: string;
  name: string;
  hex: string | null;
}

export interface ColorWithType extends Color {
  colorType: ColorType;
}

interface Props extends SharedDisclosureProps {
  onSelect: (colors: ColorData[]) => void;
  addedColors: ColorData[];
  matrixNodeValue?: string;
}

export function ColorPicker({
  onSelect,
  matrixNodeValue,
  isOpen,
  onClose,
  addedColors,
}: Props) {
  return (
    <Modal size="6xl" onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <InnerContent
        matrixNodeValue={matrixNodeValue}
        onSelect={onSelect}
        addedColors={addedColors}
      />
    </Modal>
  );
}

function InnerContent({
  addedColors,
  matrixNodeValue,
  onSelect,
}: {
  addedColors: ColorData[];
  matrixNodeValue?: string;
  onSelect: (colors: ColorData[]) => void;
}) {
  const { t } = useTranslation();
  const { search, setSearch, regexSearch } = useRegexSearch();
  const [pickedColors, setPickedColors] = useState<ColorData[]>(addedColors);

  return (
    <ModalContent>
      <ModalCloseButton />
      <Flex>
        <ModalBody bg="texas.bg.800" _light={{ bg: 'gray.50' }} flex={2}>
          <Heading textTransform="none" fontSize="3xl">
            {t('colors.colorPicker')}
          </Heading>
          <FormControl pb={2}>
            <SearchFilterInput
              debounceDelay={0}
              autoFocus={true}
              placeholder={t('colors.searchColor')}
              value={search}
              onChange={(s) => setSearch(s)}
            />
          </FormControl>
          <Tabs variant="composition" defaultIndex={matrixNodeValue ? 2 : 0}>
            <TabList gap={2}>
              <Tab>{t('colors.tcx')}</Tab>
              <Tab>{t('colors.tpg')}</Tab>
              <Tab>{t('colors.rudholmColors')}</Tab>
              <Tab>{t('colors.customColor')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ColorGrid
                  columns={4}
                  searchTerm={regexSearch}
                  colors={cottonTcx.colors}
                  pickedColors={pickedColors.map((x) => x.code)}
                  colorPicked={(code, hex, name) => {
                    if (pickedColors.some((x) => x.code === code)) {
                      setPickedColors((s) => s.filter((p) => p.code !== code));
                      return;
                    }
                    setPickedColors((s) => [
                      ...s,
                      {
                        colorType: ColorType.PantoneTcx,
                        description: '',
                        code,
                        hex,
                        name,
                      },
                    ]);
                  }}
                />
              </TabPanel>
              <TabPanel>
                <ColorGrid
                  columns={4}
                  searchTerm={regexSearch}
                  colors={paperTpg.colors}
                  pickedColors={pickedColors.map((x) => x.code)}
                  colorPicked={(code, hex, name) => {
                    if (pickedColors.some((x) => x.code === code)) {
                      setPickedColors((s) => s.filter((p) => p.code !== code));
                      return;
                    }
                    setPickedColors((s) => [
                      ...s,
                      {
                        colorType: ColorType.PantoneTpg,
                        description: '',
                        code,
                        hex,
                        name,
                      },
                    ]);
                  }}
                />
              </TabPanel>
              <TabPanel>
                <ColorGrid
                  columns={10}
                  searchTerm={regexSearch}
                  colors={rudholmMatrixColors.colors}
                  pickedColors={pickedColors.map((x) => x.code)}
                  colorPicked={(code, hex, name) => {
                    if (pickedColors.some((x) => x.code === code)) {
                      setPickedColors((s) => s.filter((p) => p.code !== code));
                      return;
                    }
                    setPickedColors((s) => [
                      ...s,
                      {
                        colorType: ColorType.RudholmMatrix,
                        description: '',
                        code,
                        hex,
                        name,
                      },
                    ]);
                  }}
                />
              </TabPanel>
              <TabPanel>
                <CustomColorPicker
                  onAdd={(c) => setPickedColors((p) => [...p, c])}
                  pickedColors={pickedColors}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalBody as={Flex} flexDir="column" justifyContent="end" w="30%">
          <Box mb="auto" mt={12}>
            <RecommendedColors
              matrixNodeValue={matrixNodeValue}
              pickedColors={pickedColors}
              colorPicked={(color) => setPickedColors((s) => [...s, color])}
            />
          </Box>
          <Flex flexDir="column" gap={2}>
            {pickedColors.length === 0 ? (
              <Text variant="sub">{t('colors.noSelected')}</Text>
            ) : (
              <Heading fontSize="md">{t('colors.picked')}</Heading>
            )}
            <Collapse in={pickedColors.some((x) => x.hex?.length === 0)}>
              <Text color="gray.300" fontSize="sm">
                {t('colors.colorRepresentationDescription')}
              </Text>
            </Collapse>
            {pickedColors.map((p) => {
              return (
                <ColorBox
                  key={p.code}
                  color={{ name: p.name, code: p.code, hex: p.hex }}
                  colorDescription={p.description}
                  fallbackDefaultValueHex={getClosestRudholmColor(p)}
                  onColorChange={
                    ensureEnumNumber(ColorType, p.colorType) !==
                      ColorType.PantoneTcx &&
                    ensureEnumNumber(ColorType, p.colorType) !==
                      ColorType.PantoneTpg
                      ? (e) =>
                          setPickedColors((s) =>
                            s.map((x) => {
                              if (x.code === p.code) {
                                return { ...x, hex: e };
                              }
                              return x;
                            }),
                          )
                      : undefined
                  }
                  onRemove={() =>
                    setPickedColors((s) => s.filter((x) => x.code !== p.code))
                  }
                  onEdit={(d) =>
                    setPickedColors((s) =>
                      s.map((x) => {
                        if (x.code === p.code) {
                          return { ...x, description: d };
                        }
                        return x;
                      }),
                    )
                  }
                />
              );
            })}

            <Button
              minW="auto"
              onClick={() => {
                const selectedColors: ColorData[] = [];

                for (let i = 0; i < pickedColors.length; i++) {
                  selectedColors.push();
                }

                onSelect(
                  pickedColors.map((x) => ({
                    code: x.code,
                    description: x.description,
                    hex: x.hex,
                    name: x.name,
                    colorType: x.colorType,
                  })),
                );
              }}
            >
              {t('colors.selectColors')}
            </Button>
          </Flex>
        </ModalBody>
      </Flex>
    </ModalContent>
  );
}

function getClosestRudholmColor(color: ColorWithType) {
  if (color.colorType !== ColorType.RudholmMatrix) return undefined;

  let index = rudholmMatrixColors.colors.findIndex(
    (x) => x.code === color.code,
  );

  let iterations = 1;
  while (rudholmMatrixColors.colors[index].hex.length === 0) {
    if (!rudholmMatrixColors.colors[index].searchClosest) return undefined;

    const delta = (iterations % 2) * 2 - 1;
    index = index + iterations * delta;

    iterations++;
  }

  return rudholmMatrixColors.colors[index].hex;
}

// Since we do have saved colors in the database without a type, this function
// is needed for type lookup
export function GetColorWithType(color: Color): ColorWithType {
  const rudholmColor = rudholmMatrixColors.colors.find(
    (x) => x.code === color.code,
  );

  if (rudholmColor) {
    return {
      name: rudholmColor.name,
      code: rudholmColor.code,
      hex: rudholmColor.hex,
      colorType: ColorType.RudholmMatrix,
    };
  }

  let pantone = paperTpg.colors.find((x) => x.code === color.code);

  if (pantone) {
    return {
      name: pantone.name,
      code: pantone.code,
      hex: pantone.hex,
      colorType: ColorType.PantoneTpg,
    };
  }

  pantone = cottonTcx.colors.find((x) => x.code === color.code);

  if (pantone) {
    return {
      name: pantone.name,
      code: pantone.code,
      hex: pantone.hex,
      colorType: ColorType.PantoneTcx,
    };
  }

  return {
    ...color,
    colorType: ColorType.Custom,
  };
}
