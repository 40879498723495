import { Badge, Flex } from '@chakra-ui/react';

import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { SearchArticlesGeneric } from '../../returnTypes';
import { SearchFilter } from '@texas/api/endpoints/search/searchApi';
import { SearchVariantsBase } from '@texas/api/endpoints/search/searchVariantsTypes';
import { ArticleProcess } from '@texas/api/endpoints/variantsApi';
import { ArticleState } from '@texas/api/endpoints/articlesApi';

export function ArticlesPrinterFilter<T extends SearchVariantsBase>({
  search,
}: {
  search: SearchArticlesGeneric<T>;
}) {
  const { articles, searchParamsRef } = search;
  const { t } = useTranslation();

  return (
    <Flex flexWrap="wrap" gap={1}>
      <PrintFilters filters={articles?.branchFilters} />
      <PrintFilters filters={articles?.conceptFilters} />
      <PrintFilters filters={articles?.customerFilters} />
      <PrintFilters filters={articles?.materialFilters} />
      <PrintFilters filters={articles?.categoryCodeFilters} />
      <PrintFilters filters={articles?.productGroupFilters} />
      <PrintFilters
        filters={searchParamsRef.current.processes?.map<SearchFilter>((x) => ({
          name: ArticleProcess[x],
          id: x,
        }))}
      />
      <PrintFilters
        filters={searchParamsRef.current.states?.map<SearchFilter>((x) => ({
          name: ArticleState[x],
          id: x,
        }))}
      />
      <PrintFilters
        filters={
          searchParamsRef.current.showMyBrands
            ? [{ name: t('filter.myBrands'), id: 0 }]
            : undefined
        }
      />
      <PrintFilters
        filters={
          searchParamsRef.current.includeArchived
            ? [{ name: t('filter.archivedIncluded'), id: 0 }]
            : undefined
        }
      />
      <PrintFilters
        filters={
          searchParamsRef.current.showAllVariants
            ? [{ name: t('filter.allVariants'), id: 0 }]
            : undefined
        }
      />
      <PrintFilters
        filters={
          searchParamsRef.current.searchTerm
            ? [{ name: searchParamsRef.current.searchTerm, id: 0 }]
            : undefined
        }
      />
    </Flex>
  );
}

function PrintFilters({ filters }: { filters?: SearchFilter[] }) {
  if (!filters) return null;
  return (
    <>
      {filters.map((f) => (
        <Badge animation={fadeInScaleAnimation()} key={f.id}>
          {f.name}
        </Badge>
      ))}
    </>
  );
}
