import { Flex, Spinner, Text } from '@chakra-ui/react';

export function SpinnerWithLabel({ label }: { label: string }) {
  return (
    <Flex
      w="fit-content"
      gap={2}
      align="center"
      justify="center"
      bg="texas.bg.900"
      p={2}
      borderRadius="md"
      _light={{ bg: 'gray.50' }}
    >
      <Spinner />
      <Text>{label}</Text>
    </Flex>
  );
}
