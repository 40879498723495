import { useEffect, useState } from 'react';
import { angularEvents } from 'src/bridge/angularEvents';
import { AddSupplier } from './add/AddSupplier';
import { reactEvents } from 'src/bridge/reactEvents';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { purchaseInformationApi } from '@texas/api/endpoints/purchaseInformationApi';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function AngularAddSupplier() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  const getPurchaseInformation = useApiRequest(
    purchaseInformationApi.getPurchaseInformation,
  );
  const toast = useToast();
  const [addSupplierData, setAddSupplierData] = useState<{
    branchId: number;
    variantId: number;
    branchName: string;
  } | null>(null);
  useEffect(() => {
    return angularEvents.addSupplierClicked.subscribe((data) => {
      setAddSupplierData({
        branchId: data.branchId,
        variantId: data.variantId,
        branchName: data.branchName,
      });
      onOpen();
    });
  }, [onOpen]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="sm">
      <DrawerOverlay />

      <DrawerContent bg="texas.bg.900" _light={{ bg: 'gray.10' }}>
        {addSupplierData && (
          <AddSupplier
            variantId={addSupplierData.variantId}
            branchId={addSupplierData.branchId}
            branchName={addSupplierData.branchName}
            onAdd={async (data) => {
              const purchaseInfo = await getPurchaseInformation.request(
                data.variantId,
                data.branchId,
                data.supplierId,
              );
              if (purchaseInfo.hasError) {
                toast({
                  title: t('variant.purchase.errorFetchingPurchaseInfo'),
                  status: 'error',
                  description: purchaseInfo.error.message,
                  isClosable: true,
                });
                return;
              }
              reactEvents.supplierAdded.dispatch(purchaseInfo.data);
            }}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
}
1;
