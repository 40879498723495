import { reactEvents } from '@bridge/reactEvents';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { ArticleAxisInfo, articlesApi } from '@texas/api/endpoints/articlesApi';
import { MatrixAxis } from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import {
  changeColorsAnimation,
  fadeInBottomAnimation,
  fadeInRightAnimation,
  moveDownUpAnimation,
  moveRightLeftAnimation,
  stretchAnimation,
} from '@texas/resources/animations/animations';
import {
  ensureEnumNumber,
  getEnumNamesAndValues,
} from '@texas/utils/helpers/enumHelpers';
import { request } from '@texas/utils/helpers/httpHelpers';
import { formatSingleNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  variantId: number;
  children: React.ReactNode;
}

export function MatrixAxisGuard({ variantId, children }: Props) {
  const { data, refetch, loading, set } = useApiResource(
    variantsApi.getAxisInfo,
  );

  useEffect(() => {
    refetch(variantId);
  }, [variantId, refetch]);

  if (loading) return <Spinner />;

  if (data && !data.colorAxis) {
    return <InnerComponent info={data} onUpdate={set} />;
  }

  return <>{children}</>;
}

function InnerComponent({
  info,
  onUpdate,
}: {
  info: ArticleAxisInfo;
  onUpdate: (data: ArticleAxisInfo) => void;
}) {
  const { t } = useTranslation();
  const { request: setColorAxisRequest } = useApiRequest(
    articlesApi.setColorAxis,
  );

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm<{ axis: MatrixAxis }>({ defaultValues: { axis: undefined } });

  const submit = async (data: { axis: MatrixAxis }) =>
    await request(
      setColorAxisRequest,
      [info.articleId, data.axis],
      (response: ArticleAxisInfo) => {
        onUpdate(response);
        const value = (
          ensureEnumNumber(MatrixAxis, response.colorAxis) ?? -1
        ).toString();
        reactEvents.onMatrixColorAxisUpdate.dispatch(parseInt(value));
      },
    );

  return (
    <Flex flexDir="column" gap={2}>
      <Heading animation={fadeInRightAnimation()}>
        {t('matrix.missingAxis')}
      </Heading>
      <Text animation={fadeInBottomAnimation()}>
        {t('matrix.missingAxisDesc')}
      </Text>
      <Box w="fit-content" pt={6}>
        <form onSubmit={handleSubmit(submit)}>
          <FormControl>
            <TexasFormLabel>{t('matrix.guardColors')}</TexasFormLabel>
            <Controller
              name="axis"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TexasSelect
                  {...field}
                  value={convertToOption(field.value)}
                  onChange={(e) => field.onChange(e?.value)}
                  placeholder={t('matrix.selectAxis')}
                  options={getEnumNamesAndValues(MatrixAxis).map((s) => {
                    return {
                      label: axisFriendlyName(s.value as MatrixAxis),
                      value: s.value,
                    };
                  })}
                />
              )}
            />
            <ErrorLabel text={errors.axis?.message} />
          </FormControl>
        </form>
      </Box>
      <Grid
        overflow="auto"
        bg="texas.bg.900"
        borderRadius="md"
        padding={4}
        gridTemplateRows={`42px 100px repeat(${
          info.nodeYValues.length + 2
        }, auto)`}
        gridTemplateColumns={`42px 200px repeat(${
          info.nodeXValues.length + 2
        }, 150px)`}
        gridGap={2}
        _light={{ bg: 'gray.50' }}
      >
        <GridItem
          gridRow={1}
          gridColumnStart={3}
          gridColumnEnd={info.nodeYValues.length + 2}
        >
          <ColumnAxisInfo colorAxis={watch('axis')} />
        </GridItem>
        <GridItem
          gridRow={3}
          gridColumn={1}
          gridRowStart={3}
          gridRowEnd={info.nodeXValues.length + 2}
        >
          <RowAxisInfo colorAxis={watch('axis')} />
        </GridItem>
        {info.nodeYValues.map((y, i) => {
          return (
            <GridItem
              key={y.id}
              gridRow={3 + i}
              gridColumn={2}
              bg="texas.bg.800"
              _light={{ bg: 'gray.100' }}
              padding={2}
            >
              <Text>{formatSingleNodeGroup(y)}</Text>
            </GridItem>
          );
        })}
        {info.nodeXValues.map((x, i) => {
          return (
            <GridItem
              bg="texas.bg.800"
              padding={2}
              key={x.id}
              gridRow={2}
              gridColumn={3 + i}
              _light={{ bg: 'gray.100' }}
            >
              <Text>{formatSingleNodeGroup(x)}</Text>
            </GridItem>
          );
        })}
      </Grid>
      <Button
        isDisabled={!isValid || isSubmitting}
        isLoading={isSubmitting}
        mt={2}
        variant="texas-solid"
        w="fit-content"
        onClick={() => handleSubmit(submit)()}
      >
        {t('matrix.saveAxis')}
      </Button>
      <Text variant="sub" fontSize="sm">
        {t('general.canBeChangedLater')}
      </Text>
    </Flex>
  );
}

export function axisFriendlyName(option: MatrixAxis) {
  switch (option) {
    case MatrixAxis.Columns:
      return 'Columns';
    case MatrixAxis.Rows:
      return 'Rows';
  }
}

function convertToOption(option?: MatrixAxis) {
  if (option === MatrixAxis.Columns) {
    return {
      value: MatrixAxis.Columns,
      label: 'Columns',
    };
  } else if (option === MatrixAxis.Rows) {
    return {
      value: MatrixAxis.Rows,
      label: 'Rows',
    };
  }

  return null;
}

function ColumnAxisInfo({ colorAxis }: { colorAxis?: MatrixAxis }) {
  const { t } = useTranslation();
  if (colorAxis === undefined) {
    return <Text variant="sub">{t('matrix.noAxis')}</Text>;
  }

  if (colorAxis === MatrixAxis.Columns) {
    return (
      <Flex gap={1} align="center">
        <Icons.PaletteSwatch
          animation={changeColorsAnimation(6000)}
          boxSize={defaultIconSize}
        />
        <Heading fontSize="xl">{t('general.colors')}</Heading>
        <Icons.ArrowRight
          animation={moveRightLeftAnimation()}
          boxSize={defaultIconSize}
        />
      </Flex>
    );
  }

  return (
    <Flex gap={1} align="center">
      <Icons.AxisArrow
        animation={stretchAnimation()}
        boxSize={defaultIconSize}
      />
      <Heading fontSize="xl">{t('general.sizes')}</Heading>
      <Icons.ArrowRight
        animation={moveRightLeftAnimation()}
        boxSize={defaultIconSize}
      />
    </Flex>
  );
}

function RowAxisInfo({ colorAxis }: { colorAxis?: MatrixAxis }) {
  const { t } = useTranslation();
  if (colorAxis === undefined) {
    return (
      <Text variant="sub" sx={{ writingMode: 'vertical-lr' }}>
        {t('matrix.noAxis')}
      </Text>
    );
  }

  if (colorAxis === MatrixAxis.Columns) {
    return (
      <Flex gap={1} align="center" flexDir="column">
        <Icons.AxisArrow
          animation={stretchAnimation()}
          boxSize={defaultIconSize}
        />
        <Heading sx={{ writingMode: 'vertical-lr' }} fontSize="xl">
          {t('general.sizes')}
        </Heading>
        <Icons.ArrowDown
          animation={moveDownUpAnimation()}
          boxSize={defaultIconSize}
        />
      </Flex>
    );
  }

  return (
    <Flex gap={1} align="center" flexDir="column">
      <Icons.PaletteSwatch
        animation={changeColorsAnimation()}
        boxSize={defaultIconSize}
      />
      <Heading sx={{ writingMode: 'vertical-lr' }} fontSize="xl">
        {t('general.colors')}
      </Heading>
      <Icons.ArrowDown
        animation={moveDownUpAnimation()}
        boxSize={defaultIconSize}
      />
    </Flex>
  );
}
