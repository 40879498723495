import {
  Flex,
  Button,
  ModalOverlay,
  ModalContent,
  Modal,
  ModalBody,
  Text,
  Divider,
} from '@chakra-ui/react';
import { CompositionGroupView } from './CompositionGroupView';
import { RefObject } from 'react';
import { SimpleNote } from '@texas/components/shared/SimpleNote';
import { useTranslation } from 'react-i18next';

export function ConfirmCopy({
  compositionGroupId,
  onConfirm,
  isOpen,
  title,
  buttonLabel,
  ref,
  onClose,
}: {
  compositionGroupId: number;
  onConfirm: (id: number) => void;
  isOpen: boolean;
  title: string;
  buttonLabel: string;
  ref?: RefObject<HTMLElement>;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      portalProps={{ containerRef: ref }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent width="auto" maxW="fit-content">
        <ModalBody>
          <Flex align="center" flexDir="column">
            <Text>{title}</Text>
            <SimpleNote color="yellow.400" _light={{ color: 'yellow.500' }}>
              <Text>{t('compositionGroup.copyNoEdit')}</Text>
            </SimpleNote>
          </Flex>
          <Divider py={2} />
          <Flex flexDir="column" gap={4}>
            <CompositionGroupView
              variantId={0}
              compositionGroupId={compositionGroupId}
              componentSettings={{
                compositionArchiveRestore: 'hidden',
                addMaterials: 'hidden',
                materialsMode: 'read-only',
                removeMaterials: 'hidden',
                editImage: 'hidden',
                characterEdit: 'hidden',
                dimensionMode: 'read-only',
                compositionGroupDots: 'hidden',
              }}
            />
            <Button onClick={() => onConfirm(compositionGroupId)}>
              {buttonLabel}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
