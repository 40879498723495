import { bridgeConverter } from '../../bridge/bridgeConverter';
import { SearchArticles } from '../../react/components/searchArticles/SearchArticles';
import { NavigationBar } from '../../react/components/navigationBar/NavigationBar';
import { Sales } from '../../react/components/article/variant/sales/Sales';
import { AvailableBranches } from '../../react/components/configuration/branches/AvailableBranches';
import { UserProfiles } from '../../react/components/users/keyAccountManager/UserProfiles';
import { ProjectContainer } from '../../react/components/customer/projects/ProjectContainer';
import { SaveAndSync } from '../../react/components/article/variant/saveAndSync/SaveAndSync';
import { Dashboard } from '../../react/components/dashboard/Dashboard';
import { Treatments } from '../../react/components/configuration/treatments/Treatments';
import { Customer } from '../../react/components/customer/Customer';
import { DimensionGroups } from '../../react/components/configuration/dimensionGroups/DimensionGroups';
import { VariantSuppliers } from '../../react/components/article/variant/suppliers/VariantSuppliers';
import { AngularAddSupplier } from '../../react/components/article/variant/suppliers/AngularAddSupplier';
import { Qualities } from '../../react/components/configuration/qualities/Qualities';
import { ProductGroups } from '../../react/components/configuration/productGroups/ProductGroups';
import { Materials } from '../../react/components/configuration/materials/Materials';
import { CustomerContacts } from '../../react/components/customer/contacts/Contacts';
import { General } from '../../react/components/article/variant/general/General';
import { Versions } from '../../react/components/article/variant/versions/Versions';
import { ProductApprovalLockedMessage } from '../../react/components/shared/productApproval/LockedMessage';
import { CreateProductApprovalButton } from '../../react/components/article/productApproval/create/CreateProductApprovalButton';
import { ProductApprovals } from '../../react/components/article/productApproval/ProductApprovals';
import { NewConcept } from '../../react/components/customer/newConcept/NewConcept';
import { NewSupplier } from '../../react/components/suppliers/newSupplier/newSupplier';
import { CreateHandlingFee } from '../../react/components/configuration/handlingsFees/CreateHandlingFee';
import { NewCustomer } from '../../react/components/customer/newCustomer/NewCustomer';
import { FileBrowserTab } from '../../react/components/shared/files/FileBrowserTab';
import { MatrixCellPhoto } from '../../react/components/article/variant/matrix/MatrixCellPhoto';
import { Login } from '../../react/components/login/Login';
import { ResetPassword } from '../../react/components/login/ResetPassword';
import { Users } from '../../react/components/users/Users';
import { Overview } from '../../react/components/article/variant/overview/Overview';
import { VariantOptions } from '../../react/components/article/variant/options/VariantOptions';
import { AngularContactsModal } from '../../react/components/customer/contacts/AngularContactsModal';
import { CreateFreightCost } from '../../react/components/configuration/freightCost/CreateFreightCost';
import { Suppliers } from '../../react/components/suppliers/Suppliers';
import { SupplierContacts } from '../../react/components/suppliers/contacts/Contacts';
import { ArticleProductDevelopers } from '../../react/components/article/manage/ArticleProductDevelopers';

angular
  .module('app.bridgeComponents', [])
  .component('searchArticles', bridgeConverter(SearchArticles, []))
  .component(
    'customerContacts',
    bridgeConverter(CustomerContacts, ['customerId']),
  )
  .component(
    'sales',
    bridgeConverter(Sales, [
      'articleId',
      'variantId',
      'customerId',
      'articleNodeGroup',
    ]),
  )
  .component('availableBranches', bridgeConverter(AvailableBranches, []))
  .component('users', bridgeConverter(Users, []))
  .component('userProfiles', bridgeConverter(UserProfiles, ['userId']))
  .component('navBar', bridgeConverter(NavigationBar, []))
  .component(
    'angularContactsModal',
    bridgeConverter(AngularContactsModal, ['customerId', 'customerName']),
  )
  .component(
    'saveAndSync',
    bridgeConverter(SaveAndSync, [
      'variantId',
      'isMain',
      'articleId',
      'syncedVariantId',
    ]),
  )
  .component(
    'createProductApproval',
    bridgeConverter(CreateProductApprovalButton, [
      'variantId',
      'articleNodeGroups',
      'customerId',
    ]),
  )
  .component(
    'projectContainer',
    bridgeConverter(ProjectContainer, [
      'customerId',
      'brandName',
      'identifier',
      'keyAccountManagerId',
      'customerArchived',
    ]),
  )
  .component('createFreightCost', bridgeConverter(CreateFreightCost, []))
  .component('treatments', bridgeConverter(Treatments, []))
  .component('variantOverview', bridgeConverter(Overview, ['articleNodeGroup']))
  .component(
    'variantOptions',
    bridgeConverter(VariantOptions, ['variantId', 'articleNodeGroup']),
  )
  .component('customer', bridgeConverter(Customer, ['customerId', 'conceptId']))
  .component(
    'variantSuppliers',
    bridgeConverter(VariantSuppliers, ['variantId']),
  )
  .component('angularAddSupplier', bridgeConverter(AngularAddSupplier, []))
  .component('qualities', bridgeConverter(Qualities, []))
  .component('createHandlingFee', bridgeConverter(CreateHandlingFee, []))
  .component('productGroups', bridgeConverter(ProductGroups, []))
  .component('dimensionGroups', bridgeConverter(DimensionGroups, ['folderId']))
  .component('materials', bridgeConverter(Materials, []))
  .component('dashboard', bridgeConverter(Dashboard, []))
  .component('newSupplier', bridgeConverter(NewSupplier, []))
  .component('general', bridgeConverter(General, ['id']))
  .component('login', bridgeConverter(Login, []))
  .component('resetPassword', bridgeConverter(ResetPassword, []))
  .component(
    'versions',
    bridgeConverter(Versions, ['variantId', 'articleNodeGroup']),
  )
  .component(
    'lockedMessage',
    bridgeConverter(ProductApprovalLockedMessage, [
      'variantId',
      'branchId',
      'matrixNodeXId',
      'matrixNodeYId',
      'isOnlyInfo',
    ]),
  )
  .component('newConcept', bridgeConverter(NewConcept, ['customerId']))
  .component(
    'productApproval',
    bridgeConverter(ProductApprovals, ['articleId']),
  )
  .component('newCustomer', bridgeConverter(NewCustomer, []))
  .component(
    'fileBrowserTab',
    bridgeConverter(FileBrowserTab, ['rootFolderId', 'isArticle']),
  )
  .component(
    'matrixCellPhoto',
    bridgeConverter(MatrixCellPhoto, ['matrixValue', 'variantId', 'folderId']),
  )
  .component('suppliers', bridgeConverter(Suppliers, []))
  .component(
    'supplierContacts',
    bridgeConverter(SupplierContacts, ['supplierId']),
  )
  .component(
    'articleProductDevelopers',
    bridgeConverter(ArticleProductDevelopers, ['articleId']),
  );
