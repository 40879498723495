import { CompositionMaterial } from '@texas/api/endpoints/compositionApi';

export function getRoundedPurchasePriceWithFees(
  price: number,
  freightCost: number,
  handlingFee: number,
  decimalsInPrice: number,
) {
  if (handlingFee) {
    price += (handlingFee / 100) * price;
  }
  if (freightCost) {
    price += (freightCost / 100) * price;
  }

  return round(price, decimalsInPrice);
}

export function exchangeRateCalculation(
  price: number,
  baseExchangeRate: number,
  targetExchangeRate: number,
  numberOfDecimals?: number,
) {
  const newPrice = (price * baseExchangeRate) / targetExchangeRate;
  if (numberOfDecimals) {
    return round(newPrice, numberOfDecimals);
  }

  return newPrice;
}

export function round(num: number, decimals: number) {
  const n = Math.pow(10, decimals);
  return Math.round(parseFloat((n * num).toFixed(decimals))) / n;
}

export function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(value, max));
}

export function multipliedBy(num1: number, num2: number) {
  const value = round(num1 / num2, 3);
  return isNaN(value) ? 0 : value;
}
export function marginBy(num1: number, num2: number, ceilToZero: boolean) {
  const value = round(
    ((num1 - num2) / Math.abs(num1 === 0 ? 1 : num1)) * 100,
    3,
  );

  if (isNaN(value)) return 0;

  if (ceilToZero && num1 <= 0) return value < 0 ? 0 : value;

  return value;
}

export function totalMaterialWeights(materials: CompositionMaterial[]) {
  let total = 0;

  for (let i = 0; i < materials.length; i++) {
    total += materials[i].weight;
  }

  return total;
}
