import {
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Menu,
  Button,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { createContext } from 'react';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { CompositionGroupView } from '../../compositions/shared/group/CompositionGroupView';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';
import { VariantBranchSupplier } from '@texas/api/endpoints/variantsApi';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { CompositionGroupTitle } from '@texas/components/shared/compositionGroup/CompositionGroupTitle';
import { SpinnerWithLabel } from '@texas/components/shared/SpinnerWithLabel';
import { useOptionsOverview } from '../../options/standard/useOptionsOverview';

interface Props {
  variantId: number;
  suppliers: VariantBranchSupplier[];
}

export const OptionsContext = createContext<ReturnType<
  typeof useOptionsOverview
> | null>(null);

export function OptionsOverview({ variantId, suppliers }: Props) {
  const options = useOptionsOverview({
    variantId,
  });
  const { t } = useTranslation();
  const { value, isOpen, onClose, onOpen } = useValueDisclosure<number>();

  useSignalSubscriptionEvents({
    triggerOnEvent: onClose,
    signalEvents: [
      compositionGroupEvents.onArchived,
      compositionGroupEvents.onRestored,
    ],
  });

  return (
    <OptionsContext.Provider value={options}>
      <Text variant="sub" pb={2}>
        {t('general.options')}
      </Text>
      <Flex gap={2}>
        {options.versionsLoading && (
          <SpinnerWithLabel label={t('compositionGroup.option.loading')} />
        )}
        {options.versions?.length === 0 && (
          <Text variant="sub">{t('compositionGroup.option.noOptions')}</Text>
        )}
        {options.versions?.map((x) => (
          <Menu key={x.id}>
            <MenuButton
              as={Button}
              rightIcon={<Icons.ChevronDown />}
              h="auto"
              borderRadius="md"
              textAlign="start"
              py={2}
              isDisabled={!x.isReady}
            >
              <Flex flexDir="column" gap={2}>
                <CompositionGroupTitle
                  version={x.version}
                  note={x.note}
                  type="option"
                  fontSize="md"
                />
                {!x.isReady && (
                  <Text
                    fontSize="sm"
                    fontWeight="normal"
                    color="yellow.400"
                    _light={{ color: 'yellow.500' }}
                  >
                    {t('compositionGroup.option.notYetReady')}
                  </Text>
                )}
              </Flex>
            </MenuButton>

            <MenuList>
              <MenuItem
                icon={<Icons.OpenInNew boxSize={defaultIconSize} />}
                onClick={() => onOpen(x.id)}
              >
                {t('compositionGroup.option.preview')}
              </MenuItem>
              <MenuDivider />
              {suppliers.map((s) => {
                return (
                  <MenuItem
                    key={s.supplierId}
                    icon={<Icons.Plus boxSize={defaultIconSize} />}
                    onClick={() =>
                      options.copyGroup({
                        fromId: x.id,
                        location: {
                          variantId: x.variantId,
                          supplierId: s.supplierId,
                          branchId: s.branchId,
                        },
                        basedOnId: x.id,
                      })
                    }
                  >
                    {t('compositionGroup.option.addToSupplier', {
                      name: s.supplierName,
                    })}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        ))}
      </Flex>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="auto" maxW="fit-content">
          <ModalBody>{value && <InnerModal id={value} />}</ModalBody>
        </ModalContent>
      </Modal>
    </OptionsContext.Provider>
  );
}

function InnerModal({ id }: { id: number }) {
  return (
    <>
      <CompositionGroupView
        variantId={0}
        compositionGroupId={id}
        componentSettings={{
          expandedDefault: true,
          addMaterials: 'hidden',
          materialsMode: 'read-only',
          removeMaterials: 'hidden',
          editImage: 'hidden',
          characterEdit: 'hidden',
          dimensionMode: 'read-only',
          compositionArchiveRestore: 'hidden',
          compositionGroupDots: 'hidden',
        }}
      />
    </>
  );
}
