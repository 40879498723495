import { httpClient } from '@texas/api/httpClient/httpClient';
import { SearchQuery } from './types';
import { SimpleFileLink } from '../filesApi';

export enum Metric {
  Millimeters = 1,
  Centimeters = 2,
  Inch = 3,
  Meters = 4,
  Ligne = 5,
  Micron = 6,
  Mil = 7,
}

export interface Dimension {
  id: number;
  name: string;
  identifier: string;
  displayOrder: number;
}

export interface DimensionGroup {
  id: number;
  name: string;
  description: string;
  file: SimpleFileLink | null;
  linkedProductGroupIds: number[];
  dimensions: Dimension[];
  archived: string | null;
}

export interface DimensionGroupListItem {
  id: number;
  name: string;
  description: string;
  dimensions: string[];
  archived: string | null;
}

export interface UpdateDimensionGroupRequest {
  name: string;
  description: string | undefined;
  dimensions: { id: number; name: string; identifier: string }[];
}

export interface CreateDimensionGroupRequest {
  name: string;
  description: string | undefined;
  fileId: number | null;
  dimensions: { name: string; identifier: string }[];
}

export const dimensionGroupsApi = {
  get: (id: number) =>
    httpClient.get<DimensionGroup>(`metadata/dimensiongroups/${id}`),
  getAll: () =>
    httpClient.get<DimensionGroup[]>(`metadata/dimensiongroups/all`),
  search: (params: SearchQuery) =>
    httpClient.get<DimensionGroupListItem[]>(
      `metadata/dimensiongroups/search`,
      {
        params,
      },
    ),
  create: (request: CreateDimensionGroupRequest) =>
    httpClient.post<DimensionGroup>(`metadata/dimensiongroups`, {
      data: request,
    }),
  update: (id: number, request: UpdateDimensionGroupRequest) =>
    httpClient.put<DimensionGroup>(`metadata/dimensiongroups/${id}`, {
      data: request,
    }),
  archive: (id: number) =>
    httpClient.put(`metadata/dimensiongroups/${id}/archive`),
  restore: (id: number) =>
    httpClient.put(`metadata/dimensiongroups/${id}/restore`),
  updateFile: (id: number, fildeId: number) =>
    httpClient.put(`metadata/dimensiongroups/${id}/file/${fildeId}`),
  deleteFile: (id: number) =>
    httpClient.delete(`metadata/dimensiongroups/${id}/file`),
};
