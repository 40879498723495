import { UserType, Role } from '@texas/api/endpoints/userApi';
import { ReactSelectOption } from '@texas/types';
import {
  roleFriendlyName,
  userTypeFriendlyName,
} from '@texas/utils/helpers/roleHelpers';
import { TFunction } from 'i18next';

export function getUserTypeOption(
  userType: UserType | null,
  t: TFunction,
): ReactSelectOption | null {
  if (!userType) return null;

  return {
    label: userTypeFriendlyName(userType, t),
    value: userType,
  };
}

export function getRoleOption(
  role: Role | null,
  t: TFunction,
): ReactSelectOption | null {
  if (!role) return null;

  return {
    label: roleFriendlyName(role, t),
    value: role,
  };
}
