import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Button,
  FormControl,
  Input,
  Box,
  Flex,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { convertToOption, findOptionFromProfiles } from './shared';
import {
  Project,
  UpdateProjectRequest,
  projectApi,
} from '@texas/api/endpoints/projectsApi';
import {
  userProfilesApi,
  UserProfileType,
} from '@texas/api/endpoints/userProfilesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useActiveSession } from '@texas/hooks/useSession';
import { ServerError, ReactSelectOption } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';
import { hasRole } from '@texas/utils/helpers/roleHelpers';
import { Role } from '@texas/api/endpoints/userApi';

export interface EditProjectModalProps extends SharedDisclosureProps {
  userId: number;
  onProjectUpdated: (project: Project) => void;
  project: Project;
}

export function EditProjectModal(props: EditProjectModalProps) {
  const { data: keyAccountManagers, refetch } = useApiResource(
    userProfilesApi.getUserProfilesWithType,
  );

  const session = useActiveSession();

  const toast = useToast();
  const { t } = useTranslation();
  const { request: updateProjectRequest, error: updateError } = useApiRequest(
    projectApi.updateProject,
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<UpdateProjectRequest>({
    defaultValues: {
      name: props.project.name,
      userProfileId: props.project.keyAccountManager?.id,
    },
  });

  const onSubmit = async (data: UpdateProjectRequest) =>
    await request(
      updateProjectRequest,
      [props.project.id, data],
      (data: Project) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        props.onProjectUpdated(data);
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  useEffect(() => {
    refetch(UserProfileType.KeyAccountManager, false);
  }, [props.userId, refetch]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('project.editProject')}</ModalHeader>
        <ModalCloseButton />
        {updateError && <ErrorDetails error={updateError} />}
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <ModalBody>
            <Flex direction="column" gap={4} align="start">
              <FormControl>
                <TexasFormLabel>{t('project.area')}</TexasFormLabel>
                <Controller
                  name="userProfileId"
                  control={control}
                  render={({ field }) => (
                    <TexasSelect
                      {...field}
                      value={findOptionFromProfiles(
                        field.value,
                        keyAccountManagers ?? [],
                      )}
                      onChange={(value) => field.onChange(value?.value ?? null)}
                      isClearable={true}
                      options={
                        keyAccountManagers?.map<ReactSelectOption>((u) =>
                          convertToOption(u),
                        ) ?? []
                      }
                    />
                  )}
                />
              </FormControl>
              <Box>
                {keyAccountManagers
                  ?.filter((s) => s.userId === props.userId)
                  .map((u) => (
                    <Button
                      mr={2}
                      mb={2}
                      display="inline-block"
                      variant="texas-solid"
                      size="xs"
                      key={u.id}
                      onClick={() => setValue('userProfileId', u.id)}
                    >
                      {u.name}
                    </Button>
                  ))}
              </Box>
              <FormControl isRequired={true} isInvalid={!!errors.name}>
                <TexasFormLabel>{t('project.projectName')}</TexasFormLabel>
                <Input
                  variant="outline"
                  {...register('name', {
                    required: true,
                    maxLength: {
                      value: 25,
                      message: t('errors.maxLength', { count: 25 }),
                    },
                  })}
                  placeholder="Name"
                  disabled={
                    !hasRole(session.currentUser.role, [
                      Role.InternalAdministrator,
                      Role.SystemAdministrator,
                    ])
                  }
                />
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <SubmitButton loading={isSubmitting}>
              {t('general.update')}
            </SubmitButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
