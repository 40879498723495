import { Flex } from '@chakra-ui/react';
import { CompositionSupplier } from '@texas/api/endpoints/compositionGroup/compositionGroupMatrixApi';
import { CountIcon } from '@texas/components/shared/CountIcon';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { useTranslation } from 'react-i18next';

export function SupplierOverview({
  supplier,
}: {
  supplier?: CompositionSupplier;
}) {
  const { t } = useTranslation();
  const activeVersions = getTotalActiveVersions(supplier);
  const activePas = getTotalPendingPas(supplier);
  const acceptedPas = getTotalAcceptedPas(supplier);

  return (
    <Flex gap={3}>
      <CountIcon
        icon={<Icons.Layers boxSize={defaultIconSize} />}
        count={activeVersions}
        tooltip={t('compositionGroup.overview.activeVersions')}
      />
      <CountIcon
        icon={<Icons.Eye boxSize={defaultIconSize} />}
        count={activePas}
        tooltip={t('compositionGroup.overview.activePa')}
      />
      <CountIcon
        icon={<Icons.EyeCheck boxSize={defaultIconSize} />}
        count={acceptedPas}
        tooltip={t('compositionGroup.overview.acceptedPa')}
      />
    </Flex>
  );
}

function getTotalActiveVersions(supplier?: CompositionSupplier) {
  if (!supplier) return 0;

  let addedCells = 0;

  supplier.cells.forEach((c) => {
    addedCells += c.versions.length;
  });

  return addedCells;
}

function getTotalPendingPas(supplier?: CompositionSupplier) {
  if (!supplier) return 0;

  let addedCells = 0;

  supplier.cells.forEach((c) => {
    c.versions.forEach((v) => {
      addedCells += v.acceptedPas.length + v.pendingPas.length;
    });
  });

  return addedCells;
}

function getTotalAcceptedPas(supplier?: CompositionSupplier) {
  if (!supplier) return 0;

  const addedCells: Record<string, boolean> = {};

  supplier.cells.forEach((c) => {
    if (c.versions.some((v) => v.acceptedPas.length > 0)) {
      const format = `${c.x}-${c.y}`;
      if (addedCells[format]) return;
      addedCells[format] = true;
    }
  });

  return Object.keys(addedCells).length;
}
