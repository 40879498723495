import { Box } from '@chakra-ui/react';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { StandardArticleOptions } from './standard/StandardArticleOptions';
import { MatrixArticleOptions } from './MatrixArticleOptions';

export function VariantOptions({
  variantId,
  articleNodeGroup,
}: {
  variantId: number;
  articleNodeGroup: ArticleNodeGroup;
}) {
  return (
    <BodyOverride>
      <Box mx="auto">
        {!nodeGroupIsPopulated(articleNodeGroup) ? (
          <StandardArticleOptions variantId={variantId} />
        ) : (
          <MatrixArticleOptions variantId={variantId} />
        )}
      </Box>
    </BodyOverride>
  );
}
