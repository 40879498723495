import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CompositionGroupView } from './CompositionGroupView';
import { useEffect } from 'react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroup/compositionGroupApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { CompositionLocationData } from '../hooks/useCompositionGroup';
import { compositionGroupEvents } from '@texas/components/shared/compositionGroup/events';
import { useSignalSubscriptionEvents } from '@texas/components/shared/hooks/useSignalSubscriptionEvents';

interface Props extends SharedDisclosureProps {
  locationData: CompositionLocationData;
}

export function PreviousCompositionGroupsModal({
  locationData,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const { data, refetch, loading } = useApiResource(
    compositionGroupApi.getAllPreviousGroupIds,
  );

  useEffect(() => {
    refetch({ ...locationData });
  }, [refetch, locationData]);

  useSignalSubscriptionEvents({
    triggerOnEvent: () => {
      refetch({ ...locationData });
    },
    signalEvents: [compositionGroupEvents.onRestored],
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {locationData.supplierId
            ? t('compositionGroup.version.archivedVersions')
            : t('compositionGroup.option.archivedOptions')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody _dark={{ bg: 'texas.bg.dark' }}>
          {loading && (
            <Flex>
              <Text mr={2}>{t('general.loading')}</Text>
              <Spinner />
            </Flex>
          )}
          {data?.length == 0 && (
            <Text py={6}>
              {locationData.supplierId
                ? t('compositionGroup.version.noArchivedVersions')
                : t('compositionGroup.option.noArchivedOptions')}
            </Text>
          )}
          <Flex overflowX="auto" gap={2}>
            {data?.map((x) => (
              <CompositionGroupView
                key={x.id}
                variantId={x.variantId}
                compositionGroupId={x.id}
                componentSettings={{
                  compositionArchiveRestore: 'hidden',
                  addMaterials: 'hidden',
                  materialsMode: 'read-only',
                  removeMaterials: 'hidden',
                  editImage: 'hidden',
                  characterEdit: 'hidden',
                  dimensionMode: 'read-only',
                }}
              />
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{t('general.close')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
