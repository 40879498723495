import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  Textarea,
  FormControl,
  Flex,
  useToast,
  Box,
  Spinner,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {
  productApprovalsApi,
  ProductApprovalState,
  ProductApprovalRejectReason,
  RejectPaRequest,
} from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { AutoGrowTextareaContainer } from '@texas/components/shared/form/AutoGrowTextareaContainer';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';
import { Icons } from '@texas/components/shared/Icons';
import { VerifyButton } from '@texas/components/shared/verifyButton/VerifyButton';
import { getEnumNamesAndValues } from '@texas/utils/helpers/enumHelpers';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getStateReasonOption, rejectReasonAsFriendlyName } from './shared';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ReactSelectOption, ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { ProductApprovalInfo } from './shared/ProductApprovalInfo';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { FormCounter } from '@texas/components/shared/form/FormCounter';
import { userApi } from '@texas/api/endpoints/userApi';

export function RejectModal({
  productApprovalId,
  onReject,
}: {
  productApprovalId: number;
  onReject: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip label={t('productApproval.rejectPaTooltip')}>
        <IconButton
          variant="texas-light"
          onClick={onOpen}
          size="sm"
          icon={<Icons.Cancel boxSize="4" />}
          aria-label={t('productApproval.rejectPaTooltip')}
        />
      </Tooltip>

      <Modal isCentered={true} size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalInner
            onReject={onReject}
            productApprovalId={productApprovalId}
            onClose={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

interface ModalInnerProps {
  productApprovalId: number;
  onReject: () => void;
  onClose: () => void;
}

function ModalInner({ productApprovalId, onClose, onReject }: ModalInnerProps) {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: productApproval,
    refetch: refetchProductApproval,
    error: productApprovalError,
    loading: productApprovalLoading,
  } = useApiResource(productApprovalsApi.get);

  const {
    data: users,
    refetch: refetchUsers,
    loading: loadingUsers,
  } = useApiResource(userApi.getUsers);

  const {
    request: rejectRequest,
    loading: rejectLoading,
    error: rejectError,
  } = useApiRequest(productApprovalsApi.rejectPa);

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<RejectPaRequest>({
    defaultValues: { note: '', reasons: [], assignedUserIds: [] },
  });

  const watchReasons = watch('reasons');
  const watchNote = watch('note');

  const onSubmit = async (data: RejectPaRequest) =>
    await request(
      rejectRequest,
      [productApprovalId, data],
      () => {
        toast({
          title: t('productApproval.rejected'),
          status: 'success',
          isClosable: true,
        });
        onReject();
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.actionFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  useEffect(() => {
    refetchProductApproval(productApprovalId);
    refetchUsers();
  }, [productApprovalId, refetchProductApproval, refetchUsers]);

  return (
    <>
      <ModalHeader
        borderBottom="1px solid"
        borderColor="texas.bg.700"
        _light={{ borderColor: 'gray.50', color: 'black' }}
      >
        {t('productApproval.reject')}
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        {productApprovalError && <ErrorDetails error={productApprovalError} />}
        {rejectError && <ErrorDetails error={rejectError} />}

        <Flex>
          <Box
            minW="50%"
            maxW="50%"
            p={4}
            bg="texas.bg.700"
            _light={{ bg: 'gray.50' }}
          >
            {productApprovalLoading && <Spinner />}
            {productApproval && (
              <>
                <Flex
                  animation={fadeInLeftAnimation(600)}
                  alignItems="center"
                  gap={1}
                >
                  <Text pb={2} fontFamily="Barlow" fontSize="xl">
                    {t('productApproval.willBeRejected')}
                  </Text>
                  <Icons.ArrowDownRight boxSize="4" />
                </Flex>
                <ProductApprovalInfo
                  pa={productApproval}
                  state={ProductApprovalState.Rejected}
                />
              </>
            )}
          </Box>

          <Flex flexDir="column" py="12" flexGrow={1} p={4} gap={2}>
            <FormControl
              isRequired={true}
              isInvalid={!!errors.reasons?.message}
            >
              <TexasFormLabel>
                {t('productApproval.reasonLabel')}
              </TexasFormLabel>
              <Controller
                name="reasons"
                control={control}
                rules={{
                  required: true,
                  minLength: {
                    value: 1,
                    message: t('productApproval.reasonsRequired', { count: 1 }),
                  },
                }}
                render={({ field }) => (
                  <TexasSelect
                    {...field}
                    isMulti={true}
                    value={field.value.map((x) => getStateReasonOption(t, x))}
                    onChange={(e) => field.onChange(e.map((x) => x.value))}
                    isClearable={true}
                    closeMenuOnSelect={false}
                    options={getEnumNamesAndValues(
                      ProductApprovalRejectReason,
                    ).map((s) => {
                      return {
                        label: rejectReasonAsFriendlyName(
                          t,
                          s.value as ProductApprovalRejectReason,
                        ),
                        value: s.value,
                      };
                    })}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <TexasFormLabel>{t('productApproval.notes')}</TexasFormLabel>
              <AutoGrowTextareaContainer value={watch('note')}>
                <Textarea
                  {...register('note', {
                    maxLength: {
                      value: 200,
                      message: t('errors.maxLength', { count: 200 }),
                    },
                  })}
                  minH="28"
                  height="auto"
                  padding={1}
                  width="100%"
                  borderRadius={3}
                  borderColor="gray"
                  _hover={{ borderColor: 'hsl(0, 0%, 70%)' }}
                />
              </AutoGrowTextareaContainer>
              <FormCounter length={watchNote.length} maxLength={200} />
              <ErrorLabel text={errors.note?.message} />
            </FormControl>
            <FormControl>
              <TexasFormLabel>
                {t('productApproval.assignedUsers')}
              </TexasFormLabel>
              <Controller
                name="assignedUserIds"
                control={control}
                render={({ field }) => (
                  <TexasSelect
                    {...field}
                    isLoading={loadingUsers}
                    isClearable={true}
                    closeMenuOnSelect={false}
                    isMulti={true}
                    value={field.value.map<ReactSelectOption>((x) => ({
                      label: users?.find((p) => p.id === x)?.name ?? '',
                      value: x,
                    }))}
                    onChange={(e) => field.onChange(e.map((x) => x.value))}
                    options={
                      users
                        ?.filter((u) => u.archived === false)
                        .map<ReactSelectOption>((x) => ({
                          value: x.id,
                          label: x.name,
                        })) ?? []
                    }
                  />
                )}
              />
              <Text pl={2} variant="small">
                {t('productApproval.taskInfo')}
              </Text>
            </FormControl>
            <Flex ml="auto" mt="auto">
              <Button mr={3} onClick={onClose}>
                {t('general.close')}
              </Button>
              <VerifyButton
                tooltipLabel={
                  watchReasons.length === 0
                    ? t('productApproval.missingReasons')
                    : undefined
                }
                buttonProps={{
                  isDisabled: !isValid,
                  isLoading: rejectLoading,
                  leftIcon: <Icons.Cancel boxSize={5} />,
                }}
                onVerified={() => handleSubmit(onSubmit)()}
                label={t('productApproval.rejectPa')}
              />
            </Flex>
          </Flex>
        </Flex>
      </form>
    </>
  );
}
