import {
  Tabs,
  TabList,
  Tab,
  Flex,
  TabPanels,
  TabPanel,
  Box,
  Divider,
  Heading,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { DragAndDrop } from '@texas/components/shared/dragAndDrop/DragAndDrop';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { nodeGroupTotal } from '@texas/utils/helpers/nodeGroupHelpers';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BranchVersionsOverview } from './BranchVersionsOverview';
import { SupplierOverview } from './SupplierOverview';
import { VersionsListView } from '../VersionsListView';
import { ControlledOptions } from './ControlledOptions';
import { useMatrixVersions } from './useMatrixVersion';
import { MissingSuppliers } from '../MissingSuppliers';

export function MatrixVersions({
  variantId,
  articleNodeGroup,
}: {
  variantId: number;
  articleNodeGroup: ArticleNodeGroup;
}) {
  const { data: suppliers, refetch: refetchSuppliers } = useApiResource(
    variantsApi.getVariantBranchSuppliers,
  );

  useEffect(() => {
    refetchSuppliers(variantId, false);
  }, [refetchSuppliers, variantId]);

  const { t } = useTranslation();
  const { data, loading } = useMatrixVersions(variantId);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <DragAndDrop dndProps={{ autoScroll: false }}>
      <>
        <Tabs variant="line" isLazy={true} ref={ref}>
          <TabList>
            {suppliers?.branches.map((x) => {
              return (
                <Tab key={x.id}>
                  <Flex flexDir="column" align="start">
                    <Text variant="tab-header">{x.identifier}</Text>
                    <Text variant="sub">{x.name}</Text>
                  </Flex>
                </Tab>
              );
            })}
          </TabList>

          <TabPanels>
            {suppliers?.branches.map((x) => {
              const branchSuppliers = suppliers.suppliers.filter(
                (s) => s.branchId === x.id,
              );
              return (
                <TabPanel key={x.id}>
                  {branchSuppliers.length === 0 ? (
                    <MissingSuppliers />
                  ) : (
                    <>
                      {loading && <Spinner />}
                      {data && (
                        <BranchVersionsOverview
                          totalCellsWithSuppliers={
                            articleNodeGroup.nodeXValues.length *
                            articleNodeGroup.nodeYValues.length *
                            branchSuppliers.length
                          }
                          totalCells={
                            articleNodeGroup.nodeXValues.length *
                            articleNodeGroup.nodeYValues.length
                          }
                          branch={data.find((d) => d.branchId === x.id)}
                        />
                      )}
                      <Tabs variant="supplier-versions" pt="12" isLazy={true}>
                        <TabList gap={2}>
                          <Tab>
                            <Text fontWeight="bold">{t('suppliers.all')}</Text>
                          </Tab>
                          {branchSuppliers.map((x) => {
                            const suppliersCells = data
                              ?.find((d) => d.branchId === x.branchId)
                              ?.suppliers.find(
                                (s) => s.supplierId === x.supplierId,
                              );
                            const nrOfCells = suppliersCells?.cells.length ?? 0;
                            return (
                              <Tab
                                key={x.supplierId}
                                borderRadius="md"
                                padding={2}
                                textAlign="start"
                              >
                                <Box minW="200px">
                                  <Text fontWeight="bold">
                                    {x.supplierName}
                                  </Text>
                                  {nrOfCells === 0 ? (
                                    <Text
                                      fontSize="sm"
                                      color="yellow.400"
                                      _light={{ color: 'yellow.500' }}
                                    >
                                      {t('compositionGroup.version.noActive')}
                                    </Text>
                                  ) : (
                                    <Text fontSize="sm">{`${nrOfCells}/${nodeGroupTotal(
                                      articleNodeGroup,
                                    )} cells`}</Text>
                                  )}
                                  <Box pt={2}>
                                    <SupplierOverview
                                      supplier={suppliersCells}
                                    />
                                  </Box>
                                </Box>
                              </Tab>
                            );
                          })}
                        </TabList>
                        <TabPanels>
                          <TabPanel>
                            <Box animation={fadeInLeftAnimation()}>
                              <Heading fontSize="4xl" pt={2}>
                                {t(
                                  'compositionGroup.version.allSupplierVersions',
                                )}
                              </Heading>
                              <Box pb={6} pt={3}>
                                <ControlledOptions
                                  branchId={x.id}
                                  totalCells={nodeGroupTotal(articleNodeGroup)}
                                  variantId={variantId}
                                  cells={data
                                    ?.find((d) => d.branchId === x.id)
                                    ?.suppliers.flatMap((s) => s.cells)}
                                />
                              </Box>
                              <VersionsListView
                                variantId={variantId}
                                branchId={x.id}
                                articleNodes={articleNodeGroup}
                              />
                            </Box>
                          </TabPanel>
                          {branchSuppliers.map((x) => {
                            const suppliersCells = data
                              ?.find((d) => d.branchId === x.branchId)
                              ?.suppliers.find(
                                (s) => s.supplierId === x.supplierId,
                              );
                            return (
                              <TabPanel key={x.supplierId}>
                                <Divider />
                                <Box animation={fadeInLeftAnimation()}>
                                  <Heading fontSize="4xl" pt={2}>
                                    {x.supplierName}
                                  </Heading>
                                  <SupplierOverview supplier={suppliersCells} />
                                  <Box pb={6} pt={3}>
                                    <ControlledOptions
                                      supplierId={x.supplierId}
                                      branchId={x.branchId}
                                      totalCells={nodeGroupTotal(
                                        articleNodeGroup,
                                      )}
                                      cells={suppliersCells?.cells}
                                      variantId={variantId}
                                    />
                                  </Box>
                                  <VersionsListView
                                    variantId={variantId}
                                    branchId={x.branchId}
                                    supplierId={x.supplierId}
                                    articleNodes={articleNodeGroup}
                                  />
                                </Box>
                              </TabPanel>
                            );
                          })}
                        </TabPanels>
                      </Tabs>
                    </>
                  )}
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </>
    </DragAndDrop>
  );
}
