// @ngInject
const BranchesApiService = (ResourcesService) => ({
  getAllByOrganizationId: (organizationId) =>
    ResourcesService.getAll('branch', {
      params: { organizationId },
    }),

  getById: (branchId) =>
    ResourcesService.getById('branch', branchId, {
      params: { organizationId: false },
    }),

  updateBranch: (data, branchId) =>
    ResourcesService.update('branch', branchId, data, {
      params: { organizationId: false },
      updatedFields: [
        'name',
        'displayOrder',
        'defaultCurrencyId',
        'defaultVatCodeId',
      ],
    }),
});

angular
  .module('resources.branches', [])
  .factory('BranchesApiService', BranchesApiService);
