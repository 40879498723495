import { Flex, IconButton } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { ContactListItem } from '@texas/api/endpoints/contactsApi';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { TFunction } from 'i18next';
import { Mode } from './ContactsModal';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';

export const modalContactsTableColumns = (
  t: TFunction,
  referenceId: number,
  mode: Mode,
  onSelect: (c: ContactListItem) => void,
  selectedIds?: number[],
) => {
  const columnHelper = createColumnHelper<ContactListItem>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Flex gap={2}>
          {mode === 'select' &&
            selectedIds?.some((c) => c === info.row.original.id) && (
              <Icons.CheckCircle
                animation={fadeInScaleAnimation()}
                boxSize={defaultIconSize}
              />
            )}
          {info.getValue()}
        </Flex>
      ),
      header: t('general.name'),
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: t('general.email'),
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {mode === 'select' ? (
              <>
                {selectedIds?.some((c) => c === info.row.original.id) ? (
                  <IconButton
                    variant="ghost"
                    aria-label="Select"
                    size="sm"
                    onClick={() => onSelect(info.row.original)}
                    icon={<Icons.Minus boxSize={defaultIconSize} />}
                  />
                ) : (
                  <IconButton
                    aria-label="Select"
                    size="sm"
                    onClick={() => onSelect(info.row.original)}
                    icon={<Icons.Plus boxSize={defaultIconSize} />}
                  />
                )}
              </>
            ) : (
              <>
                {!info.row.original.customerIds.find(
                  (c) => c === referenceId,
                ) && (
                  <IconButton
                    variant="texas-light"
                    size="sm"
                    textAlign="center"
                    onClick={() => onSelect(info.row.original)}
                    icon={<Icons.Plus boxSize={defaultIconSize} />}
                    aria-label="Add"
                  />
                )}
                {info.row.original.customerIds.find(
                  (c) => c === referenceId,
                ) && <Icons.Checkmark boxSize={defaultIconSize} />}
              </>
            )}
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
