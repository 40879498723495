import { formatBytes } from '@texas/utils/helpers/filesHelper';
import { TFunction } from 'i18next';
import { Accept, ErrorCode, FileError } from 'react-dropzone';

export const maxSize = 20000000;

export function sizeValidator(file: File, t: TFunction) {
  if (file.size > maxSize) {
    return {
      code: ErrorCode.FileTooLarge,
      message: t('fileBrowser.fileTooLarge', { limit: formatBytes(maxSize) }),
    };
  }

  return null;
}

export function notImageValidator(file: File, t: TFunction) {
  if (file.type.includes('image/')) {
    return {
      code: ErrorCode.FileInvalidType,
      message: t('fileBrowser.canNotBeImage'),
    };
  }

  return null;
}

export interface PasteResponse {
  success: boolean;
  errors?: string[];
}

export async function handlePaste(
  data: DataTransfer,
  t: TFunction,
  onUpload: (files: File[]) => void,
  multiple: boolean,
  accept?: Accept,
  validator?: (file: File) => FileError | readonly FileError[] | null,
): Promise<PasteResponse | null> {
  if (data.items.length == 0) return null;

  if (!multiple && data.items.length > 1)
    return { success: false, errors: [t('fileBrowser.tooManyFiles')] };

  const fileErrors: string[] = [];
  const files: File[] = [];

  await Promise.allSettled(
    Array.from(data.items).map(async (item) => {
      const blob = item.getAsFile();

      if (blob === null) {
        return;
      }

      const result = await readFile(blob, t, accept, validator);

      if (result.error) fileErrors.push(result.error);
      else files.push(result.file);
    }),
  );

  onUpload(files);

  const pasteResponse: PasteResponse = {
    success: fileErrors.length > 0 ? false : true,
    errors: fileErrors,
  };

  return pasteResponse;
}

async function readFile(
  blob: File,
  t: TFunction,
  accept?: Accept,
  validator?: (file: File) => FileError | readonly FileError[] | null,
): Promise<{ file: File; error?: string }> {
  if (!validFileType(blob.type, accept)) {
    return {
      file: blob,
      error: t('fileBrowser.invalidFileType', { name: blob.name }),
    };
  }

  const buffer = await blob.arrayBuffer();

  const file = new File([buffer], blob.name, {
    type: blob.type,
  });

  if (validator) {
    const errors = validator(file);

    if (Array.isArray(errors)) {
      return {
        file: file,
        error: `${blob.name}: ${errors.map((e) => e.message).join(', ')}`,
      };
    }
    if (errors && 'message' in errors) {
      return { file: file, error: `${blob.name}: ${errors.message}` };
    }
  }

  return { file: file };
}

function validFileType(type: string, accept?: Accept) {
  if (!accept) return true;
  return Object.keys(accept).includes(type);
}

export async function base64toFile(base64: string, filename: string) {
  const res: Response = await fetch(base64);
  const blob: Blob = await res.blob();

  const file = new File([blob], filename, {
    type: blob.type,
  });

  return file;
}
