import {
  GridItem,
  Card,
  CardBody,
  Flex,
  Box,
  Image,
  Tag,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Tooltip,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { clientEndpoints } from '@texas/clientEndpoints';
import { Locale } from '@texas/i18n/types';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import {
  isSupportedImage,
  getExtension,
  formatBytes,
} from '@texas/utils/helpers/filesHelper';
import { ExpandImage } from '../ExpandImage';
import { Icons } from '../Icons';
import { FileLink, FileTagType } from '@texas/api/endpoints/filesApi';
import { FileBrowserMode } from './FileBrowserModal';
import { RefObject } from 'react';
import placeholderImg from '@texas/assets/placeholder-250.png';
import { useTranslation } from 'react-i18next';
import { FileTagPopovers } from './FileTagPopovers';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';

export function FileCard({
  file,
  filteredFileIds,
  typeFilter,
  mode,
  onSelect,
  onEditFileOpen,
  onArchiveOpen,
  onRestoreOpen,
  containerRef,
}: {
  file: FileLink;
  filteredFileIds?: Record<number, number>;
  typeFilter: FileTagType[];
  mode: FileBrowserMode;
  onSelect?: (image: FileLink) => void;
  onEditFileOpen?: (image: FileLink) => void;
  onArchiveOpen?: (image: FileLink) => void;
  onRestoreOpen?: (image: FileLink) => void;
  containerRef: RefObject<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  const display =
    filteredFileIds?.[file.id] !== undefined &&
    (typeFilter.length === 0 ||
      (typeFilter.length > 0 &&
        file.tags !== null &&
        file.tags.filter((t) =>
          typeFilter.includes(convertToEnum(FileTagType, t.type)),
        ).length > 0));

  return (
    <GridItem role="group" display={display ? 'block' : 'none'}>
      <Card
        animation={fadeInScaleAnimation()}
        height="100%"
        variant="hover-shadow"
        cursor={mode === 'Edit' ? 'default' : 'pointer'}
        onClick={() => {
          if (onSelect) onSelect(file);
        }}
      >
        <CardBody role="group" p={4}>
          <Flex gap={2} position="relative">
            {file.tags && file.tags.length > 0 && (
              <Box position="absolute" right={0} top={-7}>
                <FileTagPopovers
                  fileTags={file.tags}
                  containerRef={containerRef}
                />
              </Box>
            )}
            {isSupportedImage(file.extension) && (
              <Box minW="128px" boxSize="128px">
                <ExpandImage identifier={file.identifier} variant="scale">
                  <Image
                    src={
                      file.identifier
                        ? clientEndpoints.previewImage(file.identifier, 128)
                        : undefined
                    }
                    objectFit="contain"
                    borderRadius={2}
                    loading="lazy"
                    onError={(e) => {
                      e.currentTarget.src = placeholderImg;
                    }}
                    maxW="128px"
                  />
                </ExpandImage>
              </Box>
            )}

            <Flex gap={1} direction="column" flexGrow={1}>
              <Text fontWeight="medium" wordBreak="break-word">
                {file.name}
              </Text>
              <Flex gap={2} alignItems="center">
                <Tag
                  size="sm"
                  textTransform="uppercase"
                  h="fit-content"
                  w="fit-content"
                  variant="lighter"
                >
                  {getExtension(file.extension)}
                </Tag>
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Icons.InformationOutline
                      boxSize={5}
                      color="gray.300"
                      _light={{ color: 'gray.500' }}
                    />
                  </PopoverTrigger>
                  <Portal containerRef={containerRef}>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody>
                        <Flex gap={1} alignItems="center">
                          <Text variant="sub">
                            {t('fileBrowser.filesInfo.size')}
                          </Text>
                          <Text>{formatBytes(file.size)}</Text>
                        </Flex>
                        <Flex gap={1} alignItems="center">
                          <Text variant="sub">
                            {t('fileBrowser.filesInfo.uploaded')}
                          </Text>
                          <Text>{formatDate(Locale.En, file.created)}</Text>
                        </Flex>
                        <Flex gap={1} alignItems="center">
                          <Text variant="sub">
                            {t('fileBrowser.filesInfo.lastEdited')}
                          </Text>
                          <Text>{formatDate(Locale.En, file.updated)}</Text>
                        </Flex>
                        {file.note && (
                          <Flex gap={1} alignItems="baseline">
                            <Text variant="sub">
                              {t('fileBrowser.filesInfo.note')}
                            </Text>
                            <Text>{file.note}</Text>
                          </Flex>
                        )}
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </Flex>
              <Text variant="sub">{file.creatorName}</Text>
            </Flex>
            {mode === 'Edit' && (
              <Flex direction="column" opacity={0} _groupHover={{ opacity: 1 }}>
                {!file.archived && onEditFileOpen && (
                  <Tooltip label={t('general.edit')}>
                    <IconButton
                      icon={<Icons.Pencil boxSize={5} />}
                      aria-label={t('general.edit')}
                      size="sm"
                      variant="no-bg"
                      onClick={() => onEditFileOpen(file)}
                      justifyContent="flex-end"
                    />
                  </Tooltip>
                )}
                <Tooltip label={t('fileBrowser.downloadFile')}>
                  <IconButton
                    icon={<Icons.Download boxSize={5} />}
                    aria-label={t('fileBrowser.downloadFile')}
                    size="sm"
                    variant="no-bg"
                    as="a"
                    href={clientEndpoints.downloadFile(file.identifier)}
                    target="_blank"
                    download={true}
                    justifyContent="flex-end"
                  />
                </Tooltip>
                {!file.archived && onArchiveOpen && (
                  <Tooltip label={t('general.archive')}>
                    <IconButton
                      icon={<Icons.Archive boxSize={5} />}
                      aria-label={t('general.archive')}
                      size="sm"
                      variant="no-bg"
                      onClick={() => onArchiveOpen(file)}
                      justifyContent="flex-end"
                    />
                  </Tooltip>
                )}
                {file.archived && onRestoreOpen && (
                  <Tooltip label={t('general.restore')}>
                    <IconButton
                      icon={<Icons.Undo boxSize={5} />}
                      aria-label={t('general.restore')}
                      size="sm"
                      variant="no-bg"
                      onClick={() => onRestoreOpen(file)}
                      justifyContent="flex-end"
                    />
                  </Tooltip>
                )}
              </Flex>
            )}
          </Flex>
        </CardBody>
      </Card>
    </GridItem>
  );
}
