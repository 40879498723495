import { Flex, Heading, Text } from '@chakra-ui/react';
import { defaultIconSize, Icons } from './Icons';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';

export function UserAvatar({ name, email }: { name: string; email?: string }) {
  return (
    <Flex
      padding={2}
      align="center"
      gap={2}
      bg="texas.secondary.700"
      borderRadius="md"
      _light={{
        bg: 'gray.50',
      }}
      animation={fadeInScaleAnimation()}
    >
      <Icons.AccountCircle boxSize={defaultIconSize} />
      <Flex flexDir="column" align="start">
        <Heading fontSize="md">{name}</Heading>
        {email && (
          <Text fontSize="sm" variant="sub">
            {email}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
