import { Flex, Button, Text } from '@chakra-ui/react';
import { SupplierListItem } from '@texas/api/endpoints/suppliersApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChooseContactsModal } from '../ChooseContactsModal';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';

export function SuppliersList({
  variantId,
  suppliers,
  onClick,
}: {
  variantId: number;
  suppliers: SupplierListItem[];
  onClick: (supplierId: number, contactIds: number[]) => Promise<void>;
}) {
  const { value, isOpen, onClose, onOpen } = useValueDisclosure<number>();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Flex flexDir="column" gap={2} p={2}>
      {suppliers.map((x) => (
        <SupplierListItemComponent
          supplier={x}
          key={x.id}
          onClick={() => onOpen(x.id)}
          isAdding={isAdding}
        />
      ))}
      {value && (
        <ChooseContactsModal
          acceptLabel={t('suppliers.addSupplier')}
          variantId={variantId}
          supplierId={value}
          onContactsSelect={async (ids) => {
            onClose();
            setIsAdding(true);
            await onClick(value, ids);
            setIsAdding(false);
          }}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Flex>
  );
}

function SupplierListItemComponent({
  supplier,
  isAdding,
  onClick,
}: {
  supplier: SupplierListItem;
  isAdding: boolean;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Button
      isLoading={isAdding}
      loadingText={t('general.adding')}
      height="auto"
      py={2}
      isDisabled={supplier.alreadyAdded}
      onClick={onClick}
      alignItems="start"
      variant="solid"
      display="flex"
      borderRadius="md"
      flexDir="row"
      {...(supplier.alreadyAdded
        ? {
            borderLeft: '6px solid',
            borderLeftColor: 'texas.sand.100',
            _light: { borderLeftColor: 'texas.burntSienna.500' },
          }
        : null)}
    >
      <Flex
        flexGrow={1}
        direction="column"
        align="start"
        textAlign="start"
        whiteSpace="normal"
      >
        <Text variant="main">{supplier.name}</Text>
        <Flex gap={2}>
          {supplier.activeArticles > 0 && (
            <Text variant="small">
              {t('suppliers.activeArticles', {
                count: supplier.activeArticles,
              })}
            </Text>
          )}

          {supplier.alreadyAdded && (
            <Text
              variant="small"
              color="texas.sand.100"
              _light={{ color: 'texas.burntSienna.500' }}
            >
              {t('general.added')}
            </Text>
          )}
        </Flex>
      </Flex>
    </Button>
  );
}
