import { PagedItems } from '@texas/types';
import { ExportArticleItem, SearchFilter } from './searchApi';
import { ProductApprovalState } from '../productApprovalsApi';

export const defaultEmptyFilters: FilterOptionIds = {
  branchIds: [],
  categoryCodeIds: [],
  customerIds: [],
  conceptIds: [],
  supplierIds: [],
  productGroupIds: [],
  paStates: [],
};

export interface SearchBase {
  exportArticleItems: ExportArticleItem[];
  branchFilters: SearchFilter[];
  customerFilters: SearchFilter[];
  conceptFilters: SearchFilter[];
  supplierFilters: SearchFilter[];
  productGroupFilters: SearchFilter[];
  categoryCodeFilters: SearchFilter[];
  filterOptionIds: FilterOptionIds;
}

export interface SearchPaCountResponse extends SearchBase {
  totalCount: number;
}

export interface SearchPaResponse extends SearchBase {
  pagedItems: PagedItems<ProductApprovalItem>;
}

export interface FilterOptionIds {
  conceptIds?: number[];
  categoryCodeIds?: number[];
  customerIds?: number[];
  branchIds?: number[];
  supplierIds?: number[];
  productGroupIds?: number[];
  paStates?: number[];
}

export interface ProductApprovalItem {
  productApprovalId: number;
  articleId: number;
  variantId: number;
  itemCode: string;
  name: string;
  variantName: string;
  customerId: number;
  customerIdentifier: string;
  customerName: string;
  concept: string;
  conceptId: number;
  branch: string;
  branchId: number;
  productGroup: string;
  supplierName: string;
  formatNumber: string;
  state: ProductApprovalState;
  matrixNodeXValue: string | null;
  matrixNodeYValue: string | null;
  created: string;
}

export interface PaSearchQuery extends FilterOptionIds {
  searchTerm: string;
  sortBy: string;
  sortDesc: boolean;
  page: number;
  showAllVariants?: boolean;
  includeArchived?: boolean;
  showMyBrands?: boolean;
}

export interface PaSearchQueryRequest extends PaSearchQuery {
  limit: number;
  optOutDefaultOrdering: boolean;
}
