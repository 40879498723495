import { SignalDispatcher } from 'ste-signals';
import { SimpleEventDispatcher } from 'ste-simple-events';

export const compositionEvents = {
  onRename: new SignalDispatcher(),
  onAdd: new SignalDispatcher(),
  onUpdated: new SignalDispatcher(),
  onCharacterSet: new SignalDispatcher(),
  onMaterialAdded: new SignalDispatcher(),
  onMaterialRemoved: new SignalDispatcher(),
  onMaterialChanged: new SignalDispatcher(),
  onDimensionGroupSet: new SignalDispatcher(),
  onDimensionValueChanged: new SimpleEventDispatcher<{ groupId: number }>(),
  onArchived: new SignalDispatcher(),
  onRestored: new SignalDispatcher(),
  fileUpdated: new SignalDispatcher(),
};
