export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function isSupportedImage(extension: string) {
  return imageExtensions.includes(extension.toLowerCase());
}

export function getExtension(extension: string) {
  return extension.replace('.', '');
}

export const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
export const imageMimeTypes = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
};

export const acceptedImageFormats = {
  'image/jpeg': [],
  'image/png': [],
  'image/gif': [],
};

export function saveBlobAsFile(data: Blob, fileName: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);

  a.style.display = 'none';
  const url = window.URL.createObjectURL(data);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function excelExportFileName() {
  return `Article-export-${new Date().toISOString().substring(0, 10)}.xlsx`;
}
